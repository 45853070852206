import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

class PartnersPage extends Component {
  constructor(props) {
    super(props);
    this.registerClick = this.registerClick.bind(this);
    this.state = {
      activeOption: 0,
    };
  }
  registerClick(e) {
    e.preventDefault();
    document.getElementById("sign-up").click();
  }
  render() {
    const { activeOption } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Ideas Community - Partners</title>
        </Helmet>
        <div className="partners-page">
          <div
            className="partners-small-splash-header jobs-header"
            style={{ backgroundImage: "url(/images/redfruits.jpg)" }}
          >
            <h1>Proud Partners of Teuko</h1>
            <h2>Bring Love and Support to Families who Pack Lunches</h2>
          </div>
          <div className="partners-top">
            <div className="partners-content">
              <div className="partners-content-options">
                <h3
                  className={
                    activeOption === 0 ? "lunch-content-option-active" : ""
                  }
                  onClick={() => this.setState({ activeOption: 0 })}
                >
                  Brands
                </h3>
                <h3
                  className={
                    activeOption === 1 ? "lunch-content-option-active" : ""
                  }
                  onClick={() => this.setState({ activeOption: 1 })}
                >
                  Schools
                </h3>
              </div>
              <div>
                <div className="partners-main-info">
                  {activeOption === 0 ? (
                    <div>
                      <div className="simple-fun-section">
                        <h2>
                          Partner with us, tell your brand's story on Teuko
                        </h2>
                        <div className="standard-container">
                          <div className="lunch-and-bullets">
                            <img
                              src="/images/families_and_lunches.jpg"
                              className="lunch-image"
                              alt="Teuko lunchbox community devices"
                            />
                            <div className="features-partners-list">
                              <div className="feature-item">
                                <img
                                  src="/icons/community.jpg"
                                  alt="engaged audience"
                                />
                                <div className="feature-text">
                                  <p className="feature-title">
                                    Engaged Audience
                                  </p>
                                  <p>
                                    Our audience uses Teuko to enjoy their lunch
                                    packing experience - to be inspired, to
                                    explore, to be efficient, to be motivated
                                    and everything in between. Teuko's global
                                    (and growing) scale allows your brand to
                                    reach an amazing community.
                                  </p>
                                </div>
                              </div>
                              <div className="feature-item">
                                <img
                                  src="/icons/insights.jpg"
                                  alt="unique insights"
                                />
                                <div className="feature-text">
                                  <p className="feature-title">
                                    Unique Insights
                                  </p>
                                  <p>
                                    Bring your brand's story, and capture the
                                    attention of our community with our
                                    best-in-class data-driven platform and
                                    advanced targeting capabilities.
                                  </p>
                                </div>
                              </div>
                              <div className="feature-item">
                                <img
                                  src="/icons/formats.jpg"
                                  alt="community empowerement"
                                />
                                <div className="feature-text">
                                  <p className="feature-title">
                                    Impactful Ad Formats
                                  </p>
                                  <p>
                                    Fuel your campaigns and drive impact across
                                    devices and platforms with engaging ad
                                    formats: newsletters, blog posts, social
                                    media posts, in-app features, and more!
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="featured-in">
                        <div className="standard-container partners-logos-options">
                          {/* <a target="_blank" rel="noopener noreferrer" href="https://amzn.to/2I7wYuy"><img alt="" src="/images/amazonaffiliate.jpg" /></a> */}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.playosmo.com/?utm_source=teuko"
                          >
                            <img alt="" src="/images/advertise_osmo.png" />
                          </a>
                          
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.gogosqueez.com/?utm_source=teuko"
                          >
                            <img alt="" src="/images/advertise_gogosqueez.png" />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://bankonseeds.com/?utm_source=teuko"
                          >
                            <img alt="" src="/images/advertise_bankonseeds.png" />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://bakerly.sjv.io/BjbVJ"
                          >
                            <img alt="" src="/images/advertise_bakerly.png" />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.thepinwheelers.com/?utm_source=teuko"
                          >
                            <img alt="" src="/images/thepinwheelers.jpg" />
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://nuturelife.pxf.io/c/2401127/1038701/13474"
                          >
                            <img alt="" src="/images/advertise_nurturelife.png" />
                          </a>
                        </div>
                      </div>
                      <div className="partners-contact-section">
                        <h1>Advertise with us</h1>
                        <a className="partners-button-premium" href="/contact">
                          Get in touch
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeOption === 1 ? (
                    <div>
                      <div className="simple-fun-section">
                        <h2>
                          Outfit your school for today’s success and tomorrow's
                          prestige
                        </h2>
                        <div className="standard-container">
                          <div className="lunch-and-bullets">
                            <img
                              src="/images/kidschool.jpg"
                              className="lunch-image"
                              alt="Teuko lunchbox community devices"
                            />
                            <div className="features-partners-list">
                              <div className="feature-item">
                                <img
                                  src="/icons/socialinnovation.png"
                                  alt="social innovation"
                                />
                                <div className="feature-text">
                                  <p className="feature-title">
                                    Social Innovation
                                  </p>
                                  <p>
                                    At Teuko, we believe that various foods
                                    bring fantastic culinary experiences. By
                                    anonymously sharing photos of your students'
                                    lunch boxes on Teuko, and on a regular
                                    basis, your school can celebrate the
                                    diversity of your community through food.
                                  </p>
                                </div>
                              </div>
                              <div className="feature-item">
                                <img
                                  src="/icons/wellnesspromotion.png"
                                  alt="wellness promotion"
                                />
                                <div className="feature-text">
                                  <p className="feature-title">
                                    Wellness Promotion
                                  </p>
                                  <p>
                                    Healthy eating helps achieve academic
                                    success. With Teuko, you help the families
                                    of your community fight against diet-related
                                    diseases and obesity, and, most and
                                    foremost, have the kids thrive, one lunchbox
                                    at a time.
                                  </p>
                                </div>
                              </div>
                              <div className="feature-item">
                                <img
                                  src="/icons/communityempowerement.png"
                                  alt="community empowerement"
                                />
                                <div className="feature-text">
                                  <p className="feature-title">
                                    Community Empowerment
                                  </p>
                                  <p>
                                    Teuko, it’s about being together thanks to
                                    food. It builds a bridge between your school
                                    and parents around lunch time. It also helps
                                    parents get in one place food inspiration to
                                    pack healthy lunch boxes.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-partners-school">
                        <div className="testimonial-partners-school-inner">
                          <h4>
                            "The Week of Taste is an annual event that our
                            school has been very proud to host for many years
                            now. Collaborating with Teuko this year has been a
                            terrific opportunity for us to involve more
                            families, bringing our workshops in our students'
                            homes. This platform allowed our proud students to
                            share their work with their classmates, their
                            families, and beyond!"
                          </h4>
                          <div className="testimonial-partners-school-name">
                            <h5>
                              <a
                                target="_blank"
                                href="https://www.lelycee.org/about/news/news-post/~board/lfsf-3-campuses-activities/post/packing-lunch-no-problem?utm_source=teuko"
                                rel="noopener noreferrer"
                              >
                                Patrice Possenti • Director of Lycée Français de
                                San Francisco, 2019
                              </a>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="partners-contact-section">
                        <h1>Empower your school with us</h1>
                        <a className="partners-button-premium" href="/contact">
                          Get in touch
                        </a>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PartnersPage;
