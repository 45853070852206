import React, { Component } from "react";
import { browserHistory } from "react-router";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { map, assignIn, has } from "lodash";
import { createClient } from "contentful";
import InfiniteScroll from "react-infinite-scroller";
import { Helmet } from "react-helmet";
import BrowseLunchThumb from "../../components/lunches/BrowseLunchThumb";
import BrowseLunchThumbGrid from "../../components/lunches/BrowseLunchThumbGrid";
import MailingListPopup from "../../components/general/MailingListPopup";
import FaGrid from "../../fa/grid";
import FaImage from "../../fa/image";

class Browse extends Component {
  constructor(props) {
    super(props);
    this.switchFilter = this.switchFilter.bind(this);
    this.state = {
      filter: "recent",
      adFilters: [],
      open: false,
      fetching: false,
      activeOption: 0,
    };
  }
  componentDidMount() {
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
    })(document, "script", "twitter-wjs");
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "566204480216246",
        status: true,
        xfbml: true,
        cookie: true,
        version: "v18.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }
  switchFilter(e) {
    const { filter } = e.currentTarget.dataset;
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        filter,
      },
    });
  }
  UNSAFE_componentWillMount() {
    const self = this;
    const client = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });
    client
      .getEntries({
        content_type: "collections",
        "fields.active": true,
        include: 2,
      })
      .then((entries) => {
        self.setState({ adFilters: entries.items });
      });
  }

  /* eslint jsx-a11y/anchor-is-valid: 0 */
  render() {
    const {
      browseLunches,
      loading,
      error,
      location,
      user,
      loadMore,
    } = this.props;
    const { adFilters, fetching } = this.state;
    const filter =
      location.query && location.query.filter
        ? location.query.filter
        : "recent";
    const Lunches = map(browseLunches, (l) => (
      <BrowseLunchThumb
        lunch={l}
        key={l.id}
        location={location}
        loggedIn={Boolean(user.id)}
      />
    ));
    const LunchesGrid = map(browseLunches, (l) => (
      <BrowseLunchThumbGrid
        lunch={l}
        key={l.id}
        location={location}
        loggedIn={Boolean(user.id)}
      />
    ));
    let AdditionalFilters = map(adFilters, (f) => (
      <h5
        key={f.sys.id}
        className={filter === f.fields.title ? "active-filter" : ""}
        onClick={this.switchFilter}
        data-filter={f.fields.title}
      >
        {f.fields.title}
      </h5>
    ));
    AdditionalFilters.push(
      <h5
        key="reco_similar_kid"
        className={filter === "reco_similar_kid" ? "active-filter" : ""}
        onClick={this.switchFilter}
        data-filter="reco_similar_kid"
      >
        Similar Kids
      </h5>
    );
    AdditionalFilters.push(
      <h5
        key="reco_similar_taste"
        className={filter === "reco_similar_taste" ? "active-filter" : ""}
        onClick={this.switchFilter}
        data-filter="reco_similar_taste"
      >
        Similar Taste
      </h5>
    );
    /* let banner = "";
    if (user.country === "US") {
      banner =
        <div className="contest-announcement">
            <h3 className="contest-title">
              Win $100 Brandless Store Credit!
            </h3>
            <p className="contest-text">
              Upload a lunchbox idea before Friday 5/24 11:59PM PST to enter the contest (see <a className="contest-rules" target="blank" href="https://www.teuko.com/faq">complete official rules</a>)
            </p>
        </div>;
    } */

    let title = "Lunchbox Ideas: ";
    switch (filter) {
      case "recent":
        title += "New";
        break;
      case "friends":
        title += "Friends";
        break;
      case "reco_similar_kid":
        title += "Similar Kids";
        break;
      case "reco_similar_taste":
        title += "Similar Taste";
        break;
      case "liked":
        title += "What you liked";
        break;
      case "oneyearago":
        title += "One year ago";
        break;

      default:
        title += filter;
    }
    const { activeOption } = this.state;

    return (
      <div className="browse-page">
        <Helmet>
          <title>Teuko Lunchbox Community - Browse Lunch Ideas</title>
        </Helmet>
        {/*  { banner } */}
        <div className="browse-page-header">
          <div className="title-browse">
            <a
              href="#"
              onClick={browserHistory.goBack}
              className="shoppinglist-print-back"
            >
              Back
            </a>
            <h1>{title}</h1>
          </div>

          {/* <div className="my-lunches-filters">
            <h5 className={filter === 'recent' ? 'active-filter' : ''} onClick={this.switchFilter} data-filter="recent">New</h5>
            {user.id ? <h5 className={filter === 'friends' ? 'active-filter' : ''} onClick={this.switchFilter} data-filter="friends">Friends</h5> : '' }
            <h5 className={filter === 'School Menus' ? 'active-filter' : ''} onClick={this.switchFilter} data-filter="School Menus">School Menus</h5>
            <div className="more-option" onClick={() => this.setState({ open: !open })}>
              <h5>More { open ? <FaAngleUp /> : <FaAngleDown /> }</h5>
              {open ? <div className="more-dropdown">{AdditionalFilters}</div> : '' }
            </div>
          </div> */}

          <div className="profile-content-options-display-smartphone">
            <h3
              className={
                activeOption === 0 ? "lunch-content-option-active" : ""
              }
              onClick={() => this.setState({ activeOption: 0 })}
            >
              <FaGrid />
            </h3>
            <h3
              className={
                activeOption === 1 ? "lunch-content-option-active" : ""
              }
              onClick={() => this.setState({ activeOption: 1 })}
            >
              <FaImage />
            </h3>
          </div>

        </div>

        <div className="in-app-container">
          {user.id !== 0 ? (
            ""
          ) : (
            <MailingListPopup delayInMilliseconds={15000} />
          )}

          {filter === "friends" && !Lunches.length ? (
            <p className="follow-friends">
              Start following Teuko Friends to build your own community of
              friends. The more Friends, the better inspiration!
            </p>
          ) : (
            ""
          )}

        <div className="lunch-thumb-group-display-only-smartphone">
          <div className="partners-main-info">
              {activeOption === 0 ? (

                <InfiniteScroll
                pageStart={0}
                className="profile-lunches lunch-grid-group-profile"
                loadMore={() => {
                  if (!fetching && has(this.props, "loadMore")) loadMore();
                }}
                hasMore={!loading}
                >
                {loading || error ? "" : LunchesGrid}
                </InfiniteScroll>

              ) : (
                ""
              )}
              {activeOption === 1 ? (

                <InfiniteScroll
                pageStart={0}
                className="lunch-thumb-group"
                loadMore={() => {
                  if (!fetching && has(this.props, "loadMore")) loadMore();
                }}
                hasMore={!loading}
                >
                {loading || error ? "" : Lunches}
                </InfiniteScroll>

              ) : (
                ""
              )}
          </div>
        </div>


        <div className="lunch-thumb-group-display-only-laptop">
          <InfiniteScroll
            pageStart={0}
            className="lunch-thumb-group"
            loadMore={() => {
              if (!fetching && has(this.props, "loadMore")) loadMore();
            }}
            hasMore={!loading}
          >
            {loading || error ? "" : Lunches}
          </InfiniteScroll>
        </div>

        </div>
        {/* {!loading && user.id && !user.onboard_age ? ReactDOM.createPortal(<OnboardAge id={user.id} close={this.close} />, document.getElementById('popup-mount')) : ''} */}
      </div>
    );
  }
}

Browse.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object,
};

const browseLunchQuery = gql`
  query browseLunches($filter: String!, $offset: Int) {
    browseLunches(filter: $filter, offset: $offset) {
      id
      creation_date
      image
      slug
      liked
      saved
      foods {
        id
        name
        veggies
        grains
        protein
        fruits
        dairy
        drink
      }
      user {
        id
        image
        user_name
      }
    }
  }
`;

export default graphql(browseLunchQuery, {
  options: (ownProps) => ({
    variables: {
      filter:
        ownProps.location.query && ownProps.location.query.filter
          ? ownProps.location.query.filter
          : "recent",
    },
    fetchPolicy: "network-only",
  }),
  props({ data: { fetchMore, browseLunches, loading, error, refetch } }) {
    return {
      loading,
      browseLunches,
      error,
      refetch,
      loadMore() {
        return fetchMore({
          variables: {
            offset: loading ? 0 : browseLunches ? browseLunches.length : 0,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }
            return assignIn({}, previousResult, {
              browseLunches: [
                ...previousResult.browseLunches,
                ...fetchMoreResult.browseLunches,
              ],
            });
          },
        });
      },
    };
  },
})(Browse);
