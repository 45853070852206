import React, { Component } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { graphql, compose, withApollo } from "react-apollo";
import { Link } from "react-router";
import { filter, find, map, assignIn } from "lodash";
import ReactDOM from "react-dom";
import InfiniteScroll from "react-infinite-scroller";
import { Helmet } from "react-helmet";
import countries from "../../components/accounts/countries";
import Following from "../../components/profile/Following";
import ShowFollowers from "../../components/profile/ShowFollowers";
import ShowFollowing from "../../components/profile/ShowFollowing";
import ShowFavLunchboxItems from "../../components/profile/ShowFavLunchboxItems";
import { sortFoods } from "../../components/lunches/helpers";
import FaFacebookSquare from "../../fa/facebook-square";
import FaInstagram from "../../fa/instagram";
import FaLink from "../../fa/link";
import FaTwitter from "../../fa/twitter";
import FaTiktok from "../../fa/tiktok";
import FaYoutube from "../../fa/youtube";
import FaPinterestSquare from "../../fa/pinterest-square";
import FaGrid from "../../fa/grid";
import FaImage from "../../fa/image";

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.openLunch = this.openLunch.bind(this);
    this.close = this.close.bind(this);
    this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
    this.state = {
      id: 0,
      activeOption: 0,
    };
  }
  handleInfiniteLoad(e) {
    this.props.loadMore();
  }
  openLunch(e) {
    const { id } = e.currentTarget.dataset;
    this.setState({ id: parseInt(id, 10) });
  }
  close() {
    this.setState({
      id: 0,
      showFollowers: false,
      showFollowing: false,
      showFavLunchboxItems: false,
    });
  }


  async componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677693806242-0');
        googletag.display('div-gpt-ad-1677694132749-0');
      });
    }
  }


  render() {
    const { loading, error, oneProfile, user, getTrophies } = this.props;
    const { showFollowers, showFollowing, showFavLunchboxItems } = this.state;
    if (loading) return <div />;
    if (error) return <div><br/><br/><br/><br/><hr/><h2>ERROR loading user '{this.props.params.user_name}'</h2><hr/></div>

    let userTrophies = [];
    if (getTrophies) {
      userTrophies = map(getTrophies.getTrophies, trophy => {
        return (
          <div>
            <img src={trophy.img_url}
            alt={trophy.description}
            className = "trophies-upload-img-profile"
            width="85px" height="85px"/>
          </div>
        );
      });
      userTrophies = userTrophies.slice(0, 3);
    }
    let trophyCase = "";
    if (userTrophies.length > 0) {
      trophyCase = 
        <div className="profile-trophy-case">
          <div className="profile-name-plus-link">
            <h3>Trophy Case</h3>
          </div>
          <div className="trophies-group-profile">
              <div className="trophies-items-profile">
                { userTrophies }
              </div>
          </div>
          <div className="trophy-case-profile-separator-div">
            <hr className="trophy-case-profile-separator" />
          </div>
          <div className="my-categories-seeall">
            <Link to={`/user/${oneProfile.user_name}/trophies`}>See all > </Link>
          </div>
        </div>
    }

    const {
      user_name,
      country,
      lunches,
      follower_count,
      following_count,
      lunchbox_count,
      image,
      favorite_items,
      facebook_handle,
      instagram_handle,
      twitter_handle,
      pinterest_handle
    } = oneProfile;
    let tiktok_handle = oneProfile.tiktok_handle;
    if (tiktok_handle && !tiktok_handle.startsWith('@')) {
      tiktok_handle = '@' + tiktok_handle;
    }
    let youtube_handle = oneProfile.youtube_handle;
    if (youtube_handle && !youtube_handle.startsWith("http")) {
      youtube_handle = "https://" + youtube_handle;
    }
    let bio_link = oneProfile.bio_link;
    if (bio_link && !bio_link.startsWith("http")) {
      bio_link = "https://" + bio_link;
    }
    const amUser = user.id === oneProfile.id;
    let countryName = "";
    if (country)
      countryName = find(countries.countries.country, { countryCode: country })
        .countryName;
    const Lunches = map(lunches, (l) => {
      const LunchFoods = map(sortFoods(l.foods), (f) => (
        <span key={`pfpg${f.id}`} className="food-hover-name">
          {f.name}
        </span>
      ));
      return (
        <Link
          className="lunch-thumb-for-profile"
          key={l.id}
          data-id={l.id}
          style={{ backgroundImage: `url(${l.image})` }}
          role="link"
          tabIndex="0"
          to={{
            pathname: `/l/${l.slug}`,
            state: { modal: true, returnTo: this.props.location.pathname },
          }}
        >
          <div className="lunch-thumb-hover">{LunchFoods}</div>
        </Link>
      );
    });
    const LunchesGrid = map(lunches, (l) => {
      const LunchFoods = map(sortFoods(l.foods), (f) => (
        <span key={`pfpg${f.id}`} className="food-hover-name">
          {f.name}
        </span>
      ));
      return (
        <Link
          className="lunch-grid-image-for-profile"
          key={l.id}
          data-id={l.id}
          style={{ backgroundImage: `url(${l.image})` }}
          role="link"
          tabIndex="0"
          to={{
            pathname: `/l/${l.slug}`,
            state: { modal: true, returnTo: this.props.location.pathname },
          }}
        >
          <div className="lunch-thumb-hover">{LunchFoods}</div>
        </Link>
      );
    });
    const favoriteItemCount = filter(
      (favorite_items || "").split(","),
      (k) => k !== ""
    ).length;
    let favoriteItems = "0";
    if (favoriteItemCount > 0) {
      favoriteItems = favoriteItemCount;
    }
    const { activeOption } = this.state;

    return (

      <div className="profile-page">
        <Helmet>
          <title>Teuko Lunchbox Community - {user_name}</title>
        </Helmet>


        <div className="in-app-container">
          <div className="profile-header">
            
            <div
              className="profile-image-forlaptop"
              style={{ backgroundImage: image ? `url(${image})` : "" }}
            />

            <div className="profile-details">
              <div className="profile-name-plus-link">

                <div className="profile-image-name-smartphone">
                      <div
                    className="profile-image-forsmartphone"
                    style={{ backgroundImage: image ? `url(${image})` : "" }}
                  />
                  <h2>{user_name}</h2>
                  {oneProfile.id === 2277 ? (
                    <div className="profile-user-verifiedbadge" />
                  ) : (
                    ""
                  )}
                </div>

                <div className="profile-btn-editorfollow-laptop">
                  {amUser ? (
                    <Link
                      to="/settings"
                      className="profile-button edit-profile-button"
                    >
                      Edit Profile
                    </Link>
                  ) : (
                    <Following
                      profileId={oneProfile.id}
                      user_name={oneProfile.user_name}
                      loggedIn={Boolean(user.id)}
                    />
                  )}
                </div>
              </div>

              <div className="profile-stats">
                <div className="profile-stats-counter">
                  <h5>
                    <strong>{lunchbox_count}</strong> lunchboxes 
                  </h5>
                </div>
                
                <div className="profile-stats-counter">
                  <h5 onClick={() => this.setState({ showFollowing: true })}>
                    <span className="favitems-count" id="following-count">
                      {following_count}
                    </span>{" "}
                    following 
                  </h5>
                </div>
                
                <div className="profile-stats-counter">
                  <h5 onClick={() => this.setState({ showFollowers: true })}>
                    <span className="favitems-count" id="follower-count">
                      {follower_count}
                    </span>{" "}
                    followers 
                  </h5>
                </div>
              </div>

              <div className="profile-other-details">
                <h5 className="country-name">{countryName}</h5>
                <h5
                  className="profile-lunchbox"
                  onClick={() => this.setState({ showFavLunchboxItems: true })}
                >
                <span className="favitems-count">{favoriteItems}</span> favorite
                lunchbox items
              </h5>
                <div className="profile-social">
                  {facebook_handle ? <a href={`https://www.facebook.com/${facebook_handle}`} target="#"><div className="profile-social-icon"><FaFacebookSquare /></div></a> : ""}
                  {instagram_handle ? <a href={`https://www.instagram.com/${instagram_handle}`} target="#"><div className="profile-social-icon"><FaInstagram /></div></a> : ""}
                  {twitter_handle ? <a href={`https://www.twitter.com/${twitter_handle}`} target="#"><div className="profile-social-icon"><FaTwitter /></div></a> : ""}
                  {pinterest_handle ? <a href={`https://www.pinterest.com/${pinterest_handle}`} target="#"><div className="profile-social-icon"><FaPinterestSquare /></div></a> : ""}
                  {youtube_handle ? <a href={youtube_handle} target="#"><div className="profile-social-icon"><FaYoutube /></div></a> : ""}
                  {tiktok_handle && tiktok_handle !== '@' ? <a href={`https://www.tiktok.com/${tiktok_handle}`} target="#"><div className="profile-social-icon"><FaTiktok /></div></a> : ""}
                  {bio_link ? <a href={bio_link} target="#"><div className="profile-social-icon"><FaLink /></div></a> : ""}
                </div>
              </div>

              <div className="profile-btn-editorfollow-phones">
                  {amUser ? (
                    <Link
                      to="/settings"
                      className="profile-button edit-profile-button"
                    >
                      Edit Profile
                    </Link>
                  ) : (
                    <Following
                      profileId={oneProfile.id}
                      user_name={oneProfile.user_name}
                      loggedIn={Boolean(user.id)}
                    />
                  )}
                </div>

            </div>

            { trophyCase }

          </div>

          <div className="blog-ad-banner">
            <div className="search-title-header-ad-laptop_leaderboard">
              {/* <!-- /22874318077/Teuko-Profile_Users-Laptop_AdUnit_1_Top_Page-Leaderboard-728x90 --> */}
              <div id='div-gpt-ad-1677693806242-0' style={{minWidth: "728px", minHeight: "90px"}} />
            </div>
            <div className="search-title-header-ad-smartphone_leaderboard">
              {/* <!-- /22874318077/Teuko-Profile_Users-Smartphone_AdUnit_1_Top_Page-Leaderboard-320x50 --> */}
              <div id='div-gpt-ad-1677694132749-0' style={{minWidth: "320px", minHeight: "50px"}} />
            </div>
          </div>

          <div className="profile-content-options-display-smartphone">
            <h3
              className={
                activeOption === 0 ? "lunch-content-option-active" : ""
              }
              onClick={() => this.setState({ activeOption: 0 })}
            >
              <FaGrid />
            </h3>
            <h3
              className={
                activeOption === 1 ? "lunch-content-option-active" : ""
              }
              onClick={() => this.setState({ activeOption: 1 })}
            >
              <FaImage />
            </h3>
          </div>

          <div className="lunch-thumb-group-display-only-smartphone">
            <div className="partners-main-info">
              {activeOption === 0 ? (
                  <InfiniteScroll
                  pageStart={0}
                  className="profile-lunches lunch-grid-group-profile"
                  loadMore={this.handleInfiniteLoad}
                  hasMore
                >
                  { LunchesGrid }
                </InfiniteScroll>
              
              ) : (
                ""
              )}
              {activeOption === 1 ? (
                <InfiniteScroll
                pageStart={0}
                className="profile-lunches lunch-thumb-group"
                loadMore={this.handleInfiniteLoad}
                hasMore
              >
                {Lunches}
              </InfiniteScroll>
              ) : (
                ""
              )}
            </div>
          </div>


          <div className="lunch-thumb-group-display-only-laptop">
            <InfiniteScroll
              pageStart={0}
              className="profile-lunches lunch-thumb-group"
              loadMore={this.handleInfiniteLoad}
              hasMore
            >
              {Lunches}
            </InfiniteScroll>
          </div>



          { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to get more lunchbox ideas you'll love and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}


        </div>

        {showFollowers
          ? ReactDOM.createPortal(
              <ShowFollowers
                profileId={oneProfile.id}
                user_name={oneProfile.user_name}
                close={this.close}
                userId={user.id}
              />,
              document.getElementById("popup-mount")
            )
          : ""}
        {showFollowing
          ? ReactDOM.createPortal(
              <ShowFollowing
                profileId={oneProfile.id}
                close={this.close}
                userId={user.id}
              />,
              document.getElementById("popup-mount")
            )
          : ""}
        {showFavLunchboxItems
          ? ReactDOM.createPortal(
              <ShowFavLunchboxItems
                userId={user.id}
                profileId={oneProfile.id}
                profileName={oneProfile.user_name}
                close={this.close}
              />,
              document.getElementById("popup-mount")
            )
          : ""}
      </div>
    );
  }
}

ProfilePage.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
};

export const profileQuery = gql`
  query oneProfile($user_name: String!, $offset: Int) {
    oneProfile(user_name: $user_name, offset: $offset) {
      id
      user_name
      country
      image
      first_name
      last_name
      follower_count
      following_count
      lunchbox_count
      favorite_items
      facebook_handle
      instagram_handle
      twitter_handle
      pinterest_handle
      tiktok_handle
      youtube_handle
      bio_link
      lunches {
        id
        creation_date
        image
        slug
        foods {
          id
          name
          veggies
          grains
          protein
          fruits
          dairy
          drink
        }
      }
    }
  }
`;

export const getTrophiesQuery = gql`
  query getTrophies($username: String!) {
    getTrophies(username: $username) {
        title
        start_date
        end_date
        description
        img_url
    }
  }
`;


export default compose(
  withApollo,
  graphql(getTrophiesQuery, {
    name: "getTrophies",
    options: (props) => ({
      variables: { username: props.params ? props.params["user_name"] : "" },
      fetchPolicy: "network-only",
    })
  }),
  graphql(profileQuery, {
  options: ({ params }) => ({
    variables: { user_name: params.user_name },
    fetchPolicy: "network-only",
  }),
  props({ data: { fetchMore, oneProfile, loading, error } }) {
    return {
      loading,
      oneProfile,
      error,
      loadMore() {
        const { lunches } = oneProfile;
        return fetchMore({
          variables: {
            offset: lunches.length,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }
            const oneProfileNew = assignIn({}, previousResult.oneProfile, {
              lunches: [
                ...previousResult.oneProfile.lunches,
                ...fetchMoreResult.oneProfile.lunches,
              ],
            });
            return {
              oneProfile: oneProfileNew,
            };
          },
        });
      },
    };
  },
}))(ProfilePage);
