import React, { Component } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      sent: false,
      error: "",
    };
  }
  handleSubmit(e) {
    e.preventDefault();
    const self = this;
    const email = this.email.value;
    if (!email) {
      return this.setState({ error: "Please enter an email" });
    }
    this.props.mutate({
      variables: { email },
    }).then((res) => {
      if (res.data.sendReset) {
        self.setState({ sent: true });
      } else {
        self.setState({ error: "Error while sending your request" });
      }
    });
  }
  render() {
    const { sent, error } = this.state;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    const disableStyle = {
      pointerEvents: "none",
      cursor: "default"
    };
    return (
      <div className="popup-modal reset-page">
        <h3>Reset Password</h3>
        <div className="sign-up-options">
          <span className="error-class">{error}</span>
          <div className="account-details-group">
            <input
              className="full-input"
              type="email"
              placeholder="Email"
              ref={(e) => (this.email = e)}
            />
          </div>
          <a
            href="#"
            className="standard-button account-button"
            onClick={this.handleSubmit}
            style={sent ? disableStyle : {}}
          >
            {sent ? "Sent" : "Send Password"}
          </a>
        </div>
      </div>
    );
  }
}

const sendResetAction = gql`
  mutation sendReset($email: String!) {
    sendReset(email: $email)
  }
`;

export default graphql(sendResetAction)(ResetPasswordPage);
