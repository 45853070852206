import React, { Component } from "react";
import Collapsible from "react-collapsible";
import Dropdown from "react-dropdown";
import { map, find, sortBy } from "lodash";
import countries from "../accounts/countries";
import { graphql, compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import ImageCompressor from "image-compressor.js";
import { currentUserQuery } from "../../App";

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

class General extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImage = this.handleImage.bind(this);
    this.setError = this.setError.bind(this);
    this.state = {
      countryState: "",
      error: "",
    };
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ error: "" });
    const {
      data: { currentUser },
    } = this.props;
    const user_name = this.user_name.value;
    const first_name = this.first_name.value;
    const last_name = this.last_name.value;
    let country = this.state.countryState.value;
    if (!country) country = currentUser.country;
    const postal = this.postal.value;
    const email = this.email.value;
    if (!email) return this.setError("Please enter an email and password");
    else if (!validateEmail(email))
      return this.setError("Please enter a valid email");
    if (email !== currentUser.email) {
      const response = await this.props.client.query({
        query: gql`
          query emailTaken($email: String!) {
            emailTaken(email: $email)
          }
        `,
        variables: { email },
      });
      if (response.data.emailTaken) return this.setError("This email is taken");
    }
    if (!user_name || !postal)
      return this.setError("Please fill out all fields");
    if (user_name !== currentUser.user_name) {
      const responseU = await this.props.client.query({
        query: gql`
          query usernameTaken($user_name: String!) {
            usernameTaken(user_name: $user_name)
          }
        `,
        variables: { user_name },
      });
      if (responseU.data.usernameTaken)
        return this.setError("This username is taken");
    }
    try {
      this.props.changeUserInfo({
        variables: {
          email,
          user_name,
          first_name,
          last_name,
          postal,
          country,
        },
      });
      document.getElementById("save-general").textContent = "Saved";
    } catch (e) {
      console.log(e);
    }
  }
  async handleImage(e) {
    const self = this;
    const files = e.currentTarget.files;
    if (files && files[0]) {
      const file = files[0];
      const imageCompressor = new ImageCompressor();
      const cFile = await imageCompressor.compress(file, {
        maxWidth: 600,
        mimeType: "image/jpeg",
      });

      let formData = new FormData();
      formData.append("file", cFile);
      formData.append("settings", "true");
      fetch("/upload/img", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === "OK") {
            self.props
              .submitImage({
                variables: {
                  image: response.url,
                },
              })
              .then(({ data }) => {
                document.getElementById(
                  "profpicpreview"
                ).style.backgroundImage = `url(${response.url})`;
              });
          } else {
            console.log("/upload/img response error: " + response.message);
          }
        })
        .catch((err) => console.log(err));
    }
  }
  setError(error) {
    this.setState({ error });
  }
  render() {
    const { error } = this.state;
    let { countryState } = this.state;
    const { data } = this.props;
    const { currentUser } = data;
    if (data.loading || data.error) return <div />;
    const {
      email,
      user_name,
      first_name,
      last_name,
      postal,
      country,
      image,
    } = currentUser;
    const options = sortBy(
      map(countries.countries.country, (c) => ({
        label: c.countryName,
        value: c.countryCode,
      })),
      "label"
    );
    if (country && !countryState) {
      const currentCountry = find(countries.countries.country, {
        countryCode: country,
      });
      countryState = {
        label: currentCountry.countryName,
        value: currentCountry.countryCode,
      };
    }
    return (
      <Collapsible trigger="General">
        <div className="left-settings">
          <span className="error-class">{error}</span>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              defaultValue={email}
              ref={(el) => (this.email = el)}
            />
          </div>
          <div className="form-group">
            <label>Username</label>
            <input
              type="text"
              defaultValue={user_name}
              ref={(el) => (this.user_name = el)}
            />
          </div>
          <div className="form-group">
            <label>First name</label>
            <input
              type="text"
              defaultValue={first_name}
              ref={(el) => (this.first_name = el)}
            />
          </div>
          <div className="form-group">
            <label>Last name</label>
            <input
              type="text"
              defaultValue={last_name}
              ref={(el) => (this.last_name = el)}
            />
          </div>
          <div className="form-group">
            <label>Postal Code</label>
            <input
              type="text"
              defaultValue={postal}
              ref={(el) => (this.postal = el)}
              onKeyPress={(event) =>
                event.charCode >= 48 && event.charCode <= 57
              }
            />
          </div>
          <div className="form-group">
            <label>Country</label>
            <Dropdown
              options={options}
              value={countryState}
              onChange={(e) => this.setState({ countryState: e })}
              className="country-dropdown standard-dropdown"
            />
          </div>
          <div className="form-group">
            <button
              className="settings-button"
              id="save-general"
              onClick={this.handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
        <div className="right-settings">
          <div
            className="image-preview"
            id="profpicpreview"
            style={{ backgroundImage: `url(${image})` }}
          >
            {image ? "" : "No Image"}
          </div>
          <input
            type="file"
            className="prof-pic-upload-link"
            onChange={this.handleImage}
          />
        </div>
      </Collapsible>
    );
  }
}

const generalSettingsQuery = gql`
  query currentUser {
    currentUser {
      id
      email
      user_name
      first_name
      last_name
      image
      postal
      country
    }
  }
`;

const generalSettingsMutation = gql`
  mutation changeUserInfo(
    $email: String!
    $user_name: String!
    $first_name: String
    $last_name: String
    $postal: String!
    $country: String!
  ) {
    changeUserInfo(
      email: $email
      user_name: $user_name
      first_name: $first_name
      last_name: $last_name
      postal: $postal
      country: $country
    ) {
      id
    }
  }
`;

const changeImageMutation = gql`
  mutation changeProfileImage($image: String!) {
    changeProfileImage(image: $image) {
      id
    }
  }
`;

export default compose(
  withApollo,
  graphql(generalSettingsQuery, {
    options: { fetchPolicy: "network-only" },
  }),
  graphql(generalSettingsMutation, {
    name: "changeUserInfo",
    options: {
      refetchQueries: [
        {
          query: currentUserQuery,
        },
      ],
    },
  }),
  graphql(changeImageMutation, {
    name: "submitImage",
    options: {
      refetchQueries: [
        {
          query: currentUserQuery,
        },
      ],
    },
  })
)(General);
