import React, { Component } from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import { profileQuery } from "../../pages/main/ProfilePage";

class Following extends Component {
  render() {
    const { data, submit, profileId, loggedIn } = this.props;
    const { error, loading, doIFollow } = data;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    if (error || loading)
      return (
        <a href="#" className="profile-button unfollow-button">
          ---
        </a>
      );
    if (doIFollow)
      return (
        <a
          href="#"
          className="profile-button unfollow-button"
          onClick={() => {
            if (!loggedIn) return browserHistory.push("/login");
            submit(profileId);
          }}
        >
          Unfollow
        </a>
      );
    return (
      <a
        href="#"
        className="profile-button follow-button"
        onClick={() => {
          if (!loggedIn) return browserHistory.push("/login");
          submit(profileId);
        }}
      >
        Follow
      </a>
    );
  }
}

Following.propTypes = {
  profileId: PropTypes.number,
  user_name: PropTypes.string,
  loggedIn: PropTypes.bool,
  data: PropTypes.object,
};

const followerQuery = gql`
  query doIFollow($id: Int!) {
    doIFollow(id: $id)
  }
`;

const toggleFollowAction = gql`
  mutation toggleFollow($id: Int!) {
    toggleFollow(id: $id)
  }
`;

export default compose(
  graphql(followerQuery, {
    options: (props) => ({
      variables: { id: props.profileId },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(toggleFollowAction, {
    props: ({ ownProps, mutate }) => ({
      submit: (id) =>
        mutate({
          variables: { id },
          refetchQueries: [
            "doIFollow",
            {
              query: profileQuery,
              variables: { user_name: ownProps.user_name },
            },
          ],
        }),
    }),
  })
)(Following);
