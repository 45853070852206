import React from "react";
import Icon from "react-icon-base";

const FaCheck = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m37.3 12.6q0 0.9-0.6 1.6l-19.2 19.1q-0.6 0.7-1.5 0.7t-1.6-0.7l-11.1-11.1q-0.6-0.6-0.6-1.5t0.6-1.5l3.1-3q0.6-0.7 1.5-0.7t1.5 0.7l6.6 6.5 14.6-14.6q0.6-0.6 1.5-0.6t1.5 0.6l3.1 3q0.6 0.6 0.6 1.5z" />
    </g>
  </Icon>
);

export default FaCheck;
