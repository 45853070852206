import React, { Component } from "react";
import PropTypes from "prop-types";
import FaCheck from "../../fa/check";

class CreateLunchHeader extends Component {
  render() {
    const { step } = this.props;
    const oneStyle = {
      backgroundColor: step > 1 ? "rgba(162,209,95,0.45)" : "",
    };
    const twoStyle = {
      backgroundColor: step > 1 ? "rgba(162,209,95,0.45)" : "",
    };
    const threeStyle = {
      backgroundColor: step > 1 ? "rgba(162,209,95,0.45)" : "",
    };
    const fourStyle = {
      backgroundColor: step > 1 ? "rgba(162,209,95,0.45)" : "",
    };
    const fiveStyle = {
      backgroundColor: step > 1 ? "rgba(162,209,95,0.45)" : "",
    };
    return (
      <div className="create-lunch-header">
        <div
          className={
            step === 1 ? "active-lunch-header lunch-header" : "lunch-header"
          }
          style={oneStyle}
        >
          {step > 1 ? <FaCheck /> : "1."} Photo
        </div>
        <div
          className={
            step === 2 ? "active-lunch-header lunch-header" : "lunch-header"
          }
          style={twoStyle}
        >
          {step > 2 ? <FaCheck /> : "2."} Foods
        </div>
        <div
          className={
            step === 3 ? "active-lunch-header lunch-header" : "lunch-header"
          }
          style={threeStyle}
        >
          {step > 3 ? <FaCheck /> : "3."} Themes
        </div>
        <div
          className={
            step === 4 ? "active-lunch-header lunch-header" : "lunch-header"
          }
          style={fourStyle}
        >
          {step > 4 ? <FaCheck /> : "4."} Gears
        </div>
        <div
          className={
            step === 5 ? "active-lunch-header lunch-header" : "lunch-header"
          }
          style={fiveStyle}
          >
          {step > 5 ? <FaCheck /> : "5."} Notes
        </div>
      </div>
    );
  }
}

CreateLunchHeader.propTypes = {
  step: PropTypes.number,
};

export default CreateLunchHeader;
