import React from "react";
import Icon from "react-icon-base";

const FaYoutube = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path d="m24.7 27.8v4.7q0 1.5-0.9 1.5-0.5 0-1-0.5v-6.7q0.5-0.5 1-0.5 0.9 0 0.9 1.5z m7.5 0v1h-2v-1q0-1.5 1-1.5t1 1.5z m-21.5-4.9h2.3v-2.1h-6.9v2.1h2.3v12.7h2.3v-12.7z m6.4 12.7h2v-11h-2v8.4q-0.7 1-1.3 1-0.4 0-0.5-0.5 0-0.1 0-0.8v-8.1h-2v8.7q0 1.1 0.2 1.7 0.3 0.8 1.3 0.8 1.1 0 2.3-1.4v1.2z m9.6-3.3v-4.4q0-1.6-0.2-2.2-0.4-1.2-1.6-1.2-1.1 0-2.1 1.2v-4.9h-2v14.8h2v-1q1 1.2 2.1 1.2 1.2 0 1.6-1.2 0.2-0.6 0.2-2.3z m7.5-0.2v-0.3h-2q0 1.1-0.1 1.4-0.1 0.8-0.9 0.8-1 0-1-1.6v-1.9h4v-2.3q0-1.8-0.6-2.6-0.9-1.1-2.4-1.1-1.5 0-2.4 1.1-0.6 0.8-0.6 2.6v3.9q0 1.7 0.7 2.5 0.8 1.2 2.4 1.2 1.6 0 2.4-1.2 0.4-0.6 0.5-1.2 0-0.2 0-1.3z m-13.6-20.4v-4.7q0-1.5-0.9-1.5t-1 1.5v4.7q0 1.6 1 1.6t0.9-1.6z m16.1 16.8q0 5.2-0.6 7.8-0.3 1.3-1.3 2.2t-2.3 1q-4.1 0.5-12.4 0.5t-12.3-0.5q-1.3-0.1-2.3-1t-1.3-2.2q-0.6-2.5-0.6-7.8 0-5.2 0.6-7.8 0.3-1.3 1.3-2.2t2.3-1.1q4.1-0.4 12.3-0.4t12.4 0.4q1.3 0.2 2.3 1.1t1.3 2.2q0.6 2.5 0.6 7.8z m-22.3-28.5h2.3l-2.7 8.9v6.1h-2.2v-6.1q-0.4-1.6-1.4-4.7-0.8-2.3-1.5-4.2h2.4l1.6 5.9z m8.3 7.4v3.9q0 1.8-0.7 2.7-0.8 1.1-2.3 1.1-1.5 0-2.4-1.1-0.6-0.9-0.6-2.7v-3.9q0-1.8 0.6-2.6 0.9-1.1 2.4-1.1 1.5 0 2.3 1.1 0.7 0.8 0.7 2.6z m7.4-3.6v11.2h-2v-1.3q-1.2 1.4-2.3 1.4-1 0-1.3-0.8-0.2-0.5-0.2-1.7v-8.8h2v8.2q0 0.7 0.1 0.8 0 0.5 0.4 0.5 0.6 0 1.3-1v-8.5h2z" />
    </g>
  </Icon>
);

export default FaYoutube;
