import React from "react";
import Icon from "react-icon-base";

const FaGrid = (props) => (
  <Icon viewBox="0 0 5400 5400" {...props}>
    <g>
      <path d="M120 5103 c-19 -10 -45 -33 -57 -52 l-23 -34 0 -634 c0 -687 -2 -660 55 -713 l27 -25 646 -3 c632 -2 648 -2 680 18 18 11 41 34 52 52 19 32 20 52 20 661 0 691 1 673 -61 718 l-34 24 -635 3 c-589 2 -637 1 -670 -15z"/>
      <path d="M1901 5102 c-19 -9 -44 -30 -55 -45 -21 -28 -21 -39 -24 -665 -2 -444 1 -645 9 -663 6 -15 24 -40 40 -55 l30 -29 659 0 659 0 30 29 c16 15 34 40 40 55 8 18 11 219 9 663 -3 626 -3 637 -24 665 -48 65 -24 63 -714 63 -575 0 -628 -1 -659 -18z"/>
      <path d="M3680 5101 c-19 -10 -44 -30 -55 -45 -20 -27 -20 -42 -23 -669 -2 -627 -2 -643 18 -675 11 -18 34 -41 52 -52 32 -20 48 -20 680 -18 l646 3 27 25 c57 53 55 26 55 713 l0 634 -23 34 c-48 72 -20 69 -717 69 -590 0 -627 -1 -660 -19z"/>
      <path d="M120 3322 c-19 -9 -45 -32 -58 -51 l-22 -35 2 -645 c3 -628 4 -647 23 -673 11 -14 34 -35 50 -45 28 -17 69 -18 629 -21 668 -3 686 -2 735 54 17 19 33 44 36 57 3 12 4 306 3 652 l-3 630 -22 31 c-47 66 -25 64 -715 64 -573 0 -626 -1 -658 -18z"/>
      <path d="M1900 3322 c-19 -11 -44 -31 -55 -46 -20 -27 -20 -42 -23 -670 l-2 -642 22 -34 c13 -18 36 -43 51 -54 28 -21 35 -21 667 -21 632 0 639 0 667 21 15 11 38 36 51 54 l22 33 0 629 c0 692 1 674 -61 719 l-34 24 -635 3 c-604 2 -637 1 -670 -16z"/>
      <path d="M3682 3323 c-18 -9 -43 -30 -55 -47 l-22 -31 -3 -630 c-1 -346 0 -640 3 -652 3 -13 19 -38 36 -57 49 -56 67 -57 735 -54 560 3 601 4 629 21 17 10 39 31 50 45 19 26 20 45 23 673 l2 645 -22 35 c-49 72 -21 69 -718 69 -555 0 -629 -2 -658 -17z"/>
      <path d="M130 1473 c-8 -3 -26 -15 -38 -27 -53 -48 -52 -27 -52 -709 l0 -634 23 -34 c48 -72 18 -70 727 -67 l635 3 34 24 c62 45 61 27 61 718 0 609 -1 629 -20 661 -11 18 -34 41 -52 52 -32 19 -51 20 -668 19 -349 0 -642 -3 -650 -6z"/>
      <path d="M1910 1473 c-8 -3 -26 -15 -38 -27 -53 -48 -52 -28 -52 -703 0 -693 -2 -669 63 -717 28 -21 37 -21 677 -21 640 0 649 0 677 21 65 48 63 24 63 717 0 680 2 654 -55 707 l-27 25 -646 2 c-356 1 -654 -1 -662 -4z"/>
      <path d="M3672 1460 c-18 -11 -41 -34 -52 -52 -19 -32 -20 -52 -20 -661 0 -691 -1 -673 61 -718 l34 -24 635 -3 c709 -3 679 -5 727 67 l23 34 0 634 c0 687 2 660 -55 713 l-27 25 -646 3 c-632 2 -648 2 -680 -18z"/>
    </g>
  </Icon>
);

export default FaGrid;




