import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";

class HashtagteukoPage extends Component {
  constructor(props) {
    super(props);
    this.registerClick = this.registerClick.bind(this);
  }
  registerClick(e) {
    e.preventDefault();
    document.getElementById("sign-up").click();
  }
  render() {
    
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Ideas Community - Hashtag Teuko</title>
          <meta name="description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <link rel="canonical" href="https://www.teuko.com"></link>
          <meta name="robots" content="follow, index, max-image-preview:large" />
          <meta name="googlebot" content="follow, index, max-image-preview:large"></meta>
          <meta property="og:url" content="https://www.teuko.com"></meta>
          <meta property="og:description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:title" content="Explore Teuko Features to Unlock Your Lunchbox Creativity"></meta>
          <meta property="og:site_name" content="Teuko"></meta>
          <meta name="keywords" content="teuko, lunchbox, community, online community, bento, kids food, food, school lunch, bentobox, meal prep, cooking, parenting, packed lunch, lunch bag, kids lunchbox, kids lunch, lunch, bento lunch box, lunch boxes for kids, lunches for kids, lunch bags for kids, insulated lunch box, warm lunch, hot lunch, thermos lunch box, insulated lunch bags, lunch boxes, cute lunch box, easy lunch boxes, picky eaters food, toddler food, teenager food, lunchbox accessories, planetbox lunch box, bentgo lunch box, sistema lunch box, omie lunch box, omiebox, yumbox lunch box, French school lunch menus, Japanese school lunch menus, worldwide school lunch menus, lunchbox mom, lunchbox dad, lunchbox store, lunchbox boutique, lunchbox shop, lunchbox ideas, kids lunchbox ideas, easy lunchbox ideas, easy kids lunchbox ideas, healthy lunchbox ideas, healthy kids lunchbox ideas, lunchbox containers for kids, meal prep lunchbox "/>
          <meta property="fb:app_id" content="566204480216246"></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:title" content="Explore Teuko Features to Unlock Your Lunchbox Creativity"></meta>
          <meta name="twitter:site" content="@teukoapp"></meta>
          <meta name="twitter:description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <meta name="twitter:creator" content="@teukoapp"></meta>
          <meta property="og:image" content="/images/features-social-image_w1200xh630_teuko-lunchbox-community-kids-school-lunch-bento-food.png"></meta>
          <meta name="twitter:image" content="/images/features-social-image_w1200xh630_teuko-lunchbox-community-kids-school-lunch-bento-food.png"></meta>          
        </Helmet>
        
        <div className="partners-page">


          {/* <div className="features-small-splash-header jobs-header" >
            <h1>Discover features that unlock your creativity</h1>
          </div> */}

            <div className="landing-heading-section">
                <div className="landing-heading-section-containerflex">
            
                    <div className="landing-heading-section-textcta">
                    <div className="landing-heading-section-subtitle">
                        <h1>The #Teuko Community</h1>
                        <p>Teuko is much more than an app. It's a vibrant community you can also find on Instagram, Facebook, Twitter, Pinterest, and Youtube. Find us on social media with the handle @teukoapp. Share your lunchbox ideas and tips with all of us using #Teuko. Weekly feature in the #Teuko category in the Teuko Feed!</p>
                    </div>

                    <div className="container-browse-all-btn">
                        <Link to="/signup" className="standard-button browse-all-button">
                        See #Teuko
                        </Link>
                    </div>
                    </div>

                    <div className="landing-heading-section-image" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep">
                        <img
                        src="/images/mockup_features Teuko lunchbox community kids school lunch food bento.png"
                        className="landing-heading-lunch-image"
                        alt="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"
                        />

                    </div>
                </div>
            </div>
       
          
          <div className="partners-top">
            <div className="partners-content">
              
              <div>
                <div className="partners-main-info">
                    <div className="footer-features-list">
                            <a
                            className="features-item"
                            key="teuko_on_instagram"
                            href="https://www.instagram.com/teukoapp"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <div className="community-item-image-instagram" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep Instagram"/>
                            <h2 className="community-hashtag-teuko-item-caption">On Instagram</h2>
                            </a>

                            <a
                            className="features-item"
                            key="teuko_on_facebook"
                            href="https://www.facebook.com/groups/2096593593902364"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <div className="community-item-image-facebook" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep Facebook"/>
                            <h2 className="community-hashtag-teuko-item-caption">On Facebook</h2>
                            </a>

                            <a
                            className="features-item"
                            key="teuko_on_twitter"
                            href="https://www.twitter.com/teukoapp"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <div className="community-item-image-twitter" title="Teuko lunchbox community kids bento ideas food packed lunch easy meal prep twitter"/>
                            <h2 className="community-hashtag-teuko-item-caption">On Twitter</h2>
                            </a>

                            <a
                            className="features-item"
                            key="teuko_on_pinterest"
                            href="https://www.pinterest.com/teukoapp"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <div className="community-item-image-pinterest" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep pinterest"/>
                            <h2 className="community-hashtag-teuko-item-caption">On Pinterest</h2>
                            </a>

                            <a
                            className="features-item"
                            key="teuko_on_youtube"
                            href="https://www.youtube.com/@teuko"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <div className="community-item-image-youtube" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep youtube"/>
                            <h2 className="features-lunchboxcards-item-caption">On Youtube</h2>
                            </a>

                            <a
                            className="features-item"
                            key="explore_teuko"
                            href="/signup"
                            >
                            <div className="features-item-image-exploreteuko" title="Explore feed search tool features Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">Explore Teuko</h2>
                            <p className="features-lunchboxcards-text">With the Feed, the Search tool, and more, lunch packing for kids is easy and fun!</p>
                            </a>


                    </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HashtagteukoPage;
