import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import FaHeart from "../../fa/heart";
import FaExternalLink from "../../fa/external-link";
import FaHeartO from "../../fa/heart-o";
import { Link } from "react-router";
import { map, has } from "lodash";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import Sharing from "./Sharing";
import { sortFoodsOnlyCats, fireEvent } from "./helpers";
import moment from "moment";
import FaCalendar from "../../fa/calendar";
import FaDownload from "../../fa/download";


class Lunch extends Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
    this.state = {
      loading: true,
      affiliateLinks: {},
      activeOption: 0,
    };
  }
  async UNSAFE_componentWillMount() {
    const affiliateLinks = await (
      await fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/affiliate_links.json"
      )
    ).json();
    this.setState({ loading: false, affiliateLinks });
  }
  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "566204480216246",
        status: true,
        xfbml: true,
        cookie: true,
        version: "v18.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    if (has(this.props, "close")) document.body.style.overflow = "hidden";
    window.twttr = (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        t = window.twttr || {};
      if (d.getElementById(id)) return t;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://platform.twitter.com/widgets.js";
      fjs.parentNode.insertBefore(js, fjs);

      t._e = [];
      t.ready = function (f) {
        t._e.push(f);
      };
      return t;
    })(document, "script", "twitter-wjs");
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  handleDelete() {
    const { deleteLunch, data } = this.props;
    const self = this;

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this lunch!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteLunch(data.oneLunch.id).then(() => {
          if (self.props.location.state && self.props.location.state.modal) {
            window.location.href = self.props.location.state.returnTo;
          } else {
            browserHistory.push("/me");
          }
        });
      }
    });
  }

  async componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677695143420-0');
      });
    }
  }

  render() {
    const { data, submitLike, submitSave } = this.props;
    const { loading, error, oneLunch } = data;
    const { activeOption, affiliateLinks } = this.state;
    if (loading) {
      return <div className="lunch-modal" />;
    }
    if (error) {
      return (
        <div className="lunch-popup">
          <div> Lunch '{this.props.params.slug}' does not exist!</div>
        </div>
      );
    }
    const {
      image,
      title,
      notes,
      foods,
      themes,
      accessories,
      user,
      liked,
      likes,
      saved,
      saves,
      actors,
      owned,
    } = oneLunch;
    const Foods = map(sortFoodsOnlyCats(foods), (f) => (
      <div key={"D_" + f.id} className="parent-container-foods-and-shop-now">
        <p key={f.id}>
          <Link
            className="detail-food-link"
            key={"L" + f.id}
            to={`/search?food=${f.name}`}
          >
            {f.name}
          </Link>
        </p>
        <a
          className="shop-now-amazon"
          href={affiliateLinks[f.id]}
          target="_blank"
          rel="noopener noreferrer"
        >
          SHOP NOW{" "}
          <img src="/amazon-icon.png" width="22px" height="22px" alt="amazon" />
        </a>
      </div>
    ));
    const Themes = map(themes, (t) => {
      if (t) {
        return (
          <p key={t.id}>
            <Link 
              className="detail-food-link"
              key={t.id} 
              to={`/search?theme=${t.name}`}>
              {t.name}
            </Link>
          </p>
        );
      } else {
        return null;
      }
    });
    const Accessories = map(accessories, (t) => {
      if (t) {
        return (
          <div key={"A_" + t.id} className="parent-container-foods-and-shop-now">
            <a href={t.url} className="detail-food-link" key={t.id} target="_blank" rel="noopener noreferrer">
                {t.name}
            </a>
            <a
              className="shop-now-amazon"
              href={t.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              SHOP NOW{" "}
              <img src="/amazon-icon.png" width="22px" height="22px" alt="amazon" />
            </a>
          </div>
        );
      } else {
        return null;
      }
    });

    const UserIcons = map(actors, (a) => (
      <Link
        key={`a${a.id}`}
        to={`/${a.user_name}`}
        className="like-photo"
        style={{ backgroundImage: a.image ? `url(${a.image})` : "" }}
      />
    ));
    let saveOrEditButton = (
      <div
        className={saved ? "save-button saved-button" : "save-button"}
        onClick={() => submitSave(oneLunch.id)}
      >
        <FaCalendar className="calendar-icon-save-btn"/> {saved ? "Saved" : "Save"}
      </div>
    );

    let downloadButton = (<></>);
    if (oneLunch.user.id === 8282) {
      downloadButton = (
        <a href={image} download={`teuko-lunchbox-by-${oneLunch.user.user_name}.jpg`}>
          <FaDownload />
      </a>)
    }

    const isAdmin = this.props.user && [1, 2, 17].includes(this.props.user.id);
    if (isAdmin) {
      saveOrEditButton = (
        <div
          className="save-button"
          onClick={() => (window.location = "/edit/" + oneLunch.slug)}
        >
          Admin-Edit
        </div>
      );
      downloadButton = (
        <a href={image} download={`teuko-lunchbox-by-${oneLunch.user.user_name}.jpg`}>
          <FaDownload />
      </a>
      );
    }

    if (owned) {
      saveOrEditButton = (
        <div
          className="edit-button"
          onClick={() => (window.location = "/edit/" + oneLunch.slug)}
        >
          Edit
        </div>
      );
      downloadButton = (
        <a href={image} download={`teuko-lunchbox-by-${oneLunch.user.user_name}.jpg`}>
          <FaDownload />
       </a>
      );
      }

    let buyButton = <div></div>;
    if (oneLunch.user && oneLunch.user.id === 1423 && oneLunch.link) {
      buyButton = (
        <div className="buy-button">
          <a className="buy-button-link" href={oneLunch.link} target="_blank" rel="noopener noreferrer">
            Buy Now
          </a>
        </div>
      );
    }

    let lunch_date = "NO DATE";
    if (oneLunch.creation_date) {
      const jsDate = new Date(parseInt(oneLunch.creation_date));
      lunch_date = "Shared on " + moment(jsDate).format("MMM Do YYYY");
    }
    let link = oneLunch.link;
    if (!link.startsWith("http")) {
      link = "http://" + link;
    }
    let linkDiv = <div />;
    if (oneLunch.link) {
      linkDiv = (
        <div>
          <FaExternalLink />
          <a href={link} target="_blank" rel="noopener noreferrer">
            {" "}
            {oneLunch.link}
          </a>
        </div>
      );
    }
    return (
     
      
      <div className="lunch-popup">
        <Helmet>
          <title>Teuko - {title || "Lunch Box"}</title>
          <meta
            property="og:url"
            content={`https://www.teuko.com/l/${oneLunch.id}`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`Teuko Lunch - ${oneLunch.title}`}
          />
          <meta property="og:description" content={oneLunch.notes} />
          <meta property="og:image" content={oneLunch.image} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@teukoapp" />
          <meta
            name="twitter:title"
            content={`Teuko Lunch - ${oneLunch.title}`}
          />
          <meta name="twitter:description" content={oneLunch.notes} />
          <meta name="twitter:image" content={oneLunch.image} />
        </Helmet>
        <div
          className="lunch-left"
          style={{ backgroundImage: image ? `url(${image})` : "" }}
        />
        <div className="lunch-right">
          <div className="lunch-right-top">
            <div className="lunch-modal-header">
              <div
                className="lunch-user-image"
                style={{
                  backgroundImage: user.image ? `url(${user.image})` : "",
                }}
              />
              <div className="lunch-user-details">
                <p className="lunch-title">
                  <strong>{title}</strong>
                </p>
                <span className="lunch-byline">
                  By <Link to={`/user/${user.user_name}`}>{user.user_name}</Link>
                </span>
                <p>
                  <span className="lunch-byline">{lunch_date}</span>
                </p>
              </div>
            </div>

            <div className="lunchbox-cards-ad-banner">
              <div className="lunchbox-cards-header-ad-smartphone_leaderboard">
                {/* <!-- /22874318077/Teuko-Lunchbox_Cards-AdUnit_1-Leaderboard-320x50 --> */}
                <div id='div-gpt-ad-1677695143420-0' style={{minWidth: "320px", minHeight: "50px"}} />
              </div>
            </div>

            <div className="lunch-link-recipe">{linkDiv}</div>
            <div className="lunch-content">
              <div className="lunch-content-options">
                <h6
                  className={
                    activeOption === 0 ? "lunch-content-option-active" : ""
                  }
                  onClick={() => this.setState({ activeOption: 0 })}
                >
                  Foods
                </h6>
                {Accessories && Accessories.length === 0 ? "" :
                  <h6
                    className={
                      activeOption === 3 ? "lunch-content-option-active" : ""
                    }
                    onClick={() => this.setState({ activeOption: 3 })}
                  >
                    Accessories
                  </h6>
                }
                {Themes && Themes.length === 0 ? "" :
                  <h6
                    className={
                      activeOption === 2 ? "lunch-content-option-active" : ""
                    }
                    onClick={() => this.setState({ activeOption: 2 })}
                  >
                    Themes
                  </h6>
                }
                {notes && notes.length === 0 ? "" :
                  <h6
                    className={
                      activeOption === 1 ? "lunch-content-option-active" : ""
                    }
                    onClick={() => this.setState({ activeOption: 1 })}
                  >
                    Notes
                  </h6>
                }
              </div>
              <div className="lunch-main-info">
                {activeOption === 0 ? (
                  <Fragment>
                    {Foods}
                    <div className="shop-now-section">
                      <p className="amazon-disclosure-lunch">
                        As an Amazon's affiliate company, if you buy some
                        products, we may get a small share of the revenue from
                        the sale. Any feedback? Email us at{" "}
                        <a href="mailto:contact@teuko.com">contact@teuko.com</a>
                      </p>
                    </div>
                  </Fragment>
                ) : (
                  ""
                )}
                {activeOption === 2 ? <Fragment>{Themes}</Fragment> : ""}
                {activeOption === 1 ? <p>{notes}</p> : ""}
                {activeOption === 3 ? 
                  <Fragment>
                    {Accessories}
                    <div className="shop-now-section">
                      <p className="amazon-disclosure-lunch">
                        As an Amazon's affiliate company, if you buy some
                        products, we may get a small share of the revenue from
                        the sale. Any feedback? Email us at{" "}
                        <a href="mailto:contact@teuko.com">contact@teuko.com</a>
                      </p>
                    </div>
                  </Fragment> : ""}
              </div>
            </div>
          </div>
          <div className="lunch-right-bottom">
            <div className="lunch-actions">
              <div className="like-and-share">
                {liked ? (
                  <FaHeart onClick={() => submitLike(oneLunch.id)} />
                ) : (
                  <FaHeartO onClick={() => submitLike(oneLunch.id)} />
                )}
                <Sharing lunch={oneLunch} />
               {downloadButton}
               

              </div>
              <div className="right-lunch-actions">
                <span className="delete-lunch" onClick={this.handleDelete}>
                  {owned ? "Delete" : ""}
                </span>
                {saveOrEditButton}
                {buyButton}
              </div>
            </div>
            <div className="like-info">
              <div className="likes-and-saves">
                <span>
                  <strong>{likes}</strong> Likes
                </span>
                <span>
                  <strong>{saves}</strong> Saved
                </span>
              </div>
              <div className="like-separator" />
              <div className="like-photos">{UserIcons}</div>
            </div>
          </div>
        </div>

      </div>

      
    );
  }
}

Lunch.propTypes = {
  data: PropTypes.object,
  close: PropTypes.func,
  submitLike: PropTypes.func,
  submitSave: PropTypes.func,
  handleDownload: PropTypes.func,
  deleteLunch: PropTypes.func,
};

const oneLunchQuery = gql`
  query oneLunch($slug: String!) {
    oneLunch(slug: $slug) {
      id
      slug
      image
      creation_date
      title
      link
      notes
      liked
      likes
      saved
      saves
      owned
      foods {
        id
        name
        veggies
        dairy
        grains
        protein
        fruits
        drink
      }
      themes {
        id
        name
      }
      accessories {
        id
        name
        image
        url
      }
      user {
        id
        user_name
        image
      }
      actors {
        id
        user_name
        image
      }
    }
  }
`;

const toggleLike = gql`
  mutation likeLunch($id: Int!) {
    likeLunch(id: $id) {
      id
      slug
      liked
    }
  }
`;

const toggleSave = gql`
  mutation saveLunch($id: Int!) {
    saveLunch(id: $id) {
      id
      slug
      saved
    }
  }
`;

const deleteLunchQuery = gql`
  mutation deleteLunch($id: Int!) {
    deleteLunch(id: $id)
  }
`;

export default compose(
  graphql(oneLunchQuery, {
    options: (props) => ({
      variables: { slug: props.params.slug },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(toggleLike, {
    name: "likeMutation",
    props: ({ ownProps, likeMutation }) => ({
      submitLike: (id) =>
        likeMutation({
          variables: { id },
          refetchQueries: [
            {
              query: oneLunchQuery,
              variables: { slug: ownProps.params.slug },
            },
          ],
          optimisticResponse: {
            __typename: "Mutation",
            likeLunch: {
              __typename: "Lunch",
              id: ownProps.data.oneLunch.id,
              slug: ownProps.data.oneLunch.slug,
              liked: !ownProps.data.oneLunch.liked,
            },
          },
        }),
    }),
    options: {
      update: (proxy, data) => {
        const {
          data: { likeLunch },
        } = data;
        if (!likeLunch) {
          fireEvent(document.getElementById("signup"), "click");
        } else {
          const newdata = proxy.readQuery({
            query: oneLunchQuery,
            variables: { slug: likeLunch.slug },
          });
          newdata.oneLunch.liked = likeLunch.liked;
          newdata.oneLunch.likes = likeLunch.liked
            ? newdata.oneLunch.likes + 1
            : newdata.oneLunch.likes - 1;
          proxy.writeQuery({ query: oneLunchQuery, data: newdata });
        }
      },
    },
  }),
  graphql(toggleSave, {
    name: "saveMutation",
    props: ({ ownProps, saveMutation }) => ({
      submitSave: (id) =>
        saveMutation({
          variables: { id },
          refetchQueries: [
            {
              query: oneLunchQuery,
              variables: { slug: ownProps.params.slug },
            },
          ],
          optimisticResponse: {
            __typename: "Mutation",
            saveLunch: {
              __typename: "Lunch",
              id: ownProps.data.oneLunch.id,
              slug: ownProps.data.oneLunch.slug,
              saved: !ownProps.data.oneLunch.saved,
            },
          },
        }),
    }),
    options: {
      update: (proxy, data) => {
        const {
          data: { saveLunch },
        } = data;
        if (!saveLunch) {
          fireEvent(document.getElementById("signup"), "click");
        } else {
          const newdata = proxy.readQuery({
            query: oneLunchQuery,
            variables: { slug: saveLunch.slug },
          });
          newdata.oneLunch.saved = saveLunch.saved;
          newdata.oneLunch.saves = saveLunch.saved
            ? newdata.oneLunch.saves + 1
            : newdata.oneLunch.saves - 1;
          proxy.writeQuery({ query: oneLunchQuery, data: newdata });
        }
      },
    },
  }),
  graphql(deleteLunchQuery, {
    name: "deleteLunchMutation",
    props: ({ ownProps, deleteLunchMutation }) => ({
      deleteLunch: (id) =>
        deleteLunchMutation({
          variables: { id },
        }),
    }),
  })
)(Lunch);
