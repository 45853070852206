import React, { Component } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { map, assignIn } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import MyLunchThumb from "./MyLunchThumb";
import MyLunchThumbGrid from "./MyLunchThumbGrid";
import FaGrid from "../../fa/grid";
import FaImage from "../../fa/image";

class MyLunches extends Component {
  constructor(props) {
    super(props);
    this.openLunch = this.openLunch.bind(this);
    this.close = this.close.bind(this);
    this.switchFilter = this.switchFilter.bind(this);
    this.state = {
      id: 0,
      filter: "created",
      modalOpen: false,
      fetching: false,
      activeOption: 0,
    };
  }
  openLunch(e) {
    const { id } = e.currentTarget.dataset;
    this.setState({ id: parseInt(id, 10) });
  }
  close() {
    this.setState({ id: 0 });
  }
  switchFilter(e) {
    const { filter } = e.currentTarget.dataset;
    const self = this;
    this.setState({ modalOpen: false, fetching: true }, () => {
      const { refetch } = this.props;
      this.setState({ filter });
      refetch({ filter }).then(() => self.setState({ fetching: false }));
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.modalOpen) return false;
    return true;
  }
  render() {
    const { loading, error, myLunches, loadMore, location } = this.props;
    const { filter, fetching } = this.state;
    const Lunches = map(myLunches, (l) => (
      <MyLunchThumb
        lunch={l}
        key={l.id}
        location={location}
        opened={() => this.setState({ modalOpen: true })}
      />
    ));
    const LunchesGrid = map(myLunches, (l) => (
        <MyLunchThumbGrid
          lunch={l}
          key={l.id}
          location={location}
          opened={() => this.setState({ modalOpen: true })}
        />
    ));
    const { activeOption } = this.state;

    return (
      <div className="my-lunches">
        <div className="my-lunches-header">
          <div className="my-lunches-filters">
            <h5
              className={filter === "created" ? "active-filter" : ""}
              onClick={this.switchFilter}
              data-filter="created"
            >
              Uploaded
            </h5>
            <h5
              className={filter === "saved" ? "active-filter" : ""}
              onClick={this.switchFilter}
              data-filter="saved"
            >
              Saved
            </h5>
            <h5
              className={filter === "best" ? "active-filter" : ""}
              onClick={this.switchFilter}
              data-filter="best"
            >
              My Favorites
            </h5>
          </div>
        </div>

        <div className="profile-content-options-display-smartphone">
            <h3
              className={
                activeOption === 0 ? "lunch-content-option-active" : ""
              }
              onClick={() => this.setState({ activeOption: 0 })}
            >
              <FaGrid />
            </h3>
            <h3
              className={
                activeOption === 1 ? "lunch-content-option-active" : ""
              }
              onClick={() => this.setState({ activeOption: 1 })}
            >
              <FaImage />
            </h3>
        </div>

        <div className="lunch-thumb-group-display-only-smartphone">
            <div className="partners-main-info">
              {activeOption === 0 ? (
                
                <InfiniteScroll
                  pageStart={0}
                  className="profile-lunches lunch-grid-group-profile"
                  loadMore={() => {
                    if (!fetching) loadMore();
                  }}
                  hasMore={!loading}
                >
                  {loading || error ? "" : LunchesGrid}
                </InfiniteScroll>

              
              ) : (
                ""
              )}
              {activeOption === 1 ? (

                <InfiniteScroll
                pageStart={0}
                className="lunch-thumb-group"
                loadMore={() => {
                  if (!fetching) loadMore();
                }}
                hasMore={!loading}
                >
                {loading || error ? "" : Lunches}
                </InfiniteScroll>

                ) : (
                  ""
                )}
          </div>
      </div>

      <div className="lunch-thumb-group-display-only-laptop">
        <InfiniteScroll
          pageStart={0}
          className="lunch-thumb-group"
          loadMore={() => {
            if (!fetching) loadMore();
          }}
          hasMore={!loading}
          >
          {loading || error ? "" : Lunches}
        </InfiniteScroll>
      </div>
    </div>
    );
  }
}

MyLunches.propTypes = {
  userId: PropTypes.number,
  data: PropTypes.object,
  location: PropTypes.object,
};

const currentLunchQuery = gql`
  query myLunches($filter: String, $offset: Int) {
    myLunches(filter: $filter, offset: $offset) {
      id
      creation_date
      image
      slug
      foods {
        id
        name
        veggies
        grains
        protein
        fruits
        drink
      }
    }
  }
`;

export default graphql(currentLunchQuery, {
  options: (ownProps) => ({
    variables: {
      filter: "created",
    },
    fetchPolicy: "network-only",
  }),
  props({ data: { fetchMore, myLunches, loading, error, refetch }, data }) {
    return {
      loading,
      myLunches,
      error,
      refetch,
      loadMore() {
        return fetchMore({
          variables: {
            offset: loading ? 0 : myLunches ? myLunches.length : 0,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResult;
            }
            return assignIn({}, previousResult, {
              myLunches: [
                ...previousResult.myLunches,
                ...fetchMoreResult.myLunches,
              ],
            });
          },
        });
      },
    };
  },
})(MyLunches);
