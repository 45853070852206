import React, { Component } from "react";
import PropTypes from "prop-types";
import FaPlus from "../../fa/plus";
import FaMinus from "../../fa/minus";

class FAQQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    const { open } = this.state;
    const { question, answer } = this.props;
    return (
      <div className="q-and-a-faq">
        <div
          className="question-faq"
          onClick={() => this.setState({ open: !open })}
        >
          <h5>{question}</h5>
          {open ? (
            <FaMinus className="open-question-icon" />
          ) : (
            <FaPlus className="open-question-icon" />
          )}
        </div>
        <div
          className={
            open ? "question-answer-open question-answer" : "question-answer"
          }
        >
          {answer}
        </div>
      </div>
    );
  }
}

FAQQuestion.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};

export default FAQQuestion;
