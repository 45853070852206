import React, { Component } from "react";
import { browserHistory } from "react-router";
import FaClose from "../../fa/close";

class Modal extends Component {
  render() {
    return (
      <div className="lunch-modal">
        <span onClick={browserHistory.goBack}>
          <FaClose className="close-lunch" />
        </span>
        {this.props.children}
      </div>
    );
  }
}

export default Modal;
