import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, union, flatten, groupBy, reduce } from "lodash";
import FaPrint from "../../fa/print";
import FaCalendarO from "../../fa/calendar-o";
import FaEnvelopeO from "../../fa/envelope-o";
import FaClose from "../../fa/close";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { browserHistory } from "react-router";

class ShoppingList extends Component {
  constructor(props) {
    super(props);
    this.print = this.print.bind(this);
    this.state = {
      loading: true,
    };
  }
  async UNSAFE_componentWillMount() {
    const { menu, client } = this.props;
    const allFoods = map(menu, (l) => map(l.lunch.foods, "id"));
    const uniqFoods = union(flatten(allFoods));
    const response = await client.query({
      query: gql`
        query getGroceryList($foodIds: [String]) {
          getGroceryList(foodIds: $foodIds) {
            foods {
              id
              name
              image
              groceryCategory
              groceryLabel
              asin
            }
          }
        }
      `,
      variables: { foodIds: uniqFoods },
    });
    const fullFoodItems = response.data.getGroceryList.foods;
    const foodGroups = groupBy(fullFoodItems, (i) => i.groceryCategory);
    this.setState({ foodGroups, loading: false });
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  print() {
    const { foodGroups } = this.state;
    const { dateString } = this.props;

    browserHistory.push({
      pathname: "/shoppinglistprint",
      state: {
        foodGroups,
        dateString,
      },
    });
  }
  render() {
    const { loading, foodGroups } = this.state;
    const { dateString, close } = this.props;
    if (loading) return <div />;
    const GrocerySections = map(foodGroups, (g, title) => {
      const FoodList = map(g, (food) => (
        <div className="food-list-item" key={food.id}>
          <div
            className="food-image"
            style={{ backgroundImage: `url(${food.image})` }}
          />
          <p className="food-name">{food.groceryLabel}</p>
          {food.asin ? (
            <a
              className="shop-now"
              rel="noopener noreferrer"
              href={`https://www.amazon.com/gp/product/${food.asin}/?tag=teuko-20&fpw=fresh`}
              target="_blank"
            >
              Shop Now
            </a>
          ) : (
            ""
          )}
        </div>
      ));
      return (
        <div className="food-group-section" key={title}>
          <div className="food-group-title">
            <h5>{title}</h5>
          </div>
          {FoodList}
        </div>
      );
    });
    const details = reduce(
      foodGroups,
      (text, f, title) => {
        const listText = reduce(
          f,
          (t, food) => {
            return `${t}%0A${encodeURI(food.groceryLabel.replace("&", "and"))}`;
          },
          ""
        );
        return `${text}<b>${encodeURI(
          title.replace("&", "and")
        )}</b>%0A${listText}%0A%0A`;
      },
      ""
    );
    const emailDetails = reduce(
      foodGroups,
      (text, f, title) => {
        const listText = reduce(
          f,
          (t, food) => {
            return `${t}%0A${encodeURI(food.groceryLabel.replace("&", "and"))}`;
          },
          ""
        );
        return `${text}***${encodeURI(
          title.replace("&", "and")
        )}***%0A${listText}%0A%0A`;
      },
      ""
    );
    const link = `http://www.google.com/calendar/event?action=TEMPLATE&text=Grocery%20List%20${encodeURI(
      dateString
    )}&details=${details}`;
    const mailto = `mailto:?Subject=Grocery%20List%20${encodeURI(
      dateString
    )}&Body=${emailDetails}`
      .replace(/<b>/g, "")
      .replace(/<\/b>/g, "");
    return (
      <div className="shopping-modal">
        <div className="shopping-list">
          <div className="shopping-list-header">
            <h3>Grocery List for {dateString}</h3>
            <div className="grocery-list-icons">
              <FaPrint onClick={this.print} />
              <a href={link} target="_blank" rel="noopener noreferrer">
                <FaCalendarO />
              </a>
              <a href={mailto} target="_blank" rel="noopener noreferrer">
                <FaEnvelopeO />
              </a>
              <FaClose className="close-modal-list" onClick={close} />
            </div>
          </div>
          <div className="shopping-list-body">
            {GrocerySections}
            {GrocerySections.length ? (
              ""
            ) : (
              <p className="no-lunches">
                Add a lunch to your weekly menu to generate your grocery list.
              </p>
            )}
          </div>
          <div className="shop-now-section">
            <p className="amazon-disclosure">
              As Amazon's affiliate company, if you buy some products, we may
              get a small share of the revenue from the sale. Any feedback?
              Email us at{" "}
              <a href="mailto:contact@teuko.com">contact@teuko.com</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ShoppingList.propTypes = {
  menu: PropTypes.array,
  dateString: PropTypes.string,
  close: PropTypes.func,
};

ShoppingList.defaultProps = {
  menu: [],
};

export default withApollo(ShoppingList);
