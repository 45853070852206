import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

class JobsPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Community - Jobs</title>
        </Helmet>
        <div className="jobs-page">
          <div
            className="small-splash-header jobs-header"
            style={{ backgroundImage: "url(/images/melons.jpg)" }}
          >
            <h1>Working at Teuko</h1>
            <p>Together, let&apos;s empower lunchbox makers</p>
          </div>
          <div className="jobs-section">
            <div className="standard-container">
              <div className="job-list">
                <div className="job-box open-job">
                  <div className="job-header">OPEN POSITION</div>
                  <div className="job-content">
                    <p className="job-title">Software Engineer (Full Stack)</p>
                    <p className="job-subtitle">
                      No Salary &middot; 1.0% &mdash; 10.0% &middot; Cofounder
                      &middot; Remote OK &middot; San Francisco &middot;
                      Full-Stack Developer
                    </p>
                  </div>
                </div>
              </div>
              <div className="job-disclaimer">
                <p>
                  Diversity in food creates awesome culinary experience. Teuko
                  is committed to be inclusive and diverse in its workforce.
                  Teuko is an equal opportunity employer and considers qualified
                  applicants without regard to race, color, ancestry, national
                  origin, religion, sex, sexual orientation, gender identity,
                  age, disability, veteran status, genetic information, marital
                  status or any other legally protected status.
                </p>
                <p>
                  San Francisco applicants: Pursuant to the San Francisco Fair
                  Chance Ordinance, we will consider for employment-qualified
                  applicants with arrest and conviction records.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default JobsPage;
