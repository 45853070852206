import React, { Component } from "react";
import HomePage from "../pages/home/HomePage.js";
import Feed from "../pages/main/Feed.js";
import PropTypes from "prop-types";

class HomeSwitcher extends Component {
  render() {
    const { user, location } = this.props;
    if (user.id) return <Feed user={user} location={location} />;
    return <HomePage location={location} />;
  }
}

HomeSwitcher.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

HomeSwitcher.defaultProps = {
  user: {},
};

export default HomeSwitcher;
