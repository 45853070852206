import React, { Component } from "react";
import PropTypes from "prop-types";
import "./App.css";
import OutHeader from "./components/general/OutHeader";
import InHeader from "./components/general/InHeader";
import Modal from "./components/general/Modal";
import ApolloClient from "apollo-client";
import { graphql, ApolloProvider } from "react-apollo";
import gql from "graphql-tag";

import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import InFooter from "./components/general/InFooter";
import OutFooter from "./components/general/OutFooter";

const httpLink = new HttpLink({ uri: `/graphql` });
const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem("sessionID") || null,
    },
  });
  return forward(operation);
});

const link = middlewareLink.concat(httpLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

class AppLayout extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.key !== this.props.location.key &&
      nextProps.location.state &&
      nextProps.location.state.modal
    ) {
      // save the old children (just like animation)
      this.previousChildren = this.props.children;
    }
  }
  render() {
    const { children, data, location } = this.props;

    if (data && data.loading) {
      return <div>Loading Teuko...</div>;
    }
    let user = {
      email: "",
      id: 0,
    };
    if (data && !data.error && data.currentUser) {
      user = data.currentUser;
    }
    if (data && data.error) {
      localStorage.removeItem("sessionID");
    }

    const isModal =
      location.state && location.state.modal && this.previousChildren;
    return (
      <div className="app-container">
        {user.id ? (
          <InHeader user={user} location={location} />
        ) : (
          <OutHeader location={location} />
        )}
        {isModal
          ? this.previousChildren
            ? React.cloneElement(this.previousChildren, { user })
            : ""
          : children
          ? React.cloneElement(children, { user })
          : ""}
        {isModal && (
          <Modal isOpen returnTo={location.state.returnTo}>
            {this.props.children}
          </Modal>
        )}
        <div id="popup-mount" />
        <div id="fb-root" />
        {user.id ? <InFooter user={user} location={location} /> : <OutFooter />}
      </div>
    );
  }
}

export const currentUserQuery = gql`
  query CurrentUser {
    currentUser {
      id
      email
      user_name
      image
      onboard_age
      country
    }
  }
`;

const AppLayoutWithData = graphql(currentUserQuery, {
  errorPolicy: "ignore",
})(AppLayout);

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <AppLayoutWithData {...this.props} />
      </ApolloProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default App;
