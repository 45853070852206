import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link, Element } from "react-scroll";

class GiveAway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formEmail: "",
      message: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async handleSubmit(event) {
    event.preventDefault();
    const email = this.state.formEmail;
    const tags = ["giveaway", "giveaway-newsletter"];
    const myInit = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        tags,
      }),
    };
    this.setState(
      {
        message: "Please wait, registering your email...",
      },
      async () => {
        const response = await fetch("/subscribe", myInit);
        const responseTxt = await response.text();
        if (response.ok) {
          this.setState({
            message:
              'Congrats, your subscription to the Teuko Newsletter with "' +
              email +
              '" was recorded! ' +
              "Share Teuko with your friends for more lunchbox fun!",
          });
        } else {
          console.log("Error:", responseTxt);
          this.setState({
            message:
              "Error while registering your email, please contact contact@teuko.com to register.",
          });
        }
      }
    );
  }
  handleChange(event) {
    this.setState({ formEmail: event.target.value });
  }
  render() {
    const { user } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Community - Giveaway</title>
        </Helmet>
        
        <div className="giveaway-page">
          
          <div
            key="giveawaybannerlaptop"
            className="giveaway-splash-header-laptop"
            alt="Giveaway Banner"
            style={{ backgroundImage: "url(/images/giveawaybannerlaptop-Teuko_adventcalendar2022_Corrected20221114.gif)" }}
          ></div>
          <div
            key="giveawaybannersmartphone"
            className="giveaway-splash-header-smartphone"
            alt="Giveaway Banner"
            style={{
              backgroundImage: "url(/images/giveawaybannersmartphone-Teuko_adventcalendar2022_Corrected20221114.gif)",
            }}
          ></div>


          <div className="giveaway-section">
            <div className="standard-container">
              <div className="giveaway-section_intro">
                <h1>The Holiday Season 2022 was Amazing!</h1>
                {/* <h2>
                Calling all Lunchbox Moms and Dads! */}
                {/* Win Must-Have Gear For Summer Fun With Kids!  */}
                  {/* Each day is a new chance to win!<br></br>Enter your email
                  below to participate in the Teuko Holiday Giveaway! */}
                {/* </h2> */}

                { user.id !== 0 ? (
                  // <p>In December, we give away over <span className="giveaway-winnernumber">$1,000 in fun prizes to 15 lucky participants</span>. Each day is a new chance to win! The drawing takes place on weekdays, at 9 pm PST, until Friday, Dec. 17th. Enter today! You'll be eligible for all remaining prize drawings until Friday 12/17.</p>
                  // <p>The Teuko Advent Calendar is back with <span className="giveaway-winnernumber">10 days of giveaways</span> in December. Each day is a new chance to win! The drawings will take place on weekdays, the day after at 9 am PST, starting Friday, December 2nd. Enter today, you'll be eligible for all remaining prize drawings until Wednesday 12/14. Open to US residents.</p>
                  <p>
                    The Teuko Advent Calendar Giveaway ended but there will be more in 2023! One of our mission at Teuko, indeed, is to make lunch packing fun! Subscribe to our newsletter, 
                    follow us on <a
                      href="https://www.instagram.com/teukoapp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Instagram</a> or on <a
                    href="https://www.facebook.com/teukoapp"
                    target="_blank"
                    rel="noopener noreferrer">Facebook</a> and be among the firsts to know when we'll launch our next challenges and giveaways!
                  </p>
                
                ) : (

                  // <p>In December, we give away over <span className="giveaway-winnernumber">$1,000 in fun prizes to 15 lucky participants</span>. Each day is a new chance to win! The drawing takes place on weekdays, at 9 pm PST, until Friday, Dec. 17th. Enter today! You'll be eligible for all remaining prize drawings until Friday 12/17.</p>
                  // <p>The Teuko Advent Calendar is back with <span className="giveaway-winnernumber">10 days of giveaways</span> in December. Each day is a new chance to win! The drawings will take place on weekdays, the day after at 9 am PST, starting Friday, December 2nd. Enter today, you'll be eligible for all remaining prize drawings until Wednesday 12/14. Open to US residents.</p>

                  <p>
                  The Teuko Advent Calendar Giveaway ended but there will be more in 2023! One of our mission at Teuko, indeed, is to make lunch packing fun! Join our lunchbox community (<Link to="/signup">Sign up free!</Link>), 
                  follow us on <a
                    href="https://www.instagram.com/teukoapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >Instagram</a> or on <a
                  href="https://www.facebook.com/teukoapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >Facebook</a> and be among the firsts to know when we'll launch our next challenges and giveaways!
                  </p>
                )}

                
                {/* <p>
                  In December, we give away over $1,000 in fun prizes to 24
                  lucky participants! The drawing will take place on weekdays,
                  at 5.30 pm PST, starting Tuesday, Dec 1st, until Tuesday, Dec.
                  15th. The winner(s) will be announced the same day, at 8 pm
                  PST{" "}
                  <a
                    href="https://www.instagram.com/teukoapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    on our Instagram account. Make sure to follow us!
                  </a>{" "}
                  The sooner you register, the more chances you get to win.
                  Don't miss any chance, enter today!
                </p> */}

                {/* <p>
                School's out. Adventure's in! Gear up for family fun this summer.  Enter your email below for a chance to win one of the amazing prizes from our partners. 
                8 lucky winners will be selected on Monday, June the 21st. Open to US residents. The countdown to summer has started. Enter today!
                </p> */}
              </div>

              {/* <hr className="solid giveaway-separator"></hr> */}
              {/* <div className="giveaway-section_submit"> */}
                {/* <p>
                  The giveaway is closed! Thank you to all who entered! The
                  winners have been messaged privately.<br></br>
                </p>
                <Link to="/signup">Sign up (free!)</Link> for a personalized
                experience in Teuko and for receiving the latest news.{" "}
                <a
                  href="https://www.instagram.com/teukoapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Follow us on Instagram!
                </a> */}
                {/* <div className="giveaway-validation-message"><p>{ this.state.message }</p></div>
                        <form onSubmit={this.handleSubmit}>
                            <label> 
                                <input className="giveaway-submit_email" type="email" name="email" placeholder="Email Address" value={this.state.formEmail} onChange={this.handleChange}/>
                            </label>
                            <input className="giveaway-submit_btn standard-button browse-all-button" type="submit" value="Enter Now"/>
                        </form>
                <div className="giveaway-terms-privacy">
                    <p>NO PURCHASE NECESSARY. Open to US residents (excluding residents of Alaska and Hawaii), who are age of majority. Giveaway from 8 AM PST 11/15/2022 to 11:59 PM PST 12/14/2022. <br></br>By entering the sweepstakes, I agree to the <a href="https://www.teuko.com/faq" target="_blank" rel="noopener noreferrer">sweepstakes' official rules.</a> I also agree to receive email communication and special offers from Teuko. <a href="https://www.teuko.com/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="https://www.teuko.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                </div> */}
              {/* </div> */}
              
              { user.id !== 0 ? (
              <div className="giveaway-section_submit">
                <div className="giveaway-validation-message"><p>{ this.state.message }</p>
                  <form onSubmit={this.handleSubmit}>
                      <label>
                          Email Adress 
                          <input className="giveaway-submit_email" type="email" name="email" value={this.state.formEmail} onChange={this.handleChange}/>
                      </label>
                          <input className="giveaway-submit_btn standard-button browse-all-button" type="submit" value="Subscribe"/>
                  </form>
                  <div className="giveaway-terms-privacy">
                    <p><a href="https://www.teuko.com/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="https://www.teuko.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                  </div>
                </div>
              </div>
              ) : (
                <div className="giveaway-section">
                <div className="standard-container">
                   <a className="standard-button giveaway-button" href="/signup">Join Teuko</a>
                </div>
              </div>
              )}
                 
              {/* <div className="standard-container">
                <div className="giveaway-terms-privacy">
                  <p> (*) The Planet Rover Kit includes a stainless steel lunchbox (with 2 Dipper containers), a magnet set, and Carry Bag. The winner will be able to choose their magnet & Carry Bag designs.
                      <br/><br/>
                    NO PURCHASE NECESSARY. Giveaway from Wednesday 5/5/2021 12:00 PM PST to Sunday 5/16/2021 11:59 PM PST.
                    <br></br><a href="https://www.teuko.com/faq" target="_blank" rel="noopener noreferrer">Complete official rules here.</a> <a href="https://www.teuko.com/terms" target="_blank" rel="noopener noreferrer">Terms</a> and <a href="https://www.teuko.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
                </div>
              </div> */}

              <hr className="solid-giveaway"></hr>
              <h2>10 Days of Giveaways!</h2><br/>
              {/* <h2>Enter To Win A $100 Prize Package!</h2> */}
              {/* <h2>About the #lunchboxesaroundtheworld Challenge</h2> */}
            
              {/* <div className="giveaway-section"> */}
               {/* <div className="standard-container"> */}
                 {/* <div className="giveaway-section_intro"> */}
                    {/* <p>DECEMBER 2022 | 10 DAYS 14 WINNERS</p> */}
                    {/* <p>
                      <a href="https://blog.teuko.com/2021/05/04/explore-the-world-one-lunchbox-at-a-time/" target="_blank"
                        rel="noopener noreferrer">The #lunchboxesaroundtheworld challenge</a> was brought to you by a group of amazing lunchbox moms willing to take us on a virtual trip around the world.
                        <br/><br/>How to make a #lunchboxesaroundtheworld lunch?
                        <br/>Prepare and share a themed lunch featuring a country that you like, your home country, or a country you want to travel to. Your lunch, your style. In a lunchbox or on a plate. Just ensure that you feature at least one traditional local food or specialty.  Visit <a href="https://www.instagram.com/teukoapp" target="_blank" rel="noopener noreferrer">Instagram</a> or <a href="https://blog.teuko.com" target="_blank" rel="noopener noreferrer">Teuko Blog</a> for more information.
                    </p> */}
                  {/* </div>  */}
                {/* </div> */}
              {/* </div> */}
              {/* <hr className="solid"></hr>
              <h2>The Lunchbox Moms Behind This Challenge</h2>
              <div
                key="giveawaybannerlaptop"
                className="giveaway-splash-header-laptop-bis"
                alt="Giveaway Banner"
                style={{ backgroundImage: "url(/images/giveawaybannermomsmay2021.jpg)" }}
              ></div> */}

              {/* <div className="giveaway-section">
                <div className="standard-container">
                  <div className="giveaway-section_intro">
                    <p>Meet the team: <a target="_blank" href="/funkytartines" rel="noopener noreferrer">Noemie (@funkytartines</a>, Belgium),
                    <a target="_blank" href="/abc_Lunchies" rel="noopener noreferrer"> Filio (@abc_lunchies</a>, Cyprus),
                    <a target="_blank" href="/Pausenbrotjunkie" rel="noopener noreferrer"> Sabine (@Pausenbrojunkie</a>, Germany),
                    <a target="_blank" href="/Kidscantwaitforlunch" rel="noopener noreferrer"> Inga (@kidscantwaiforlunch</a>, Canada), 
                    <a target="_blank" href="/snackboxdiaries" rel="noopener noreferrer"> Clare (@snackboxdiairies</a>, Singapore),
                    and Karla (@lunchatnoone, USA).
                    </p>
                  </div>
                </div>
              </div> */}

            <div>
              <Element
                className="giveaway-group"
                id="groupgiveawayprizes"
                key="groupgiveawayprizes"
                name="groupgiveawayprizes"
              >
                <div className="giveaway-group-items"> 

                {/* <a
                    className="giveaway-item"
                    key="giveawayprizes0calendar"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveaway_adventcalendar2021_santacalendar.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Oh Oh Oh!</span><br/><span className="giveaway-highlightsponsor">Don't miss any chance! Enter today!</span>
                    </p><br/>
                  </a> */}

                <a
                    className="giveaway-item"
                    key="giveawayprize_1_arteza"
                    target="_blank"
                    href="https://bit.ly/3U8AkRQ"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize1_arteza.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Arteza</span><br/>One (1) 2022 24-day Advent Calendar<br/>($80 value)
                      </p><br/>
                  </a>

                  <a
                    className="giveaway-item"
                    key="giveawayprize_2_lunchpunch"
                    target="_blank"
                    href="https://amzn.to/3UrB8RN"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize2_lunchpunch_corrected20221110.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">The Lunch Punch</span><br/>Three (3) Christmas Cutter & Bento Fun Sets<br/>($75 value)
                    </p><br/>
                  </a>

                  <a
                    className="giveaway-item"
                    key="giveawayprize_3_GogoSqueez"
                    target="_blank"
                    href="https://bit.ly/3A0hnIW"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize3_gogosqueez.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">GoGo squeeZ</span><br/>One (1) case of GoGo Big squeeZ<br/>($50 value) 
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_4_LaurenRivietz"
                    target="_blank"
                    href="https://bit.ly/3te7yTT"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize4_LaurenRivietz.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Lauren Rivietz</span><br/>One (1) Mini Pep-Talks Card Deck for Kids<br/>($25 value) 
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_5_pastabilities"
                    target="_blank"
                    href="https://bit.ly/3nQieGA"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize5_Pastabilities.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Pastabilities</span><br/>Two (2) participants will receive each one (1) assortment of Holiday Pasta and Meals<br/>($150 value)
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_6_mywish4u"
                    target="_blank"
                    href="https://bit.ly/3SPf2YA"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize6_mywish4u.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">MyWish4U</span><br/>One (1) Holiday/family set<br/>($55 value)
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_7_monbento"
                    target="_blank"
                    href="https://bit.ly/Monbento-Teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize7_monbento_corrected20221114.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">monbento</span><br/>Three (3) participants will win each one (1) metal bento box<br/>($115 value)
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_8_warmables"
                    target="_blank"
                    href="https://bit.ly/3sUzNH8"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize8_warmables.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Warmables</span><br/>One (1) Warm Lunch Bag Super Set<br/>($95 value)
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_9_iboxie"
                    target="_blank"
                    href="https://bit.ly/3sXpj9P"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize9_iboxie.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">iboxie</span><br/>One (1) set of owl bento boxes in four colors (blue, purple, pink, and yellow)<br/>($90 value)
                      </p><br/>
                   </a>

                   <a
                    className="giveaway-item"
                    key="giveawayprize_10_nurturelife"
                    target="_blank"
                    href="https://bit.ly/3DzpNrx"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveawaycalendar2022_prize10_nurturelife.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Nurture Life</span><br/>Two (2) participants will receive each two (2) weeks of healthy, delicious baby & kids meals & snacks<br/>($300 value)
                      </p><br/>
                   </a>

                   {/* <a
                    className="giveaway-item"
                    key="giveawayprizesannouncement"
                    target="_blank"
                    href="https://www.instagram.com/teukoapp"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveaway-item-image"
                      style={{
                        backgroundImage:
                          "url(/images/giveaway_adventcalendar2021_carolesannouncementig.jpg)",
                      }}
                    />
                    <p className="giveaway-item-caption">
                    <span className="giveaway-winnernumber">Falalalala</span><br/>Daily announcements on Instagram! Follow us <span className="giveaway-highlightsponsor">@Teukoapp !</span>
                      </p><br/>
                   </a> */}

                </div>
              </Element>
            </div> 

              <hr className="solid-giveaway"></hr> 
              <h2>Thank You To Our Partners</h2>

              <Element
                className="giveawaypartners-group"
                id="groupgiveawaypartners"
                key="groupgiveawaypartners"
                name="groupgiveawaypartners"
              >
                <div className="giveawaypartners-group-items">

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_nurturelife"
                    target="_blank"
                    href="https://bit.ly/3DzpNrx"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner1_nurturelife.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_pastabilities"
                    target="_blank"
                    href="https://bit.ly/3nQieGA"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/Giveawaypartner16_pastabilities.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_monbento"
                    target="_blank"
                    href="https://bit.ly/Monbento-Teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_monbento_corrected20221114.png)",
                      }}
                    />
                </a>

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_warmables"
                    target="_blank"
                    href="https://bit.ly/3sUzNH8"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_warmables.jpg)",
                      }}
                    />
                </a>

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_iboxie"
                    target="_blank"
                    href="https://bit.ly/3sXpj9P"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_iboxie.jpg)",
                      }}
                    />
                </a>

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_arteza"
                    target="_blank"
                    href="https://bit.ly/3U8AkRQ"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_arteza.jpg)",
                      }}
                    />
                </a>

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_lunchpunch"
                    target="_blank"
                    href="https://amzn.to/3UrB8RN"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_lunchpunch_corrected20221110.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_mywish4u"
                    target="_blank"
                    href="https://bit.ly/3SPf2YA"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_mywish4u.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_gogosqueez"
                    target="_blank"
                    href="https://bit.ly/3A0hnIW"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner11_gogosqueez.png)",
                      }}
                    />
                  </a>
                  
                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2022_laurenrivietz"
                    target="_blank"
                    href="https://bit.ly/3te7yTT"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2022_laurenrivietz.jpg)",
                      }}
                    />
                  </a>
                  

                </div>
              </Element>

              {/* <Element
                className="giveawaypartners-group"
                id="groupgiveawaypartners"
                key="groupgiveawaypartners"
                name="groupgiveawaypartners"
              >
                <div className="giveawaypartners-group-items_7logos">
                
                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner4"
                    target="_blank"
                    href="https://eat2explore.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner12_eat2explore.png)",
                      }}
                    />
                </a>

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner9"
                    target="_blank"
                    href="https://weangreen.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner13_weangreen.png)",
                      }}
                    />
                  </a>

                <a
                    className="giveawaypartners-item"
                    key="giveawaypartner3"
                    target="_blank"
                    href="https://www.37chocolates.com?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/Giveawaypartner15_37chocolates.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner10"
                    target="_blank"
                    href="https://thenamestamp.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/Giveawaypartner19_thenamestamp.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner11"
                    target="_blank"
                    href="https://shareasale.com/r.cfm?b=1394503&u=1917091&m=90436&urllink=&afftrack="
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/Giveawaypartner20_thinkoutside.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner8"
                    target="_blank"
                    href="https://kiddyplanet.ca/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner6_kiddyplanet.jpg)",
                      }}
                    />
                  </a>

                  <a
                    className="giveawaypartners-item"
                    key="giveawaypartner13"
                    target="_blank"
                    href="https://inklingspaperie.com?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/Giveawaypartner22_inklings.jpg)",
                      }}
                    />
                  </a> */}

                {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2"
                    target="_blank"
                    href="https://www.gogosqueez.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner11_gogosqueez.png)",
                      }}
                    />
                  </a> */}

                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner1"
                    target="_blank"
                    href="https://smashproducts.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner1_smashproducts.png)",
                      }}
                    />
                  </a> */}

                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner3"
                    target="_blank"
                    href="https://mintunited.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner3_mintunited.png)",
                      }}
                    />
                  </a> */}
                
                {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner1"
                    target="_blank"
                    href="https://www.playosmo.com/en/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner2.jpg)",
                      }}
                    />
                  </a> */}

                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner3"
                    target="_blank"
                    href="https://www.decathlon.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner8.jpg)",
                      }}
                    />
                    <p className="boutique-item-caption">Decathlon</p> 
                  </a> */}
                
                {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner2"
                    target="_blank"
                    href="/"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveaway_may2021_teukologo.png)",
                      }}
                    />
                  </a> */}

                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner5"
                    target="_blank"
                    href="https://bakerly.sjv.io/BjbVJ"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner5.jpg)",
                      }}
                    />
                    <p className="boutique-item-caption">Bakerly</p>
                  </a> */}

                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner7"
                    target="_blank"
                    href="https://shareasale.com/r.cfm?b=837137&u=1917091&m=64399&urllink=&afftrack"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner7.jpg)",
                      }}
                    />
                    <p className="boutique-item-caption">Fun Bites</p>
                  </a> */}

                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner9"
                    target="_blank"
                    href="https://www.chefclub.tv/en-us/recipes/kids?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner9.jpg)",
                      }}
                    />
                    <p className="boutique-item-caption">ChefClubKids Kiddoz</p>
                  </a> */}


                  {/* <a
                    className="giveawaypartners-item"
                    key="giveawaypartner10"
                    target="_blank"
                    href="https://www.stemchef.com/?utm_source=teuko"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="giveawaypartners-item-image"
                      style={{
                        backgroundImage: "url(/images/giveawaypartner10.jpg)",
                      }}
                    />
                    <p className="boutique-item-caption">StemChef</p>
                  </a> */}
                {/* </div>
              </Element> */}

            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default GiveAway;
