import React, { Component } from "react";
import { createClient } from "contentful";
import { map, orderBy } from "lodash";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import moment from "moment";
import { slide as Menu } from "react-burger-menu";

const MAX_NUMBER_OF_NOTIFICATIONS = 5;
const COOKIE_NAME = "notifications-last-display";

class NotificationDropDown extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };
    
    constructor(props) {
        super(props);
        const { cookies } = props;
    
        var notificationsLastDisplay = cookies.get(COOKIE_NAME);
        if (notificationsLastDisplay) {
            try {
                notificationsLastDisplay = moment.unix(parseInt(notificationsLastDisplay));
            } catch(e) {
                console.log("Error parsing " + notificationsLastDisplay, e)
                notificationsLastDisplay = moment.unix(0)
            }
        } else {
            notificationsLastDisplay = moment.unix(0);
        }

        this.state = {
            seen: false,
            smartphone: props.smartphone | false,
            dropdownOpen: false,
            mobileOpenNotification: false,
            notifs: [],
            loading: true,
            notificationsLastDisplay: notificationsLastDisplay,
            newNotifications: false,
            cookies: cookies,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.renderDesktop = this.renderDesktop.bind(this);
        this.renderSmartphone = this.renderSmartphone.bind(this);
        
        this.toggle = this.toggle.bind(this);
        this.wrapperRef = React.createRef();

        this.loadNotifsFromContentful();
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ 
                dropdownOpen: false, 
            });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    loadNotifsFromContentful() {
        const self = this;
        const client = createClient({
            space: "ismqagtvmv5l",
            accessToken:
              "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
          });
          client.getEntries({
              content_type: "notifications",
              include: 2,
            })
            .then((result) => {
                var notifs = map(result.items, item => {
                    return {
                        title: (item.fields.notificationTitle || ""),
                        text: (item.fields.notificationText || ""),
                        timestamp: moment(item.fields.notificationDate || "2022-01-01"),
                        target_link: (item.fields.notificationCtaLink || ""),
                        target_img_link: (item.fields.notificationImage.fields.file.url || ""),
                    };
                });
                notifs = orderBy(notifs, ['timestamp'], ['desc']);
                notifs = notifs.slice(0, MAX_NUMBER_OF_NOTIFICATIONS);
                const newNotifications = notifs[0].timestamp > this.state.notificationsLastDisplay;

                self.setState({
                    notifs: notifs,
                    loading: false,
                    newNotifications: newNotifications,
                }, () => {
                    self.state.cookies.set(COOKIE_NAME, notifs[0].timestamp.unix() + 1)
                });
            });
    }

    toggle() {
        const { dropdownOpen, mobileOpenNotification } = this.state;

        this.setState({
            dropdownOpen: !dropdownOpen,
            mobileOpenNotification: !mobileOpenNotification,
            seen: true,
        })
    }

    renderSmartphone() {
        const { mobileOpenNotification, newNotifications, notifs } = this.state;
        let seenSuffix = newNotifications ? "" : "-seen";

        if (mobileOpenNotification || this.state.seen) {
            this.state.seen = true;
            seenSuffix = "-seen";
        }

        const notifications = map(notifs, (notif, i) => (
            <div key={"href-notification-" + (i)}>
                <a href={notif.target_link}>
                    <div className="header-dropdown-notification-image">
                        <img src={notif.target_img_link} alt="notification" width="50px" height="50px"/>
                    </div>
                    <div className="header-dropdown-notification-content">
                        <div className="header-dropdown-notification-title-smartphone">
                            {notif.title}
                        </div>
                        <div className="header-dropdown-notification-text-smartphone">
                            {notif.text}
                        </div>
                        {/* <div className="header-dropdown-notification-time-smartphone">
                            {notif.timestamp.format("YYYY-MM-DD")}
                        </div> */}
                    </div>
                </a>
                <hr/>
            </div>
        ));

        return (
            <div className={`icon-button-notification-smartphone${seenSuffix}`}>
                <Menu
                    right
                    isOpen={mobileOpenNotification}
                    onClick={() => this.setState({ mobileOpenNotification: !mobileOpenNotification })}
                >
                    <div className="header-dropdown-notification-smartphone">
                        { notifications }
                    </div>
                </Menu>
            </div>
        );
    }

    renderDesktop() {
        const { dropdownOpen, newNotifications, notifs } = this.state;
        let seenSuffix = newNotifications ? "" : "-seen";

        if (dropdownOpen || this.state.seen) {
            this.state.seen = true;
            seenSuffix = "-seen";
        }

        const notifications = map(notifs, (notif, i) => {
            return (
                <div key={i} className="header-dropdown-notification-hover">
                    <a href={notif.target_link}>
                        <div className="header-dropdown-notification-image">
                            <img src={notif.target_img_link} alt="notification" width="50px" height="50px"/>
                        </div>
                        <div className="header-dropdown-notification-content">
                            <div className="header-dropdown-notification-title">
                                {notif.title}
                            </div>
                            <div className="header-dropdown-notification-text">
                                {notif.text}
                            </div>
                            {/* <div className="header-dropdown-notification-time">
                                {notif.timestamp.format("YYYY-MM-DD")}
                            </div> */}
                        </div>
                    </a>
                    <hr className="header-dropdown-notification-hr"/>
                </div>
            );
        });

        return (
            <div
                className={`icon-button-notification${seenSuffix}`}
                onClick={() => this.setState({ dropdownOpen: !dropdownOpen })}
                key="notif-div-desktop"
                ref={this.wrapperRef}
            >
                {dropdownOpen ? (
                    <div key="notification_div" className="header-dropdown-notification">
                        { notifications }
                    </div>
                ) : (
                ""
                )}
            </div>
        );
    }

    render() {
        const { smartphone } = this.state;
        // console.log("SMARTPHONE:", smartphone);

        if (smartphone) {
            return this.renderSmartphone();
        } else {
            return this.renderDesktop();
        }
    }
}

export default withCookies(NotificationDropDown);
