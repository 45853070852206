import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

class AboutUsPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Community - About Us</title>
        </Helmet>


        <div className="about-us-page">
          <div
            className="small-splash-header about-us-header"
            style={{ backgroundImage: "url(/images/banner_image-about-page.jpg)" }}
          >
            <h1>About</h1>
          </div>
          <div className="about-section">
            <div className="standard-container">
              <div className="story">
                {/* <div className="story-photo" /> */}
                <div className="story-content">
                  <h4>Our Story</h4>
                  <p>
                    Jessica and Alexandra are two French-American moms who prepare lunch
                    boxes every day for their young kids in San Francisco. They
                    met several times while volunteering at their kids&apos;
                    school. But it was a pure coincidence when they met again
                    and found out that they were both trying, separately, to
                    pack healthy lunches their children would enjoy.
                  </p>
                  <p>
                    Finding new ideas for what to pack in a child&apos;s
                    lunchbox is a tricky and sometimes painful process for busy
                    parents. No matter your expertise or effort, the same
                    problem presents itself every day.
                  </p>
                  <p>
                    What food should you pack? How do you pack a meal
                    that&apos;s healthy and delicious without expending too much
                    time or effort? How do you come up with new ideas when
                    you&apos;re starved for inspiration?
                  </p>
                  <p>
                    A huge number of families must answer these questions every
                    day, since half of the kids in America bring lunch boxes to
                    school. When it&apos;s time to pack lunch boxes, parents are
                    looking for ideas, efficiency, and motivation. Yet,
                    resources are all over the place.
                  </p>
                  <p>
                    Almost 3,000 lunch boxes later and inspired by 10 years in
                    management of complex projects in tech, Alexandra and
                    Jessica found their mission: bring technology into the daily
                    lunch packing routine, saving parents&apos; time and leaving
                    them inspired and motivated.
                  </p>
                  <p>
                    &quot;Teuko&quot; is a word invented by Jessica&apos;s young
                    daughter when she started to speak. In her innocent world,
                    it means &quot;cute&quot;, &quot;good&quot;, and also
                    expresses satisfaction when learning something or succeeding
                    in a task!
                  </p>
                </div>
              </div>
              <div className="our-team-section">
                <h4>Our Team</h4>
                <div className="team-members">
                  <div className="team-member">
                    <div
                      className="team-member-photo"
                      style={{ backgroundImage: `url(/images/jess.jpg)` }}
                    />
                    <h5>Jessica Gury</h5>
                    <p>
                      <strong>CEO and Co-Founder</strong>
                    </p>
                    <p>
                      Jessica Gury is CEO and Co-Founder of Teuko. Previously,
                      Jessica was leading SAP projects for major companies in
                      Europe, with CGI and Accenture. Jessica holds a Master
                      Degree in Electronics from Polytech Nice Sophia-Antipolis
                      (France), with a specialization in Information and
                      Innovation Management from NTNU (Norway).
                    </p>
                    <a
                      href="mailto:jessica@teuko.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      jessica@teuko.com
                    </a>
                  </div>
                  <div className="team-member">
                    <div
                      className="team-member-photo"
                      style={{ backgroundImage: `url(images/alex.jpg)` }}
                    />
                    <h5>Alexandra Gabalda</h5>
                    <p>
                      <strong>COO and Co-Founder</strong>
                    </p>
                    <p>
                      Alexandra Gabalda is COO and Co-Founder of Teuko.
                      Alexandra has extensive experience in marketing and
                      communication. Prior to joining Teuko, Alexandra held
                      senior roles at Atos. Alexandra holds a Master Degree in
                      Business Administration, from Toulouse Business School.
                    </p>
                    <a
                      href="mailto:alexandra@teuko.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      alexandra@teuko.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AboutUsPage;
