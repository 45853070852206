import React, { Component } from "react";
import gql from "graphql-tag";
import FaClose from "../../fa/close";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

function validateEmail(email) {
  if (!email) {
    return false;
  }
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

class MailingListPopup extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    const cookieName = "mailinglist-popup-displayed";
    const popupDelayInMilliseconds = props.delayInMilliseconds || 12 * 1000; // default = 12s
    const disableCookie = props.disableCookie || false;

    const showPopupCookie = cookies.get(cookieName);

    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      showPopup: false,
      errorMsg: "",
      infoMsg: "",
    };
    if (disableCookie || !showPopupCookie) {
      if (props.client) { // safety first
        setTimeout(
          function () {
            cookies.set(cookieName, true, { path: "/" });
            this.setState({ showPopup: true });
          }.bind(this),
          popupDelayInMilliseconds
        );
      }
    }
  }

  async handleSubscribe(e) {
    e.preventDefault();
    const email = this.email.value;
    if (!validateEmail(email)) {
      this.setState({ errorMsg: "Please enter a valid email address" });
    } else if (email) {
      try {
        await this.props.client.mutate({
          mutation: gql`
            mutation subscribeToMailingList($email: String!) {
              subscribeToMailingList(email: $email)
            }
          `,
          variables: { email },
        });
        this.setState({ infoMsg: "You're subscribed!" });
        setTimeout(() => {
          this.setState({ showPopup: false, infoMsg: "Thank you!" });
        }, 3000);
      } catch (e) {
        console.log("Error subscribeToMailingList", e)
        this.setState({ errorMsg: "Error subscribeToMailingList:" + e });
      }
    } else {
      this.setState({ errorMsg: "No email provided!" });
    }
  }

  close() {
    this.setState({ showPopup: false });
  }

  /* eslint jsx-a11y/anchor-is-valid: 0 */
  render() {
    return (
      <div className="home-page">
        {this.state.showPopup ? (
          <div className="mailchimp-modal">
            <div className="mailchimp-popup">
              <div className="close">
                <FaClose className="close-modal-list" onClick={this.close} />
              </div>
              <div className="mailchimp-popup-header">
                <h4>Get your FREE printable with lunch packing tips!</h4>
              </div>
              {this.state.infoMsg ? (
                <div className="mailchimp-modal-infomsg">
                  <p className="mailchimp-style-infomsg">
                    {this.state.infoMsg}
                  </p>
                </div>
              ) : (
                ""
              )}
              {this.state.errorMsg ? (
                <div className="mailchimp-modal-errormsg">
                  <p className="mailchimp-style-errormsg">
                    {this.state.errorMsg}
                  </p>
                </div>
              ) : (
                ""
              )}
              <div>
                <input
                  name="email"
                  type="email"
                  className="full-input-mailchimp mailchimp-getemail"
                  defaultValue=""
                  placeholder="Your email address"
                  ref={(e) => (this.email = e)}
                />
                <a
                  href="#"
                  className="standard-button mailchimp-subscribe-button"
                  onClick={this.handleSubscribe}
                >
                  Subscribe
                </a>
              </div>
              <div className="mailchimp-modal-privacypolicy">
                <p className="mailchimp-style-privacypolicy">
                  Unsubscribe at anytime |{" "}
                  <a className="mailchimp-style-privacypolicy" href="/privacy">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withCookies(MailingListPopup);
