import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import FaChevronLeft from "../../fa/chevron-left";
import FaChevronRight from "../../fa/chevron-right";
import moment from "moment";
import OneMenu from "./OneMenu";

class MyMenus extends Component {
  constructor(props) {
    super(props);
    this.subtractOne = this.subtractOne.bind(this);
    this.addOne = this.addOne.bind(this);
    this.newDate = this.newDate.bind(this);
    this.goToToday = this.goToToday.bind(this);
    this.state = {
      loading: false,
      today: moment(),
    };
  }
  UNSAFE_componentWillMount() {
    this.setState({
      dates: [
        moment().startOf("week").add(1, "d").format("YYYY-MM-DD"),
        moment().startOf("week").add(2, "d").format("YYYY-MM-DD"),
        moment().startOf("week").add(3, "d").format("YYYY-MM-DD"),
        moment().startOf("week").add(4, "d").format("YYYY-MM-DD"),
        moment().startOf("week").add(5, "d").format("YYYY-MM-DD"),
      ],
      loading: false,
    });
  }
  subtractOne() {
    const { dates } = this.state;
    const nextDate = moment(dates[0]).subtract(1, "week");
    this.newDate(nextDate);
  }
  addOne() {
    const { dates } = this.state;
    const nextDate = moment(dates[0]).add(1, "week");
    this.newDate(nextDate);
  }
  goToToday() {
    this.newDate(this.state.today);
  }
  newDate(nextDate) {
    this.setState({
      dates: [
        moment(nextDate).startOf("week").add(1, "d").format("YYYY-MM-DD"),
        moment(nextDate).startOf("week").add(2, "d").format("YYYY-MM-DD"),
        moment(nextDate).startOf("week").add(3, "d").format("YYYY-MM-DD"),
        moment(nextDate).startOf("week").add(4, "d").format("YYYY-MM-DD"),
        moment(nextDate).startOf("week").add(5, "d").format("YYYY-MM-DD"),
      ],
    });
  }

  async componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677533529897-0');
      });
    }
  }


  render() {
    const { location, user, lunchbox_count } = this.props;
    const { loading, dates } = this.state;
    if (loading) {
      return <div />;
    }
    let dateString = `${moment(dates[0]).format("MMM D")}-${moment(
      dates[4]
    ).format("D")}`;
    if (!moment().isSame(moment(dates[0]), "year")) {
      dateString = `${dateString} ${moment(dates[0]).format("YYYY")}`;
    }


    return (

  <div>

    <div className="my-lunches-header">

      <div className="my-lunches-titleandcounter">
        <h1>My Lunches</h1>
        <div className="profile-details">
          <div className="profile-stats">
            <Link to={`/user/${user.user_name}`}>
              <h2>
                  {lunchbox_count} Lunchbox Ideas
              </h2>
            </Link>
          </div>
        </div>
      </div>

      <div className="mylunches-ad-banner">
        <div className="mylunches-title-header-ad-leaderboard">
          {/* <!-- /22874318077/Teuko-MyLunches-AdUnit_1_Top_Page-Leaderboard-320x50 --> */}
          <div id='div-gpt-ad-1677533529897-0' style={{minWidth: "320px", minHeight: "50px"}} />
        </div>
      </div>

      <div className="my-menus">
        <div className="my-menus-header">
          <div className="my-menu-dates-group">
            <FaChevronLeft
              className="left-menu-arrow menu-arrow"
              onClick={this.subtractOne}
            />
            {/* <h3>Menus for {dateString}</h3> */}
            <div className="my-menus-today-btn" role="button" onClick={this.goToToday}>
              <span>
                <div className="my-menus-today-btn-text">
                  Today
                </div>
              </span>
            </div>
            <FaChevronRight
              className="right-menu-arrow menu-arrow"
              onClick={this.addOne}
            />
          </div>
        </div>
      </div>

    </div>

    <div className="my-menus"> 
      <OneMenu dates={dates} dateString={dateString} location={location} />
    </div>

  </div>

    );
  }
}

MyMenus.propTypes = {
  userId: PropTypes.number,
  location: PropTypes.object,
};

export default MyMenus;
