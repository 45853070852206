import React, { Component } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import FaClose from "../../fa/close";
import { map } from "lodash";
import { Link } from "react-router";
import { profileQuery } from "../../pages/main/ProfilePage";

class ShowFollowers extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
    document.body.style.width = "100%";
    document.body.style.height = "100%";
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      document.body.style.position = "fixed";
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
    document.body.style.width = "unset";
    document.body.style.height = "unset";
    document.body.style.position = "unset";
  }
  submit(e) {
    e.preventDefault();
    const { submit } = this.props;
    const { id } = e.currentTarget.dataset;
    submit(id);
    e.currentTarget.style.display = "none";
  }
  render() {
    const { data, close, userId } = this.props;
    const { loading, error, getFollowers } = data;
    if (loading || error) return <div />;
    const Followers = map(getFollowers, (f) => (
      <div className="follow-item" key={`f${f.id}`}>
        <div className="follow-left">
          <div
            className="follow-pic"
            style={{ backgroundImage: f.image ? `url(${f.image})` : "" }}
          />
          <Link to={`/${f.user_name}`} onClick={close} className="follow-name">
            {f.user_name}
          </Link>
        </div>
        {f.does_follow || userId === f.id || !userId ? (
          ""
        ) : (
          <button
            className="follow-small-button"
            onClick={this.submit}
            data-id={f.id}
          >
            Follow
          </button>
        )}
      </div>
    ));
    return (
      <div className="follow-modal">
        <FaClose className="close-follow" onClick={close} />
        <div className="follow-popup">
          <div className="follow-header">
            <h4>Followers</h4>
          </div>
          {Followers}
          {!getFollowers.length ? (
            <div className="follow-item">None Yet!</div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

const followerQuery = gql`
  query getFollowers($id: Int!) {
    getFollowers(id: $id) {
      id
      user_name
      does_follow
      image
    }
  }
`;

const toggleFollowAction = gql`
  mutation toggleFollow($id: Int!) {
    toggleFollow(id: $id)
  }
`;

ShowFollowers.propTypes = {
  profileId: PropTypes.number,
  userId: PropTypes.number,
  user_name: PropTypes.string,
  close: PropTypes.func,
  data: PropTypes.object,
};

export default compose(
  graphql(followerQuery, {
    options: (props) => ({
      variables: { id: props.profileId },
      fetchPolicy: "network-only",
    }),
  }),
  graphql(toggleFollowAction, {
    props: ({ ownProps, mutate }) => ({
      submit: (id) =>
        mutate({
          variables: { id },
          refetchQueries: [
            {
              query: profileQuery,
              variables: { user_name: ownProps.user_name },
            },
          ],
        }),
    }),
  })
)(ShowFollowers);
