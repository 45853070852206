import React, { Component } from "react";
import { browserHistory } from "react-router";
import PropTypes from "prop-types";
import { map, find } from "lodash";
import moment from "moment";
import { sortFoodsOnlyCats } from "../../components/lunches/helpers";

class MenuPrintPage extends Component {
  componentDidMount() {
    document.getElementById("footer").style.display = "none";
    setTimeout(() => {
      window.print();
    }, 1000);
  }
  componentWillUnmount() {
    document.getElementById("footer").style.display = "initial";
  }
  render() {
    const {
      location: {
        state: { dateString, dates, getMenu },
      },
    } = this.props;
    const Lunches = map(dates, (d) => {
      const sMenu = find(getMenu, { date: d });
      if (!sMenu) {
        return (
          <div className="lunch-print-place-holder" key={`d${d}`}>
            <h3>{moment(d).format("dddd")}</h3>
          </div>
        );
      }
      const LunchFoods = map(sortFoodsOnlyCats(sMenu.lunch.foods), (f) => (
        <span key={f.id} className="food-item-print">
          {f.name}
        </span>
      ));
      return (
        <div className="lunch-print-box" key={`awef${sMenu.id}`}>
          <h3>{moment(d).format("dddd")}</h3>
          <img src={sMenu.lunch.image} alt="" />
          {LunchFoods}
        </div>
      );
    });
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="menu-print-page">
        <a onClick={browserHistory.goBack} className="menu-print-back">
          Back
        </a>
        <img
          src="/images/teukologo.png"
          className="print-logo"
          alt="Teuko Logo"
        />
        <h1>Menu for {dateString}</h1>
        <div className="menu-boxes">{Lunches}</div>
      </div>
    );
  }
}

MenuPrintPage.propTypes = {
  location: PropTypes.object,
};

export default MenuPrintPage;
