import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import FaFacebookSquare from "../../fa/facebook-square";
import FaTwitter from "../../fa/twitter";
import { map } from "lodash";

import { MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const lodash = require("lodash");
const moment = require("moment");
const contentful = require("contentful");

const createClient = contentful.createClient;

const Bold = ({ children }) => <span class="challenge-bold">{children}</span>;

const ContentfulRichTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  // renderText: (text) => text.replace('!', '?'),
};

class ChallengesRules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      challengeName: props.params.challengeName,
    };
  }


  async getContentfulEntries(typeName, contentfulAdaptor) {
    const contentfulClient = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });

    let result = [];
    let skip = 0;
    let n = -1;
    while (n !== 0) {
      let data = [];
      data = await contentfulClient.getEntries({
        content_type: typeName,
        include: 2,
        limit: 1000,
        skip: skip,
      });
      n = data.items.length;
      const extractedData = map(data.items, (item) => {
        let obj = contentfulAdaptor(item);
        obj.id = item.sys.id;
        return obj;
      });
      result = lodash.concat(result, extractedData);
      skip += n;
    }
    return result;
  }

  async getContentfulChallenges() {
    return await this.getContentfulEntries("challenges", (item) => {
      return {
        visualBannerChallengeUrl:
          item.fields.visualBannerChallenge.fields.file.url,
        visualTrophyUrl: item.fields.visualTrophy.fields.file.url,
        titleChallenge: item.fields.titleChallenge,
        rulesShort: item.fields.rulesShort,
        prizesShort: item.fields.prizesShort,
        datesChallengeBegin: moment(item.fields.datesChallengeBegin),
        datesChallengeEnd: moment(item.fields.datesChallengeEnd),
        urlChallenge: item.fields.urlChallenge,
        challengePartnerName: item.fields.challengePartnerName,
        challengeOverview: documentToReactComponents(item.fields.challengeOverview, ContentfulRichTextOptions),
        challengeRules: documentToReactComponents(item.fields.challengeRules, ContentfulRichTextOptions),
        challengeAdditionalInformation: documentToReactComponents(item.fields.challengeAdditionalInformation, ContentfulRichTextOptions),
      };
    });
  }

  async componentDidMount() {
    const challenges = await this.getContentfulChallenges();
    const filteredChallenges = lodash.filter(challenges, c => c.titleChallenge === this.state.challengeName);

    if (filteredChallenges.length > 0) {
      this.setState({
        challenge: filteredChallenges[0],
      });
    }
  }

  render() {
    const challenge = this.state.challenge;
    if (!challenge) {
      return <h2>LOADING</h2>;
    }

    return (
      <Fragment>
        <div className="browse-page">
        <Helmet>
          <title>Teuko Lunchbox Community - Challenges</title>
          
          <meta
          property="og:url"
          content={`https://www.teuko.com/challenges`}
          />
          <meta property="og:type" content="website" />
          <meta
          property="og:title"
          content={`Teuko Lunchbox Community - Challenges`}
          />
          <meta property="og:description" content="Challenges are a way to find motivation with your friends on Teuko!" />
          <meta property="og:image" content="/images/Teuko challenges share visual.jpg" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@teukoapp" />
          <meta
          name="twitter:title"
          content={`Teuko Lunchbox Community - Challenges`}
          />
          <meta name="twitter:description" content="Challenges are a way to find motivation with your friends on Teuko!" />
          <meta name="twitter:image" content="/images/teuko trophies.jpg" />
      </Helmet>

          <div className="trophies-page">
            <img
              src={challenge.visualBannerChallengeUrl}
              className="challengesrules-featured-banner"
              alt={challenge.titleChallenge}
            ></img>

            <div className="in-app-container">
              <div className="challenges-featured-group">
                <div className="challenges-featured-group-items">
                  <div className="challengesrules-featured-details">
                    <div className="challengesrules-featured-trophy-badge">
                      <img
                        src={challenge.visualTrophyUrl}
                        className="challengesrules-featured-trophy-badge-image"
                        alt={challenge.titleChallenge}
                      ></img>
                    </div>

                    <div className="challenges-featured-text-details">
                      <div className="challenges-featured-text-title">
                        <h1>{challenge.titleChallenge}</h1>
                      </div>
                      <div className="challenges-featured-rule-short">
                        <img
                          src="/images/Banner_Challenges_Visual_Rules.jpg"
                          className="challenges-visual-rules"
                          alt="teuko lunchbox community challenges visual rules"
                        ></img>
                        <p>{challenge.rulesShort}</p>
                      </div>
                      <div className="challenges-featured-prize-short">
                        <img
                          src="/images/Banner_Challenges_Visual_Prizes.jpg"
                          className="challenges-visual-prizes"
                          alt="teuko lunchbox community challenges visual prizes"
                        ></img>
                        <p>{challenge.prizesShort}
                        
                        </p>
                      </div>

                      <div className="challenges-featured-dates">
                        <img
                          src="/images/Banner_Challenges_Visual_Dates.jpg"
                          className="challenges-visual-dates"
                          alt="teuko lunchbox community challenges visual dates"
                        ></img>
                        <p>{challenge.datesChallengeBegin.format("MMM Do YYYY")} to {challenge.datesChallengeEnd.format("MMM Do YYYY")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="challengesrules-featured-details">
                    <h2>Overview</h2>
                    <p>{challenge.challengeOverview}</p>
                    <h2>Rules</h2>
                    <p>{challenge.challengeRules}</p>
                    <h2>Additional Info</h2>
                    <p>{challenge.challengeAdditionalInformation}</p>
                  </div>
                </div>

                <div className="challenges-button-cta-complete-rules">
                  <ul className="trophies-social-shares">
                    <li className="landing-social-shares-list-item">
                    <a data-share-url={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com/challenges`}
                                data-ga-action="Facebook share"
                                data-ga-event="Site"
                                aria-describedby="NewWindow"
                                className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com/challenges`}
                                target="_blank"
                                rel="noopener noreferrer"
                                >
                                    <FaFacebookSquare />
                                    <span className="visuallyhidden">Facebook</span>
                                    <span className="trophies-social-share__button-text">Share</span>
                                </a>
                    </li>
                    <li className="landing-social-shares-list-item">
                      <a
                        data-share-url="https://twitter.com/intent/tweet?text=Unlock+lunch+packing+superpowers+with+Teuko%21+Get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com"
                        data-ga-action="Twitter share"
                        data-ga-event="Site"
                        aria-describedby="NewWindow"
                        className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                        href="https://twitter.com/intent/tweet?text=Challenge+yourself+with+Teuko%21+Plus+get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                        <span className="visuallyhidden">Twitter</span>
                        <span className="trophies-social-share__button-text">
                          Tweet
                        </span>
                      </a>
                    </li>
                    <li className="landing-social-shares-list-item">
                      <a
                        className="challenges-button challenges-button-cta-complete-rules"
                        href="/challenges"
                      >
                      View More Challenges
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChallengesRules;
