import React, { Component, Fragment } from "react";
import { Link, browserHistory } from "react-router";
import PropTypes from "prop-types";
import { graphql, compose, withApollo } from "react-apollo";
import gql from "graphql-tag";
import countries from "./countries.js";
import Dropdown from "react-dropdown";
import { map } from "lodash";
import FacebookLogin from '@greatsumini/react-facebook-login';
import FaFacebook from "../../fa/facebook-square";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";

ReactPixel.init("565944627072649");

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

function isValid(str) {
  // eslint-disable-next-line no-useless-escape
  return !/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(str);
}

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.stageOne = this.stageOne.bind(this);
    this.stageTwo = this.stageTwo.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.setError = this.setError.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.state = {
      stage: 1,
      country: { value: "US", label: "United States" },
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.user.id) browserHistory.push("/");
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  async handleRegister(e) {
    e.preventDefault();
    this.setState({ error: "" });
    const email = this.email.value;
    const password = this.password.value;
    if (!email || !password)
      return this.setError("Please enter an email and password");
    else if (!validateEmail(email))
      return this.setError("Please enter a valid email");
    else if (password.length < 5)
      return this.setError(
        "Please enter a password at least 5 characters long"
      );
    const response = await this.props.client.query({
      query: gql`
        query emailTaken($email: String!) {
          emailTaken(email: $email)
        }
      `,
      variables: { email },
    });
    if (response.data.emailTaken) return this.setError("This email is taken");
    this.setState({ stage: 2, email, password });
    window.scrollTo(0, 0);
  }
  async handleSignUp(e) {
    ReactPixel.fbq("track", "CompleteRegistration", {
      content_name: "email_signup",
      value: 0.0,
      currency: "USD",
    });

    e.preventDefault();
    this.setState({ error: "" });
    const { email, password } = this.state;
    const user_name = this.user_name.value;
    const country = this.state.country.value;
    const postal = this.zipcode.value;
    if (!user_name || !postal)
      return this.setError("Please fill out all fields");
    else if (!isValid(user_name)) return this.setError("Invalid User Name");
    const response = await this.props.client.query({
      query: gql`
        query usernameTaken($user_name: String!) {
          usernameTaken(user_name: $user_name)
        }
      `,
      variables: { user_name },
    });
    if (response.data.usernameTaken)
      return this.setError("This username is taken");
    try {
      const data = await this.props.createUser({
        variables: {
          email,
          password,
          user_name,
          country,
          postal,
        },
      });
      if (data.data.createUser.id) {
        const myInit = {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email,
            password,
          }),
        };
        fetch(`/login`, myInit)
          .then((res) => {
            if (!res.ok) throw Error("Incorrect email or password");
            return res.json();
          })
          .then((data) => {
            localStorage.setItem("sessionID", data.sessionID);
            window.location.href = "/";
          })
          .catch((err) => {
            this.setState({ error: err });
          });
      }
    } catch (e) {
      console.log(e);
      return this.setError("There was an unknown error");
    }
  }
  setError(error) {
    this.setState({ error });
  }
  async responseFacebook(response) {
    const myInit = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        accessToken: response.accessToken,
        profile: response,
      }),
    };
    fetch(`/loginfacebook`, myInit)
      .then((res) => {
        if (!res.ok)
          return this.setError(
            "No account associated with this Facebook account"
          );
        return res.json();
      })
      .then((data, err) => {
        console.log("/loginfacebook response", data);
        if (data.newUser) {
          ReactPixel.fbq("track", "CompleteRegistration", {
            content_name: "facebook_signup",
            value: 0.0,
            currency: "USD",
          });
        }
        if (data) {
          localStorage.setItem("sessionID", data.sessionID);
          window.location.href = "/";
        } else {
          throw new Error("Facebook login error! " + err);
        }
      })
      .catch((err) => {
        this.setError(err.toString());
      });
  }
  stageOne() {
    const { email, password } = this.state;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      
      <Fragment>
        <div
            className="signup-small-splash-header"
            style={{ backgroundImage: "url(/images/banner_image-teuko-lunchbox-bento-school-lunch-ideas-community-signup-3.jpg)" }}
          >
          </div>
        <Helmet>
          <title>Teuko Lunchbox Ideas Community - Sign Up</title>
        </Helmet>
        

        <div className="sign-up-options">
          {/* <h3>
            Get easy kids' lunch ideas, recipes, and tips, all in one place!
            Sign Up with
          </h3> */}
          
          <div className="landing-heading-section-subtitle">
            <h2>Lunchbox ideas, by parents for parents.<br/>Sign up in seconds!</h2>
            <p><br/>
            Use your email or Facebook login to continue with Teuko.<br/>It's FREE! No credit card required.<br/><br/>
            </p>
          </div>
          <FacebookLogin
            appId="566204480216246"
            fields="name, email, picture"
            onSuccess={this.responseFacebook}
            initParams={{
              version: 'v17.0',
              xfbml: true,
            }}
            style={{
              borderRadius: '4px',
              marginTop: '12px',
              borderRadius: '27.5px',
              textTransform: 'uppercase',
              fontSize: '15px',
              backgroundColor: '#3b5997',
              fontWeight: '500',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              margin: '0 auto',
            }}
            render={({ onClick, logout }) => (
              <button 
                onClick={onClick} 
                onLogoutClick={logout} 
                type="button" 
                class="standard-button account-button facebook-button facebook-login-button metro"
              >
                <FaFacebook/>&nbsp;&nbsp;Sign Up with Facebook
              </button>
            )}
            disableMobileRedirect={true}
          />
          <h6>Or</h6>
          <div className="account-details-group">
            <span className="error-class">{this.state.error}</span>
            <input
              className="full-input"
              defaultValue={email}
              type="email"
              placeholder="Email"
              ref={(e) => (this.email = e)}
            />
            <input
              className="full-input"
              defaultValue={password}
              type="password"
              placeholder="Password"
              ref={(e) => (this.password = e)}
            />
          </div>
          <a
            href="#"
            className="standard-button account-button"
            onClick={this.handleRegister}
          >
            Free Sign Up for Teuko
          </a>
        </div>
        <div className="teuko-agreement">
          <p>
            By Signing up for Teuko, you agree to the{" "}
            <Link to="/terms">Terms of Service</Link>. View our{" "}
            <Link to="/privacy">Privacy Policy</Link>.
          </p>
        </div>
        <div className="loginpage-invitesignup">
          <p>
            Already have an account? Log in <Link to="/login">here</Link>
          </p>
        </div>
      </Fragment>
    );
  }
  stageTwo() {
    const { email, country } = this.state;
    const options = map(countries.countries.country, (c) => ({
      label: c.countryName,
      value: c.countryCode,
    }));
    return (
      <Fragment>
        <h3>Welcome to Teuko!</h3>
        <h5 className="connect-header">
          Get lunchbox inspiration, organization, and motivation, all in one
          place.
        </h5>
        <div className="secondary-sign-up">
          <h4 className="email-address">{email}</h4>
          <div className="input-group">
            <span className="error-class">{this.state.error}</span>
            <input
              className="full-input"
              type="text"
              placeholder="Username"
              ref={(e) => (this.user_name = e)}
            />
            <div className="location-input-group">
              <Dropdown
                options={options}
                value={country}
                onChange={(e) => this.setState({ country: e })}
                className="country-dropdown standard-dropdown"
              />
              <input
                className="zip-input"
                type="text"
                onKeyPress={(event) =>
                  event.charCode >= 48 && event.charCode <= 57
                }
                placeholder="Zip Code"
                ref={(e) => (this.zipcode = e)}
              />
            </div>
          </div>
          <a
            href="#"
            className="standard-button sign-up-button"
            onClick={this.handleSignUp}
          >
            Sign Up
          </a>
          <a
            href="#"
            className="go-back"
            onClick={() => this.setState({ stage: 1, error: "" })}
          >
            Go Back
          </a>
        </div>
      </Fragment>
    );
  }
  render() {
    const { stage } = this.state;
    return (
      <div className="popup-modal accounts-modal">
        {stage === 1 ? this.stageOne() : ""}
        {stage === 2 ? this.stageTwo() : ""}
      </div>
    );
  }
}

SignUp.propTypes = {
  close: PropTypes.func,
};

const createNewUserAction = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $user_name: String!
    $country: String!
    $postal: String!
  ) {
    createUser(
      email: $email
      password: $password
      user_name: $user_name
      country: $country
      postal: $postal
    ) {
      id
    }
  }
`;

const createNewUserFacebookAction = gql`
  mutation createUserFacebook(
    $email: String!
    $accessToken: String!
    $fb_id: String!
    $first_name: String
    $last_name: String
    $image: String
    $user_name: String!
    $country: String!
    $postal: String!
  ) {
    createUserFacebook(
      email: $email
      accessToken: $accessToken
      fb_id: $fb_id
      first_name: $first_name
      last_name: $last_name
      image: $image
      user_name: $user_name
      country: $country
      postal: $postal
    ) {
      id
    }
  }
`;

export default compose(
  withApollo,
  graphql(createNewUserAction, {
    name: "createUser",
  }),
  graphql(createNewUserFacebookAction, {
    name: "createUserFacebook",
  })
)(SignUp);
