import React from "react";
import Icon from "react-icon-base";

const Partypopper = (props) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      
<path
        d="M3716 5099c-33-26-34-64-4-145 30-84 30-244 0-331-30-85-29-116 5-143 32-25 50-23 158 10 90 28 231 24 315-9 72-28 105-27 135 4 31 30 31 46 0 139-35 106-35 227 1 336 23 69 25 84 14 109-6 16-25 35-41 41-25 11-40 9-109-14-109-36-227-36-337-1-95 30-103 30-137 4zM3192 4487c-12-13-22-32-22-44 0-11-16-51-36-89-32-62-36-75-32-130 3-50 11-74 41-122 20-33 37-68 37-77 0-10-13-41-29-69-54-94-49-175 13-204 46-23 106 13 106 63 0 9 16 49 35 88 52 105 44 196-25 284-27 35-25 56 10 119 63 109 45 204-39 204-26 0-44-7-59-23zM2492 4167c-38-40-29-79 34-155 137-166 188-387 135-595-25-94-42-129-148-294l-86-133-244 238c-142 138-263 247-289 261-97 52-222 35-309-41-55-48-62-61-155-292l-73-178 724-725c398-398 830-827 959-953l235-228 185 74c102 40 199 81 215 90 116 65 172 208 130 335-16 49-40 77-268 306-138 139-249 254-246 256 12 11 192 128 244 159 140 85 329 117 485 84 99-21 220-80 299-146 71-59 113-69 150-36 47 43 29 98-52 164-211 171-485 230-748 163-99-25-171-62-352-179l-149-95-313 314-313 314 104 160c164 252 200 362 192 585-4 106-10 139-37 220-39 116-93 210-167 290-64 69-103 79-142 37z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
      <path
        d="M4253 4138c-41-43-68-100-83-171-16-80-55-118-145-141-158-40-231-112-270-267-24-96-59-130-155-150-73-15-122-41-170-89-49-49-74-98-90-173-18-88-66-136-155-151-99-18-195-89-195-144 0-38 27-70 65-78 23-4 41 2 75 25 25 16 68 35 95 41 149 33 236 117 269 258 8 35 22 74 31 88 22 34 70 61 119 69 130 21 228 114 262 248 29 114 62 150 153 167 74 14 115 34 166 81 53 49 88 111 104 186 6 29 24 72 41 94 35 49 38 77 9 113-31 39-85 36-126-6zM1914 4021c-39-17-74-70-74-112 0-31 7-47 34-77 31-33 39-37 85-37 44 0 55 4 83 33 28 28 33 39 33 82 0 42-5 54-31 81-33 33-91 46-130 30zM4652 3604c-28-19-52-65-52-99s24-80 52-99c12-9 43-16 68-16 52 0 83 18 105 60 28 55 11 120-39 154-32 20-105 21-134 0zM4088 3020c-39-30-39-90-2-119 15-12 36-21 48-21 11 0 41-14 65-30 89-61 182-64 277-9 61 35 85 34 138-5 69-51 152-61 191-21 47 46 23 117-42 130-16 3-39 12-53 20s-43 24-65 37c-30 17-56 22-105 23-55 0-74-5-122-32-32-18-65-33-74-33s-42 16-73 35c-76 47-144 56-183 25zM1276 2779c-109-264-526-1292-526-1297 0-4 228-235 508-514l507-507 655 265c360 145 663 267 673 272 15 6-148 172-893 911l-910 903-14-33zM4146 1809c-33-26-34-64-4-139 18-46 22-75 22-170s-4-124-22-170c-30-76-29-113 5-140 32-25 50-23 158 10 92 28 218 24 322-10 84-27 118-22 142 22 13 23 13 34 3 70-7 24-19 66-27 93-23 80-19 194 10 281 29 86 31 111 10 141-24 35-63 37-151 7-70-23-90-26-184-22-74 3-122 11-165 26-73 27-86 27-119 1zM672 1293c-5-10-94-225-198-478-186-455-188-461-188-535-1-112 46-190 146-245C477 9 496 5 560 5c74 0 81 3 455 154 209 85 426 173 482 195 57 23 103 43 103 46s-207 209-460 458c-389 383-460 450-468 435z"
        transform="matrix(.1 0 0 -.1 0 512)"
      ></path>
    
    </g>
  </Icon>
);

export default Partypopper;
