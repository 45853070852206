import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";

class GroceryshoppingPage extends Component {
  constructor(props) {
    super(props);
    this.registerClick = this.registerClick.bind(this);
  }
  registerClick(e) {
    e.preventDefault();
    document.getElementById("sign-up").click();
  }
  render() {
    
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Ideas Community - Features Grocery Shopping</title>
          <meta name="description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <link rel="canonical" href="https://www.teuko.com/Lunchboxcards/"></link>
          <meta name="robots" content="follow, index, max-image-preview:large" />
          <meta name="googlebot" content="follow, index, max-image-preview:large"></meta>
          <meta property="og:url" content="https://www.teuko.com/features/"></meta>
          <meta property="og:description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:title" content="Explore Teuko Features to Unlock Your Lunchbox Creativity"></meta>
          <meta property="og:site_name" content="Teuko"></meta>
          <meta name="keywords" content="teuko, lunchbox, community, online community, bento, kids food, food, school lunch, bentobox, meal prep, cooking, parenting, packed lunch, lunch bag, kids lunchbox, kids lunch, lunch, bento lunch box, lunch boxes for kids, lunches for kids, lunch bags for kids, insulated lunch box, warm lunch, hot lunch, thermos lunch box, insulated lunch bags, lunch boxes, cute lunch box, easy lunch boxes, picky eaters food, toddler food, teenager food, lunchbox accessories, planetbox lunch box, bentgo lunch box, sistema lunch box, omie lunch box, omiebox, yumbox lunch box, French school lunch menus, Japanese school lunch menus, worldwide school lunch menus, lunchbox mom, lunchbox dad, lunchbox store, lunchbox boutique, lunchbox shop, lunchbox ideas, kids lunchbox ideas, easy lunchbox ideas, easy kids lunchbox ideas, healthy lunchbox ideas, healthy kids lunchbox ideas, lunchbox containers for kids, meal prep lunchbox "/>
          <meta property="fb:app_id" content="566204480216246"></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:title" content="Explore Teuko Features to Unlock Your Lunchbox Creativity"></meta>
          <meta name="twitter:site" content="@teukoapp"></meta>
          <meta name="twitter:description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <meta name="twitter:creator" content="@teukoapp"></meta>
          <meta property="og:image" content="/images/features-social-image_w1200xh630_teuko-lunchbox-community-kids-school-lunch-bento-food.png"></meta>
          <meta name="twitter:image" content="/images/features-social-image_w1200xh630_teuko-lunchbox-community-kids-school-lunch-bento-food.png"></meta>          
        </Helmet>
        
        <div className="partners-page">

            <div className="landing-heading-section">

                <section className="navigation-features">
                    <ul className="navigation-features-list">
                        <li className="navigation-features-list-item">
                            <a draggable="false" className="navigation-features-list-item-link" tabIndex="0" href="https://www.teuko.com/">Home</a>
                            <span aria-hidden="true" className="navigation-features-list-item-signet"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path></svg></span>
                        </li>
                        <li className="navigation-features-list-item">
                            <a draggable="false" className="navigation-features-list-item-link" tabIndex="0" href="https://www.teuko.com/features">Features</a>
                            <span aria-hidden="true" className="navigation-features-list-item-signet"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z"></path></svg></span>
                        </li>
                        <li className="navigation-features-list-item">
                        <a draggable="false" className="navigation-features-list-item-nolink" tabIndex="0" href="/groceryshopping">Grocery Shopping</a>
                        </li>
                    </ul>
                </section>

                <div className="features-heading-section-containerflex">
            
                    <div className="landing-heading-section-textcta">
                    <div className="landing-heading-section-subtitle">
                        <h1>Grocery shopping made easy, in your grocery store, or online</h1>
                        <p>You can save time by getting a grocery list at the touch of a button. You can also order your groceries online and conveniently get your groceries directly at your doorstep.<br/><br/></p>
                        <h3>Currently integrated with</h3>
                        <img
                        src="/images/AmazonFreshLogo_nobackground.png"
                        href="https://amzn.to/3jPS6tL"
                        alt="Amazon Fresh Logo"
                        />
                        <p className="amazon-grocery-region"><br/>The online grocery shopping and delivery service is currently available within the United States of America only.<br/>Not in the U.S.A.? You can still join Teuko (free signup!) and you'll get notified when it's available in your region.</p>
                    </div>

                    <div className="container-browse-all-btn">
                        <Link to="/signup" className="standard-button browse-all-button">
                        Enjoy now
                        </Link>
                    </div>
                    </div>

                    <div className="landing-heading-section-image" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep">
                        <img
                        src="/images/features_groceryshopping.jpg"
                        className="features-item-image-groceryshopping2"
                        alt="Teuko lunchbox community kids school lunch bento ideas packed lunch easy meal prep food groups"
                        />

                    </div>
                </div>
            </div>
       
        </div>
      </Fragment>
    );
  }
}

export default GroceryshoppingPage;
