import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { graphql, withApollo, compose } from "react-apollo";
import gql from "graphql-tag";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setError = this.setError.bind(this);
    this.state = {
      error: "",
    };
  }
  setError(error) {
    this.setState({ error });
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.setError("");
    const {
      mutate,
      data: {
        currentUser: { hashed_password },
      },
    } = this.props;
    const new_password = this.new_password.value;
    const new_password_confirm = this.new_password_confirm.value;
    const variables = { new_password };
    let password = "";
    if (!new_password || !new_password_confirm)
      return this.setError("Please fill in all fields");
    else if (new_password !== new_password_confirm)
      return this.setError("Your new passwords do not match");
    if (hashed_password) {
      password = this.password.value;
      variables.password = password;
      if (!password) return this.setError("Please enter your password");
    }
    const response = await mutate({ variables });
    if (response.data.changePassword) {
      document.getElementById("save-password").innerText = "Password Changed!";
    } else return this.setError("Incorrect Password");
  }
  render() {
    const { error } = this.state;
    const { data } = this.props;
    if (data.loading || data.error) return <div />;
    const {
      currentUser: { hashed_password },
    } = data;
    return (
      <Collapsible trigger="Password">
        <div className="change-password-form">
          <span className="error-class">{error}</span>
          {!hashed_password ? (
            ""
          ) : (
            <div className="form-group">
              <label>Current Password</label>
              <input type="password" ref={(el) => (this.password = el)} />
            </div>
          )}
          <div className="form-group">
            <label>New Password</label>
            <input type="password" ref={(el) => (this.new_password = el)} />
          </div>
          <div className="form-group">
            <label>Confirm New Password</label>
            <input
              type="password"
              ref={(el) => (this.new_password_confirm = el)}
            />
          </div>
          <div className="form-group">
            <button
              className="settings-button"
              id="save-password"
              onClick={this.handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
      </Collapsible>
    );
  }
}

const passwordQuery = gql`
  query currentUser {
    currentUser {
      id
      hashed_password
    }
  }
`;

const changePasswordMutation = gql`
  mutation changePassword($password: String, $new_password: String!) {
    changePassword(password: $password, new_password: $new_password)
  }
`;

export default compose(
  withApollo,
  graphql(passwordQuery),
  graphql(changePasswordMutation)
)(ChangePassword);
