import { filter, map } from "lodash";
import FaFacebookSquare from "../../fa/facebook-square";
import FaTwitter from "../../fa/twitter";
import React, { Component, Fragment } from "react";
import { Link } from "react-router";

const lodash = require("lodash");
const moment = require("moment");
const contentful = require("contentful");

const createClient = contentful.createClient;

class ChallengesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async getContentfulEntries(typeName, contentfulAdaptor) {
    const contentfulClient = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });

    let result = [];
    let skip = 0;
    let n = -1;
    while (n !== 0) {
      let data = [];
      data = await contentfulClient.getEntries({
        content_type: typeName,
        include: 2,
        limit: 1000,
        skip: skip,
      });
      n = data.items.length;
      const extractedData = map(data.items, (item) => {
        let obj = contentfulAdaptor(item);
        obj.id = item.sys.id;
        return obj;
      });
      result = lodash.concat(result, extractedData);
      skip += n;
    }
    return result;
  }

  async getContentfulChallenges() {
    return await this.getContentfulEntries("challenges", (item) => {
      return {
        visualBannerChallengeUrl:
          item.fields.visualBannerChallenge.fields.file.url,
        visualTrophyUrl: item.fields.visualTrophy.fields.file.url,
        titleChallenge: item.fields.titleChallenge,
        rulesShort: item.fields.rulesShort,
        prizesShort: item.fields.prizesShort,
        datesChallengeBegin: moment(item.fields.datesChallengeBegin),
        datesChallengeEnd: moment(item.fields.datesChallengeEnd),
        urlChallenge: item.fields.urlChallenge,
        challengePartnerName: item.fields.challengePartnerName,
        challengeOverview:
          item.fields.challengeOverview.content[0].content[0].value,
        challengeRules: item.fields.challengeRules.content[0].content[0].value,
        challengeAdditionalInformation:
          item.fields.challengeAdditionalInformation ? 
            item.fields.challengeAdditionalInformation.content[0].content[0].value 
            : "",
        featured: item.fields.featured,
      };
    });
  }

  async componentDidMount() {
    this.setState({
      challenges: await this.getContentfulChallenges(),
    });
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677287789124-0');
        googletag.display('div-gpt-ad-1677287940644-0');
        googletag.display('div-gpt-ad-1677288687679-0');
        googletag.display('div-gpt-ad-1677288831800-0');
      });
    }
  }

  render() {
    console.log(this);
    const username = this.props.user.user_name;
    const { user } = this.props;

    if (!this.state.challenges) {
      return <h2>LOADING</h2>;
    }

    const regularChallenges = filter(this.state.challenges, c => !c.featured )
    const featuredChallenges = filter(this.state.challenges, c => c.featured )

    const challenges1 = map(featuredChallenges, (c) => {
      return (
        <div className="challenges-featured-group-items">
          <Link to={`/challenges/${c.titleChallenge}/rules`}>
            <img
              src={c.visualBannerChallengeUrl}
              className="challenges-featured-banner"
              alt={c.titleChallenge}
            ></img>
          </Link>

          <div className="challenges-featured-details">
            <div className="challenges-featured-trophy-badge">
              <img
                src={c.visualTrophyUrl}
                className="challenges-featured-trophy-badge-image"
                alt="teuko lunchbox community challenges Planetbox June 2022"
              ></img>
            </div>
            <div className="challenges-featured-text-details">
              <div className="challenges-featured-text-title">
                <h1>{c.titleChallenge}</h1>
              </div>
              <div className="challenges-featured-rule-short">
                <img
                  src="/images/Banner_Challenges_Visual_Rules.jpg"
                  className="challenges-visual-rules"
                  alt="teuko lunchbox community challenges visual rules"
                ></img>
                <p>{c.rulesShort}</p>
              </div>
              <div className="challenges-featured-prize-short">
                <img
                  src="/images/Banner_Challenges_Visual_Prizes.jpg"
                  className="challenges-visual-prizes"
                  alt="teuko lunchbox community challenges visual prizes"
                ></img>
                <p>{c.prizesShort}</p>
              </div>
              <div className="challenges-featured-dates">
                <img
                  src="/images/Banner_Challenges_Visual_Dates.jpg"
                  className="challenges-visual-dates"
                  alt="teuko lunchbox community challenges visual dates"
                ></img>
                <p>
                  {c.datesChallengeBegin.format("MMM Do YYYY")} to {c.datesChallengeEnd.format("MMM Do YYYY")}
                </p>
              </div>
              <div className="challenges-button-cta-complete-rules">
                <Link
                  className="challenges-button"
                  to={`/challenges/${c.titleChallenge}/rules`}
                >
                  See Complete Rules
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });

    const challenges2 = map(regularChallenges, (c) => {
      return (
        <div className="challenges-group-items-card">
          <div className="challenge-group-items-header">
            <div className="challenge-group-items-header-trophy-badge">
              <img
                src={c.visualTrophyUrl}
                alt="trophies teuko lunchbox community"
                width="75px"
                height="75px"
              />
            </div>
            {/* <div className="challenge-group-items-header-banner">
              <img
                src={c.visualBannerChallengeUrl}
                alt="trophies teuko lunchbox community"
                width="267px"
                height="85px"
              />
            </div> */}
          </div>
          <div className="challenge-group-items-details">
            <div className="challenges-featured-text-title">
              <p className="challenge-title">{c.titleChallenge}</p>
            </div>
            <div className="challenges-featured-rule-short">
              <img
                src="/images/Banner_Challenges_Visual_Rules.jpg"
                className="challenges-visual-rules"
                alt="teuko lunchbox community challenges visual rules"
              ></img>
              <p>{c.rulesShort}</p>
            </div>
            <div className="challenges-featured-prize-short">
              <img
                src="/images/Banner_Challenges_Visual_Prizes.jpg"
                className="challenges-visual-prizes"
                alt="teuko lunchbox community challenges visual prizes"
              ></img>
              <p>{c.prizesShort}</p>
            </div>
            <div className="challenges-featured-dates">
              <img
                src="/images/Banner_Challenges_Visual_Dates.jpg"
                className="challenges-visual-dates"
                alt="teuko lunchbox community challenges visual dates"
              ></img>
              <p>
                {c.datesChallengeBegin.format("MMM Do YYYY")} to {c.datesChallengeEnd.format("MMM Do YYYY")}
              </p>
            </div>
            <div className="challenges-button-cta-complete-rules">
              <a href={`/challenges/${c.titleChallenge}/rules`} className="challenges-button">See Complete Rules</a>
            </div>
          </div>
        </div>
      );
    });        

    let viewTrophiesButton = "";
    if (username !== "") {
      viewTrophiesButton = 
        <div className="trophies-header-submit-challenge">
          <ul className="trophies-social-shares">
            <li className="landing-social-shares-list-item">
              <a
                href={`/user/${username}/trophies`}
                rel="noopener noreferrer"
                className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
              >
                <span className="trophies-social-share__button-text">
                  View my trophies
                </span>
              </a>
            </li>
          </ul>
        </div>
    }

    return (
      <Fragment>
        <div className="browse-page">
          <div className="trophies-page">
            <div className="in-app-container">
              <div className="challenges-page-header">
                <div className="trophies-header">
                  {/* <div className="trophies-header-challenge-yourself-laptop"> */}
                    <h1>Challenges</h1>
                    
                

                    <ul className="trophies-social-shares">
                      <li className="landing-social-shares-list-item">
                        <a
                          data-share-url="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com"
                          data-ga-action="Facebook share"
                          data-ga-event="Site"
                          aria-describedby="NewWindow"
                          className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com/challenges"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFacebookSquare />
                          <span className="visuallyhidden">Facebook</span>
                          <span className="trophies-social-share__button-text">
                            Share
                          </span>
                        </a>
                      </li>
                      <li className="landing-social-shares-list-item">
                        <a
                          data-share-url="https://twitter.com/intent/tweet?text=Unlock+lunch+packing+superpowers+with+Teuko%21+Get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com"
                          data-ga-action="Twitter share"
                          data-ga-event="Site"
                          aria-describedby="NewWindow"
                          className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                          href="https://twitter.com/intent/tweet?text=Challenge+yourself+with+Teuko%21+Plus+get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaTwitter />
                          <span className="visuallyhidden">Twitter</span>
                          <span className="trophies-social-share__button-text">
                            Tweet
                          </span>
                        </a>
                      </li>
                    </ul>
                  

                  { viewTrophiesButton }

                  {/* <div className="trophies-header-submit-challenge">
                    <ul className="trophies-social-shares">
                      <li className="landing-social-shares-list-item">
                        <a
                          href="https://in7gdap593s.typeform.com/to/PBooEZzg"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                        >
                          <span className="trophies-social-share__button-text">
                            Submit Challenge
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
                
              </div>

              <div className="challenges-ad-banner">
                <div className="search-title-header-ad-laptop_leaderboard">
                  {/* <!-- /22874318077/Teuko-Challenges-Laptop_AdUnit_1_Top_Page-Leaderboard-728x90 --> */}
                  <div id='div-gpt-ad-1677287789124-0' style={{minWidth: "728px", minHeight: "90px"}} />
                </div>
                <div className="search-title-header-ad-smartphone_leaderboard">
                  {/* <!-- /22874318077/Teuko-Challenges-Smartphone_AdUnit_1_Top_Page-Leaderboard-320x50 --> */}
                  <div id='div-gpt-ad-1677287940644-0' style={{minWidth: "320px", minHeight: "50px"}} />
                </div>
              </div>

              <div className="challenges-featured-group">
                { challenges1 }
              </div>

              <div className="challenges-group">
                <div className="challenges-group-items">
                  { challenges2 }
                </div>
              </div>

              <div className="challenges-ad-banner">
                <div className="search-title-header-ad-laptop_leaderboard">
                  {/* <!-- /22874318077/Teuko-Challenges-Laptop_AdUnit_2_Bottom_Page-Leaderboard-728x90 --> */}
                  <div id='div-gpt-ad-1677288687679-0' style={{minWidth: "728px", minHeight: "90px"}} />
                </div>
                <div className="search-title-header-ad-smartphone_leaderboard">
                  {/* <!-- /22874318077/Teuko-Challenges-Smartphone_AdUnit_2_Bottom_Page-Leaderboard-320x50 --> */}
                  <div id='div-gpt-ad-1677288831800-0' style={{minWidth: "320px", minHeight: "50px"}} />
                </div>
              </div>


              { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to discover lunchbox ideas you'll love and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}


            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ChallengesPage;
