import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Helmet } from "react-helmet";

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      submitted: false,
    };
  }
  handleSubmit(e) {
    e.preventDefault();
    const self = this;
    const { mutate } = this.props;
    mutate({
      variables: {
        email: this.email.value,
        message: this.message.value,
      },
    }).then(() => {
      self.setState({ submitted: true });
      self.email.value = "";
      self.message.value = "";
    });
  }
  render() {
    const { submitted } = this.state;
    return (
      <div className="contact-page">
        <Helmet>
          <title>Teuko Lunchbox Community - Contact Us</title>
        </Helmet>
        <div
          className="contact-small-splash-header contact-header"
          style={{ backgroundImage: "url(/images/contact.jpg)" }}
        >
          <div className="overlay-small-header" />
          <h1>Contact Us</h1>
        </div>
        <div className="contact-section">
          <div className="standard-container">
            <form className="contact-form" onSubmit={this.handleSubmit}>
              <div className="cform-group">
                <label>Your Email Address</label>
                <input
                  type="email"
                  className="cf-input"
                  placeholder="Email"
                  ref={(el) => (this.email = el)}
                />
              </div>
              <div className="cform-group">
                <label>Your Message</label>
                <textarea
                  className="cf-input"
                  placeholder="Share your thoughts"
                  ref={(el) => (this.message = el)}
                />
              </div>
              <div className="cform-group">
                <input
                  type="submit"
                  value={submitted ? "Sent!" : "Submit"}
                  className="cf-submit"
                />
              </div>
            </form>
            <div className="contact-right">
              <h4>We&apos;re here to help!</h4>
              <p>
                Have a question or want to share some ideas? Don't hesitate to
                contact us, via the form, or at{" "}
                <a href="mailto:contact@teuko.com">contact@teuko.com</a>
              </p>
              <div className="c-separator" />
              <h4>We can work together</h4>
              <p>
                Collaborations, Brand Ambassadorship, Sponsored Content... There
                are many ways to partner with us!{" "}
                <a href="mailto:contact@teuko.com">Let's get in touch!</a>
              </p>
              <br />
              <p>
                Interested in writing about us?{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://drive.google.com/open?id=1SqTLd1_agKaqOpT9w-_XwfmpCj4a9cCi"
                >
                  Our press kit is available for download here
                </a>
                . Also, please feel free to{" "}
                <a href="mailto:contact@teuko.com" rel="noopener noreferrer">
                  email us
                </a>{" "}
                directly!
              </p>
              <div className="c-separator" />
              <h4>Let's connect on Social Media</h4>
              <p>
                You can find us on
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/teukoapp"
                >
                  Facebook
                </a>{" "}
                -{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/teukoapp"
                >
                  Instagram
                </a>{" "}
                -{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.twitter.com/teukoapp"
                >
                  Twitter
                </a>{" "}
                -{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.pinterest.com/teukoapp"
                >
                  Pinterest
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const contactMutation = gql`
  mutation contact($email: String!, $message: String) {
    contact(email: $email, message: $message)
  }
`;

export default graphql(contactMutation)(ContactPage);
