import React, { Component } from "react";
import { browserHistory } from "react-router";
import PropTypes from "prop-types";
import { keys, map, values } from "lodash";

class ShoppingListPrintPage extends Component {
  componentDidMount() {
    document.getElementById("footer").style.display = "none";
    setTimeout(() => {
      window.print();
    }, 1000);
  }
  componentWillUnmount() {
    document.getElementById("footer").style.display = "initial";
  }
  render() {
    const {
      location: {
        state: { dateString, foodGroups },
      },
    } = this.props;

    const groups = keys(foodGroups);
    const groceryList = map(groups, (groupName) => {
      const foods = map(foodGroups[groupName], (line) => {
        //line.id, line.asin, line.groceryCategory, line.groceryLabel, line.name, line.image,
        return (
          <div key={`key_${line.id}`} className="shoppinglist-entry">
            &#9634; {line.name}
          </div>
        );
      });

      return (
        <div key={`key_${groupName}`}>
          <h3>{groupName}</h3>
          {foods}
        </div>
      );
    });

    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="shoppinglist-print-page" align="left">
        <a onClick={browserHistory.goBack} className="shoppinglist-print-back">
          Back
        </a>
        <img
          src="/images/teukologo.png"
          className="print-logo"
          alt="Teuko Logo"
        />
        <h1>Grocery list for {dateString}</h1>
        <div className="shoppinglist-boxes">{values(groceryList)}</div>
      </div>
    );
  }
}

ShoppingListPrintPage.propTypes = {
  location: PropTypes.object,
};

export default ShoppingListPrintPage;
