import React, { Component } from "react";
import PropTypes from "prop-types";
import FaClose from "../../fa/close";
import { concat, filter, groupBy, map } from "lodash";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router";

class ShowFavLunchboxItems extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
    document.body.style.width = "100%";
    document.body.style.height = "100%";
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      document.body.style.position = "fixed";
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
    document.body.style.width = "unset";
    document.body.style.height = "unset";
    document.body.style.position = "unset";
  }
  async UNSAFE_componentWillMount() {
    document.body.style.overflow = "auto";
    document.body.style.width = "unset";
    document.body.style.height = "unset";
    document.body.style.position = "unset";

    const boutiqueItems = await (
      await fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/boutique.json"
      )
    ).json();

    this.setState({ loading: false, boutiqueItems });
  }
  submit(e) {
    e.preventDefault();
    const { submit } = this.props;
    const { id } = e.currentTarget.dataset;
    submit(id);
    e.currentTarget.style.display = "none";
  }
  render() {
    const { close, userId, profileId, profileName, data } = this.props;
    const { loading, boutiqueItems } = this.state;
    if (loading || data.loading) return <div>Loading...</div>;

    const favoriteItems = filter(boutiqueItems, (item) => {
      return data.getFavoriteItems.indexOf(item.id) >= 0;
    });
    const groupedFavorites = groupBy(favoriteItems, (item) => item.category);

    function listOfFavorite(categoryName, favoriteItems) {
      let items = map(favoriteItems, (item) => (
        <div className="favlunchboxitems-list-item" key={`i_${item.id}`}>
          <div className="follow-left">
            <div
              className="favlunchboxitems-image"
              style={{
                backgroundImage: item.image ? `url(${item.image})` : "",
              }}
            />
            <p className="favlunchboxitems-name">{item.name}</p>
          </div>
          <a
            className="shop-now"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Shop Now
          </a>
        </div>
      ));
      return concat(
        <div className="favlunchboxitems-group-title" key={categoryName}>
          <h5>{categoryName}</h5>
        </div>,
        items
      );
    }

    const favorites = map(groupedFavorites, (items, category) => {
      return listOfFavorite(category, items);
    });

    return (
      <div className="follow-modal">
        <div className="follow-popup">
          <div className="favlunchboxitems-list-header">
            <h3> {profileName}'s Favorite Items</h3>
            <div className="favlunchboxitems-list-icons">
              <FaClose className="close-modal-list" onClick={close} />
            </div>
          </div>
          <div>{favorites}</div>
          <div>
            {favorites.length === 0 && userId !== profileId ? (
              <div className="follow-item">None Yet!</div>
            ) : (
              ""
            )}
          </div>
          <div>
            {favorites.length === 0 && userId === profileId ? (
              <div className="follow-lunchbox-item">
                None yet, you can add your favorite items in{" "}
                <Link to="/settings">Preferences</Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

ShowFavLunchboxItems.propTypes = {
  profileId: PropTypes.number,
  userId: PropTypes.number,
  close: PropTypes.func,
  data: PropTypes.object,
};

const favoriteItemQuery = gql`
  query getFavoriteItems($profileId: Int!) {
    getFavoriteItems(profileId: $profileId)
  }
`;

export default graphql(favoriteItemQuery, {
  options: (props) => ({
    variables: { profileId: props.profileId },
    fetchPolicy: "network-only",
  }),
})(ShowFavLunchboxItems);
