import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { graphql, withApollo, compose } from "react-apollo";
import gql from "graphql-tag";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setError = this.setError.bind(this);
    this.state = {
      error: "",
    };
  }
  setError(error) {
    this.setState({ error });
  }
  async handleSubmit(e) {
    e.preventDefault();
    const email_on_likes = this.email_on_likes.checked ? 1 : 0;
    const email_on_settings = this.email_on_settings.checked ? 1 : 0;
    const email_on_bookmarks = this.email_on_bookmarks.checked ? 1 : 0;
    const variables = { email_on_likes, email_on_settings, email_on_bookmarks };
    const response = await this.props.mutate({ variables });
    if (response.data.changeNotifications) {
      document.getElementById("save-notification-settings").innerText =
        "Changes Saved!";
    }
  }
  render() {
    const { error } = this.state;
    const { data } = this.props;
    if (data.loading || data.error) return <div />;
    const {
      currentUser: { email_on_likes, email_on_settings, email_on_bookmarks },
    } = data;
    return (
      <Collapsible trigger="Notifications">
        <div className="change-notifications-form">
          <span className="error-class">{error}</span>
          <h5>Receive email notifications when:</h5>
          <div className="preference-group">
            <input
              type="checkbox"
              ref={(el) => (this.email_on_likes = el)}
              defaultChecked={!!email_on_likes}
            />
            Friends send you Likes
          </div>
          <div className="preference-group">
            <input
              type="checkbox"
              ref={(el) => (this.email_on_bookmarks = el)}
              defaultChecked={!!email_on_bookmarks}
            />
            Friends save your lunchbox ideas
          </div>
          <div className="preference-group">
            <input
              type="checkbox"
              ref={(el) => (this.email_on_settings = el)}
              defaultChecked={!!email_on_settings}
            />
            Your Settings are changed
          </div>
          <div className="form-group">
            <button
              className="settings-button"
              id="save-notification-settings"
              onClick={this.handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
      </Collapsible>
    );
  }
}

const notificationsQuery = gql`
  query currentUser {
    currentUser {
      id
      email_on_likes
      email_on_bookmarks
      email_on_settings
    }
  }
`;

const changeNotificationsMutation = gql`
  mutation changeNotifications(
    $email_on_likes: Int
    $email_on_bookmarks: Int
    $email_on_settings: Int
  ) {
    changeNotifications(
      email_on_likes: $email_on_likes
      email_on_bookmarks: $email_on_bookmarks
      email_on_settings: $email_on_settings
    )
  }
`;

export default compose(
  withApollo,
  graphql(notificationsQuery, {
    options: { fetchPolicy: "network-only" },
  }),
  graphql(changeNotificationsMutation)
)(Notifications);
