import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

class TermsPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Community - Privacy Policy</title>
        </Helmet>
        <div className="terms-page">
          <div className="standard-container">
            <div className="container">
              <div className="page-header">
                <center>
                  <h1 className="bg-info">Teuko Privacy Policy</h1>
                </center>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <p>Effective Date: August 2023, 23</p>
                  <p>
                    Thank you for using Teuko! We are committed to provide you
                    the best experience possible for being empowered against the
                    lunchbox blues!
                    <br />
                    Your privacy is very important to us. Please read below to
                    see how Teuko handles information. Before you get to the
                    details, here are a few of our fundamental privacy
                    principles:
                  </p>
                  <ul>
                    <li>
                      Information you upload or post to Teuko may be seen by
                      others depending on your privacy settings.
                    </li>
                    <li>
                      We aggregate and de-identify certain information about our
                      members to use for business purposes. We do not sell your
                      personal information.
                    </li>
                    <li>
                      We give you several ways to control the privacy of your
                      personal information and are continuously working to
                      enhance privacy options available to you.
                    </li>
                  </ul>
                  <p>
                    Teuko respects your privacy and shares your concern about
                    the security of information you may submit to Teuko’s
                    websites and the services (collectively, the “Services”).
                    Our privacy policy (the “Privacy Policy”) explains the
                    information we collect, how we use and share it, how to
                    manage your privacy settings and your rights in connection
                    with the Services. Please also read the Terms of Service
                    which sets out the terms governing the Services.
                  </p>
                  <p>
                    Teuko is headquartered in San Francisco and our Services are
                    provided to you by Teuko, Inc. If you are a resident of the
                    European Economic Area (“EEA”), Teuko, Inc. is the
                    controller of your personal data for the purposes of EU data
                    protection law.
                  </p>
                  <p>
                    This policy was written in English. To the extent a
                    translated version conflicts with the English version, the
                    English version controls. Unless indicated otherwise, this
                    Privacy Policy does not apply to third party products or
                    services or the practices of companies that we do not own or
                    control, including other companies you might interact with
                    on or through the Services.
                  </p>
                  <p>
                    Questions or comments about this Privacy Policy may be
                    submitted by mail to the address below or at{" "}
                    <a
                      href="mailto:contact@teuko.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      contact@teuko.com
                    </a>
                  </p>
                  <p>
                    Teuko, Inc.
                    <br />
                    3145 Geary Blvd #473
                    <br />
                    San Francisco, CA 94118
                    <br />
                    Attn: Legal
                    <br />
                    contact@teuko.com
                  </p>
                  <h3>
                    We Collect, Use, Share and Otherwise Process Information
                  </h3>
                  <p>
                    Teuko relies on a number of legal bases to collect, use,
                    share, and otherwise process the information we have about
                    you for the purposes described in this Privacy Policy,
                    including:
                  </p>
                  <ul>
                    <li>
                      as necessary to provide the Services and fulfill our
                      obligations pursuant to the Terms of Service. For example,
                      we cannot provide the Services unless we collect and use
                      your lunchbox menu information;
                    </li>
                    <li>
                      where you have consented to the processing, which you can
                      revoke at any time. For example, you may consent to the
                      processing of food-related information;
                    </li>
                    <li>
                      where necessary to comply with a legal obligation, a court
                      order, or to exercise and defend legal claims;
                    </li>
                    <li>
                      to protect your vital interests, or those of others, such
                      as in the case of emergencies;
                    </li>
                    <li>where you have made the information public;</li>
                    <li>where necessary in the public interest; and</li>
                    <li>
                      where necessary for the purposes of Teuko’s or a third
                      party’s legitimate interests, such as those of visitors,
                      members or partners.
                    </li>
                  </ul>
                  <h3>Our Legitimate Interests</h3>
                  <p>
                    We process your information in furtherance of our legitimate
                    interests, including:
                  </p>
                  <ul>
                    <li>
                      providing and improving the Services, including any
                      personalized Services. We do so as it is necessary to
                      pursue our legitimate interests of providing and
                      developing innovative and tailored offerings to our
                      members on a sustained basis; and
                    </li>
                    <li>
                      keeping the Services safe and secure. We do so as it is
                      necessary to pursue the legitimate interests of Teuko and
                      its members in ensuring the Services are secure, and to
                      protect against fraud, spam and abuse, etc.
                    </li>
                  </ul>
                  <h3>Information Teuko Collects</h3>
                  <p>
                    Teuko collects information about you, including information
                    that directly or indirectly identifies you, if you or other
                    members choose to share it with Teuko. We receive
                    information in a few different ways, including when you
                    track, complete or upload lunchbox activities using the
                    Services. Teuko also collects information about how you use
                    the Services. There are also several opportunities for you
                    to share information about yourself and your activities with
                    Teuko. For example:
                  </p>
                  <ul>
                    <li>
                      We collect basic account information such as your name,
                      email address, age range, username and password that helps
                      secure and provide you with access to our Services.
                    </li>
                    <li>
                      Profile and use information is collected about you when
                      you choose to upload a picture, a post, join a challenge,
                      add your equipment usage, view others’ activities, or
                      otherwise use the Services.
                    </li>
                    <li>
                      Teuko collects information from devices and apps you
                      connect to Teuko. For example, you may connect your Iphone
                      to Teuko via Safari and information from the device and
                      browser will be passed along to Teuko.
                    </li>
                    <li>
                      Teuko may collect or infer food information which may
                      include information such as proteins, grains, vegetables,
                      fruits, dairy. By using Teuko, when you upload food
                      information to Teuko or information from which Teuko can
                      infer food information, you give your consent to the
                      processing of that food information by Teuko. You can
                      withdraw your consent to Teuko processing your food
                      information at any time.
                    </li>
                    <li>
                      We gather information from the photos, posts, comments,
                      likes and other content you share on the Services,
                      including when you participate in partner events.
                    </li>
                    <li>
                      Teuko allows you to sign up and log in to the Services
                      using accounts you create with third-party products and
                      services, such as Facebook (with other third-party
                      products and services, we’ll mention collectively as
                      “Third-Party Accounts”). If you access the Services with
                      Third-Party Accounts we will collect information that you
                      have agreed to make available such as your name, email
                      address, profile information and preferences with the
                      applicable Third-Party Account. This information is
                      collected by the Third-Party Account provider and is
                      provided to Teuko under their privacy policies. You can
                      generally control the information that we receive from
                      these sources using the privacy settings in your
                      Third-Party Account.
                    </li>
                    <li>
                      To help us to provide you with the best possible service,
                      you can choose to provide us with your contact information
                      so we can better respond to your support requests and
                      comments.
                    </li>
                    <li>
                      We also obtain additional third party information about
                      members from marketers, partners, researchers, and others.
                      We may combine information that we collect from you with
                      information obtained from other members, third parties and
                      information derived from any other subscription, product,
                      or service we provide. We may also collect information
                      about you from other members such as when they give you
                      likes or comment about you.
                    </li>
                    <li>
                      We collect information from your browser, computer, or
                      mobile device, which provide us with technical information
                      when you access or use the Services. This technical
                      information includes device and network information,
                      cookies, log files and analytics information.
                    </li>
                  </ul>
                  <h5>Cookies and Similar Technologies</h5>
                  <p>
                    When you visit the Services, a cookie will be stored on your
                    computer. Generally, cookies and similar technologies work
                    by assigning to your browser or device a unique number that
                    has no meaning outside of Teuko. Teuko uses these
                    technologies to personalize your experience and to assist in
                    delivering content specific to your interests. Additionally,
                    after you’ve entered your username/email and password during
                    a session on the Services, Teuko saves that information so
                    you don’t have to re-enter it repeatedly during that
                    session.
                  </p>
                  <p>
                    Most browsers automatically accept cookies. To manage the
                    collection of information through cookies or other
                    equivalent technology you can use the settings on your
                    browser or mobile device. Teuko is committed to providing
                    you choices to manage your privacy and sharing. However,
                    Teuko does not recognize or respond to browser-initiated Do
                    Not Track signals, as the internet industry has not fully
                    developed Do Not Track standards, implementations and
                    solutions. To learn more about Do Not Track signals, you can
                    visit https://allaboutdnt.com. Not accepting cookies may
                    make certain features of the Services unavailable to you.
                    Teuko may also use your IP address to identify you, to
                    administer the Services and to assist in diagnosing problems
                    with Teuko’s servers.
                  </p>
                  <h5>Log Files</h5>
                  <p>
                    The Services use log files. Stored information includes IP
                    addresses, browser type, internet service provider (ISP),
                    referring/exit pages, platform type, date/time stamp, and
                    number of clicks. This information is used to analyze
                    trends, administer, protect and secure the Services, track
                    member movement in the aggregate, and gather broad
                    demographic information for aggregate use. IP addresses,
                    etc., may be linked to session IDs, lunch packers IDs and
                    device identifiers.
                  </p>
                  <h5>Other Information</h5>
                  <p>
                    We may collect information from you through third parties,
                    such as when we collect your feedback through surveys.
                  </p>
                  <p>
                    We may also collect information about you from other members
                    such as when they give you likes or comment on your
                    activities.
                  </p>
                  <h3>How Teuko Uses Information</h3>
                  <p>
                    Teuko uses the information we collect and receive to
                    administer and operate the Services and as otherwise
                    described in this Privacy Policy. For example, we use your
                    food information to provide you with statistics. Subject to
                    your privacy settings, your information, including your
                    profile, username, photos, members you follow and who follow
                    you, and likes and comments you give and receive will be
                    shared on Teuko to participate in the Services, for example
                    to show your lunchbox idea on a feed.
                  </p>
                  <p>
                    We also use your information to analyze, develop and improve
                    the Services. To do this, Teuko may use third party
                    analytics providers to gain insights into how our Services
                    are used and to help us improve the Services. We may also
                    use your information to market the Services, credit or
                    accept payments, provide support related to the Services,
                    protect members and enforce our Terms of Service, promote
                    safety, and communicate with you. Additionally, your
                    information may be shared with third parties, as set forth
                    below.
                  </p>
                  <p>
                    Teuko may de-identify or aggregate the content you make
                    available in connection with the Services, in ways that do
                    not personally identify you. Examples of such aggregated
                    information or statistical data include information about
                    equipment (for example, lunchbox items), usage,
                    demographics, food. Teuko may use, sell, license, and share
                    this information with third parties for research, business
                    or other purposes such as to improve lunch packing.
                  </p>
                  <p>
                    We use your information to communicate with you about the
                    Services, send you marketing communications, or let you know
                    about new features or updates to our Terms of Service. We
                    also use your information to respond to you when you contact
                    us. Teuko will use your information to communicate with you,
                    for example by sending you notifications.
                  </p>
                  <h3>Aggregate Information</h3>
                  <p>
                    We do not sell your personal information. Teuko may
                    aggregate the information you and others make available in
                    connection with the Services and post it publicly or share
                    it with third parties. Examples of the type of information
                    we may aggregate include information about lunch boxes,
                    demographics, foods and statistics. Teuko may use, sell,
                    license, and share this aggregated information with third
                    parties for research, business or other purposes such as to
                    improve lunchbox items or food or to help our partners
                    understand more about lunchbox packerss, including the
                    people who use their products and services.
                  </p>
                  <h3>How Information is Shared</h3>
                  <h5>Service Providers</h5>
                  <p>
                    We may share your information with third parties who provide
                    services to Teuko such as supporting and improving the
                    Services, promoting the Services, processing payments, or
                    fulfilling orders. These service providers will only have
                    access to the information necessary to perform these limited
                    functions on our behalf and are required to protect and
                    secure your information. We may also engage service
                    providers to collect information about your use of the
                    Services over time on our behalf so that we or they may
                    promote Teuko or display information that may be relevant to
                    your interests on the Services or other websites or
                    services.
                  </p>
                  <h5>Publicly Available Information</h5>
                  <p>
                    Subject to your settings, your information and content may
                    be publicly accessible.
                  </p>
                  <h5>Third Party Business via API or Other Integrations</h5>
                  <p>
                    When you choose to use third party apps, plug-ins, or
                    websites that integrate with the Services, they may receive
                    your information and content, including your personal
                    information, photos, and your activity data. Information
                    collected by these third parties is subject to their terms
                    and policies. Teuko is not responsible for the terms or
                    policies of third parties.
                  </p>
                  <h5>Affiliates and Acquirors of our Business or Assets</h5>
                  <p>
                    We may share your information with affiliates under common
                    control with us, who are required to comply with the terms
                    of this Privacy Policy with regard to your information. If
                    Teuko becomes involved in a business combination, securities
                    offering, bankruptcy, reorganization, dissolution or other
                    similar transaction, we may share or transfer your
                    information in connection with such transaction.
                  </p>
                  <h5>Legal Requirements</h5>
                  <p>
                    We may preserve and share your information with third
                    parties, including law enforcement, public or governmental
                    agencies, or private litigants, within or outside your
                    country of residence, if we determine that such disclosure
                    is reasonably necessary to comply with the law, including to
                    respond to court orders, warrants, subpoenas, or other legal
                    or regulatory process. We may also retain, preserve or
                    disclose your information if we determine that disclosure is
                    reasonably necessary or appropriate to prevent any person
                    from death or serious bodily injury, to address issues of
                    national security or other issues of public importance, to
                    prevent or detect violations of our Terms of Service or
                    fraud or abuse of Teuko or its members, or to protect our
                    operations or our property or other legal rights, including
                    by disclosure to our legal counsel and other consultants and
                    third parties in connection with actual or potential
                    litigation.
                  </p>
                  <h5>DMCA Notices</h5>
                  <p>
                    We may share your information with third parties when we
                    forward Digital Millennium Copyright Act (DMCA)
                    notifications, which will be forwarded as submitted to us
                    without any deletions.
                  </p>
                  <h3>How We Protect Information</h3>
                  <p>
                    We take several measures to safeguard the collection,
                    transmission and storage of the data we collect. Although we
                    strive to employ reasonable protections for your information
                    that are appropriate to its sensitivity, we do not guarantee
                    or warrant the security of the information you share with us
                    and we are not responsible for the theft, destruction, loss
                    or inadvertent disclosure of your information or content. No
                    system is 100% secure. The Services uses industry standard
                    Secure Sockets Layer (SSL) technology to allow for the
                    encryption of personal information and credit card numbers.
                    The Services are registered with site identification
                    authorities so that your browser can confirm Teuko’s
                    identity before any personally identifiable information is
                    sent. In addition, Teuko’s secure servers protect this
                    information using advanced firewall technology.
                  </p>
                  <p>
                    To help ensure that these measures are effective in
                    preventing unauthorized access to your private information,
                    you should be aware of the security features available to
                    you through your browser. You should use a security-enabled
                    browser to submit your credit card information and other
                    personal information at the Services. Please note that if
                    you do not use a SSL-capable browser, you are at risk for
                    having data intercepted.
                  </p>
                  <p>
                    Most browsers have the ability to notify you if you change
                    between secure and insecure communications, receive invalid
                    services identification information for the Services you are
                    communicating with, or send information over an unsecured
                    connection. Teuko recommends that you enable these browser
                    functions to help ensure that your communications are
                    secure. You can also monitor the URL of the services you are
                    visiting (secure URLs begin with https:// rather than
                    http://), along with the security symbol of your browser to
                    help identify when you are communicating with a secure
                    server. You can also view the details of the security
                    certificate of the services to which you are connected.
                    Please check the validity of any Services you connect to
                    using secure communications.
                  </p>
                  <h5>Disclaimer</h5>
                  <p>
                    While Teuko continues to work hard to protect your personal
                    information, no data transmission over the Internet can be
                    guaranteed to be absolutely secure, and Teuko cannot ensure
                    or warrant the security of any information you transmit to
                    Teuko. Transmitting personal information is done at your own
                    risk.
                  </p>
                  <h3>Managing Your Settings</h3>
                  <h5>Privacy Settings</h5>
                  <p>
                    Teuko offers settings to help you manage your privacy. To
                    manage your privacy settings, please visit
                    https://teuko.com/settings
                  </p>
                  <h5>Adjust Notification and Email Preferences</h5>
                  <p>
                    Teuko offers various ways to manage the notifications you
                    receive. You can choose to stop receiving certain emails and
                    notifications by indicating your preference at
                    https://www.teuko.com/settings. You may also unsubscribe by
                    following the instructions contained at the bottom of each
                    type of email. Any administrative or service-related emails
                    (to confirm a purchase, or an update to this Privacy Policy
                    or the Terms of Service, etc.) generally do not offer an
                    option to unsubscribe as they are necessary to provide the
                    Services you requested.
                  </p>
                  <h5>Updating Account Information</h5>
                  <p>
                    You may correct, amend or update profile or account
                    information that is inaccurate at any time by adjusting that
                    information in your account settings. If you need further
                    assistance, please contact Teuko at contact@teuko.com. Teuko
                    will generally respond to your request within 7-10 business
                    days.
                  </p>
                  <h5>Deleting Information and Accounts</h5>
                  <p>
                    You may request that your account is deleted by visiting
                    https://www.teuko.com/settings. Once deleted, your data,
                    including your account, cannot be reinstated.
                  </p>
                  <p>
                    Note that content you have shared with others (for example,
                    through social media) or that others have copied may also
                    remain visible after you have deleted your account or
                    deleted the information from your own profile. Your public
                    profile may be displayed in search engine results until the
                    search engine refreshes its cache.
                  </p>
                  <h3>Your Rights and Our Legal Bases</h3>
                  <p>
                    We provide the same suite of privacy tools and controls to
                    all of our members worldwide. Particular rights may be
                    available to you if you reside in certain locations, such as
                    the European Economic Area ("EEA") or California. Learn more
                    below about your rights and how to exercise them.
                  </p>
                  <h4>European Economic Area Member Rights</h4>
                  <h5>Your Rights and How to Exercise Them</h5>
                  <p>
                    If you are habitually located in the European Economic Area
                    ("EEA"), you have the right to access, rectify, download or
                    erase your information, as well as the right to restrict and
                    object to certain processing of your information. While some
                    of these rights apply generally, certain rights apply only
                    in certain limited circumstances. We describe these rights
                    below:
                  </p>
                  <h6>Object</h6>
                  <p>
                    Where we process your information based on our legitimate
                    interests explained above, or in the public interest, you
                    can object to this processing in certain circumstances. In
                    such cases, we will cease processing your information unless
                    we have compelling legitimate grounds to continue processing
                    or where it is needed for legal reasons.
                  </p>
                  <h6>Access and Porting</h6>
                  <p>
                    You can access much of your information by logging into your
                    account. If you require additional access or if you are not
                    a Teuko member, contact us at contact@teuko.com. To download
                    a copy of your data, contact us at contact@teuko.com. You
                    will receive an email with a link to download your data.
                    Your data archive will contain the following
                  </p>
                  <ul>
                    <li>
                      A folder with all the photos you’ve uploaded to Teuko.
                    </li>
                    <li>
                      CSV files that include: all the lunchbox menus you’ve
                      posted, the information in your profile, actions you’ve
                      performed including Likes and Bookmarks.
                    </li>
                  </ul>
                  <p>
                    Note that information about other Teuko members will not be
                    included in your data archive.
                  </p>
                  <h6>Rectify, Restrict, Limit, Delete</h6>
                  <p>
                    You can also rectify, restrict, limit or delete much of your
                    information by logging into your account. If you are unable
                    to do this, please contact us at contact@teuko.com. Teuko
                    will generally respond to your request within 10-14 business
                    days.
                  </p>
                  <h6>Revoke consent</h6>
                  <p>
                    Where you have previously provided your consent, you have
                    the right to withdraw your consent to the processing of your
                    information at any time. For example, you can withdraw your
                    consent by updating your settings. In certain cases, we may
                    continue to process your information after you have
                    withdrawn consent if we have a legal basis to do so or if
                    your withdrawal of consent was limited to certain processing
                    activities.
                  </p>
                  <h6>Complain</h6>
                  <p>
                    Should you wish to raise a concern about our use of your
                    information (and without prejudice to any other rights you
                    may have), you have the right to do so with your local
                    supervisory authority.
                  </p>
                  <h5>
                    European Economic Area Members: Our Legal Bases for
                    Processing Your Information
                  </h5>
                  <p>
                    European data protection law requires organizations to have
                    legal bases to collect, use, share and otherwise process
                    information about you. While some of your rights apply
                    generally, certain rights only apply depending on the legal
                    bases we rely on to process data. We’ve explained these
                    legal bases and your rights below.
                  </p>
                  <h6>To provide the Teuko Services</h6>
                  <p>
                    As described in the Terms of Service, the core Teuko
                    Services cannot be provided, and the Terms of Service cannot
                    be performed, without Teuko processing data about you
                    including your food data. Since we process data you provide
                    to us which is necessary to perform our contract with you,
                    you have the right to port or transfer that data if you are
                    habitually resident in the EEA.
                  </p>
                  <h6>With your consent</h6>
                  <p>
                    We ask for your permission to process your information for
                    certain purposes and you have the right to withdraw your
                    consent at any time. We ask for your consent to:
                  </p>
                  <ul>
                    <li>
                      Collect or infer food information. We use your food
                      information to provide helpful statistics and
                      visualizations.
                    </li>
                    <li>Send you marketing communications.</li>
                    <li>
                      Collect and process information from third-party products
                      and services, such as Facebook or Google.
                    </li>
                    <li>
                      Access photos, location, and contacts information through
                      your device-based settings so we can provide the services
                      described when you enable the settings.
                    </li>
                  </ul>
                  <p>
                    When we process data you provide to us based on your
                    consent, you have the right to withdraw your consent at any
                    time via your settings. You also have the right to port or
                    transfer the data.
                  </p>
                  <h6>
                    Legal obligation or for the establishment, exercise or
                    defence of legal claims
                  </h6>
                  <p>
                    We process data where we have a legal obligation to do so,
                    for example, where we're responding to valid and binding
                    legal process from law enforcement agencies for certain
                    data. See our Privacy Policy, under "Legal Requirements" for
                    more information. In addition, processing may be needed for
                    us to establish, exercise or defend civil or criminal claims
                    in connection with actual or potential litigation including
                    to protect the Teuko Services, our property or other legal
                    rights, including those of our members or partners.
                  </p>
                  <h6>To protect vital interests</h6>
                  <p>
                    We process data where it is necessary to protect an interest
                    which is essential to someone’s life or protect any person
                    from serious bodily injury. This includes processing
                    information to combat harmful conduct both on and off of our
                    Services.
                  </p>
                  <h6>Carrying out a task in the public interest</h6>
                  <p>
                    Where laid down by EU law or the law in an EU Member State,
                    we may process your data to perform processing in the public
                    interest. This may include protecting against harm and
                    undertaking research for social good. You have the right to
                    object to, and seek restriction of, our processing of your
                    personal data when we process data using this legal basis.
                  </p>
                  <h6>In furtherance of legitimate interests</h6>
                  <p>
                    We process your information for our legitimate interests,
                    and those of third parties, while applying appropriate
                    safeguards that protect your privacy, rights and interests.
                    We do this to:
                  </p>
                  <ul>
                    <li>
                      Market the Services, activities on Teuko and other
                      commercial products or services. For example, our partners
                      may pay us to promote their products, services, events,
                      gear or devices on Teuko. This is one of the ways we are
                      able to provide the Services on a sustainable basis. We
                      provide you with controls and safeguards, including the
                      ability to object.
                    </li>
                    <li>
                      Maintain our business by conducting research and
                      continuously improving the Services so as to offer
                      innovative and customised offerings to our members and
                      partners.
                    </li>
                    <li>
                      Convert it into aggregated form for use by us and our
                      partners. Our partners may use this information to improve
                      lunchbox gears, access to food, or even foods, or for
                      other commercial purposes including developing useful
                      insights. We also aggregate information to generate our
                      Teuko Rundown.
                    </li>
                    <li>
                      Keep the Services safe and secure by using information to
                      prevent or detect violations of our Terms of Service,
                      fraud or abuse, and other harmful or illegal conduct. We
                      may also share information with third parties, including
                      law enforcement agencies for this purpose.
                    </li>
                    <li>
                      Promote the Services, including email and in-product
                      marketing campaigns to inform you about our Services.
                    </li>
                    <li>
                      Encourage users to find new ways to interact, including
                      activities, followers, challenges, or events. We rely on
                      our legitimate interest in retaining members when ensuring
                      that we offer new opportunities, such as showing lunch
                      boxes of interest to our community, and we may use
                      location information when suggesting such opportunities.
                    </li>
                  </ul>
                  <p>
                    You have the right to object to, and seek restriction of,
                    our processing of your personal data based on legitimate
                    interests. Please visit your privacy controls if you object
                    to us using your information as part of Rundown.
                  </p>
                  <h4>California Member Rights</h4>
                  <p>
                    If you are a California resident, as defined in the
                    California Code of Regulations, you have rights under the
                    California Consumer Privacy Act of 2018 ("the CCPA"). Below,
                    we provide a description of your rights and disclosures
                    about your personal information.
                  </p>
                  <h5>Your Rights and How to Exercise Them</h5>
                  <h6>
                    Right to know about the personal information we collect and
                    share
                  </h6>
                  <p>
                    The CCPA gives you the right to request that we disclose the
                    specific pieces of personal information we have collected
                    about you, which we do after we receive and validate your
                    request.
                  </p>
                  <p>
                    Teuko does not sell your personal information. However, we
                    may disclose certain personal information for a business
                    purpose. When you make a request to download your personal
                    information, we will include a list of the categories of
                    personal information that we may have disclosed about you,
                    as well as the categories of third parties to whom your
                    personal information may have been disclosed.
                  </p>
                  <h6>How to make a disclosure request</h6>
                  <p>
                    You may request these disclosures by contacting
                    contact@teuko.com requesting a copy of your data.
                  </p>
                  <p>
                    You have the right to make a free request two times in any
                    12-month period. We will make the disclosure within 45 days
                    of receiving your request, unless we request an extension.
                    In the event that we reasonably need a 45-day extension, we
                    will notify you of the extension within the initial 45-day
                    period.
                  </p>
                  <h6>Right of deletion</h6>
                  <p>
                    You have the right to request that we delete your personal
                    information, subject to certain exceptions. After we receive
                    and validate your request, we will delete your personal
                    information, as well as direct our service providers to
                    delete your personal information, unless an exception
                    applies. Contact contact@teuko.com to request the deletion
                    of your data.
                  </p>
                  <h5>Disclosures About Your Personal Information</h5>
                  <h6>
                    Categories of information we collect and disclose for a
                    business purpose
                  </h6>
                  <p>
                    We collect the following categories of personal information
                    from you in connection with the Services, as defined in the
                    CCPA. In addition, during the past twelve months, we have
                    disclosed these categories of personal information for a
                    business purpose:
                  </p>
                  <ul>
                    <li>
                      Identifiers, such as your real name, lunchbox packer ID,
                      Internet Protocol address, email address, and other
                      similar identifiers.
                    </li>
                    <li>
                      Personal information categories listed in the California
                      Customer Records provisions, including payment
                      information, such as your credit card number.
                    </li>
                    <li>
                      Characteristics of protected classifications under
                      California or federal law, such as your gender and age.
                    </li>
                    <li>Commercial information</li>
                    <li>
                      Internet or other electronic network activity information,
                      such as session logs.
                    </li>
                    <li>
                      Electronic, visual, or similar information, such as
                      photos.
                    </li>
                    <li>
                      Inferences drawn from any of the above information to
                      create a profile reflecting your preferences,
                      characteristics, behavior, abilities, and aptitudes.
                    </li>
                  </ul>
                  <p>
                    According to California law, personal information does not
                    include:
                  </p>
                  <ul>
                    <li>
                      Publicly available information from government records.
                    </li>
                    <li>De-identified or aggregated consumer information.</li>
                  </ul>
                  <h6>Other disclosures about your personal information</h6>
                  <p>
                    Our Privacy Policy covers additional disclosures about your
                    personal information that the CCPA requires we provide to
                    you. Learn more about the sources from which we collect your
                    personal information, the business or commercial purposes
                    for which we collect your personal information, and the
                    categories of third parties with whom we share your personal
                    information.
                  </p>
                  <h6>How to contact us</h6>
                  <p>
                    If you have questions about your rights or our disclosures
                    under the CCPA, you may reach us at contact@teuko.com.
                  </p>
                  <h4>Nevada Member Rights</h4>
                  <p>
                    We do not sell your covered information, as defined by
                    Section 1.6 of Chapter 603A of the Nevada Revised Statutes.
                    If you reside in Nevada, you have the right to submit a
                    request to contact@teuko.com regarding the sale of covered
                    information.
                  </p>
                  <h3>Our Legal Bases</h3>
                  <p>
                    Teuko relies on a number of legal bases to collect, use,
                    share, and otherwise process the information we have about
                    you for the purposes described in this Privacy Policy,
                    including:
                  </p>
                  <ul>
                    <li>
                      as necessary to provide the Services and fulfill our
                      obligations pursuant to the Terms of Service. For example,
                      we cannot provide the Services unless we collect and use
                      your location information;
                    </li>
                    <li>where you have consented to the processing;</li>
                    <li>
                      where necessary to comply with a legal obligation, a court
                      order, or to exercise and defend legal claims;
                    </li>
                    <li>
                      to protect your vital interests, or those of others, such
                      as in the case of emergencies; and
                    </li>
                    <li>
                      where necessary for the purposes of Teuko’s or a third
                      party’s legitimate interests, such as our interests in
                      protecting our members, our partners’ interests in
                      collaborating with our members, and our commercial
                      interests in ensuring the sustainability of the Services.
                    </li>
                  </ul>
                  <h3>Transfers</h3>
                  <p>
                    The Services are operated from the United States. If you are
                    located outside of the United States and choose to use the
                    Services or provide information to us, you acknowledge and
                    understand that your information will be transferred,
                    processed and stored in the United States, as it is
                    necessary to provide the Services and perform the Terms of
                    Service. United States privacy laws may not be as protective
                    as those in your jurisdiction.
                  </p>
                  <h3>Retention of Information</h3>
                  <p>
                    We retain information as long as it is necessary to provide
                    the Services to you and others, subject to any legal
                    obligations to further retain such information. Information
                    associated with your account will generally be kept until it
                    is no longer necessary to provide the Services or until you
                    ask us to delete it or your account is deleted whichever
                    comes first. For example, where you withdraw your consent to
                    Teuko processing your food-related information, Teuko will
                    delete all food-related information you uploaded. Following
                    your deletion of your account, it may take up to 30 days to
                    fully delete your personal information and system logs from
                    our systems. Additionally, we may retain information from
                    deleted accounts to comply with the law, prevent fraud,
                    collect fees, resolve disputes, troubleshoot problems,
                    assist with investigations, enforce the Terms of Service and
                    take other actions permitted by law. The information we
                    retain will be handled in accordance with this Privacy
                    Policy.
                  </p>
                  <p>
                    Information about you that is no longer necessary and
                    relevant to provide our Services may be de-identified and
                    aggregated with other non-personal data to provide insights
                    which are commercially valuable to Teuko, such as statistics
                    of the use of the Services. This information will be
                    de-associated with your name and other identifiers.
                  </p>
                  <h3>Other Teuko Sites</h3>
                  <p>
                    Teuko maintains certain websites that can be accessed
                    outside of https://www.teuko.com, such as
                    https://blog.teuko.com (the “Other Sites”). The Other Sites
                    may maintain the look and feel of the Services, but are
                    hosted by outside service providers with their own terms and
                    privacy policies. If you interact with the Other Sites, your
                    information may be stored, processed, or shared outside of
                    the Services. If you interact with the Other Sites, you
                    acknowledge that you may be subject to the terms and
                    conditions and policies applicable to such Other Site.
                    Please be aware that any personal information you submit to
                    the Other Sites may be read, collected, or used by other
                    users of these forums indefinitely, and could be used to
                    send you unsolicited messages. Teuko is not responsible for
                    the personal information you choose to submit via the Other
                    Sites.
                  </p>
                  <h3>Privacy Policy Information</h3>
                  <p>
                    Teuko reserves the right to modify this Privacy Policy at
                    any time. Please review it occasionally. If Teuko makes
                    changes to this Privacy Policy, the updated Privacy Policy
                    will be posted on the Services in a timely manner and, if we
                    make material changes, we will provide a prominent notice.
                    If you object to any of the changes to this Privacy Policy,
                    you should stop using the Services and delete your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TermsPage;
