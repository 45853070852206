import React, { Component } from "react";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import General from "../../components/settings/General";
import ChangePassword from "../../components/settings/ChangePassword";
import Preferences from "../../components/settings/Preferences";
import Notifications from "../../components/settings/Notifications";
import Social from "../../components/settings/Social";

class SettingsPage extends Component {
  UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!user.id) browserHistory.push("/");
  }
  render() {
    return (
      <div className="settings-page">
        <Helmet>
          <title>Teuko Lunchbox Community - My Settings</title>
        </Helmet>
        <div className="standard-container">
          <h1>Settings</h1>
          <General />
          <Preferences />
          <Social />
          <ChangePassword />
          <Notifications />
          <p className="delete-account-blurb">
            If you want to cancel your Teuko account, please send an email with
            the subject header Account Deletion to{" "}
            <a
              href="mailto:contact@teuko.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact@teuko.com
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default SettingsPage;
