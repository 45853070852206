import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import FaClose from "../../fa/close";
import { map, find, concat, reject } from "lodash";
import VirtualizedSelect from "react-virtualized-select";
import dairy from "./icons/dairy.png";
import dairya from "./icons/dairya.png";
import drink from "./icons/drink.png";
import drinka from "./icons/drinka.png";
import fruits from "./icons/fruits.png";
import fruitsa from "./icons/fruitsa.png";
import grains from "./icons/grains.png";
import grainsa from "./icons/grainsa.png";
import protein from "./icons/protein.png";
import proteina from "./icons/proteina.png";
import veggies from "./icons/veggies.png";
import veggiesa from "./icons/veggiesa.png";

class CreateLunchStepTwo extends Component {
  constructor(props) {
    super(props);
    this.lunchOptionRenderer = this.lunchOptionRenderer.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.removeIngredient = this.removeIngredient.bind(this);
    this.checkFoodStatus = this.checkFoodStatus.bind(this);
    this.state = {
      loading: true,
    };
  }
  async UNSAFE_componentWillMount() {
    const { chosenFoods, foodStatus } = this.props;
    this.setState({ foodStatus, chosenFoods });
    const ingredients = await (
      await fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/ingredients.json"
      )
    ).json();
    this.setState(
      { loading: false, ingredients, chosenFoods, foodStatus },
      () => {
        document.getElementsByClassName("Select-placeholder")[0].textContent =
          "Tag food items";
      }
    );
  }
  lunchOptionRenderer({
    focusedOption,
    focusedOptionIndex,
    focusOption,
    key,
    labelKey,
    option,
    options,
    selectValue,
    style,
    valueArray,
  }) {
    const classNames = ["country-option"];
    if (option === focusedOption) {
      classNames.push("focused-country-option");
    }
    return (
      <div
        key={key}
        onClick={() => {
          selectValue(option);
        }}
        onMouseEnter={() => focusOption(option.id)}
        className={classNames.join(" ")}
        style={style}
      >
        <div
          className="country-image-option"
          style={{ backgroundImage: `url(${option.image})` }}
        />
        <label>{option.name}</label>
      </div>
    );
  }
  handleSelect(option) {
    const { chosenFoods } = this.state;
    if (!find(chosenFoods, { id: option.id })) {
      this.setState(
        { chosenFoods: concat(chosenFoods, option) },
        this.checkFoodStatus
      );
    }
  }
  removeIngredient(e) {
    const { chosenFoods } = this.state;
    const id = e.currentTarget.dataset.id;
    this.setState(
      { chosenFoods: reject(chosenFoods, { id }) },
      this.checkFoodStatus
    );
  }
  checkFoodStatus() {
    const { chosenFoods } = this.state;
    this.setState({
      foodStatus: {
        veggies: Boolean(find(chosenFoods, { veggies: true })),
        grains: Boolean(find(chosenFoods, { grains: true })),
        protein: Boolean(find(chosenFoods, { protein: true })),
        dairy: Boolean(find(chosenFoods, { dairy: true })),
        fruits: Boolean(find(chosenFoods, { fruits: true })),
        drink: Boolean(find(chosenFoods, { drink: true })),
      },
    });
  }
  render() {
    const { file, finishStep } = this.props;
    const { loading, ingredients, chosenFoods, foodStatus } = this.state;
    const veggiessrc = !foodStatus.veggies ? veggies : veggiesa;
    const grainssrc = !foodStatus.grains ? grains : grainsa;
    const proteinsrc = !foodStatus.protein ? protein : proteina;
    const dairysrc = !foodStatus.dairy ? dairy : dairya;
    const fruitssrc = !foodStatus.fruits ? fruits : fruitsa;
    const drinksrc = !foodStatus.drink ? drink : drinka;
    const veggiesStyle = {
      backgroundColor: foodStatus.veggies ? "#A2D15F" : "",
    };
    const grainsStyle = {
      backgroundColor: foodStatus.grains ? "#F7E442" : "",
    };
    const proteinsStyle = {
      backgroundColor: foodStatus.protein ? "#E84937" : "",
    };
    const dairyStyle = {
      backgroundColor: foodStatus.dairy ? "#2A3DAF" : "",
    };
    const fruitsStyle = {
      backgroundColor: foodStatus.fruits ? "#EA74A1" : "",
    };
    const drinkStyle = {
      backgroundColor: foodStatus.drink ? "#8BD3EF" : "",
    };
    const buttonStyle = {
      backgroundColor: Boolean(chosenFoods.length) ? "" : "#FFFFB3",
      pointerEvents: Boolean(chosenFoods.length) ? "" : "none",
    };
    const foods = map(chosenFoods, (f) => (
      <p key={f.id}>
        {f.name} <FaClose data-id={f.id} onClick={this.removeIngredient} />
      </p>
    ));
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="create-lunch-step-two">
        {loading ? (
          <div />
        ) : (
          <Fragment>
            <div className="food-selection-group">
              <div className="fs-left">
                <div
                  className="fs-left-img-preview"
                  style={{
                    backgroundImage: `url(${file.preview})`,
                  }}
                />
                <div className="food-group-options">
                  <div className="food-group-option" style={veggiesStyle}>
                    <img src={veggiessrc} alt="" />
                    <span className="food-tooltip veggies-tooltip">
                      veggies
                    </span>
                  </div>
                  <div className="food-group-option" style={grainsStyle}>
                    <img src={grainssrc} alt="" />
                    <span className="food-tooltip grains-tooltip">grains</span>
                  </div>
                  <div className="food-group-option" style={proteinsStyle}>
                    <img src={proteinsrc} alt="" />
                    <span className="food-tooltip protein-tooltip">
                      protein
                    </span>
                  </div>
                  <div className="food-group-option" style={dairyStyle}>
                    <img src={dairysrc} alt="" />
                    <span className="food-tooltip dairy-tooltip">dairy</span>
                  </div>
                  <div className="food-group-option" style={fruitsStyle}>
                    <img src={fruitssrc} alt="" />
                    <span className="food-tooltip fruits-tooltip">fruits</span>
                  </div>
                  <div className="food-group-option" style={drinkStyle}>
                    <img src={drinksrc} alt="" />
                    <span className="food-tooltip drink-tooltip">drink</span>
                  </div>
                </div>
              </div>
              <div className="fs-right">
                <div className="missing-food-items">
                  <p>
                    Foods missing?{" "}
                    <a href="mailto:contact@teuko.com?subject=Food%20Items%20Missing&amp;body=Hi%20Teuko%20Team%2C%0A%0AThe%20following%20food%20items%20are%20missing%3A%0A-">
                      Let us know!
                    </a>
                  </p>
                </div>
                <div className="search-bar-holder">
                  <VirtualizedSelect
                    valueKey="id"
                    labelKey="name"
                    options={ingredients}
                    optionHeight={50}
                    optionRenderer={this.lunchOptionRenderer}
                    className="food-selector"
                    onChange={(option) => this.handleSelect(option)}
                  />
                  {/* <FaSearch className="select-search-icon" /> */}
                </div>

                <div className="selected-foods-section">
                  <h4>Foods</h4>
                  {foods}
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div
          className="standard-button continue-ingredients-button"
          disabled={!Boolean(chosenFoods.length)}
          style={buttonStyle}
          onClick={() => finishStep(chosenFoods, foodStatus)}
        >
          Continue
        </div>
        <a
          href="#"
          className="go-back"
          onClick={() => finishStep(chosenFoods, foodStatus, 1)}
        >
          Back
        </a>
      </div>
    );
  }
}

CreateLunchStepTwo.propTypes = {
  file: PropTypes.object,
  foodStatus: PropTypes.object,
  chosenFoods: PropTypes.array,
  finishStep: PropTypes.func,
};

export default CreateLunchStepTwo;
