import React, { Component } from "react";
import PropTypes from "prop-types";
import { map, flatten } from "lodash";
import FaClose from "../../fa/close";
import gql from "graphql-tag";
import { withApollo } from "react-apollo";
import { browserHistory } from "react-router";
import dairyIconBW from "./icons/dairy.png";
import dairyIconColor from "./icons/dairya.png";
import drinkIconBW from "./icons/drink.png";
import drinkIconColor from "./icons/drinka.png";
import fruitsIconBW from "./icons/fruits.png";
import fruitsIconColor from "./icons/fruitsa.png";
import grainsIconBW from "./icons/grains.png";
import grainsIconColor from "./icons/grainsa.png";
import proteinIconBW from "./icons/protein.png";
import proteinIconColor from "./icons/proteina.png";
import veggiesIconBW from "./icons/veggies.png";
import veggiesIconColor from "./icons/veggiesa.png";

class StatList extends Component {
  constructor(props) {
    super(props);
    this.print = this.print.bind(this);
    this.state = {
      loading: true,
    };
  }
  async UNSAFE_componentWillMount() {
    const { menu, client } = this.props;
    const allFoods = map(menu, (l) => map(l.lunch.foods, "id"));
    const uniqFoods = flatten(allFoods);
    const response = await client.query({
      query: gql`
        query getWeeklyStats($foodIds: [String]) {
          getWeeklyStats(foodIds: $foodIds) {
            veggies
            fruits
            grains
            dairy
            protein
            drink
          }
        }
      `,
      variables: { foodIds: uniqFoods },
    });
    const {
      veggies,
      fruits,
      grains,
      dairy,
      protein,
      drink,
    } = response.data.getWeeklyStats;
    this.setState({
      veggies,
      fruits,
      grains,
      dairy,
      protein,
      drink,
      loading: false,
      menu,
    });
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  print() {
    const { foodStatGroups } = this.state;
    const { dateString } = this.props;

    browserHistory.push({
      pathname: "/statlistprint",
      state: {
        foodStatGroups,
        dateString,
      },
    });
  }
  render() {
    const {
      loading,
      veggies,
      fruits,
      grains,
      dairy,
      protein,
      drink,
      menu,
    } = this.state;
    const { dateString, close } = this.props;
    const veggiesIcon = veggies ? veggiesIconColor : veggiesIconBW;
    const veggiesStyle = {
      backgroundColor: veggies ? "#A2D15F" : "",
    };
    const grainsIcon = grains ? grainsIconColor : grainsIconBW;
    const grainsStyle = {
      backgroundColor: grains ? "#F7E442" : "",
    };
    const proteinIcon = protein ? proteinIconColor : proteinIconBW;
    const proteinsStyle = {
      backgroundColor: protein ? "#E84937" : "",
    };
    const dairyIcon = dairy ? dairyIconColor : dairyIconBW;
    const dairyStyle = {
      backgroundColor: dairy ? "#2A3DAF" : "",
    };
    const fruitsIcon = fruits ? fruitsIconColor : fruitsIconBW;
    const fruitsStyle = {
      backgroundColor: fruits ? "#EA74A1" : "",
    };
    const drinkIcon = drink ? drinkIconColor : drinkIconBW;
    const drinkStyle = {
      backgroundColor: drink ? "#8BD3EF" : "",
    };
    if (loading) return <div />;

    return (
      <div className="shopping-modal">
        <div className="shopping-list">
          <div className="shopping-list-header">
            <h3>Your Stats for {dateString}</h3>
            <div className="grocery-list-icons">
              <FaClose className="close-modal-list" onClick={close} />
            </div>
          </div>
          <div className="shopping-list-body">
            <div className="food-list-item">
              <p className="food-name">
                You packed {menu.length} lunchbox ideas this week
              </p>
            </div>
            <div className="food-list-item" key="veggies-list">
              <div className="food-group-option" style={veggiesStyle}>
                <img src={veggiesIcon} alt="" />
              </div>
              <p className="foodgroup-name">Veggies</p>
              <p className="food-name">{loading ? 0 : veggies}x</p>
            </div>
            <div className="food-list-item" key="grains-list">
              <div className="food-group-option" style={grainsStyle}>
                <img src={grainsIcon} alt="" />
              </div>
              <p className="foodgroup-name">Grains</p>
              <p className="food-name">{loading ? 0 : grains}x</p>
            </div>
            <div className="food-list-item" key="protein-list">
              <div className="food-group-option" style={proteinsStyle}>
                <img src={proteinIcon} alt="" />
              </div>
              <p className="foodgroup-name">Protein</p>
              <p className="food-name">{loading ? 0 : protein}x</p>
            </div>
            <div className="food-list-item" key="dairy-list">
              <div className="food-group-option" style={dairyStyle}>
                <img src={dairyIcon} alt="" />
              </div>
              <p className="foodgroup-name">Dairy</p>
              <p className="food-name">{loading ? 0 : dairy}x</p>
            </div>
            <div className="food-list-item" key="fruits-list">
              <div className="food-group-option" style={fruitsStyle}>
                <img src={fruitsIcon} alt="" />
              </div>
              <p className="foodgroup-name">Fruits</p>
              <p className="food-name">{loading ? 0 : fruits}x</p>
            </div>
            <div className="food-list-item" key="drink-list">
              <div className="food-group-option" style={drinkStyle}>
                <img src={drinkIcon} alt="" />
              </div>
              <p className="foodgroup-name">Drinks</p>
              <p className="food-name">{loading ? 0 : drink}x</p>
            </div>
            {/* <div className="food-list-item">
              <FaHeart className="heart-icon"/>
              <p className="food-name">You received TeukoLove 18 times</p>
            </div> */}
          </div>
          <div className="shop-now-section">
            <p className="amazon-disclosure">
              These stats are for your general educational information only. The
              content cannot, and is not intended to, replace the relationship
              that you have with your health care professionals. Any question or
              feedback? Email us at{" "}
              <a href="mailto:contact@teuko.com">contact@teuko.com</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

StatList.propTypes = {
  menu: PropTypes.array,
  dateString: PropTypes.string,
  close: PropTypes.func,
};

StatList.defaultProps = {
  menu: [],
};

export default withApollo(StatList);
