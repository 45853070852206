import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { createClient } from "contentful";
import { map } from "lodash";
import { Link, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import MailingListPopup from "../../components/general/MailingListPopup";

class BoutiquePageBrands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      groups: [],
      activeOption: 0,
    };
  }
  UNSAFE_componentWillMount() {
    document.body.classList.add("home");
    const self = this;
    const client = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });
    client
      .getEntries({
        content_type: "boutique",
        "fields.number": 1,
        include: 2,
      })
      .then((entries) => {
        self.setState({
          groups: entries.items[0].fields.group,
          loading: false,
        });
      });
  }

  componentDidMount () {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() { 
        setTimeout(() => {
            googletag.display('div-gpt-ad-1676589485366-0');
            googletag.display('div-gpt-ad-1676589684288-0');
            
           
            
        }, 2000);
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("home");
  }
  render() {
    const { activeOption } = this.state;
    const { user } = this.props;
    const { groups, loading } = this.state;
    if (loading) return <div />;
   



    return (
      <Fragment>
        <Helmet>
          <title>Teuko Bento LunchBox Shop - Brands A-Z</title>
        </Helmet>
        <div className="boutique-page">
          {user.id !== 0 ? (
            ""
          ) : (
            <MailingListPopup delayInMilliseconds={15000} client={this.props.client}/>
          )}
          <div className="browse-boutique-header">
          <h1>Teuko Boutique</h1>
            <div className="my-boutique-filters">
              
              <div className="boutique-content-options">
                <a href="/boutique"><h3 style={{color: '#737373'}}>Themes by Category</h3></a>
                <a href="/boutique-themes-AZ"><h3 style={{color: '#737373'}}>Themes A-Z</h3></a>
                <h3>Brands</h3>
              </div>
          
            </div>
          </div>


          <div>
                 
          <div className="boutique-menus">
                    
                    <Link
                    key="BrandsAtoF"
                    to="BrandsAtoF"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>A-F</h3>
                    </Link>

                    <Link
                    key="BrandsItoO"
                    to="BrandsItoO"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>I-O</h3>
                    </Link>

                    <Link
                    key="BrandsPtoS"
                    to="BrandsPtoS"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>P-S</h3>
                    </Link>

                    <Link
                    key="BrandsTtoZ"
                    to="BrandsTtoZ"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>T-Z</h3>
                    </Link>

                </div>

                <div className="standard-container">
                  <div className="boutique-groups">
                    <div className="boutique-affiliate-message">
                      <p>
                      Disclaimer: As an Amazon Associate, Teuko earns from qualifying purchases. If you buy some
                      products, we may get a small share of the revenue from the sale. This helps Teuko.com remain free!
                      </p>
                    </div>

                    <div className="search-google-ad-banner-type-leaderboard-centered">
                      <div className="search-title-header-ad-laptop_leaderboard">
                        {/* <!-- /22874318077/Teuko-Boutique-Laptop_AdUnit_3-Leaderboard-728x90 */}
                        <div id='div-gpt-ad-1676589485366-0' style={{minWidth: "728px", minHeight: "90px"}} />
                      </div>

                      <div className="search-title-header-ad-smartphone_leaderboard2">
                        {/* {<!-- /22874318077/Teuko-Boutique-Smartphone_AdUnit_3-Leaderboard-320x50 */}
                        <div id='div-gpt-ad-1676589684288-0' style={{minWidth: "320px", minHeight: "50px"}} />
                      </div> 
                    </div>

                  
                  <Element
                    className="boutique-group"
                    id="BrandsAtoZ"
                    key="BrandsAtoZ"
                    name="BrandsAtoZ"
  >
                  <div>
                    <div className="boutique-header-bar">
                    <h2 id="BrandsAtoF"
                    key="BrandsAtoF"
                    name="BrandsAtoF">Brands A-F</h2>
                    </div>

                    <div className="boutique-group-items">
                    <a
                          className="boutique-item"
                          key="Bentgo"
                          target="_blank"
                          href="https://amzn.to/3weYrnQ"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Bentgo.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Bentgo</h5>
                        </a>
                      <a
                          className="boutique-item"
                          key="Bentology"
                          target="_blank"
                          href="https://amzn.to/3QSWmWt"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Bentology.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Bentology</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="EasyLunchboxes"
                          target="_blank"
                          href="https://amzn.to/3AbOlFh"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_EasyLunchboxes.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Easylunchboxes</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="EasyTogo"
                          target="_blank"
                          href="https://amzn.to/3WDC6xR"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_EasyTogo.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Easy Togo</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Fluf"
                          target="_blank"
                          href="https://amzn.to/3QMfm9c"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Fluf.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Fluf</h5>
                        </a>
                        </div>

  

                        

                        <div className="boutique-header-bar">
                          <h2 id="BrandsItoO"
                          key="BrandsItoO"
                          name="BrandsItoO">Brands I-O</h2>
                        </div>

                        <div className="boutique-group-items">

                        <a
                          className="boutique-item"
                          key="Iboxie"
                          target="_blank"
                          href="https://iboxie.com/?ref=awZJYGv8"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Iboxie.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Iboxie</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Kinsho"
                          target="_blank"
                          href="https://amzn.to/3PBCBBJ"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Kinsho.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Kinsho</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="LilleHome"
                          target="_blank"
                          href="https://amzn.to/3QB0kn1"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_LilleHome.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Lille Home</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="LunchBots"
                          target="_blank"
                          href="https://amzn.to/3c6M46y"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_LunchBots.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">LunchBots</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="monbento"
                          target="_blank"
                          href="https://amzn.to/3wf92iw"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_monbento.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">monbento</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Omie"
                          target="_blank"
                          href="https://amzn.to/3QD1e2c"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Omie.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Omie</h5>
                        </a>
                        </div>

                        

                        <div className="boutique-header-bar">
                          <h2 id="BrandsPtoS"
                          key="BrandsPtoS"
                          name="BrandsPtoS">Brands P-S</h2>
                        </div>

                        <div className="boutique-group-items">

                        <a
                          className="boutique-item"
                          key="Packit"
                          target="_blank"
                          href="https://amzn.to/3pvZxIc"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Packit.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">PackIt</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Rubbermaid"
                          target="_blank"
                          href="https://amzn.to/3KcC2gQ"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Rubbermaid.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Rubbermaid</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Swell"
                          target="_blank"
                          href="https://amzn.to/3QX2mOc"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Swell.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">S'well</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="SimpleModern"
                          target="_blank"
                          href="https://amzn.to/3wfMz4O"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_SimpleModern.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Simple Modern</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Sistema"
                          target="_blank"
                          href="https://amzn.to/3c4FrBx"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Sistema.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Sistema</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Skater"
                          target="_blank"
                          href="https://amzn.to/3AyGhQr"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Skater.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Skater</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="StephenJoseph"
                          target="_blank"
                          href="https://amzn.to/3CgyQP4"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_StephenJoseph.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Stephen Joseph</h5>
                        </a>
                        </div>

                        

                        <div className="boutique-header-bar">
                          <h2 id="BrandsTtoZ"
                          key="BrandsTtoZ"
                          name="BrandsTtoZ">Brands T-Z</h2>
                        </div>

                        <div className="boutique-group-items">

                        
                        <a
                          className="boutique-item"
                          key="Thermos"
                          target="_blank"
                          href="https://amzn.to/3QyDexq"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Thermos.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Thermos</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Torune"
                          target="_blank"
                          href="https://amzn.to/3CgewgM"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Torune.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Torune</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="Yumbox"
                          target="_blank"
                          href="https://amzn.to/3K4R8os"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_Yumbox.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Yumbox</h5>
                        </a>
                        <a
                          className="boutique-item"
                          key="ZakDesigns"
                          target="_blank"
                          href="https://amzn.to/3QSeHmv"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="boutique-item-image"
                            style={{
                              backgroundImage: "url(/images/boutiquepage_brandsAZ_ZakDesigns.jpg)",
                            }}
                          />
                          <h5 className="boutique-item-caption">Zak Designs</h5>
                        </a>


                      </div>
                    </div>

                    </Element>
                  </div>
                </div>
                  

                 </div>

            
     

          <div className="boutique-visit-amz-section">
            <a
              href="https://amzn.to/3nRksn3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>
                See our entire selection of lunch accessories in our Amazon Store!
              </h2>
            </a>
          
              <a href="https://amzn.to/38VE9DN" 
                 target="_blank"
                 rel="noopener noreferrer"
                 className="standard-button">
                  Visit Teuko Amazon Store
              </a>
            
          </div>


          { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to discover lunchbox ideas you'll love and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}

          



        </div>
      </Fragment>
    );
  }
}

export default withApollo(BoutiquePageBrands);
