import React, { Component } from "react";
import PropTypes from "prop-types";
import FaCamera from "../../fa/camera";
import FaClose from "../../fa/close";
import Dropzone from "react-dropzone";

class CreateLunchStepOne extends Component {
  constructor(props) {
    super(props);
    this.clearFiles = this.clearFiles.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.processDrop = this.processDrop.bind(this);
    this.state = {
      files: {},
      error: "",
    };
  }
  UNSAFE_componentWillMount() {
    const { file } = this.props;
    this.setState({ files: file });
    if (file) {
      this.setState({ files: file, error: "" });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.file.preview !== this.props.file.preview) {
      this.setState({ files: this.props.file });
    }
  }
  processDrop(files) {
    this.setState({ files: files[0], error: "" });
  }
  clearFiles(e) {
    e.stopPropagation();
    this.setState({ files: {}, error: "" });
    this.dropzone.setState({ acceptedFiles: [] });
  }
  handleSubmit(e) {
    if (this.state.files)
      this.props.finishStep(this.state.files, this.state.type);
  }

  render() {
    const { files, error } = this.state;
    const dropStyle = {
      backgroundImage: files.preview ? `url(${files.preview})` : "",
      cursor: "default",
    };
    const buttonStyle = {
      backgroundColor: files.preview ? "" : "#ffeb81",
      pointerEvents: files.preview ? "" : "none",
    };
    return (
      <div className="create-lunch-step-one">
        <h2>Upload your lunch</h2>
        <Dropzone
          accept="image/png, image/jpg, image/jpeg"
          maxSize={50 * 1000 * 1000}
          className="photo-upload-zone"
          onDropAccepted={this.processDrop}
          onDropRejected={() =>
            this.setState({ error: "You must upload an image file below 5MB" })
          }
          multiple={false}
          style={dropStyle}
          activeClassName="drop-active"
          ref={(e) => (this.dropzone = e)}
        >
          {files.preview ? (
            <div className="image-delete" onClick={this.clearFiles}>
              <FaClose />
            </div>
          ) : (
            <div className="photo-zone-desc">
              <FaCamera />
              <p>
                Drag or Upload Photo<br></br>
                <br></br>Square photos recommended
              </p>
            </div>
          )}
          <div className="error-class">{error}</div>
        </Dropzone>
        <div
          className="standard-button continue-lunch-button"
          onClick={this.handleSubmit}
          disabled={!Boolean(files)}
          style={buttonStyle}
        >
          Continue
        </div>
      </div>
    );
  }
}

CreateLunchStepOne.propTypes = {
  finishStep: PropTypes.func,
  file: PropTypes.object,
};

export default CreateLunchStepOne;
