import { find, filter, concat, map, reject, uniqBy, shuffle } from "lodash";
import { Helmet } from "react-helmet";
import { withApollo } from "react-apollo";
import FaClose from "../../fa/close";
import Flatpickr from "react-flatpickr";
import gql from "graphql-tag";
import InfiniteScroll from "react-infinite-scroller";
import PropTypes from "prop-types";
import React, { Component } from "react";
import VirtualizedSelect from "react-virtualized-select";
import MailingListPopup from "../../components/general/MailingListPopup";
import BrowseLunchThumb from "../../components/lunches/BrowseLunchThumb";
import BrowseLunchThumbGrid from "../../components/lunches/BrowseLunchThumbGrid";
import { Element } from "react-scroll";
import FaGrid from "../../fa/grid";
import FaImage from "../../fa/image";
import { Link } from "react-router";

const ALL = -1;
const FRIENDS = -2;
const SCHOOLS = -3;

class ExploreFoodPage extends Component {
  constructor(props) {
    super(props);
    this.openLunch = this.openLunch.bind(this);
    this.close = this.close.bind(this);
    this.lunchOptionRenderer = this.lunchOptionRenderer.bind(this);
    this.selectFood = this.selectFood.bind(this);
    this.removeFood = this.removeFood.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.selectTheme = this.selectTheme.bind(this);
    this.removeTheme = this.removeTheme.bind(this);
    this.selectAccessory = this.selectAccessory.bind(this);
    this.removeAccessory = this.removeAccessory.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.state = {
      id: 0,
      ingredients: [],
      users: [],
      dateRange: [new Date(), new Date()],
      chosenFoods: [],
      chosenThemes: [],
      chosenAccessories: [],
      chosenUsers: [
        {
          id: ALL,
          name: "All",
          image:
            "https://s3-us-west-2.amazonaws.com/teuko-test-steve/Teuko+Logo+Glyph.jpg",
        },
      ],
      exploreFood: [],
      lastLoad: 1,
      offset: 0,
      firstTime: true,
      activeOption: 0,
    };
    this.fp = null;
  }

  clearDate() {
    if (this.fp && this.fp.flatpickr) {
      this.fp.flatpickr.clear();
    }
  }

  async UNSAFE_componentWillMount() {
    console.log("componentWillMount");
    const self = this;
    const [ingredients, users, themes, accessories] = await Promise.all([
      fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/searchfoods.json"
      ).then((data) => data.json()),
      fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/searchusers.json"
      ).then((data) => data.json()),
      fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/themes.json"
      ).then((data) => data.json()),
      fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/boutique.json"
      ).then((data) => data.json()),
    ]);
    const prefix = [
      {
        id: ALL,
        name: "All Users",
        image:
          "https://s3-us-west-2.amazonaws.com/teuko-test-steve/Teuko+Logo+Glyph.jpg",
      },
      {
        id: FRIENDS,
        name: "My Friends",
        image:
          "https://s3-us-west-2.amazonaws.com/teuko-test-steve/Teuko+My+friends+icon+search.jpg",
      },
      {
        id: SCHOOLS,
        name: "Schools",
        image:
          "https://s3-us-west-2.amazonaws.com/teuko-test-steve/Teuko+School+icon+search.jpg",
      },
    ];
    let foodFilter = null;
    const { location } = this.props;
    if (location.query && location.query.food) {
      const filter = location.query.food;
      foodFilter = find(ingredients, (entry) => entry.name === filter);
    }
    let themeFilter = null;
    if (location.query && location.query.theme) {
      const filter = location.query.theme;
      themeFilter = find(themes, (entry) => entry.name === filter);
    }
    let accessoryFilter = null;
    if (location.query && location.query.accessory) {
      const filter = location.query.accessory;
      accessoryFilter = find(accessories, (entry) => entry.name === filter);
    }
    this.setState(
      {
        loading: false,
        ingredients,
        themes,
        accessories,
        users: concat(prefix, users),
      },
      () => {
        if (foodFilter) {
          self.selectFood(foodFilter);
        }
        if (themeFilter) {
          self.selectTheme(themeFilter);
        }
        if (accessoryFilter) {
          self.selectAccessory(accessoryFilter);
        }
      }
    );
  }

  componentDidMount () {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1676499879279-0');
        googletag.display('div-gpt-ad-1676500831298-0');
        googletag.display('div-gpt-ad-1676503537129-0');
        googletag.display('div-gpt-ad-1676505631807-0');
      });
    }
  }

  async removeFood(e) {
    const { chosenFoods } = this.state;
    const id = e.currentTarget.dataset.id;
    const newChosenFoods = reject(chosenFoods, { id });
    this.setState(
      {
        chosenFoods: newChosenFoods,
        exploreFood: [],
        offset: 0,
      },
      () =>
        this.runSearch(
          map(newChosenFoods, (food) => food.id),
          map(this.state.chosenUsers, (user) => user.id.toString()),
          map(this.state.chosenThemes, (theme) => theme.id),
          map(this.state.chosenAccessories, (accessory) => accessory.id),
          0
        )
    );
  }

  async selectFood(option) {
    const { chosenFoods } = this.state;
    const newChosenFoods = uniqBy(concat(chosenFoods, option), "id");
    if (!find(chosenFoods, { id: option.id })) {
      this.setState(
        {
          chosenFoods: newChosenFoods,
          exploreFood: [],
          offset: 0,
        },
        () =>
          this.runSearch(
            map(newChosenFoods, (food) => food.id),
            map(this.state.chosenUsers, (user) => user.id.toString()),
            map(this.state.chosenThemes, (theme) => theme.id),
            map(this.state.chosenAccessories, (accessory) => accessory.id),
            0
          )
      );
    }
  }

  async removeTheme(e) {
    const { chosenFoods, chosenThemes } = this.state;
    const id = e.currentTarget.dataset.id;
    const newChosenThemes = reject(chosenThemes, { id });
    this.setState(
      {
        exploreFood: [],
        chosenThemes: newChosenThemes,
        exploreTheme: [],
        offset: 0,
      },
      () =>
        this.runSearch(
          map(chosenFoods, (food) => food.id),
          map(this.state.chosenUsers, (user) => user.id.toString()),
          map(newChosenThemes, (theme) => theme.id),
          map(this.state.chosenAccessories, (accessory) => accessory.id),
          0
        )
    );
  }

  async selectTheme(option) {
    const { chosenFoods, chosenThemes } = this.state;
    const newChosenThemes = uniqBy(concat(chosenThemes, option), "id");
    if (!find(chosenThemes, { id: option.id })) {
      this.setState(
        {
          exploreFood: [],
          chosenThemes: newChosenThemes,
          exploreTheme: [],
          offset: 0,
        },
        () =>
          this.runSearch(
            map(chosenFoods, (food) => food.id),
            map(this.state.chosenUsers, (user) => user.id.toString()),
            map(newChosenThemes, (theme) => theme.id),
            map(this.state.chosenAccessories, (accessory) => accessory.id),
            0
          )
      );
    }
  }

  async removeAccessory(e) {
    const { chosenFoods, chosenAccessories } = this.state;
    const id = e.currentTarget.dataset.id;
    const newChosenAccessories = reject(chosenAccessories, { id });
    this.setState(
      {
        exploreFood: [],
        chosenAccessories: newChosenAccessories,
        exploreAccessory: [],
        offset: 0,
      },
      () =>
        this.runSearch(
          map(chosenFoods, (food) => food.id),
          map(this.state.chosenUsers, (user) => user.id.toString()),
          map(this.state.chosenThemes, (theme) => theme.id),
          map(newChosenAccessories, (theme) => theme.id),
          0
        )
    );
  }

  async selectAccessory(option) {
    const { chosenFoods, chosenAccessories } = this.state;
    const newChosenAccessories = uniqBy(concat(chosenAccessories, option), "id");
    if (!find(chosenAccessories, { id: option.id })) {
      this.setState(
        {
          exploreFood: [],
          chosenAccessories: newChosenAccessories,
          exploreAccessory: [],
          offset: 0,
        },
        () =>
          this.runSearch(
            map(chosenFoods, (food) => food.id),
            map(this.state.chosenUsers, (user) => user.id.toString()),
            map(this.state.chosenThemes, (theme) => theme.id),
            map(newChosenAccessories, (theme) => theme.id),
            0
          )
      );
    }
  }

  async removeUser(e) {
    const { chosenUsers } = this.state;
    const id = parseInt(e.currentTarget.dataset.id);
    let newChosenUsers = reject(chosenUsers, { id });
    if (newChosenUsers.length === 0) {
      newChosenUsers = [
        {
          id: ALL,
          name: "All",
          image:
            "https://s3-us-west-2.amazonaws.com/teuko-test-steve/Teuko+Logo+Glyph.jpg",
        },
      ];
    }

    this.setState(
      {
        chosenUsers: newChosenUsers,
        exploreFood: [],
        offset: 0,
      },
      () =>
        this.runSearch(
          map(this.state.chosenFoods, (food) => food.id),
          map(newChosenUsers, (user) => user.id.toString()),
          map(this.state.chosenThemes, (theme) => theme.id),
          map(this.state.chosenAccessories, (accessory) => accessory.id),
          0
        )
    );
  }

  async selectUser(option) {
    const { chosenUsers } = this.state;
    let newChosenUsers = [];
    if (option.id === ALL || option.id === FRIENDS || option.id === SCHOOLS) {
      newChosenUsers = [option];
    } else {
      newChosenUsers = filter(
        chosenUsers,
        (user) => user.id !== ALL && user.id !== FRIENDS && user.id !== SCHOOLS
      );
      newChosenUsers = concat(newChosenUsers, option);
    }
    newChosenUsers = uniqBy(newChosenUsers, "id");

    if (!find(chosenUsers, { id: option.id })) {
      this.setState(
        {
          chosenUsers: newChosenUsers,
          exploreFood: [],
          offset: 0,
        },
        () =>
          this.runSearch(
            map(this.state.chosenFoods, (food) => food.id),
            map(newChosenUsers, (user) => user.id.toString()),
            map(this.state.chosenThemes, (theme) => theme.id),
            map(this.state.chosenAccessories, (accessory) => accessory.id),
            0
          )
      );
    }
  }

  async loadMore() {
    if (this.state.exploreFood.length !== 0 && this.state.lastLoad > 0) {
      this.state.offset += 50;
      const foodIds = map(this.state.chosenFoods, (food) => food.id);
      const userIds = map(this.state.chosenUsers, (user) => user.id.toString());
      const themeIds = map(this.state.chosenThemes, (theme) => theme.id);
      const accessoryIds = map(this.state.chosenAccessories, (accesory) => accesory.id);
      await this.runSearch(foodIds, userIds, themeIds, accessoryIds, this.state.offset);
    }
  }

  async runSearch(foodIds, userIds, themeIds, accessoryIds, offset) {
    // console.log(
    //   "runSearch(foodIds, userIds, themeIds, accessoryIds, offset)",
    //   foodIds,
    //   userIds,
    //   themeIds,
    //   accessoryIds,
    //   offset
    // );
    let fromDate = this.state.dateRange[0];
    let toDate = null;
    if (this.state.dateRange.length > 1) {
      toDate = this.state.dateRange[1];
    }
    const response = await this.props.client.query({
      query: gql`
        query exploreFood2(
          $foodIds: [String]
          $userIds: [String]
          $themeIds: [String]
          $accessoryIds: [String]
          $fromDate: String
          $toDate: String
          $offset: Int
        ) {
          exploreFood2(
            foodIds: $foodIds
            userIds: $userIds
            themeIds: $themeIds
            accessoryIds: $accessoryIds
            fromDate: $fromDate
            toDate: $toDate
            offset: $offset
          ) {
            id
            slug
            user_id
            user {
              id
              user_name
              image
            }
            image
            liked
            saved
            foods {
              id
              name
              veggies
              grains
              protein
              fruits
              dairy
              drink
            }
            themes {
              id
              name
              image
            }
          }
        }
      `,
      variables: { foodIds, userIds, themeIds, accessoryIds, fromDate, toDate, offset },
    });
    const exploreFood2 = shuffle(response.data.exploreFood2);
    if (!exploreFood2) {
      return false;
    }

    let exploreFood = concat(this.state.exploreFood, exploreFood2);
    let index = {};
    exploreFood = filter(exploreFood, (food) => {
      if (!index[food.id]) {
        index[food.id] = true;
        return true;
      }
      return false;
    });
    this.setState({
      exploreFood: exploreFood,
      lastLoad: exploreFood2.length,
      firstTime: false,
    });
  }

  lunchOptionRenderer({
    focusedOption,
    focusedOptionIndex,
    focusOption,
    key,
    labelKey,
    option,
    options,
    selectValue,
    style,
    valueArray,
  }) {
    const classNames = ["country-option"];
    if (option === focusedOption) {
      classNames.push("focused-country-option");
    }
    return (
      <div
        key={key}
        onClick={() => {
          selectValue(option);
        }}
        onMouseEnter={() => focusOption(option.id)}
        className={classNames.join(" ")}
        style={style}
      >
        <div
          className="country-image-option"
          style={{ backgroundImage: `url(${option.image})` }}
        />
        <label>{option.name}</label>
      </div>
    );
  }

  openLunch(e) {
    const { id } = e.currentTarget.dataset;
    this.setState({ id: parseInt(id, 10) });
  }

  close() {
    this.setState({ id: 0 });
  }

  render() {
    const self = this;
    const { loading, error, location, user } = this.props;
    const { ingredients, users, exploreFood, themes, accessories } = this.state;
    const { dateRange } = this.state;
    if (loading || error) return <div />;
    const Lunches = map(exploreFood, (lunch) => {
      return (
        <BrowseLunchThumb
          lunch={lunch}
          key={lunch.id}
          location={location}
          loggedIn={Boolean(user.id)}
          hideActionPanel={true}
        />
      );
    });
    const LunchesGrid = map(exploreFood, (lunch) => {
      return (
        <BrowseLunchThumbGrid
          lunch={lunch}
          key={lunch.id}
          location={location}
          loggedIn={Boolean(user.id)}
          hideActionPanel={true}
        />
      );
    });
    let searchTerms = map(this.state.chosenFoods, (food) => {
      return (
        <div key={food.id} className="fav-items-ids">
          <div
            className="fav-items-image-option"
            style={{
              backgroundImage: `url(${food.image})`,
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          />
          <div
            className="search-text-option"
            style={{
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          >
            {food.name} <FaClose data-id={food.id} onClick={this.removeFood} />
          </div>
        </div>
      );
    });
    const searchUsers = map(this.state.chosenUsers, (user) => {
      return (
        <div key={user.id} className="fav-items-ids">
          <div
            className="fav-items-image-option"
            style={{
              backgroundImage: `url(${user.image})`,
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          />
          <div
            className="search-text-option"
            style={{
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          >
            {user.name} <FaClose data-id={user.id} onClick={this.removeUser} />
          </div>
        </div>
      );
    });
    const searchThemes = map(this.state.chosenThemes, (theme) => {
      return (
        <div key={theme.id} className="fav-items-ids">
          <div
            className="fav-items-image-option"
            style={{
              backgroundImage: `url(${theme.image})`,
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          />
          <div
            className="search-text-option"
            style={{
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          >
            {theme.name}{" "}
            <FaClose data-id={theme.id} onClick={this.removeTheme} />
          </div>
        </div>
      );
    });
    const searchAccessories = map(this.state.chosenAccessories, (accessory) => {
      return (
        <div key={accessory.id} className="fav-items-ids">
          <div
            className="fav-items-image-option"
            style={{
              backgroundImage: `url(${accessory.image})`,
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          />
          <div
            className="search-text-option"
            style={{
              display: "inline-block",
              alignItems: "center",
              verticalAlign: "middle",
            }}
          >
            {accessory.name}{" "}
            <FaClose data-id={accessory.id} onClick={this.removeAccessory} />
          </div>
        </div>
      );
    });

    let searchTextHeader = "No results found";
    if (this.state.firstTime) {
      searchTextHeader = "";
    }
    if (Lunches.length > 0) {
      if (Lunches.length === 100) {
        searchTextHeader = "More than 99+ results found";
      } else {
        searchTextHeader =
          Lunches.length +
          " result" +
          (Lunches.length > 1 ? "s" : "") +
          " found";
      }
    }

    const fp = (
      <Flatpickr
        ref={(f) => {
          this.fp = f;
        }}
        className="search-date-box"
        value={dateRange}
        onChange={(dates) => {
          this.setState(
            {
              dateRange: dates,
            },
            () => {
              // force refresh
              self.selectFood(this.state.chosenFoods);
            }
          );
        }}
        options={{
          altInput: true,
          altFormat: "F j, Y",
          dateFormat: "Y-m-d",
          mode: "range",
          maxDate: "today",
        }}
      />
    );

    const { activeOption } = this.state;

    return (
      <div className="explore-food-page">
        <Helmet>
          <title>Teuko - Lunchbox Search</title>
        </Helmet>
        <div className="in-app-container">
          
          <div className="search-title-header">
            <div className="search-title">
              <h1>Search</h1>
            </div>
            <div className="search-title-header-ad-laptop_leaderboard">
              {/* <!-- /22874318077/Teuko-Search-Laptop_AdUnit_1-Leaderboard-728x90 --> */}
              <div id='div-gpt-ad-1676499879279-0' style={{minWidth: "728px", minHeight: "90px"}} />
            </div>
            <div className="search-title-header-ad-smartphone_leaderboard">
              {/* <!-- /22874318077/Teuko-Search-Smartphone_AdUnit_1-Leaderboard-320x50 --> */}
              <div id='div-gpt-ad-1676500831298-0' style={{minWidth: "320px", minHeight: "50px"}} />
            </div>
          </div>

          {user.id !== 0 ? (
            ""
          ) : (
            <MailingListPopup delayInMilliseconds={20000} client={this.props.client}/>
          )}
          <div className="search-selection-group">
            <div className="preference-fs-right-search">
              <p>Foods</p>
              <div className="search-bar-holder">
                <VirtualizedSelect
                  valueKey="id"
                  labelKey="name"
                  options={ingredients}
                  optionHeight={50}
                  optionRenderer={this.lunchOptionRenderer}
                  className="search-selector"
                  onChange={(option) => this.selectFood(option)}
                />
                {/* <FaSearch className="select-search-icon" /> */}
              </div>
              <div className="selected-food-section">{searchTerms}</div>
            </div>

            <div className="preference-fs-right-search">
              <p>Themes</p>
              <div className="search-bar-holder">
                <VirtualizedSelect
                  valueKey="id"
                  labelKey="name"
                  options={themes}
                  optionHeight={50}
                  optionRenderer={this.lunchOptionRenderer}
                  className="search-selector"
                  onChange={(option) => this.selectTheme(option)}
                />
                {/* <FaFilter className="select-search-icon" /> */}
              </div>
              {searchThemes}
            </div>

            <div className="preference-fs-right-search">
              <p>Accessories</p>
              <div className="search-bar-holder">
                <VirtualizedSelect
                  valueKey="id"
                  labelKey="name"
                  options={accessories}
                  optionHeight={50}
                  optionRenderer={this.lunchOptionRenderer}
                  className="search-selector"
                  onChange={(option) => this.selectAccessory(option)}
                />
              </div>
              {searchAccessories}
            </div>

            <div className="preference-fs-right-search">
              <p>People</p>
              <div className="search-bar-holder">
                <VirtualizedSelect
                  valueKey="id"
                  labelKey="name"
                  options={users}
                  optionHeight={50}
                  optionRenderer={this.lunchOptionRenderer}
                  className="search-selector"
                  onChange={(option) => this.selectUser(option)}
                />
                {/* <FaFilter className="select-search-icon" /> */}
              </div>
              {searchUsers}
            </div>

            <div className="preference-fs-right-search">
              <p>Dates</p>
              <div className="search-bar-holder">
                {fp}
                <button
                  className="input-button"
                  title="clear"
                  onClick={this.clearDate}
                >
                  <FaClose />
                </button>
              </div>
            </div>
          </div>

          <hr />
          <div className="search-title-header">
            <div className="search-title">
              <h2>{searchTextHeader}</h2>
            </div>
          </div>
          

          {Lunches.length > 0 ? "" : 
          <Element
            className="boutique-group"
            id="searchpagefeaturedideas"
            key="searchpagefeaturedideas"
            name="searchpagefeaturedideas"
          >

          <h3>Popular Lunchbox Categories</h3>

            <div className="feature-searchpage-items">
              
              <a
                className="feature-searchpage"
                key="featuresearchpagetrending"
                href="/browse?filter=Trending"
                alt="popular kids lunchbox ideas trending"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_trend.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Trending</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagebirthday"
                href="/search?theme=Birthday"
                alt="popular kids lunchbox ideas celebrating birthday"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_birthday.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Birthday</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpageflowers"
                href="/search?theme=Flowers"
                alt="popular kids lunchbox ideas  with flowers"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_flowers.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Flowers</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchfrenchschoollunchmenus"
                href="/Paris%20School%20Menus"
                alt="popular kids lunchbox ideas with French school lunch menus"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_frenchschoollunchmenus.jpg)",}}
              />
              {/* <p className="boutique-item-caption">French School Lunch Menus</p> */}
              </a>

            </div>

            <h3>Veggie-Loaded Lunchbox Ideas</h3>

            <div className="feature-searchpage-items">
              
              <a
                className="feature-searchpage"
                key="featuresearchpagetomato"
                href="/search?food=Tomatoes"
                alt="Kids lunchbox ideas with veggies vegetables tomato tomatoes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_tomato.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Tomato</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagebroccoli"
                href="/search?food=Broccoli"
                alt="Kids lunchbox ideas with veggies vegetables broccoli"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_broccoli.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Broccoli</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagecorn"
                href="/search?food=Corn"
                alt="Kids lunchbox ideas with veggies vegetables corn"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_corn.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Corn</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagecarot"
                href="/search?food=Carrots"
                alt="Kids lunchbox ideas with veggies vegetables carrots"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_carrot.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Carrots</p> */}
              </a>

            </div>

            <h3>Fun Lunchbox Ideas for Kids</h3>

              <div className="feature-searchpage-items">

              <a
                className="feature-searchpage"
                key="featuresearchpagerainbows"
                href="/search?theme=Rainbows"
                alt="Fun lunchbox ideas for kids with rainbows"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_rainbows.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Rainbows</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagemovies"
                href="/search?theme=Movies"
                alt="Fun lunchbox ideas for kids with movies themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_movies.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Movies</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagesports"
                href="/search?theme=Sports"
                alt="Fun lunchbox ideas for kids with sport themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_sports.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Sports</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagevideogames"
                href="/search?theme=Video%20Game"
                alt="Fun lunchbox ideas for kids with video games themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_videogames.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Video Games</p> */}
              </a>

            </div>

            <div className="search-google-ad-banner-type-leaderboard-centered">
              <div className="search-title-header-ad-laptop_leaderboard">
                {/* <!-- /22874318077/Teuko-Search-Laptop_AdUnit_2-Leaderboard-728x90 --> */}
                <div id='div-gpt-ad-1676503537129-0' style={{minWidth: "728px", minHeight: "90px"}} />

              </div>
              <div className="search-title-header-ad-smartphone_leaderboard2">
                {/* {<!-- /22874318077/Teuko-Search-Smartphone_AdUnit_2-Leaderboard-320x50 --> */}
                <div id='div-gpt-ad-1676505631807-0' style={{minWidth: "320px", minHeight: "50px"}} />
              </div> 
            </div>

            <h3>Lunchbox Ideas with Fruits</h3>

              <div className="feature-searchpage-items">

              <a
                className="feature-searchpage"
                key="featuresearchpageapple"
                href="/search?food=Apple"
                alt="Kids lunchbox ideas with fruits apple"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_apple.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Apples</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagegrapes"
                href="/search?food=Grapes"
                alt="Kids lunchbox ideas with fruits grapes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_grapes.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Apples</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpageblueberries"
                href="/search?food=Blueberries"
                alt="Kids lunchbox ideas with fruits blueberries"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_blueberries.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Apples</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagestrawberries"
                href="/search?food=Strawberries"
                alt="Kids lunchbox ideas with fruits strawberries"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_strawberries.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Apples</p> */}
              </a>

              </div>

              <h3>Seasonal Lunchbox Ideas</h3>

              <div className="feature-searchpage-items">
              <a
                className="feature-searchpage"
                key="featuresearchpagespring"
                href="/search?theme=Spring"
                alt="Kids lunchbox ideas Spring Seasonal Foods and Themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_spring.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Spring</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagesummer"
                href="/search?theme=Summer"
                alt="Kids lunchbox ideas Summer Seasonal Foods and Themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_summer.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Summer</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagefallautumn"
                href="/search?theme=Autumn"
                alt="Kids lunchbox ideas Autumn Fall Seasonal Foods and Themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_autumn.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Fall Autumn</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagewinter"
                href="/search?theme=Winter"
                alt="Kids lunchbox ideas Winter Seasonal Foods and Themes"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_winter.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Summer</p> */}
              </a>

              </div>

              <h3>Protein Ideas for Kids' Lunch Boxes</h3>

              <div className="feature-searchpage-items">

              <a
                className="feature-searchpage"
                key="featuresearchpagebeans"
                href="/search?food=Beans"
                alt="Kids lunchbox ideas with beans vegetable proteins"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_beans.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Beans</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagechicken"
                href="/search?food=Chicken"
                alt="Kids lunchbox ideas with proteins chicken"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_chicken.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Beans</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpagebeef"
                href="/search?food=Beef"
                alt="Kids lunchbox ideas with proteins beef"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_beef.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Beans</p> */}
              </a>

              <a
                className="feature-searchpage"
                key="featuresearchpageeggs"
                href="/search?food=Eggs"
                alt="Kids lunchbox ideas with proteins eggs"
              >
              <div
                className="feature-searchpage-image"
                style={{backgroundImage:"url(/images/searchpage_eggs.jpg)",}}
              />
              {/* <p className="boutique-item-caption">Beans</p> */}
              </a>

              </div>





          </Element>}

          {Lunches.length > 0 ?

          <div>

            <div className="profile-content-options-display-smartphone">
              <h3
                className={
                  activeOption === 0 ? "lunch-content-option-active" : ""
                }
                onClick={() => this.setState({ activeOption: 0 })}
              >
                <FaGrid />
              </h3>
              <h3
                className={
                  activeOption === 1 ? "lunch-content-option-active" : ""
                }
                onClick={() => this.setState({ activeOption: 1 })}
              >
                <FaImage />
              </h3>
            </div>


            <div className="lunch-thumb-group-display-only-smartphone">
              <div className="partners-main-info">
                {activeOption === 0 ? (
                <InfiniteScroll
                pageStart={0}
                className="explore-food-lunches lunch-grid-group-profile"
                loadMore={() => this.loadMore()}
                hasMore
              >
                {LunchesGrid}
              </InfiniteScroll>
              ) : (
                ""
              )}
              {activeOption === 1 ? (
                <InfiniteScroll
                pageStart={0}
                className="explore-food-lunches lunch-thumb-group"
                loadMore={() => this.loadMore()}
                hasMore
              >
                {Lunches}
              </InfiniteScroll>

              ) : (
                ""
              )}
            </div>
          </div>


            <div className="lunch-thumb-group-display-only-laptop">
              <InfiniteScroll
                pageStart={0}
                className="explore-food-lunches lunch-thumb-group"
                loadMore={() => this.loadMore()}
                hasMore
              >
                {Lunches}
              </InfiniteScroll>
            </div>

          </div>



          : ""
        }

        </div>



        { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to get more lunchbox ideas and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}



      </div>



    );
  }
}

ExploreFoodPage.propTypes = {
  data: PropTypes.object,
  params: PropTypes.object,
  location: PropTypes.object,
};

export default withApollo(ExploreFoodPage);
