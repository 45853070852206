import React, { Component } from "react";
import { Link } from "react-router";
import SignUp from "../accounts/SignUp.js";
import LogIn from "../accounts/LogIn.js";

class OutHeader extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.state = {
      mobileOpen: false,
      signup: false,
      login: false,
    };
  }
  close() {
    this.setState({ signup: false, login: false });
  }
  render() {
    const { /*mobileOpen,*/ signup, login } = this.state;
    return (
      <div className="main-header">
        <header className="out-header header-standard">
          <div className="options-left">
            <Link to="/" className="full-menu-option">
              <img src="/images/teukologo.png" alt="Teuko Logo Lunchbox" />
            </Link>
            <Link
              to="/feed"
              id="feedlink-outheader"
              className="full-menu-option"
            >
              Feed
            </Link>
            <Link
              to="/search"
              id="searchlink-outheader"
              className="full-menu-option"
            >
              Search
            </Link>
            <Link
              to="/challenges"
              id="challengeslink-outheader"
              className="full-menu-option"
            >
              Challenges
            </Link>
            <Link
              to="/blog"
              id="bloglink-outheader"
              className="full-menu-option"
            >
              Blog
            </Link>
            <Link
              to="/boutique"
              id="boutiquelink-outheader"
              className="full-menu-option"
            >
              Shop
            </Link>
          </div>
          <div className="options-right">
            <Link to="/login" id="log-in" className="full-menu-option full-menu-option-laptop-login">
              Log In
            </Link>
            <Link to="/signup" id="signup" className="full-menu-option full-menu-option-laptop-signup">
              Sign Up
            </Link>
          </div>
        </header>
        <div className="mobile-menu">
          {/* <Menu right isOpen={mobileOpen} onClick={() => this.setState({ mobileOpen: !mobileOpen })}>
            <Link to="/login">Log in</Link>
            <Link to="/signup" id="sign-up">Sign Up Free</Link>
            <Link to="/feed">Explore</Link>
            <Link to="/boutique">Boutique</Link>
            <Link to="/blog">Blog</Link>
          </Menu> */}
          <Link to="/" className="mobile-company-logo">
            <img src="/images/teukologo.png" alt="Teuko Logo Lunchbox" />
          </Link>
          <div className="mobile-right">
            <Link to="/login" className="full-menu-option-outheader-login">
              Log In
            </Link>
            <Link to="/signup" className="full-menu-option-outheader-signup">
              Sign Up
            </Link>
          </div>
        </div>
        {signup ? <SignUp close={this.close} /> : ""}
        {login ? <LogIn close={this.close} /> : ""}
      </div>
    );
  }
}

export default OutHeader;
