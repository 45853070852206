import React, { Component } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import CreateLunch from "../create/CreateLunch";
import onClickOutside from "react-onclickoutside";
// import FaFacebookSquare from "../../fa/facebook-square";
// import FaInstagram from "../../fa/instagram";
// import FaTwitter from "../../fa/twitter";
// import FaYoutube from "../../fa/youtube";
// import FaPinterestSquare from "../../fa/pinterest-square";
import FaHome from "../../fa/home";
import FaShoppingBag from "../../fa/shopping-bag";
import FaPartyPopper from "../../fa/party-popper";
import FaCalendar from "../../fa/calendar";
import FaSearch from "../../fa/search";
import FaNewspaper from "../../fa/newspaper";

class InFooter extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      mobileOpen: false,
      createLunch: false,
      mobileSearchOpen: false,
      dropdownOpen: false,
    };
  }
  handleClickOutside() {
    this.setState({ dropdownOpen: false });
  }
  logout(e) {
    e.preventDefault();
    const myInit = {
      method: "get",
      headers: {
        authorization: localStorage.getItem("sessionID") || null,
      },
    };
    fetch(`/logout`, myInit)
      .then((res) => {
        localStorage.removeItem("sessionID");
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677697817671-0');
        googletag.display('div-gpt-ad-1677697979427-0');
      });
    }
  }

  render() {
    const { createLunch } = this.state;
    const { user, location } = this.props;
    const { pathname } = location;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div>
        {createLunch ? (
          <CreateLunch
            userId={user.id}
            closePopup={() => this.setState({ createLunch: false })}
          />
        ) : (
          ""
        )}
        <div className="bottom-nav in-mobile-menu" id="bottom-nav">
          <div className="footer-icons">
            <Link
              to="/"
              className={
                pathname === "/browse" || pathname === "/"
                  ? "active-icon-option"
                  : ""
              }
            >
              <FaHome /><span className="footer-icons-text">Feed</span>
            </Link>
            {/* <Link
              to="/blog"
              className={pathname === "/blog" ? "active-icon-option" : ""}
            >
              <FaLightbulbO />
            </Link> */}
            <Link 
              to="/search" 
              className={pathname === "/search" ? "active-icon-option" : ""}
            >
              <FaSearch /><span className="footer-icons-text">Search</span>
            </Link>
            {/* <a href="#" onClick={() => this.setState({ createLunch: true })}>
              <FaPlusSquare />
            </a> */}
            <Link
              to="/mylunches"
              className={pathname === "/mylunches" ? "active-icon-option" : ""}
            >
              {/* <FaUser /> */}
              <FaCalendar /><span className="footer-icons-text">Lunches</span>
            </Link>
            
              <Link to="/challenges"
              className={pathname === "/challenges" ? "active-icon-option" : ""}>
              <FaPartyPopper /><span className="footer-icons-text">Challenges</span>
            </Link>
            
            <Link
              to="/blog"
              className={pathname === "/blog" ? "active-icon-option" : ""}
            >
              <FaNewspaper /><span className="footer-icons-text">Blog</span>
            </Link>

            <Link
              to="/boutique"
              className={pathname === "/boutique" ? "active-icon-option" : ""}
            >
              <FaShoppingBag /><span className="footer-icons-text">Shop</span>
            </Link>
            
            
          </div>
        </div>

        <div className="blog-ad-banner">
            <div className="search-title-header-ad-laptop_leaderboard">
              {/* <!-- /22874318077/Teuko-Footer-Laptop_AdUnit_1-Leaderboard-728x90 --> */}
              <div id='div-gpt-ad-1677697817671-0' style={{minWidth: "728px", minHeight: "90px"}} />
            </div>
            <div className="search-title-header-ad-smartphone_leaderboard">
              {/* <!-- /22874318077/Teuko-Footer-Smartphone_AdUnit_1-Leaderboard-320x50 --> */}
              <div id='div-gpt-ad-1677697979427-0'style={{minWidth: "320px", minHeight: "50px"}} />
            </div>
          </div>

        <footer className="footer2" id="footer2023">
            <div className="footer2-interior-div">
              <nav className="footer2-nav">
                {/* <h2 className="footer2-resources_links"></h2> */}
                
                <div className="footer2-column_resources">
                  <div className="footer2_column_title">
                    <h3 className="footer2_column_title_h3">Features</h3>
                  </div>
                  <ul className="footer2_column_list_resources_ul">
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/lunchboxcards" draggable="false">
                          Lunchbox Cards
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/foodgroups" draggable="false">
                          Food Groups Indicators
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/schoollunchmenus" draggable="false">
                          School Lunch Menus
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/lunchboxdiary" draggable="false">
                          Lunchbox Diary
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/groceryshopping" draggable="false">
                          Grocery Shopping
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/features" draggable="false">
                          See more features
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="footer2-column_resources">
                  <div className="footer2_column_title">
                    <h3 className="footer2_column_title_h3">Explore</h3>
                  </div>
                  <ul className="footer2_column_list_resources_ul">
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/feed" draggable="false">
                          Feed
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/search" draggable="false">
                          Search
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/blog" draggable="false">
                          Blog
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/boutique" draggable="false">
                          Shop
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/challenges" draggable="false">
                          Challenges
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="footer2-column_resources">
                  <div className="footer2_column_title">
                    <h3 className="footer2_column_title_h3">Community</h3>
                  </div>
                  <ul className="footer2_column_list_resources_ul">
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/partners" draggable="false">
                          Partnerships
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/hashtagteuko" draggable="false">
                          #Teuko
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="footer2-column_resources">
                  <div className="footer2_column_title">
                    <h3 className="footer2_column_title_h3">Company</h3>
                  </div>
                  <ul className="footer2_column_list_resources_ul">
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/about" draggable="false">
                          About
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/jobs" draggable="false">
                          Careers
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/faq" draggable="false">
                          FAQ
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/terms" draggable="false">
                          Terms
                        </a>
                      </p>
                    </li>
                    <li className="footer2_column_list_resources_li">
                      <p className="footer2_column_list_resources_p">
                        <a className="footer2_column_list_resources_a" href="/privacy" draggable="false">
                          Privacy
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>

              </nav>

              <div className="footer2-section_privacy_and_social">

                <div className="footer2-section_privacy_and_social-terms_privacy">
                  <p className="footer2-section_privacy_and_social-terms_privacy_all_rights_reserved_p">
                    <span className="footer2-section_privacy_and_social-terms_privacy_all_rights_reserved_span">
                      © 2024 All Rights Reserved, Teuko, Inc.
                    </span>
                  </p>
                  {/* <span className="footer2-section_privacy_and_social-terms_privacy_privacy_terms_span">
                    <a href="/privacy" target="_blank" rel="nooppener nofollow" className="footer2_section_privacy-privacy_a">
                      Privacy Policy
                    </a>
                    <span className="footer2-section_privacy_and_social-terms_privacy_separator_span"></span>
                    <a href="/terms" target="_blank" rel="nooppener nofollow" className="footer2_section_privacy-privacy_a">
                      Terms
                    </a>
                  </span> */}
                </div>

                <div className="footer2-section_social">
                  <a className="footer2-section_social-logo_a" href="https://www.instagram.com/teukoapp" draggable="false" target="_blank" rel="nooppener noreferrer">
                    <span aria-hidden="true" className="footer2-section_social-logo_span_instagram">
                      <svg fill="none" heigh="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <linearGradient id="_1646198408__a" y1="100%"><stop offset="0%" stopColor="#ffd520"></stop><stop offset="49%" stopColor="#f50000"></stop><stop offset="100%" stopColor="#b900b4"></stop></linearGradient>
                        <path fill="url(#_1646198408__a)" d="M12 3c-2.44 0-2.75.01-3.71.05-.96.05-1.61.2-2.19.42a4.4 4.4 0 0 0-1.59 1.04c-.5.5-.8 1-1.04 1.6a6.6 6.6 0 0 0-.42 2.18C3.01 9.25 3 9.56 3 12s.01 2.75.05 3.71c.05.96.2 1.61.42 2.19.23.59.54 1.09 1.04 1.59s1 .8 1.6 1.04a6.6 6.6 0 0 0 2.18.42c.96.04 1.27.05 3.71.05s2.75-.01 3.71-.05c.96-.05 1.61-.2 2.19-.42a4.4 4.4 0 0 0 1.59-1.04c.5-.5.8-1 1.04-1.6a6.6 6.6 0 0 0 .42-2.18c.04-.96.05-1.27.05-3.71s-.01-2.75-.05-3.71a6.62 6.62 0 0 0-.42-2.19 4.4 4.4 0 0 0-1.04-1.59c-.5-.5-1-.8-1.6-1.04a6.6 6.6 0 0 0-2.18-.42C14.75 3.01 14.44 3 12 3zm0 1.62c2.4 0 2.69.01 3.64.05.87.04 1.35.2 1.67.31.42.17.72.36 1.03.68.32.31.51.61.68 1.03.12.32.27.8.3 1.67.05.95.06 1.24.06 3.64 0 2.4-.01 2.69-.06 3.64-.04.87-.19 1.35-.31 1.67-.17.42-.36.72-.68 1.03-.31.32-.62.51-1.03.68-.32.12-.8.27-1.68.3-.95.05-1.24.06-3.64.06-2.41 0-2.7-.01-3.65-.06a5.12 5.12 0 0 1-1.67-.31 2.8 2.8 0 0 1-1.04-.68 2.75 2.75 0 0 1-.67-1.03c-.13-.32-.27-.8-.32-1.68-.03-.94-.04-1.24-.04-3.63 0-2.4 0-2.7.04-3.65.05-.87.2-1.36.32-1.67.15-.43.36-.72.67-1.04a2.67 2.67 0 0 1 1.04-.67c.31-.13.78-.27 1.66-.32.96-.03 1.24-.04 3.65-.04l.03.02zm0 2.76a4.62 4.62 0 1 0 0 9.24 4.62 4.62 0 0 0 0-9.24zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm5.88-7.8a1.08 1.08 0 1 1-2.16 0 1.08 1.08 0 0 1 2.16 0z"></path>
                      </svg>
                    </span>
                    <div className="footer2-Facebook_logo">Instagram</div>
                    <span className="footer2-Facebook_logo">(opens in a new tab or window)</span>
                  </a>
                  <a className="footer2-section_social-logo_a" href="https://www.facebook.com/teukoapp" draggable="false" target="_blank" rel="nooppener noreferrer">
                    <span aria-hidden="true" className="footer2-section_social-logo_span_facebook">
                      <svg fill="none" heigh="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.5 12.058c0-5.28-4.253-9.558-9.5-9.558s-9.5 4.279-9.5 9.558c0 4.771 3.473 8.725 8.016 9.442v-6.68H8.104v-2.762h2.412V9.952c0-2.395 1.417-3.718 3.588-3.718 1.04 0 2.126.186 2.126.186v2.352h-1.198c-1.18 0-1.548.738-1.548 1.494v1.792h2.635l-.421 2.763h-2.214V21.5c4.543-.717 8.016-4.67 8.016-9.442z" fill="currentColor">
                        </path>
                      </svg>
                    </span>
                    <div className="footer2-Facebook_logo">Facebook</div>
                    <span className="footer2-Facebook_logo">(opens in a new tab or window)</span>
                  </a>
                  <a className="footer2-section_social-logo_a" href="https://www.twitter.com/teukoapp" draggable="false" target="_blank" rel="nooppener noreferrer">
                    <span aria-hidden="true" className="footer2-section_social-logo_span_twitter">
                      <svg fill="none" heigh="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.74 6.69a4.26 4.26 0 0 0 1.7-2.38c-.75.5-1.58.85-2.46 1.05A3.73 3.73 0 0 0 16.13 4c-2.15 0-3.89 1.92-3.89 4.3 0 .33.04.66.1.97a10.75 10.75 0 0 1-8.02-4.48 4.61 4.61 0 0 0 1.2 5.73 3.6 3.6 0 0 1-1.76-.54v.05c0 2.08 1.34 3.82 3.12 4.21a3.56 3.56 0 0 1-1.75.07 3.97 3.97 0 0 0 3.63 2.98A7.36 7.36 0 0 1 3 19.07 10.29 10.29 0 0 0 8.97 21c7.16 0 11.07-6.54 11.07-12.21l-.01-.56a8.4 8.4 0 0 0 1.94-2.22c-.7.34-1.45.57-2.23.68z" fill="currentColor">
                        </path>
                      </svg>
                    </span>
                    <div className="footer2-Facebook_logo">Twitter</div>
                    <span className="footer2-Facebook_logo">(opens in a new tab or window)</span>
                  </a>
                  <a className="footer2-section_social-logo_a" href="https://www.pinterest.com/teukoapp" draggable="false" target="_blank" rel="nooppener noreferrer">
                    <span aria-hidden="true" className="footer2-section_social-logo_span_pinterest">
                      <svg fill="none" heigh="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2a10 10 0 0 0-3.64 19.31c-.1-.79-.17-2 .03-2.87l1.17-4.97s-.3-.6-.3-1.48c0-1.4.8-2.43 1.81-2.43.86 0 1.27.64 1.27 1.4 0 .87-.55 2.15-.83 3.34-.24 1 .5 1.8 1.48 1.8 1.78 0 3.14-1.87 3.14-4.58 0-2.39-1.72-4.06-4.17-4.06a4.33 4.33 0 0 0-4.52 4.34c0 .86.33 1.78.75 2.28a.3.3 0 0 1 .07.29l-.28 1.13c-.05.18-.15.22-.34.13-1.25-.58-2.03-2.4-2.03-3.87 0-3.15 2.3-6.05 6.61-6.05 3.47 0 6.16 2.47 6.16 5.77 0 3.45-2.17 6.22-5.18 6.22-1.02 0-1.97-.52-2.3-1.15l-.62 2.38c-.22.87-.83 1.96-1.24 2.62A10 10 0 1 0 12 2" fill="currentColor">
                        </path>
                      </svg>
                    </span>
                    <div className="footer2-Facebook_logo">Pinterest</div>
                    <span className="footer2-Facebook_logo">(opens in a new tab or window)</span>
                  </a>
                  <a className="footer2-section_social-logo_a" href="https://www.youtube.com/@teuko" draggable="false" target="_blank" rel="nooppener noreferrer">
                    <span aria-hidden="true" className="footer2-section_social-logo_span_youtube">
                      <svg fill="none" heigh="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" fill="currentColor">
                        </path>
                      </svg>
                    </span>
                    <div className="footer2-Facebook_logo">Youtube</div>
                    <span className="footer2-Facebook_logo">(opens in a new tab or window)</span>
                  </a>
                </div>

              </div>

            </div>
        </footer>
        

      </div>
    );
  }
}

InFooter.propTypes = {
  user: PropTypes.object,
};

export default onClickOutside(InFooter);
