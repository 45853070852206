import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { graphql, withApollo, compose } from "react-apollo";
import gql from "graphql-tag";
import FaAt from "../../fa/at";
import FaFacebookSquare from "../../fa/facebook-square";
import FaInstagram from "../../fa/instagram";
import FaLink from "../../fa/link";
import FaTwitter from "../../fa/twitter";
import FaYoutube from "../../fa/youtube";
import FaTiktok from "../../fa/tiktok";
import FaPinterestSquare from "../../fa/pinterest-square";

class Social extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(e) {
        e.preventDefault();
        const facebook_handle = document.getElementById('facebook_handle_input').value;
        const instagram_handle = document.getElementById('instagram_handle_input').value;
        const twitter_handle = document.getElementById('twitter_handle_input').value;
        const pinterest_handle = document.getElementById('pinterest_handle_input').value;
        const tiktok_handle = document.getElementById('tiktok_handle_input').value;
        const youtube_handle = document.getElementById('youtube_handle_input').value;
        const bio_link = document.getElementById('bio_link_input').value;
        const variables = {
            facebook_handle,
            instagram_handle,
            twitter_handle,
            pinterest_handle,
            tiktok_handle,
            youtube_handle,
            bio_link,
        };
        const response = await this.props.mutate({ variables });
        if (response.data.changeSocial) {
            const button = document.getElementById("save-social");
            const previousText = button.innerText;
            button.innerText = "Changes Saved!";
            setTimeout(() => { button.innerText = previousText }, 3000);
        }
    }    

    render() {
        const { data } = this.props;
        if (data.loading || data.error) {
            return <div>Loading...</div>;
        }

        const {
            currentUser: {
                facebook_handle,
                instagram_handle,
                twitter_handle,
                pinterest_handle,
                tiktok_handle,
                youtube_handle,
                bio_link,
            },
          } = data;
      
    
        return (
            <Collapsible trigger="Social">
                <div className="social-profiles-form">
                    <div className="input-group-social">
                        <FaFacebookSquare /><label htmlFor="facebook_handle_input" className="social-network-name">Facebook</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaAt /></span>
                            <input className="settings-social-input" type="text" id="facebook_handle_input" name="facebook_handle_input" placeholder="Username" defaultValue={facebook_handle}>
                            </input>
                        </div>
                    </div>

                    <div className="input-group-social">
                        <FaInstagram /><label htmlFor="instagram_handle_input" className="social-network-name">Instagram</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaAt /></span>
                            <input className="settings-social-input" type="text" id="instagram_handle_input" name="instagram_handle_input" placeholder="Username" defaultValue={instagram_handle}></input>
                        </div>
                    </div>

                    <div className="input-group-social">
                        <FaTwitter /><label htmlFor="twitter_handle_input" className="social-network-name">Twitter</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaAt /></span>
                            <input className="settings-social-input" type="text" id="twitter_handle_input" name="twitter_handle_input" placeholder="Username" defaultValue={twitter_handle}></input>
                        </div>
                    </div>

                    <div className="input-group-social">
                        <FaPinterestSquare /><label htmlFor="pinterest_handle_input" className="social-network-name">Pinterest</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaAt /></span>
                            <input className="settings-social-input" type="text" id="pinterest_handle_input" name="pinterest_handle_input" placeholder="Username" defaultValue={pinterest_handle}></input>
                        </div>
                    </div>

                    <div className="input-group-social">
                        <FaTiktok /><label htmlFor="tiktok_handle_input" className="social-network-name">TikTok</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaAt /></span>
                            <input className="settings-social-input" type="text" id="tiktok_handle_input" name="tiktok_handle_input" placeholder="Username" defaultValue={tiktok_handle}></input>
                        </div>
                    </div>

                    <div className="input-group-social">
                        <FaYoutube /><label htmlFor="youtube_handle_input" className="social-network-name">Youtube</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaLink /></span>
                            <input type="url" id="youtube_handle_input" name="youtube_handle_input" placeholder="Youtube URL" defaultValue={youtube_handle}></input>
                        </div>
                    </div>

                    <div className="input-group-social">
                        <FaLink /><label htmlFor="bio_link_input" className="social-network-name">Link in Bio</label>
                        <div className="input-group-social-table">
                            <span className="input-group-addon"><FaLink /></span>
                            <input type="url" id="bio_link_input" name="bio_link_input" placeholder="URL Link" defaultValue={bio_link}></input>
                        </div>
                    </div>

                    <div className="form-group">
                        <button
                        className="settings-button"
                        id="save-social"
                        onClick={this.handleSubmit}
                        >
                        Save Changes
                        </button>
                    </div>

                </div>
            </Collapsible>
        );

    }

}

const socialQuery = gql`
  query currentUser {
    currentUser {
      id
      facebook_handle
      instagram_handle
      twitter_handle
      pinterest_handle
      tiktok_handle
      youtube_handle
      bio_link
    }
  }
`;

const changeSocialMutation = gql`
  mutation changeSocial(
    $facebook_handle: String
    $instagram_handle: String
    $twitter_handle: String
    $pinterest_handle: String
    $tiktok_handle: String
    $youtube_handle: String
    $bio_link: String
  ) {
    changeSocial(
        facebook_handle: $facebook_handle
        instagram_handle: $instagram_handle
        twitter_handle: $twitter_handle
        pinterest_handle: $pinterest_handle
        tiktok_handle: $tiktok_handle
        youtube_handle: $youtube_handle
        bio_link: $bio_link
    )
  }
`;

export default compose(
  withApollo,
  graphql(socialQuery, {
    options: { fetchPolicy: "network-only" },
  }),
  graphql(changeSocialMutation)
)(Social);
