import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router";

class FeaturesPage extends Component {
  constructor(props) {
    super(props);
    this.registerClick = this.registerClick.bind(this);
  }
  registerClick(e) {
    e.preventDefault();
    document.getElementById("sign-up").click();
  }

  // componentDidMount () {
  //   const googletag = window.googletag;
  //   if (googletag) {
  //     googletag.cmd.push(function() { 
  //       googletag.display('div-gpt-ad-1675713995486-0'); 
  //     });
  //   }
  // }

  render() {

    return (
      <Fragment>
        <Helmet>
          <title>Teuko Kids Lunchbox Ideas Community - Features</title>
          <meta name="description" content="With amazing features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <link rel="canonical" href="https://www.teuko.com/features/"></link>
          <meta name="robots" content="follow, index, max-image-preview:large" />
          <meta name="googlebot" content="follow, index, max-image-preview:large"></meta>
          <meta property="og:url" content="https://www.teuko.com/features/"></meta>
          <meta property="og:description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:title" content="Explore Teuko Features to Unlock Your Lunchbox Creativity"></meta>
          <meta property="og:site_name" content="Teuko"></meta>
          <meta name="keywords" content="teuko, lunchbox, community, online community, bento, kids food, food, school lunch, bentobox, meal prep, cooking, parenting, packed lunch, lunch bag, kids lunchbox, kids lunch, lunch, bento lunch box, lunch boxes for kids, lunches for kids, lunch bags for kids, insulated lunch box, warm lunch, hot lunch, thermos lunch box, insulated lunch bags, lunch boxes, cute lunch box, easy lunch boxes, picky eaters food, toddler food, teenager food, lunchbox accessories, planetbox lunch box, bentgo lunch box, sistema lunch box, omie lunch box, omiebox, yumbox lunch box, French school lunch menus, Japanese school lunch menus, worldwide school lunch menus, lunchbox mom, lunchbox dad, lunchbox store, lunchbox boutique, lunchbox shop, lunchbox ideas, kids lunchbox ideas, easy lunchbox ideas, easy kids lunchbox ideas, healthy lunchbox ideas, healthy kids lunchbox ideas, lunchbox containers for kids, meal prep lunchbox "/>
          <meta property="fb:app_id" content="566204480216246"></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:title" content="Explore Teuko Features to Unlock Your Lunchbox Creativity"></meta>
          <meta name="twitter:site" content="@teukoapp"></meta>
          <meta name="twitter:description" content="With impressive features all in one place, creating your own lunchbox ideas that stand out has never been this easy. Sign up for a Teuko account today."></meta>
          <meta name="twitter:creator" content="@teukoapp"></meta>
          <meta property="og:image" content="/images/features-social-image_w1200xh630_teuko-lunchbox-community-kids-school-lunch-bento-food.png"></meta>
          <meta name="twitter:image" content="/images/features-social-image_w1200xh630_teuko-lunchbox-community-kids-school-lunch-bento-food.png"></meta>          
        </Helmet>
        
        <div className="partners-page">

            <div className="landing-heading-section">
                <div className="landing-heading-section-containerflex">
            
                    <div className="landing-heading-section-textcta">
                    <div className="landing-heading-section-subtitle">
                        <h1>Discover features that unlock your lunchbox creativity</h1>
                        <p>Teuko makes it easy to spice up your kids' lunches! Get inspired and creative with kids-approved lunchbox ideas to make your own delicious, healthy, or cute!</p>
                    </div>

                    <div className="container-browse-all-btn">
                        <Link to="/signup" className="standard-button browse-all-button">
                        Start now
                        </Link>
                    </div>
                    </div>

                    <div className="landing-heading-section-image" title="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep">
                        <img
                        src="/images/mockup_features Teuko lunchbox community kids school lunch food bento.png"
                        className="landing-heading-lunch-image"
                        alt="Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"
                        />

                    </div>
                </div>
            </div>
       
          
          <div className="partners-top">
            <div className="partners-content">
              
              <div>
                <div className="partners-main-info">
                    <div className="footer-features-list">
                            <a
                            className="features-item"
                            key="lunchboxcardsfeature"
                            href="/lunchboxcards"
                            >
                            <div className="features-item-image-lunchboxcards" title="Lunchbox Cards Features Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">Lunchbox Cards</h2>
                            <p className="features-lunchboxcards-text">All the information about the bento box that inspires you in one place</p>
                            </a>

                            <a
                            className="features-item"
                            key="foodgroupindicatorsfeature"
                            href="/foodgroups"
                            >
                            <div className="features-item-image-foodgroupindicators" title="Food Group Indicators features Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">Food Group Indicators</h2>
                            <p className="features-lunchboxcards-text">Pay attention to dietary diversity easily, one lunchbox at a time</p>
                            </a>

                            <a
                            className="features-item"
                            key="schoollunchmenusfeature"
                            href="/schoollunchmenus"
                            >
                            <div className="features-item-image-schoollunchmenus" title="Worldwide School lunch menus features Teuko lunchbox community kids bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">School Lunch Menus</h2>
                            <p className="features-lunchboxcards-text">Discover 1,000+ school lunch menus from all around the world</p>
                            </a>

                            {/* <GPT
                                adUnitPath="/6355419/Travel/Europe/France/Paris"
                                slotSize={[300, 250]}
                            /> */}

                            {/* <!-- /22874318077/Teuko-Features-AdUnit_1-300x250 --> */}
                            {/* <div id='div-gpt-ad-1675713995486-0' style={{minWidth: "300px", minHeight: "250px", marginTop: "45px"}}>
                            </div> */}

                            <a
                            className="features-item"
                            key="lunchboxdiaryfeature"
                            href="/lunchboxdiary"
                            >
                            <div className="features-item-image-lunchboxdiary" title="Lunchbox Diary features Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">Lunchbox Diary</h2>
                            <p className="features-lunchboxcards-text">Track what you pack. Or plan and organize your week. Or do both!</p>
                            </a>

                            <a
                            className="features-item"
                            key="groceryshoppingfeature"
                            href="/groceryshopping"
                            >
                            <div className="features-item-image-groceryshopping" title="Grocery Shopping personalized list online features Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">Grocery Shopping</h2>
                            <p className="features-lunchboxcards-text">Get your grocery shopping list or shop online at the touch of a button</p>
                            </a>

                            <a
                            className="features-item"
                            key="explore_teuko_from_features_page"
                            href="/signup"
                            >
                            <div className="features-item-image-exploreteuko" title="Explore feed search tool features Teuko lunchbox community kids school lunch bento ideas food packed lunch easy meal prep"/>
                            <h2 className="features-lunchboxcards-item-caption">Explore Teuko</h2>
                            <p className="features-lunchboxcards-text">With the Feed, the Search tool, and more, lunch packing for kids is easy and fun!</p>
                            </a>

                            {/* <GPT
                                adUnitPath="/6355419/Travel/Europe/France/Paris"
                                slotSize={[300, 250]}
                            /> */}


                    </div>
                  
                </div>
              </div>
            </div>
          </div>

          </div>


      </Fragment>
    );
  }
}

export default FeaturesPage;
