import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { createClient } from "contentful";
import { map } from "lodash";
import { Link, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import MailingListPopup from "../../components/general/MailingListPopup";

class BoutiquePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      groups: [],
      activeOption: 0,
    };
  }
  UNSAFE_componentWillMount() {
    document.body.classList.add("home");
    const self = this;
    const client = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });
    client
      .getEntries({
        content_type: "boutique",
        "fields.number": 1,
        include: 2,
      })
      .then((entries) => {
        self.setState({
          groups: entries.items[0].fields.group,
          loading: false,
        });
      });
  }

  componentDidUpdate () {
    if (this.props.location.hash) {
      const hash = this.props.location.hash;
      setTimeout(() => {
        const targetSection = document.querySelector(hash);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        } else {
          console.log("Error: invalid scroll hash ", hash);
        }
      }, 1000);
    }
  }

  componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() { 
        setTimeout(() => {
          googletag.display('div-gpt-ad-1676586272959-0');
          googletag.display('div-gpt-ad-1676586706379-0');
          
        }, 2000);
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("home");
  }
  render() {
    const { user } = this.props;
    const { groups, loading } = this.state;
    if (loading) return <div />;
    const Groups = map(groups, (g) => {
      const Items = map(g.fields.items, (i) => (
        <a
          className="boutique-item"
          key={i.sys.id}
          target="_blank"
          href={i.fields.url}
          rel="noopener noreferrer"
        >
          <div
            className="boutique-item-image"
            style={{
              backgroundImage: `url(${i.fields.image.fields.file.url})`,
            }}
          />
          <h5 className="boutique-item-caption">{i.fields.productName}</h5>
        </a>
      ));
      return (
        <div key={`boutique_div_${g.sys.id}`}>
          
            <Element
              className="boutique-group"
              id={"_" + g.sys.id}
              key={`bg${g.sys.id}`}
              name={g.sys.id}
            >
              <div className="boutique-header-bar">
                <h2>{g.fields.title}</h2>
              </div>
              <div className="boutique-group-items">{Items}</div>
            </Element> 
          

        </div>
      );
    });


    return (
      <Fragment>
        <Helmet>
          <title>Teuko Bento LunchBox Shop - Themes by Category </title>
        </Helmet>
        <div className="boutique-page">
          {user.id !== 0 ? (
            ""
          ) : (
            <MailingListPopup delayInMilliseconds={15000} client={this.props.client}/>
          )}
          <div className="browse-boutique-header">
           
              <h1>Teuko Boutique</h1>

            <div className="my-boutique-filters">
              
              <div className="boutique-content-options">
                <h3>Themes by Category</h3>
                <a href="/boutique-themes-AZ"><h3 style={{color: '#737373'}}>Themes A-Z</h3></a>
                <a href="/boutique-brands"><h3 style={{color: '#737373'}}>Brands</h3></a>
              </div>
          
            </div>
                  
          </div>


          <div>

                <div>
                  
                  <div className="boutique-menus">
                    <Link
                    key="_DvRhS9ue8tUrewYquMQZU"
                    to="_DvRhS9ue8tUrewYquMQZU"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Lunchbox Essentials</h3>
                    </Link>

                    <Link
                    key="_6XwP376E1RONFrXYN2ULbr"
                    to="_6XwP376E1RONFrXYN2ULbr"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Seasonal</h3>
                    </Link>

                    <Link
                    key="_2hg46R5042x1bhJSe5jnHh"
                    to="_2hg46R5042x1bhJSe5jnHh"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Popular Licensed Characters</h3>
                    </Link>

                    <Link
                    key="_1jWoRVrD6CIl0zseRln4S8"
                    to="_1jWoRVrD6CIl0zseRln4S8"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>For Little Ones</h3>
                    </Link>

                    <Link
                    key="_tHRo0V9x8TrIcEUNMPAM0"
                    to="_tHRo0V9x8TrIcEUNMPAM0"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Cute Animals</h3>
                    </Link>

                    <Link
                    key="_5LK4pbNhnoKYgmslWkIGHn"
                    to="_5LK4pbNhnoKYgmslWkIGHn"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>With Energizing Vibes</h3>
                    </Link>

                    <Link
                    key="_2z0D2qlV9DDR1EwmzLWVPF"
                    to="_2z0D2qlV9DDR1EwmzLWVPF"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Magical World</h3>
                    </Link>

                    <Link
                    key="_7oLGnitpqXRII0ycMpHmeA"
                    to="_7oLGnitpqXRII0ycMpHmeA"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Vehicles / Transportation</h3>
                    </Link>

                    <Link
                    key="_7mQ2h119V5CJs1eLFsZbfI"
                    to="_7mQ2h119V5CJs1eLFsZbfI"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>For Imaginative Minds</h3>
                    </Link>

                    <Link
                    key="_7vlCZ5i0NeEYiydt8kdxOJ"
                    to="_7vlCZ5i0NeEYiydt8kdxOJ"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>For Gamers</h3>
                    </Link>

                    <Link
                    key="_4Hr1GQe9zVxVrrDePWMwFz"
                    to="_4Hr1GQe9zVxVrrDePWMwFz"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>Beach Vibes</h3>
                    </Link>
                    
                  </div>

                  <div className="standard-container">
                  <div className="boutique-groups">
                    <div className="boutique-affiliate-message">
                      <p>
                        Disclaimer: As an Amazon Associate, Teuko earns from qualifying purchases. If you buy some
                        products, we may get a small share of the revenue from the sale. The Teuko's Choice section may also be sponsored. This helps Teuko.com remain free!
                      </p>
                    </div>

                    <div className="search-google-ad-banner-type-leaderboard-centered">
                      <div className="search-title-header-ad-laptop_leaderboard">
                        {/* <!-- /22874318077/Teuko-Boutique-Laptop_AdUnit_1-Leaderboard-728x90 --> */}
                        <div id='div-gpt-ad-1676586272959-0' style={{minWidth: "728px", minHeight: "90px"}} />
                      </div>

                      <div className="search-title-header-ad-smartphone_leaderboard2">
                        {/* {<!-- /22874318077/Teuko-Boutique-Smartphone_AdUnit_1-Leaderboard-320x50 */}
                        <div id='div-gpt-ad-1676586706379-0' style={{minWidth: "320px", minHeight: "50px"}} />
                      </div> 
                    </div>

                    
                    {Groups}


                  </div>
                  </div>
                </div>


              </div>

            
     

          <div className="boutique-visit-amz-section">
            <a
              href="https://amzn.to/3nRksn3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>
                See our entire selection of lunch accessories in our Amazon Store!
              </h2>
            </a>
          
              <a href="https://amzn.to/38VE9DN" 
                 target="_blank"
                 rel="noopener noreferrer"
                 className="standard-button">
                  Visit Teuko Amazon Store
              </a>
            
          </div>

         

         



        </div>
      </Fragment>
    );
  }
}

export default withApollo(BoutiquePage);
