import { graphql } from "react-apollo";
import { Helmet } from "react-helmet";
import { map } from "lodash";
import FaFacebookSquare from "../../fa/facebook-square";
import FaTwitter from "../../fa/twitter";
import gql from "graphql-tag";
import React, { Component, Fragment } from "react";

class TrophiesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    render() {
        if (!this.props || !this.props || !this.props.getTrophies || this.props.getTrophies.loading) {
            console.log("LOADING... ", this.props.getTrophies);
            return (
                <div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <h2>Preparing your trophies... this may take a few seconds!</h2>
                </div>
            );
        }
        const username = this.props.params.user_name;
        console.log(this);
        const trophyData = this.props.getTrophies.getTrophies
        
        if (trophyData.length === 0) {
            if (username === this.props.user.user_name) {
                return (
                    <div>
                        <br/><br/><br/><br/>
                        You don't have any trophy yet. Join a challenge!
                        <br/><br/>
                    </div>
                );
            } else {
                return (
                    <div>
                        <br/><br/><br/><br/>
                        This user doesn't have any trophy yet!
                        <br/><br/>
                    </div>
                );
            }
        }
        
        const trophies = map(trophyData, (t) => {
            return (
                <div>
                    <img src={t.img_url} alt={t.description} width="150px" height="150px"/>
                    <p className="trophies-title">{t.title}</p>
                    <p className="trophies-date">{t.description}</p>
                </div>
            );
        });

        let title = "My Trophies";
        if (this.props.user.user_name !== username) {
            title = `${username}'s Trophies`;
        }

        return (
            <Fragment>
                <div className="browse-page">
                <Helmet>
                    <title>Teuko Lunchbox Community - Trophies</title>
                    
                    <meta
                    property="og:url"
                    content={`https://www.teuko.com/${username}/trophies`}
                    />
                    <meta property="og:type" content="website" />
                    <meta
                    property="og:title"
                    content={`Teuko Lunchbox Community - ${username}'s Trophies`}
                    />
                    <meta property="og:description" content="Challenges are a way to find motivation with your friends on Teuko!" />
                    <meta property="og:image" content="/images/Teuko challenges share visual.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@teukoapp" />
                    <meta
                    name="twitter:title"
                    content={`Teuko Lunchbox Community - ${username}'s Trophies`}
                    />
                    <meta name="twitter:description" content="Challenges are a way to find motivation with your friends on Teuko!" />
                    <meta name="twitter:image" content="/images/teuko trophies.jpg" />
                </Helmet>
            
            <div className="trophies-page">
                <div className="in-app-container">
                    <div className="browse-page-header">
            
                        <div className="trophies-header">
                            <h1>{title}</h1>
                            <ul className="trophies-social-shares">
                            <li className="landing-social-shares-list-item">
                            <a data-share-url={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com/${username}/trophies`}
                            data-ga-action="Facebook share"
                            data-ga-event="Site"
                            aria-describedby="NewWindow"
                            className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                            href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.teuko.com/${username}/trophies`}
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                                <FaFacebookSquare />
                                <span className="visuallyhidden">Facebook</span>
                                <span className="trophies-social-share__button-text">Share</span>
                            </a>
                            </li>
                            <li className="landing-social-shares-list-item">
                            <a data-share-url={`https://twitter.com/intent/tweet?text=Unlock+lunch+packing+superpowers+with+Teuko%21+Get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com/${username}/trophies`}
                            data-ga-action="Twitter share"
                            data-ga-event="Site"
                            aria-describedby="NewWindow"
                            className="trophies-social-shares__icon-wrapper social-shares__icon-wrapper--facebook js-social-share"
                            href={`https://twitter.com/intent/tweet?text=Check+out+my+trophies+on+Teuko%21+Plus+get+kids+lunchbox+ideas%2C+tips%2C+and+fun+shared+by+families+around+the+world%E2%80%94+https%3A%2F%2Fwww.teuko.com/${username}/trophies`}
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            <FaTwitter />
                            <span className="visuallyhidden">Twitter</span>
                            <span className="trophies-social-share__button-text">Tweet</span>
                            </a>
                            </li>
                            </ul>
                        </div>
                    </div>
            
                    <div className="trophies-group">            
                        <div className="trophies-group-items">
                            {trophies}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Fragment>
    );
    }
}

export const getTrophyQuery = gql`
  query getTrophies($username: String!) {
    getTrophies(username: $username) {
        title
        start_date
        end_date
        description
        img_url
    }
  }
`;

export default graphql(getTrophyQuery, {
    name: "getTrophies",
    options: (props) => ({
      variables: { username: props.params ? props.params["user_name"] : "" },
      fetchPolicy: "network-only",
    }),
})(TrophiesPage);
