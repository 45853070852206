import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import FaPlus from "../../fa/plus";
import FaCalendar from "../../fa/calendar";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import moment from "moment";
import { sortFoods } from "./helpers";
import { map } from "lodash";
import { menuQuery } from "./OneMenu";

class MyLunchThumb extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      open: false,
      date: [new Date()],
    };
  }
  async handleSubmit() {
    const { lunch } = this.props;
    const { date } = this.state;
    const dates = [
      moment(date[0]).startOf("week").add(1, "d").format("YYYY-MM-DD"),
      moment(date[0]).startOf("week").add(2, "d").format("YYYY-MM-DD"),
      moment(date[0]).startOf("week").add(3, "d").format("YYYY-MM-DD"),
      moment(date[0]).startOf("week").add(4, "d").format("YYYY-MM-DD"),
      moment(date[0]).startOf("week").add(5, "d").format("YYYY-MM-DD"),
    ];
    await this.props.mutate({
      variables: {
        lunch_id: lunch.id.toString(),
        date: moment(date[0]).format("YYYY-MM-DD"),
      },
      refetchQueries: [
        {
          query: menuQuery,
          variables: { dates },
        },
      ],
    });
    this.setState({ open: false });
  }
  render() {
    const { lunch, location, opened } = this.props;
    const { open } = this.state;
    const options = {
      disable: [(date) => date.getDay() === 0 || date.getDay() === 6],
      defaultDate: "today",
      dateFormat: "m/d/y",
    };
    const LunchFoods = map(sortFoods(lunch.foods), (f) => (
      <span key={`mlt${f.id}`} className="food-hover-name">
        {f.name}
      </span>
    ));
    function lowres(img) {
      const i = img.lastIndexOf(".");
      if (i < 0) {
        return img;
      }
      return img.substring(0, i) + "_lowres" + img.substring(i);
    }
    return (
      <div className="lunch-thumb-for-profile">
        <Link
          className="lunch-thumb-image"
          data-id={lunch.id}
          style={{ backgroundImage: `url(${lowres(lunch.image)})` }}
          to={{
            pathname: `/l/${lunch.slug}`,
            state: { modal: true, returnTo: location.pathname },
          }}
          onClick={opened}
        />
        <Link
          className="lunch-thumb-hover"
          to={{
            pathname: `/l/${lunch.slug}`,
            state: { modal: true, returnTo: location.pathname },
          }}
          onClick={opened}
        >
          {LunchFoods}
        </Link>
        <div
          className={open ? "lunch-menu-adder adder-open" : "lunch-menu-adder"}
        >
          <span
            className="lunch-menu-toggler"
            onClick={() => this.setState({ open: !open })}
          >
            <FaPlus className="plus-menu" />
            <span className="toggler-text">Select Date</span>
          </span>
          <div className="toggler-calendar">
            <Flatpickr
              className="toggler-picker"
              options={options}
              onChange={(date) => this.setState({ date })}
              ref={(el) => (this.fp = el)}
            />
            <div
              className="togg-icon-h"
              onClick={() => this.fp.flatpickr.open()}
            >
              <FaCalendar />
            </div>
          </div>
          <button className="submit-menu" onClick={this.handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    );
  }
}

MyLunchThumb.propTypes = {
  lunch: PropTypes.object,
  location: PropTypes.object,
  opened: PropTypes.func,
};

const addToMenu = gql`
  mutation addToMenu($lunch_id: String!, $date: String!) {
    addToMenu(lunch_id: $lunch_id, date: $date) {
      id
      lunch_id
      date
    }
  }
`;

export default graphql(addToMenu)(MyLunchThumb);
