import React, { Component } from "react";
import PropTypes from "prop-types";
import isURL from "validator/lib/isURL";

class CreateLunchStepFive extends Component {
  constructor(props) {
    super(props);
    this.trackTitleCount = this.trackTitleCount.bind(this);
    this.trackNotesCount = this.trackNotesCount.bind(this);
    this.trackLinkCount = this.trackLinkCount.bind(this);
    this.submitFinish = this.submitFinish.bind(this);
    this.state = {
      title: "",
      link: "",
      linkGood: true,
      notes: "",
    };
  }
  componentDidMount() {
    this.setState({
      title: this.title.value,
      link: this.link ? this.link.value : "",
      notes: this.notes,
    });
  }
  trackTitleCount(e) {
    const length = this.state.title.length;
    if (length >= 70) e.preventDefault();
    this.setState({ titleChars: length });
  }
  trackNotesCount(e) {
    const length = this.state.notes.length;
    if (length >= 70) e.preventDefault();
    this.setState({ notesChars: length });
  }
  trackLinkCount(e) {
    const length = this.state.link.length;
    const options = { protocols: ["http", "https"] };
    const linkGood = this.link.value === "" || isURL(this.link.value, options);

    if (length >= 70) e.preventDefault();
    this.setState({
      linkChars: length,
      linkGood: linkGood,
    });
  }
  submitFinish(e) {
    const { finishStep } = this.props;
    const notesRight = this.notes && this.notes.value.length < 181;
    const linkRight = this.link && this.link.value.length < 70;
    const titleRight = this.title && this.title.value.length < 70;
    if (titleRight && linkRight && notesRight) {
      finishStep(this.title.value, this.link.value, this.notes.value);
    }
  }
  render() {
    const { file, finishStep, title, link, notes } = this.props;
    const { linkGood } = this.state;
    const notesRight = this.notes && this.notes.value.length < 181;
    const linkRight = this.link && this.link.value.length < 70;
    const titleRight = this.title && this.title.value.length < 70;
    const buttonStyle = {
      backgroundColor:
        linkGood && notesRight && titleRight && linkRight ? "" : "#c1d3a7",
      pointerEvents:
        linkGood && notesRight && titleRight && linkRight ? "" : "none",
    };
    const linkStyle = {
      backgroundColor: linkGood ? "" : "#ff9d9d",
    };
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="create-lunch-step-three">
        <div className="titling-full-group">
          <div className="titling-left">
            <div
              className="titling-left-img-preview"
              style={{
                backgroundImage: `url(${file.preview})`,
              }}
            />
          </div>
          <div className="titling-right">
            <div className="tn-group">
              <input
                type="text"
                placeholder="My Lunch Title (optional)"
                max="70"
                defaultValue={title}
                onChange={this.trackTitleCount}
                ref={(e) => (this.title = e)}
              />
              <span className="word-count">
                {70 - (this.title ? this.title.value.length : 0)}
              </span>
            </div>
            <div className="tn-group">
              <input
                style={linkStyle}
                type="text"
                placeholder="https:// (optional)"
                defaultValue={link}
                max="70"
                onChange={this.trackLinkCount}
                ref={(e) => (this.link = e)}
              />
              {linkGood ? (
                <span className="word-count">URL link of the recipe</span>
              ) : (
                <span className="word-count" style={{ color: "#CC1111" }}>
                  Valid URL link only (e.g. instagram.com/teukoapp)
                </span>
              )}
            </div>
            <div className="tn-group">
              <textarea
                placeholder="Notes... (optional)"
                max="180"
                defaultValue={notes}
                onChange={this.trackNotesCount}
                ref={(e) => (this.notes = e)}
              />
              <span className="word-count">
                {180 - (this.notes ? this.notes.value.length : 0)}
              </span>
            </div>
          </div>
        </div>
        <div
          id="finish_button"
          className="standard-button finsh-menu-button"
          style={buttonStyle}
          onClick={this.submitFinish}
        >
          Finish
        </div>
        <a
          href="#"
          className="go-back"
          onClick={() =>
            finishStep(
              this.title.value,
              this.link.value,
              this.notes.value,
              true
            )
          }
        >
          Back
        </a>
      </div>
    );
  }
}

CreateLunchStepFive.propTypes = {
  finishStep: PropTypes.func,
  file: PropTypes.object,
  title: PropTypes.string,
  notes: PropTypes.string,
};

export default CreateLunchStepFive;
