import React, { Component, Fragment } from "react";
import { createClient } from "contentful";
import { map } from "lodash";
import FAQQuestion from "./FAQQuestion";
import { Helmet } from "react-helmet";

class FAQPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      groups: [],
    };
  }
  UNSAFE_componentWillMount() {
    const self = this;
    const client = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });
    client
      .getEntries({
        content_type: "faq",
        "fields.number": 1,
        include: 2,
      })
      .then((entries) => {
        self.setState({
          groups: entries.items[0].fields.groups,
          loading: false,
        });
      });
  }
  render() {
    const { groups, loading } = this.state;
    if (loading) return <div />;
    const Groups = map(groups, (g) => {
      const QAndAs = map(g.fields.questions, (q) => (
        <FAQQuestion
          key={q.sys.id}
          question={q.fields.question}
          answer={q.fields.answer}
        />
      ));
      return (
        <div className="faq-group" key={g.sys.id}>
          <h4>{g.fields.title}</h4>
          <div className="question-section">{QAndAs}</div>
        </div>
      );
    });
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Community - About Us</title>
        </Helmet>
        <div className="faq-page">
          <div
            className="small-splash-header faq-header"
            style={{ backgroundImage: "url(/images/apples.jpg)" }}
          >
            <h1>FAQ</h1>
          </div>
          <div className="faq-section">
            <div className="standard-container">{Groups}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default FAQPage;
