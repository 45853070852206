import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import { createClient } from "contentful";
import { map, filter } from "lodash";
import MailingListPopup from "../../components/general/MailingListPopup";
import { Link } from "react-router";

class DiscoverPage extends Component {
  constructor(props) {
    super(props);
    this.switchFilter = this.switchFilter.bind(this);
    this.state = {
      filter: "New",
      blogData: null,
      modalOpen: false,
      open: false,
      fetching: false,
    };
  }
  switchFilter(e) {
    const { filter } = e.currentTarget.dataset;
    browserHistory.push({
      pathname: this.props.location.pathname,
      query: {
        filter,
      },
    });
  }
  UNSAFE_componentWillMount() {
    const self = this;
    const client = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });
    client
      .getEntries({
        content_type: "blog",
        "fields.numberForBlog": 1,
        include: 2,
      })
      .then((entries) => {
        self.setState({ blogData: entries.items[0] ? entries.items[0] : [] });
      });
  }


  async componentDidMount() {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() {  
        googletag.display('div-gpt-ad-1677543132656-0');
        googletag.display('div-gpt-ad-1677543321715-0');
      });
    }
  }

  render() {
    const { blogData } = this.state;
    const { location, user } = this.props;
    const filterArgument =
      location.query && location.query.filter ? location.query.filter : "New";

    let posts = [];

    if (blogData) {
      const postsOfGroup = filter(
        blogData.fields.blogGroupsForBlog,
        (entry) => {
          return entry.fields.titleBlogGroup === filterArgument;
        }
      );

      posts = map(postsOfGroup[0].fields.blogPostsInGroup, (post) => {
        const photoUrl = post.fields.photoHeaderBlogPost.fields.file.url;
        if (!photoUrl) {
          return;
        }
        return (
          <div className="discover-section" key={post.sys.id}>
            {/* <div className="standard-container"> */}

            
              <div className="discover-posts"> 
                  <a
                    href={post.fields.urlBlogPost}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="discover-small-splash-header"
                    alt={post.fields.photoHeaderBlogPost.fields.title}
                    style={{ backgroundImage: `url(${photoUrl})` }}
                  >
                    {" "}
                  </a>
                  <div className="discover">
                    <div className="discover-content">
                      <a
                        href={post.fields.urlBlogPost}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h4>{post.fields.titleBlogPost}</h4>
                      </a>
                      <a
                        href={post.fields.urlBlogPost}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{post.fields.textIntroBlogPost}</p>
                      </a>
                      <a
                        href={post.fields.urlBlogPost}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        See More >
                      </a>
                    </div>
                  </div>
                </div>
             

            {/* </div> */}
          </div>
        );
      });
    }

    return (
      <Fragment>
        <div className="discover-page">
          <Helmet>
            <title>Teuko Lunchbox Community - Discover Blog Posts</title>
          </Helmet>


          <div className="browse-blog-header">
            {user.id !== 0 ? (
              ""
            ) : (
              <MailingListPopup delayInMilliseconds={10000} client={this.props.client}/>
            )}

            <h1>Blog</h1>

            <div className="my-blog-filters">
              <div className="my-categories">
                <div className="my-categories-header">
                  <div className="category-blog-items">
                    <div className="blog-explore-grid">
                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_news"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog new.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog New"
                            key="hHFDJb726srZOwceIPHDB"
                            onClick={this.switchFilter}
                            data-filter="New"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="hHFDJb726srZOwceIPHDB"
                            className={
                              filterArgument === "New" ? "active-filter" : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="New"
                          >
                            New
                          </h3>
                        </div>
                      </div>

                      {/* <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_news"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog hot deals_2.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Hot Deals"
                            key="01jSQUwujWU79w9a5MPw0r"
                            onClick={this.switchFilter}
                            data-filter="Deals"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="01jSQUwujWU79w9a5MPw0r"
                            className={
                              filterArgument === "Deals" ? "active-filter" : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="Deals"
                          >
                            Hot Deals
                          </h3>
                        </div>
                      </div> */}

                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_hacks"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog hacks.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Hacks"
                            key="ElZIgr2TFauP44PlJyR42"
                            onClick={this.switchFilter}
                            data-filter="Hacks"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="ElZIgr2TFauP44PlJyR42"
                            className={
                              filterArgument === "Hacks" ? "active-filter" : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="Hacks"
                          >
                            Hacks
                          </h3>
                        </div>
                      </div>

                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_howto"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog howto.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Howto"
                            key="7dNFqTGfHFDszwpYrf0SD0"
                            onClick={this.switchFilter}
                            data-filter="How To"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="7dNFqTGfHFDszwpYrf0SD0"
                            className={
                              filterArgument === "How To" ? "active-filter" : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="How To"
                          >
                            How To
                          </h3>
                        </div>
                      </div>

                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_seasonal"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog seasonal summer.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Seasonal"
                            key="2nHn0tEMf1HvTb7XCrx8Fd"
                            onClick={this.switchFilter}
                            data-filter="Seasonal"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="2nHn0tEMf1HvTb7XCrx8Fd"
                            className={
                              filterArgument === "Seasonal"
                                ? "active-filter"
                                : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="Seasonal"
                          >
                            Seasonal
                          </h3>
                        </div>
                      </div>

                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_community"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog community.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Community"
                            key="2Wj0DfiNA5TEkUwzn2O12V"
                            onClick={this.switchFilter}
                            data-filter="Community"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="2Wj0DfiNA5TEkUwzn2O12V"
                            className={
                              filterArgument === "Community"
                                ? "active-filter"
                                : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="Community"
                          >
                            Community
                          </h3>
                        </div>
                      </div>

                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_discovery"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog discovery.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Discovery"
                            key="2OHpbOU50TH2ucnvdJUiB3"
                            onClick={this.switchFilter}
                            data-filter="Discovery"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="2OHpbOU50TH2ucnvdJUiB3"
                            className={
                              filterArgument === "Discovery"
                                ? "active-filter"
                                : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="Discovery"
                          >
                            Discovery
                          </h3>
                        </div>
                      </div>

                      <div
                        className="infocard-blog"
                        data-qa="explore_teuko_lunch_blog_updates"
                      >
                        <div className="infocard-boutique-content">
                          <img
                            src="/images/teuko lunchbox blog updates.jpg"
                            className="infocard-blog-image"
                            alt="Teuko Lunch Box Blog Updates"
                            key="WnbzWcgDxscMjy3rZ7dW8"
                            onClick={this.switchFilter}
                            data-filter="Updates"
                          />
                        </div>
                        <div className="infocard-header-landing">
                          <h3
                            key="WnbzWcgDxscMjy3rZ7dW8"
                            className={
                              filterArgument === "Updates"
                                ? "active-filter"
                                : ""
                            }
                            onClick={this.switchFilter}
                            data-filter="Updates"
                          >
                            Updates
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="blog-ad-banner">
            <div className="search-title-header-ad-laptop_leaderboard">
              {/* <!-- /22874318077/Teuko-Blog-Laptop_AdUnit_1_Top_Page-Leaderboard-728x90 --> */}
              <div id='div-gpt-ad-1677543132656-0' style={{minWidth: "728px", minHeight: "90px"}} />
            </div>
            <div className="search-title-header-ad-smartphone_leaderboard">
              {/* <!-- /22874318077/Teuko-Blog-Smartphone_AdUnit_1_Top_Page-Leaderboard-320x50 --> */}
              <div id='div-gpt-ad-1677543321715-0' style={{minWidth: "320px", minHeight: "50px"}} />
            </div>
          </div>

        <div className="discover-posts-section">
          {posts}
        </div>

        { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to get lunchbox ideas you'll love and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}

        </div>
      </Fragment>
    );
  }
}

export default withApollo(DiscoverPage);
