import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import FaClose from "../../fa/close";
import { map, find, concat, reject } from "lodash";
import VirtualizedSelect from "react-virtualized-select";

class CreateLunchStepFour extends Component {
  constructor(props) {
    super(props);
    this.lunchOptionRenderer = this.lunchOptionRenderer.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.removeAccessory = this.removeAccessory.bind(this);
    this.state = {
      loading: true,
    };
  }
  async UNSAFE_componentWillMount() {
    const { chosenAccessories } = this.props;
    this.setState({ chosenAccessories });
    const tags = await (
      await fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/boutique.json"
      )
    ).json();
    this.setState({ loading: false, tags, chosenAccessories }, () => {
      document.getElementsByClassName("Select-placeholder")[0].textContent =
        "Tag Accessories (optional)";
    });
  }
  lunchOptionRenderer({
    focusedOption,
    focusOption,
    key,
    option,
    selectValue,
    style,
  }) {
    const classNames = ["country-option"];
    if (option === focusedOption) {
      classNames.push("focused-country-option");
    }
    return (
      <div
        key={key}
        onClick={() => {
          selectValue(option);
        }}
        onMouseEnter={() => focusOption(option.id)}
        className={classNames.join(" ")}
        style={style}
      >
        <div
          className="country-image-option"
          style={{ backgroundImage: `url(${option.image})` }}
        />
        <label>{option.name}</label>
      </div>
    );
  }
  handleSelect(option) {
    const { chosenAccessories } = this.state;
    if (!find(chosenAccessories, { id: option.id })) {
      this.setState({ chosenAccessories: concat(chosenAccessories, option) });
    }
  }
  removeAccessory(e) {
    const { chosenAccessories } = this.state;
    const id = e.currentTarget.dataset.id;
    this.setState({ chosenAccessories: reject(chosenAccessories, { id }) });
  }

  render() {
    const { file, finishStep } = this.props;
    const { loading, tags, chosenAccessories } = this.state;

    const accessories = map(chosenAccessories, (t) => {
      return (
        <p key={t.id}>
            {t.name} <FaClose data-id={t.id} onClick={this.removeAccessory} />
        </p>
      );
    });
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="create-lunch-step-two">
        {loading ? (
          <div />
        ) : (
          <Fragment>
            <div className="food-selection-group">
              <div className="fs-left">
                <div
                  className="fs-left-img-preview"
                  style={{
                    backgroundImage: `url(${file.preview})`,
                  }}
                />
              </div>
              <div className="fs-right">
                <div className="missing-food-items">
                  <p>
                    Accessories missing?{" "}
                    <a href="mailto:contact@teuko.com?subject=Accessories%20Missing&amp;body=Hi%20Teuko%20Team%2C%0A%0AThe%20following%20accessories%20are%20missing%3A%0A-">
                      Let us know!
                    </a>
                  </p>
                </div>
                <div className="search-bar-holder">
                  <VirtualizedSelect
                    valueKey="id"
                    labelKey="name"
                    options={tags}
                    optionHeight={50}
                    optionRenderer={this.lunchOptionRenderer}
                    className="food-selector"
                    onChange={(option) => this.handleSelect(option)}
                  />
                  {/* <FaSearch className="select-search-icon" /> */}
                </div>

                <div className="selected-foods-section">
                  <h4>Accessories</h4>
                  {accessories}
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div
          className="standard-button continue-ingredients-button"
          onClick={() => finishStep(chosenAccessories)}
        >
          Continue
        </div>
        <a
          href="#"
          className="go-back"
          onClick={() => finishStep(chosenAccessories, 3)}
        >
          Back
        </a>
      </div>
    );
  }
}

CreateLunchStepFour.propTypes = {
  file: PropTypes.object,
  chosenAccessories: PropTypes.array,
  finishStep: PropTypes.func,
};

export default CreateLunchStepFour;
