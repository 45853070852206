import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

class TermsPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Teuko Lunchbox Community - Terms and Conditions</title>
        </Helmet>
        <div className="terms-page">
          <div className="standard-container">
            <div className="container">
              <div className="page-header">
                <center>
                  <h1 className="bg-info">Teuko Terms of Service</h1>
                </center>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <h3>Thank you for using Teuko! </h3>
                  <p>
                    We are committed to providing you the best lunch packing
                    experience! These Terms are important and affect your legal
                    rights, so please read them carefully.
                  </p>
                  <p>Last updated date: August 2023, 23</p>
                  <h3>Agreement and Terms</h3>
                  <p>
                    These Terms of Service (“Terms”) govern your use of the
                    Teuko services, including our website, apps, blog and other
                    products and services and mobile applications (the
                    “Services”), and are entered into by you and Teuko, Inc. , a
                    Delaware corporation (“Teuko”), with its address at 3145
                    Geary Blvd #473, San Francisco, CA 94418, USA.
                  </p>
                  <p>
                    By using the Services or our subsidiaries or other
                    affiliates (collectively, “Teuko,” “we,” “us” or “our”) that
                    link to these Terms, you agree to be bound by these Terms
                    and acknowledge our{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="privacypolicy"
                    >
                      Privacy Policy
                    </a>
                    , which explains how we collect, use, and share information.
                  </p>
                  <p>
                    The Teuko Services cannot be provided and the agreement
                    described in these Terms of Service cannot be performed
                    without Teuko processing data about you, and other Teuko
                    members. Processing of the data you share with Teuko is
                    essential to the Services which we provide and a necessary
                    part of our performance of the agreement we have with you.
                  </p>
                  <p>
                    You agree to comply with these Terms and any supplemental
                    terms which Teuko makes available to you on the Services
                    which shall form part of the Terms.
                    <br />
                    BY ACCESSING, USING OR UPLOADING OR DOWNLOADING ANY
                    INFORMATION OR MATERIALS TO OR FROM THE SERVICES, OR BY
                    INDICATING YOUR ASSENT TO THESE TERMS BY CREATING AN
                    ACCOUNT, CLICKING “SIGN UP” OR ANY SIMILAR MECHANISM, YOU
                    ARE AGREEING TO THE THESE TERMS. IF YOU DO NOT AGREE TO THE
                    THESE TERMS, DO NOT ACCESS OR USE THE SERVICES.
                    <br />
                    This agreement was written in English. To the extent a
                    translated version of the Terms conflict with the English
                    version, the English version controls.
                  </p>
                  <p>
                    These Terms do not alter in any way the terms or conditions
                    of any other agreement you may have with us in respect of
                    any products, services or otherwise. If you are using the
                    Services on behalf of any entity, you represent and warrant
                    that you are authorized to accept these Terms on such
                    entity’s behalf and that such entity agrees to be
                    responsible to us if you or such entity violates these
                    Terms.
                  </p>
                  <h3>Changes to these Terms</h3>
                  <p>
                    Teuko reserves the right to change or modify these Terms at
                    any time and in our sole discretion. If we make changes to
                    these Terms, we will be updating the “Last Updated” date at
                    the beginning of these Terms. By continuing to access or use
                    the Services, you confirm your acceptance of the revised
                    Terms and all of the terms incorporated therein by
                    reference. We encourage you to review the Terms frequently
                    to ensure that you understand the terms and conditions that
                    apply when you access or use the Services.
                  </p>
                  <p className="text-uppercase">
                    If you do not agree to the revised Terms, you may not access
                    or use the Services.
                  </p>
                  <h3>Privacy Policy</h3>
                  Please refer to our{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="privacypolicy"
                  >
                    Privacy Policy
                  </a>{" "}
                  for information about how we collect, use and disclose
                  information about you.
                  <h3>Eligibility</h3>
                  <p>
                    When we write "personal information", we're using the same
                    definitions that the U.S. Federal Trade Commission (or
                    "FTC") uses in its Children's Online Privacy Protection Act
                    (or "COPPA") rule Commission (more info here:
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.business.ftc.gov/documents/bus84-childrens-online-privacy-protection-rule-six-step-compliance-plan-your-business"
                    >
                      http://www.business.ftc.gov/documents/bus84-childrens-online-privacy-protection-rule-six-step-compliance-plan-your-business
                    </a>
                    ).
                  </p>
                  <p>
                    When you browse through the Teuko website, read pages, or
                    download information, we may gather and store certain
                    information about your visit automatically. We may use
                    technologies to gauge your browsing habits or web surfing
                    history to improve the information presented to you.
                  </p>
                  <p>
                    As long as no account is created on Teuko.com, no "personal
                    information", as defined by the FTC is recorded. In other
                    words, the information we store does not identify you
                    personally. We do not collect any personal identifiable
                    information without your explicit consent.
                  </p>
                  <p>
                    If you become aware that your child has provided us with
                    personal information without your consent, please contact us
                    at contact[at]teuko.com. We do not knowingly collect
                    personal information from children under 13. If we become
                    aware that a child under 13 has provided us with personal
                    information, we take steps to remove such information and
                    terminate the child’s account.
                  </p>
                  <h3>Important Note regarding Website Content</h3>
                  <p>
                    The information and content (collectively, “Content”) on
                    this website is for your general educational information
                    only. The Content cannot, and is not intended to, replace
                    the relationship that you have with your health care
                    professionals. The Content on this website should not be
                    considered medical advice and is not intended as medical
                    advice. If you are experiencing a medical emergency, you
                    should not rely on any information on this website and
                    should seek appropriate emergency medical assistance, such
                    as calling “911”.
                  </p>
                  <p>
                    This website is intended for a United States audience. If
                    you live outside the U.S., you may see information on this
                    website that are not available or authorized in your
                    country.
                  </p>
                  <h3>Registration, Account and Communication Preferences</h3>
                  <p>
                    In order to access and use certain areas or features of the
                    Services, you will need to register for a Teuko account. By
                    creating an account, you agree to (a) provide accurate,
                    current and complete account information, (b) maintain and
                    promptly update from time to time as necessary your account
                    information, (c) maintain the security of your password and
                    accept all risks of unauthorized access to your account and
                    the information you provide to us, and (d) immediately
                    notify us if you discover or otherwise suspect any security
                    breaches related to the Sites or your account.
                  </p>
                  <p>
                    By creating a Teuko account, you also consent to receive
                    electronic communications from Teuko (e.g., via email or by
                    posting notices to the Services). These communications may
                    include notices about your account (e.g., password changes
                    and other transactional information) and are part of your
                    relationship with us. You agree that any notices,
                    agreements, disclosures or other communications that we send
                    to you electronically will satisfy any legal communication
                    requirements, including, but not limited to, that such
                    communications be in writing. You should maintain copies of
                    electronic communications from us by printing a paper copy
                    or saving an electronic copy. We may also send you
                    promotional communications via email, including, but not
                    limited to, newsletters, special offers, surveys and other
                    news and information we think will be of interest to you.
                    You may opt out of receiving these promotional emails at any
                    time by following the unsubscribe instructions provided
                    therein.
                  </p>
                  <h3>Your Use of the Services</h3>
                  <p>
                    Teuko grants you a limited, non-exclusive, non-transferable,
                    and revocable license to use the Services for their intended
                    purposes subject to your compliance with these Terms and our
                    policies. You may not copy, modify, distribute, sell, or
                    lease any part of the Services. Unless such restriction is
                    prohibited by law or you have our written permission, you
                    may not reverse engineer or attempt to extract the source
                    code of the Services. You may only access the Services
                    through the interfaces that we provide for that purpose (for
                    example, you may not “scrape” the Services through automated
                    means or “frame” any part of our Services), and you may not
                    interfere or attempt to disrupt our Services.
                  </p>
                  <p>
                    In other words, unless otherwise indicated in writing by us,
                    the Services and all content and other materials contained
                    therein, including, without limitation, the Teuko logo and
                    all designs, text, graphics, pictures, information, data,
                    software, video files, User Content (as defined in the User
                    Content Section), other files and the selection and
                    arrangement thereof (collectively, “Content”) are the
                    proprietary property of Teuko or our licensors or users, as
                    applicable, and are protected by U.S. and international
                    copyright laws. Notwithstanding anything to the contrary in
                    these Terms, the Sites and Content may include software
                    components provided by Teuko or a third party that are
                    subject to separate license terms, in which case those
                    license terms will govern such software components.
                  </p>
                  <p>
                    Some parts of our Services may allow you to upload or submit
                    content (such as text, images, video, recipes, lists, links,
                    and other materials). You retain all rights in any content
                    that you upload or submit, and are solely responsible for
                    that content. You grant Teuko a non-exclusive, royalty-free,
                    worldwide, transferable, sub-licenseable license to use,
                    store, publicly display, publicly perform, reproduce,
                    modify, create derivative works from, and distribute any
                    such content for the purposes of operating, providing, and
                    improving the Services. Teuko may, in its sole discretion,
                    remove or take down any content that you upload or submit to
                    the Services for any reason, including violation of these
                    Terms or any other policies.
                  </p>
                  <p>
                    Some software in our Services may be governed by open source
                    licenses. In that case, we will make such licenses available
                    to you and, in the case of conflict between such a license
                    and these Terms, the open source license will control but
                    only with respect to the software to which it applies.
                  </p>
                  <p>
                    If you are using Teuko on behalf of a business or other
                    entity, you represent and warrant that you have the
                    necessary authority to bind that business or entity to these
                    Terms and that you are agreeing to these Terms on behalf of
                    that business or entity.
                  </p>
                  <p>
                    In order to use the Services, you may need to create a user
                    account. You agree that your are responsible for all conduct
                    and transactions that take place on or using your account
                    and that you will take precautions to keep your password and
                    other account information secure.
                  </p>
                  <p>
                    We’re constantly modifying and improving our products. We
                    may introduce new features, change existing features, or
                    remove features from the Services at any time and without
                    notice. If you provide us with any feedback on or comments
                    regarding the Services, you grant Teuko the right to use
                    such feedback or comments for any purpose without
                    restriction or payment to you.
                  </p>
                  <h3>Hyperlinks</h3>
                  <p>
                    You are granted a limited, nonexclusive, nontransferable
                    right to create a text hyperlink to the Services for
                    noncommercial purposes, provided that such link does not
                    portray Teuko in a false, misleading, derogatory or
                    otherwise defamatory manner, and provided further that the
                    linking site does not contain any adult or illegal material
                    or any material that is offensive, harassing or otherwise
                    objectionable. This limited right may be revoked at any
                    time. You may not use a Teuko logo or other proprietary
                    graphic of Teuko to link to the Services without our express
                    written permission. Further, you may not use, frame or
                    utilize framing techniques to enclose any Teuko logo or
                    other proprietary information, including the images found on
                    the Sites or Products, the content of any text or the layout
                    or design of any page, or form contained on a page, on the
                    Sites without our express written consent.
                  </p>
                  <p>
                    Teuko makes no claim or representation regarding, and
                    accepts no responsibility for, the quality, content, nature
                    or reliability of third party websites accessible by
                    hyperlink from the Sites or of websites linking to the
                    Services. Such sites are not under our control, and we are
                    not responsible for the contents of any linked site, any
                    link contained in a linked site, or any review, changes or
                    updates to such sites. We provide these links to you only as
                    a convenience, and the inclusion of any link does not imply
                    our affiliation, endorsement or adoption of any site or any
                    information contained therein. When you leave the Services,
                    you should be aware that our terms and policies no longer
                    govern. You should review the applicable terms and policies,
                    including privacy and data gathering practices, of any site
                    to which you navigate from the Services.
                  </p>
                  <h3>Account Security</h3>
                  <p>
                    You are responsible for maintaining the confidentiality of
                    passwords and account information for the Services, and you
                    are fully responsible for all activities that occur under
                    your account. You agree to immediately notify Teuko of any
                    unauthorized use of accounts or any other breach of
                    security.
                  </p>
                  <h3>Third-party Products and Content</h3>
                  <p>
                    You agree that Teuko does not assume responsibility for any
                    products, content, services, websites, advertisements,
                    offers, or information that is provided by third parties and
                    made available through our Services. If you purchase, use or
                    access any such products, content, services, advertisements,
                    offers, or information through the Services, you agree that
                    you do so at your own risk and that Teuko will have no
                    liability based on such purchase, use or access. We do not
                    control, endorse or adopt any Third Party Content, and we
                    make no representations or warranties of any kind regarding
                    such Third Party Content, including, without limitation,
                    regarding its accuracy or completeness. You acknowledge and
                    agree that your interactions with third parties providing
                    Third Party Content are solely between you and such third
                    parties, and that Teuko is not responsible or liable in any
                    manner for such interactions or Third Party Content.{" "}
                  </p>
                  <h3>User Conduct</h3>
                  <p>
                    You agree that you will not violate any law, contract,
                    intellectual property or other third party right or commit a
                    tort, and that you are solely responsible for your conduct,
                    while accessing or using the Services. You agree that you
                    will abide by these Terms and will not:
                  </p>
                  <ul>
                    <li>
                      Engage in any harassing, threatening, intimidating,
                      predatory or stalking conduct;
                    </li>
                    <li>
                      Use or attempt to use another user’s account without
                      authorization from such user and Teuko;
                    </li>
                    <li>
                      Use the Services in any manner that could interfere with,
                      disrupt, negatively affect or inhibit other users from
                      fully enjoying the Services or that could damage, disable,
                      overburden or impair the functioning of the Services in
                      any manner;
                    </li>
                    <li>
                      Reverse engineer any aspect of the Services or do anything
                      that might discover source code or bypass or circumvent
                      measures employed to prevent or limit access to any
                      Content, area or code of the Services;
                    </li>
                    <li>
                      Attempt to circumvent any content-filtering techniques we
                      employ or attempt to access any feature or area of the
                      Services that you are not authorized to access;
                    </li>
                    <li>
                      Develop any third party applications that interact with
                      User Content or the Services without our prior written
                      consent;
                    </li>
                    <li>
                      Use any robot, spider, crawler, scraper, script, browser
                      extension, offline reader or other automated means or
                      interface not authorized by us to access the Services,
                      extract data or otherwise interfere with or modify the
                      rendering of Services pages or functionality;
                    </li>
                    <li>
                      Use the Services for any illegal or unauthorized purpose,
                      or engage in, encourage or promote any activity that
                      violates these Terms.
                    </li>
                  </ul>
                  <h3>User Content</h3>
                  <p>
                    The Services may include interactive features and areas that
                    allow users to create, post, share or store content,
                    including, but not limited to, recipes, reviews, photos,
                    videos, music, sound, text, graphics, code, items or other
                    materials (collectively, “User Content”). In the event you
                    decide to share your User Content with others through the
                    Services or third party platforms, you understand that this
                    User Content will be viewable by others in accordance with
                    the privacy settings you establish. You agree that you are
                    solely responsible for your User Content and for your use of
                    any interactive features and areas of the Services.
                  </p>
                  <p>
                    By using the interactive features and areas of the Services,
                    you further agree not to create, post, share or store any of
                    the following:
                  </p>
                  <ul>
                    <li>
                      User Content that is unlawful, libelous, defamatory,
                      obscene, pornographic, indecent, lewd, suggestive,
                      harassing, threatening, invasive of privacy or publicity
                      rights, abusive, inflammatory, fraudulent or otherwise
                      objectionable;
                    </li>
                    <li>
                      User Content that would constitute, encourage or provide
                      instructions for a criminal offense, violate the rights of
                      any party or otherwise create liability or violate any
                      local, state, national or international law;
                    </li>
                    <li>
                      User Content that may infringe any patent, trademark,
                      trade secret, copyright or other intellectual or
                      proprietary right of any party;
                    </li>
                    <li>
                      User Content that contains or depicts any statements,
                      remarks or claims that do not reflect your honest views
                      and experiences;
                    </li>
                    <li>
                      User Content that impersonates, or misrepresents your
                      affiliation with, any person or entity;
                    </li>
                    <li>
                      User Content that references or depicts Teuko but fails to
                      disclose a material connection to us, if you have one (for
                      example, if you are a paid blogger);
                    </li>
                    <li>
                      User Content that contains any unsolicited promotions,
                      political campaigning, advertising or solicitations;
                    </li>
                    <li>
                      User Content that contains any private or personal
                      information of a third party without such third party’s
                      consent;
                    </li>
                    <li>
                      User Content that contains any viruses, corrupted data or
                      other harmful, disruptive or destructive files or content;
                      or
                    </li>
                    <li>
                      User Content that, in our sole judgment, is objectionable
                      or that restricts or inhibits any other person from using
                      or enjoying the Services, or that may expose Teuko or
                      others to any harm or liability of any type.
                    </li>
                  </ul>
                  <p>
                    We are not responsible or liable for any User Content.
                    Although we have no obligation to screen, edit or monitor
                    User Content, we reserve the right, and have absolute
                    discretion, to remove, screen or edit User Content posted or
                    stored on the Services at any time and for any reason, and
                    you are solely responsible for creating backup copies of and
                    replacing any User Content you post or store on the Services
                    at your sole cost and expense.
                  </p>
                  <h3>Rights in User Content</h3>
                  <p>
                    We do not claim any ownership interest in your User Content.
                    However, by uploading, posting or submitting User Content to
                    the Services or to our pages or feeds on third party social
                    media platforms (e.g., Teuko's Facebook page, Teuko's Instagram page, Teuko's
                    Pinterest page or Teuko's Twitter feed), you hereby grant
                    Teuko a nonexclusive, royalty-free, worldwide, perpetual,
                    irrevocable and fully sublicensable right and license to
                    use, reproduce, modify, adapt, publish, translate, create
                    derivative works from, distribute, perform and publicly
                    display your User Content, in whole or in part, and your
                    name, likeness, voice and persona in any manner or media and
                    for any purpose whatsoever at our sole discretion,
                    including, without limitation, for publicity, promotional,
                    advertising, trade, business, illustration, artistic and
                    other commercial and noncommercial purposes.
                  </p>
                  <p>
                    By uploading, posting or submitting User Content to Teuko
                    through the Services or through our pages or feeds on third
                    party social media platforms, you represent and warrant that
                    (a) such User Content is nonconfidential, (b) you own and
                    control all of the rights to the User Content or you
                    otherwise have all necessary rights to post such User
                    Content, (c) you authorize Teuko to use such User Content
                    for the purposes described in these Terms, (d) the User
                    Content is accurate and not misleading or harmful in any
                    manner, and (e) the User Content, and your use and posting
                    thereof, does not and will not violate these Terms or any
                    applicable law, rule, regulation or third party right.
                  </p>
                  <h3>Feedback</h3>
                  <p>
                    Separate and apart from User Content, you can submit
                    questions, comments, suggestions, ideas, original or
                    creative materials or other information about Teuko and the
                    Services (collectively, “Feedback”). Feedback is
                    nonconfidential and shall become the sole property of Teuko.
                    Teuko shall own exclusive rights, including, without
                    limitation, all intellectual property rights, in and to such
                    Feedback and shall be entitled to the unrestricted use and
                    dissemination of this Feedback for any purpose, commercial
                    or otherwise, without acknowledgment or compensation to you.
                  </p>
                  <h3>Disclaimers</h3>
                  <p className="text-uppercase">
                    YOU ARE SOLELY RESPONSIBLE FOR THE PROPER AND SAFE WASHING,
                    HANDLING, PREPARATION, STORAGE, COOKING, USE AND CONSUMPTION
                    OF THE PRODUCTS YOU HAVE WHILE USING THE SERVICES, FOLLOWING
                    GUIDELINES WHICH ARE ON THE SERVICES FOR GENERAL EDUCATIONAL
                    INFORMATION. YOU ARE ALSO SOLELY RESPONSIBLE FOR KNOWING
                    ABOUT ANY FOOD ALLERGIES YOU MAY HAVE AND VERIFYING THE
                    PRODUCTS AND THEIR CONTENTS BEFORE HANDLING, PREPARING,
                    USING OR CONSUMING SUCH PRODUCTS.
                  </p>
                  <p className="text-uppercase">
                    WE ATTEMPT TO DISPLAY THE PRODUCTS AND OTHER MATERIALS AND
                    INFORMATION YOU VIEW ON THE SERVICES AS ACCURATELY AS
                    POSSIBLE. HOWEVER, WE DO NOT GUARANTEE THE ACCURACY OF SUCH
                    MATERIALS AND INFORMATION AND ARE NOT RESPONSIBLE FOR ANY
                    CLAIMS ARISING OR RESULTING FROM YOUR RELIANCE THEREON. IN
                    THE EVENT OF AN ERROR ON OUR SITES/SERVICES, WE RESERVE THE
                    RIGHT TO CORRECT SUCH ERROR.
                  </p>
                  <p>
                    We reserve the right to change any and all Content and to
                    modify, suspend or stop providing access to the Services (or
                    any features or functionality of the Services) and the
                    Products at any time without notice and without obligation
                    or liability to you. Reference to any products, services,
                    processes or other information by trade name, trademark,
                    manufacturer, supplier, vendor or otherwise does not
                    constitute or imply endorsement, sponsorship or
                    recommendation thereof, or any affiliation therewith, by us.
                    Some jurisdictions do not allow the disclaimer of implied
                    terms in contracts with consumers, so some or all of the
                    disclaimers in this section may not apply to you.
                  </p>
                  <h3 className="text-uppercase">Limitation of Liability</h3>
                  <p className="text-uppercase">
                    IN NO EVENT SHALL TEUKO (INCLUDING OUR AFFILIATES, AND EACH
                    OF OUR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                    SHAREHOLDERS, RETAIL PARTNERS, LICENSORS, AND SUPPLIERS) BE
                    LIABLE TO YOU FOR ANY INCIDENTAL, SPECIAL, PUNITIVE,
                    CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, BUT NOT
                    LIMITED TO, DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA,
                    LOSS OF PROGRAMS, FAILURE TO STORE ANY INFORMATION OR OTHER
                    CONTENT MAINTAINED OR TRANSMITTED BY THE SERVICES, SERVICE
                    INTERRUPTIONS, OR FOR THE COST OF PROCUREMENT OF SUBSTITUTE
                    SERVICES) ARISING OUT OF OR IN CONNECTION WITH THE SERVICES,
                    OR THESE TERMS, HOWEVER ARISING INCLUDING NEGLIGENCE, EVEN
                    IF WE OR OUR AGENTS OR REPRESENTATIVES KNOW OR HAVE BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </p>
                  <p className="text-uppercase">
                    WE WILL NOT BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT,
                    SPECIAL, PUNITIVE, INCIDENTAL AND/OR CONSEQUENTIAL
                    (INCLUDING, BUT NOT LIMITED TO PHYSICAL DAMAGES, BODILY
                    INJURY, DEATH AND/OR EMOTIONAL DISTRESS AND DISCOMFORT)
                    ARISING OUT OF YOUR USE OF THE SERVICES, EVEN IF WE KNOW OR
                    HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                  </p>
                  <p className="text-uppercase">
                    TEUKO, ITS AFFILIATES, RETAIL PARTNERS, LICENSORS, SUPPLIERS
                    AND DISTRIBUTORS WILL NOT BE LIABLE FOR AGGREGATE LIABILITY
                    FOR ALL CLAIMS RELATING TO THE SERVICES.
                  </p>
                  <p className="text-uppercase">
                    IF YOU ARE A CONSUMER RESIDING IN THE EUROPEAN UNION, YOU
                    HAVE LEGAL REMEDIES IF WE CAUSE YOU LOSS BY OUR BREACH OR
                    NON-PERFORMANCE OF OUR LEGAL OBLIGATIONS, OR BY OUR
                    NEGLIGENCE, AND THESE TERMS DO NOT AFFECT THOSE STATUTORY
                    REMEDIES.
                  </p>
                  <h3>Indemnification</h3>
                  <p>
                    To the fullest extent permitted by applicable law, you agree
                    to indemnify, defend and hold harmless Teuko, and our
                    respective past, present and future employees, officers,
                    directors, contractors, consultants, equityholders,
                    suppliers, vendors, service providers, parent companies,
                    subsidiaries, affiliates, agents, representatives,
                    predecessors, successors and assigns (individually and
                    collectively, the “Teuko Parties”), from and against all
                    actual or alleged Teuko Party or third party claims,
                    damages, awards, judgments, losses, liabilities,
                    obligations, penalties, interest, fees, expenses (including,
                    without limitation, attorneys’ fees and expenses) and costs
                    (including, without limitation, court costs, costs of
                    settlement and costs of pursuing indemnification and
                    insurance), of every kind and nature whatsoever, whether
                    known or unknown, foreseen or unforeseen, matured or
                    unmatured, or suspected or unsuspected, in law or equity,
                    whether in tort, contract or otherwise (collectively,
                    “Claims”), including, but not limited to, damages to
                    property or personal injury, that are caused by, arise out
                    of or are related to (a) your use or misuse of the Services,
                    Content or Products, (b) any User Content you create, post,
                    share or store on or through the Sites or our pages or feeds
                    on third party social media platforms, (c) any Feedback you
                    provide, (d) your violation of these Terms, (e) your
                    violation of the rights of another, and (f) any third
                    party’s use or misuse of the Sites or Products provided to
                    you. You agree to promptly notify Teuko of any third party
                    Claims and cooperate with the Teuko Parties in defending
                    such Claims. You further agree that the Teuko Parties shall
                    have control of the defense or settlement of any third party
                    Claims. This indemnity is in addition to, and not in lieu
                    of, any other indemnities set forth in a written agreement
                    between you and Teuko.
                  </p>
                  <h3>Notice for California Members</h3>
                  <p>
                    Under California Civil Code Section 1789.3, California
                    Services members are entitled to the following specific
                    consumer rights notice: The Complaint Assistance Unit of the
                    Division of Consumer Services of the California Department
                    of Consumer Affairs may be contacted in writing at 1625 N.
                    Market Blvd., Suite N 112, Sacramento, California 95834, or
                    by telephone at (800) 952-5210.
                  </p>
                  <h3>Disputes & Arbitration</h3>
                  <p>
                    If you have a dispute with Teuko, you agree to contact us
                    first and attempt to work it out amicably.
                  </p>
                  <p>
                    For residents of the United States, you agree to the
                    following mandatory arbitration provisions:
                  </p>
                  <p>
                    Mandatory Arbitration: If we’re unable to work out a
                    solution amicably, both you and Teuko agree to final and
                    binding arbitration as the sole method of resolving any
                    disputes, claims, or causes of actions arising out of your
                    use of the Services or these Terms.
                  </p>
                  <p className="text-uppercase">
                    CLASS ACTION WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, ALL
                    CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY,
                    AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
                    CLASS, COLLECTIVE ACTION, OR NON-PAGA REPRESENTATIVE
                    PROCEEDING (COLLECTIVELY “CLASS ACTION WAIVER”). THE
                    ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS
                    OR ENGAGE IN ANY CLASS ARBITRATION. YOU AGREE THAT, BY
                    ENTERING INTO THESE TERMS, YOU AND TEUKO ARE EACH WAIVING
                    THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
                    ACTION.
                  </p>
                  <p>
                    In any lawsuit in which (1) the complaint is filed as a
                    class action, collective action or non-PAGA representative
                    action; and (2) the civil court of competent jurisdiction in
                    which the complaint was filed finds the Class Action Waiver
                    is unenforceable (and such finding is confirmed by appellate
                    review if review is sought), the Class Action Waiver shall
                    be severable from this Agreement and in such instances, the
                    class action, collective action and/or non-PAGA
                    representative action must be litigated in a civil court of
                    competent jurisdiction and not as a class, collective or
                    non-PAGA representative arbitration.
                  </p>
                  <p className="text-uppercase">
                    PAGA WAIVER: TO THE EXTENT PERMISSIBLE BY LAW, THERE WILL BE
                    NO RIGHT OR AUTHORITY FOR ANY DISPUTE TO BE BROUGHT, HEARD,
                    OR ARBITRATED ON A GROUP BASIS OR IN ANY ACTION IN WHICH A
                    PARTY SEEKS TO REPRESENT OTHER INDIVIDUAL(S) IN A PRIVATE
                    ATTORNEY GENERAL ACTION (“PAGA WAIVER”). PAGA CLAIMS MAY
                    ONLY BE ARBITRATED ON AN INDIVIDUAL BASIS.
                  </p>
                  <p>
                    In any lawsuit in which (1) the complaint is filed as a
                    private attorney general action seeking to represent any
                    individual(s) other than the named plaintiff; and (2) the
                    civil court of competent jurisdiction in which the complaint
                    was filed finds the PAGA Waiver is unenforceable (and such
                    finding is confirmed by appellate review if review is
                    sought), the PAGA Waiver shall be severable from this
                    Agreement and in such instances, the private attorney
                    general action must be litigated in a civil court of
                    competent jurisdiction and not as a private attorney general
                    arbitration.
                  </p>
                  <p>
                    Notwithstanding any other clause contained in this
                    Agreement, any claim that all or part of the Class Action
                    Waiver or PAGA Waiver is invalid, unenforceable,
                    unconscionable, void or voidable may be determined only by a
                    court of competent jurisdiction and not by an arbitrator.
                    The Class Action Waiver and PAGA Waiver shall be severable
                    when a dispute is filed as an individual action and
                    severance is necessary to ensure that the individual action
                    proceeds in arbitration.
                  </p>
                  <p>
                    Opt-out of Mandatory Arbitration: You can decline this
                    mandatory arbitration provision within 30 days of accepting
                    these Terms by emailing us at contact[at]teuko.com with your
                    first and last name and stating your intent to opt-out of
                    the arbitration provision. Note that opting out of this
                    arbitration provision does not affect any other part of
                    these Terms, including the provisions regarding controlling
                    law or in which courts any disputes must be brought.
                  </p>
                  <p>
                    Time Limitation on Claims: You agree that any claim you may
                    have arising out of or related to your relationship with
                    Teuko and these Terms must be filed within one year after
                    such claim arose; otherwise, your claim is permanently
                    barred. This provision does not apply if you are based in
                    the European Union in which case time limitations shall be
                    determined in accordance with governing law for EU users
                    mentioned below.
                  </p>
                  <p>
                    Arbitration Procedures and Location: The arbitration shall
                    be administered by JAMS under its Comprehensive Rules in
                    effect at the time the arbitration demand is made. The
                    current rules, effective July 1, 2014, can be found here:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.jamsadr.com/rules-comprehensive-arbitration/"
                    >
                      http://www.jamsadr.com/rules-comprehensive-arbitration/
                    </a>
                    . JAMS arbitration rules can be found here:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.jamsadr.com/rules-clauses/"
                    >
                      http://www.jamsadr.com/rules-clauses/
                    </a>
                    . In the event of any conflict between the rules and this
                    Agreement, this Agreement shall apply. The Parties agree to
                    submit to the jurisdiction of a single neutral arbitrator
                    selected in accordance with the JAMS Comprehensive Rules.
                    The arbitration will be held in the United States, San
                    Francisco, California.
                  </p>
                  <p>
                    Arbitration Fees: The JAMS rules will govern payment of all
                    arbitration fees and each party will be responsible for
                    their own fees under those rules. However, Teuko will pay
                    for your reasonable arbitration fees for claims: (a) where
                    the claim for damages does not exceed $10, and (b) are not
                    frivolous under Federal Rule of Civil Procedure 11(b). Teuko
                    will not seek attorneys' fees or costs in arbitration unless
                    the arbitrator determines the claims are frivolous under
                    Federal Rule of Civil Procedure 11(b).
                  </p>
                  <p>
                    Exceptions to Mandatory Arbitration: Either party may bring
                    a lawsuit solely for injunctive relief to stop unauthorized
                    use or abuse of the Services, or intellectual property
                    infringement (for example, trademark, trade secret,
                    copyright, or patent rights) without first engaging in
                    arbitration or the informal dispute-resolution process
                    described above. For such claims, both parties agree to the
                    exclusive jurisdiction of the state and federal courts in
                    San Francisco County, California.
                  </p>
                  <h3>United States Operation</h3>
                  <p>
                    The Services are controlled by Teuko from its offices within
                    the United States of America. Teuko makes no representation
                    that the Content or the Services are appropriate or
                    available for use in other locations. Access to or use of
                    the Content or the Services from territories where such
                    access or use is illegal is prohibited. Those who choose to
                    access the Services from locations outside of the United
                    States do so on their own initiative and are responsible for
                    compliance with applicable local laws. You may not use or
                    export the Content in violation of U.S. export laws and
                    regulations.
                  </p>
                  <h3>Termination</h3>
                  <p>
                    You can stop using the Services at any time and without
                    notice to us. Similarly, we may terminate access to the
                    Services to you or any other users or stop offering the
                    Service at any time without notice. In the event of
                    Termination, some sections of these Terms survive and
                    continue to apply to you.
                  </p>
                  <h3>Controlling Law</h3>
                  <p>
                    These Terms will be governed by the laws of the State of
                    California, without respect to its conflicts of laws
                    principles. Any claims arising out of or relating to these
                    Terms or use of the Services that are not subject to the
                    Section about Disputes & Arbitration of these Terms shall be
                    brought exclusively in the federal or state courts of San
                    Francisco County, California, USA, and you and Teuko consent
                    to the personal jurisdiction of those courts.
                  </p>
                  <p>
                    If you are a user based in the European Union, then Irish
                    law shall apply to these Terms and the Irish courts shall
                    have exclusive jurisdiction to hear disputes arising in
                    relation to the Terms. Despite this, your local laws in your
                    European Union Member State may allow you to take legal
                    action against Teuko in your Member State and to invoke
                    certain local laws against Teuko.
                  </p>
                  <h3>Entire Agreement & Severability</h3>
                  <p>
                    These Terms, subject to any amendments, modifications, or
                    additional agreements you enter into with Teuko, shall
                    constitute the entire agreement between you and Teuko with
                    respect to the Services and any use of the Services. If any
                    provision of these Terms is found to be invalid by a court
                    competent jurisdiction, that provision only will be limited
                    to the minimum extent necessary and the remaining provisions
                    will remain in full force and effect.
                  </p>
                  <h3>No Waiver</h3>
                  <p>
                    Teuko’s failure to monitor or enforce a provision of these
                    Terms does not constitute a waiver of its right to do so in
                    the future with respect to that provision, any other
                    provision, or these Terms as a whole.
                  </p>
                  <h3>Assignment</h3>
                  <p>
                    You may not assign any of your rights, licenses, or
                    obligations under these Terms. Any such attempt at
                    assignment by you shall be void. Teuko may assign its
                    rights, licenses, and obligations under these Terms without
                    limitation.
                  </p>
                  <h3>Copyright Policy</h3>
                  <p>
                    Teuko respects the intellectual property rights of others
                    and has implemented a copyright policy in accordance with
                    the Digital Millennium Copyright Act and other relevant
                    laws. Teuko will respond to valid notices of copyright
                    infringement and reserves the right to terminate any users,
                    at our sole discretion and without notice, who repeatedly
                    infringe copyrights or other intellectual property rights of
                    others.
                  </p>
                  <p>
                    If you believe any content posted or made available on the
                    Teuko Services constitutes infringement of your copyright
                    rights, you may send a written notice of infringement to
                    Teuko using the contact information listed below. In your
                    notice, please specify the nature of the copyright
                    infringement and include the following information: (a) an
                    electronic or physical signature of the owner of the
                    copyright in question or a person authorized to act on
                    behalf of the owner of the copyright; (b) a description of
                    the claimed infringing material as well as identification of
                    the claimed infringing material, including the location of
                    such material on the Teuko Services (e.g., the URL of the
                    claimed infringing material if applicable or other means by
                    which we may locate the material); (c) complete contact
                    information, including the name of the owner of the
                    copyright and your name, title, address, telephone number,
                    and email address; (d) a statement that you have a good
                    faith belief that the disputed use is not authorized by the
                    copyright owner, its agent, or the law; and (e) a statement,
                    made under penalty of perjury, that the information provided
                    in your notice is accurate and that you are the copyright
                    owner or authorized to act on behalf of the owner.
                  </p>
                  <h3>Contact Information</h3>
                  <p>
                    Questions or comments? You can contact us at{" "}
                    <a href="mailto:contact@teuko.com">contact@teuko.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default TermsPage;
