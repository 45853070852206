import {
  uniqWith,
  isEqual,
  take,
  remove,
  sortBy,
  clone,
  find,
  concat,
} from "lodash";

export function sortFoods(foods) {
  let origFoods = clone(foods);
  let finalFoods = [];
  if (foods.length > 6) {
    const veggies = find(origFoods, ["veggies", true]);
    if (veggies) {
      remove(origFoods, (f) => veggies.id === f.id);
      finalFoods.push(veggies);
    }
    const grains = find(origFoods, ["grains", true]);
    if (grains) {
      remove(origFoods, (f) => grains.id === f.id);
      finalFoods.push(grains);
    }
    const protein = find(origFoods, ["protein", true]);
    if (protein) {
      remove(origFoods, (f) => protein.id === f.id);
      finalFoods.push(protein);
    }
    const dairy = find(origFoods, ["dairy", true]);
    if (dairy) {
      remove(origFoods, (f) => dairy.id === f.id);
      finalFoods.push(dairy);
    }
    const fruits = find(origFoods, ["fruits", true]);
    if (fruits) {
      remove(origFoods, (f) => fruits.id === f.id);
      finalFoods.push(fruits);
    }
    const drink = find(origFoods, ["drink", true]);
    if (drink) {
      remove(origFoods, (f) => drink.id === f.id);
      finalFoods.push(drink);
    }
    if (finalFoods.length < 6) finalFoods = concat(finalFoods, foods);
    finalFoods = take(uniqWith(finalFoods, isEqual), 6);
  } else finalFoods = foods;
  return sortBy(finalFoods, (f) => {
    if (f.veggies) return 1;
    if (f.grains) return 2;
    if (f.protein) return 3;
    if (f.dairy) return 4;
    if (f.fruits) return 5;
    if (f.drink) return 6;
    return 7;
  });
}

export function sortFoodsOnlyCats(foods) {
  return sortBy(foods, (f) => {
    if (f.veggies) return 1;
    if (f.grains) return 2;
    if (f.protein) return 3;
    if (f.dairy) return 4;
    if (f.fruits) return 5;
    if (f.drink) return 6;
    return 7;
  });
}

export function fireEvent(node, eventName) {
  var doc;
  if (node.ownerDocument) {
    doc = node.ownerDocument;
  } else if (node.nodeType === 9) {
    // the node may be the document itself, nodeType 9 = DOCUMENT_NODE
    doc = node;
  } else {
    throw new Error("Invalid node passed to fireEvent: " + node.id);
  }

  if (node.dispatchEvent) {
    // Gecko-style approach (now the standard) takes more work
    var eventClass = "";

    // Different events have different event classes.
    // If this switch statement can't map an eventName to an eventClass,
    // the event firing is going to fail.
    switch (eventName) {
      case "click": // Dispatching of 'click' appears to not work correctly in Safari. Use 'mousedown' or 'mouseup' instead.
      case "mousedown":
      case "mouseup":
        eventClass = "MouseEvents";
        break;

      case "focus":
      case "change":
      case "blur":
      case "select":
        eventClass = "HTMLEvents";
        break;

      default:
        throw Error(
          "fireEvent: Couldn't find an event class for event '" +
            eventName +
            "'."
        );
    }
    var event = doc.createEvent(eventClass);
    event.initEvent(eventName, true, true); // All events created as bubbling and cancelable.

    event.synthetic = true; // allow detection of synthetic events
    // The second parameter says go ahead with the default action
    node.dispatchEvent(event, true);
  } else if (node.fireEvent) {
    // IE-old school style, you can drop this if you don't need to support IE8 and lower
    event = doc.createEventObject();
    event.synthetic = true; // allow detection of synthetic events
    node.fireEvent("on" + eventName, event);
  }
}
