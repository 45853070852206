import React, { Component } from "react";
import PropTypes from "prop-types";
import FaPaperPlaneO from "../../fa/paper-plane-o";
import onClickOutside from "react-onclickoutside";

class Sharing extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.shareFacebook = this.shareFacebook.bind(this);
    this.state = {
      open: false,
    };
  }
  componentDidMount() {
    window.fbAsyncInit = function() {
        //SDK loaded, initialize it
        window.FB.init({
          appId: "566204480216246",
          status: true,
          xfbml: true,
          cookie: true,
          version: "v18.0",
        });
        window.FB.XFBML.parse();
    };

    //load the JavaScript SDK
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }
  handleClickOutside() {
    this.setState({ open: false });
  }
  shareFacebook(e) {
    const { lunch } = this.props;
    e.preventDefault();
    window.FB.ui(
      {
        method: "share",
        href: `https://www.teuko.com/l/${lunch.slug}`,
        display: 'popup'
      },
      function (response) {}
    );
  }
  render() {
    const { open } = this.state;
    const { lunch } = this.props;
    const url = `https://www.teuko.com/l/${lunch.slug}`;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="sharing-block">
        <FaPaperPlaneO onClick={() => this.setState({ open: !open })} />
        {open ? (
          <div className="sharing-options">
            <a href="#" onClick={this.shareFacebook}>
              Share on Facebook
            </a>
            <a
              className="twitter-share-button"
              href={`https://twitter.com/intent/tweet?text=${encodeURI(
                "Let’s make lunchbox packing easier together, join our community of lunchbox packers on Teuko!"
              )}&url=${encodeURI(url)}&via=teukoapp&hashtags=teuko&`}
            >
              Share on Twitter
            </a>
            <a
              href={`mailto:?subject=Lunchbox%20idea%20for%20you&body=Hi,%0D%0DCheck%20out%20this%20lunchbox%20idea%20that%20I%20found%20on%20Teuko,%20the%20online%20community%20for%20lunchbox%20packers%3A%0D%0D${encodeURI(
                url
              )}%0D%0DEnjoy!`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Share by Email
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Sharing.propTypes = {
  lunch: PropTypes.object,
};

export default onClickOutside(Sharing);
