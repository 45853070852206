import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import FaHeart from "../../fa/heart";
import FaHeartO from "../../fa/heart-o";
import FaBookmark from "../../fa/bookmark";
import FaBookmarkO from "../../fa/bookmark-o";
import { fireEvent } from "./helpers";
import Sharing from "./Sharing";

class BrowseLunchThumbGrid extends Component {
  constructor(props) {
    super(props);
    this.testLoggedIn = this.testLoggedIn.bind(this);
  }
  testLoggedIn() {
    if (!this.props.loggedIn)
      fireEvent(document.getElementById("signup"), "click");
  }
  render() {
    const {
      lunch,
      lunch: { user, liked, saved },
      submitLike,
      submitSave,
      location,
      opened,
      loggedIn,
    } = this.props;
    
    let path = location.pathname;
    if (location.search) path = `${path}${location.search}`;
    const actionPanel = this.props.hideActionPanel ? (
      ""
    ) : (
      <div className="ltb-lunch-actions-for-grid">
        <div className="like-and-share">
          {liked ? (
            <FaHeart onClick={() => submitLike(lunch.id)} />
          ) : (
            <FaHeartO
              onClick={() => {
                if (!loggedIn) return this.testLoggedIn();
                submitLike(lunch.id);
              }}
            />
          )}
          <Sharing lunch={lunch} />
        </div>
        <div className="bookmark-box" onClick={this.testLoggedIn}>
          {saved ? (
            <FaBookmark onClick={() => submitSave(lunch.id)} />
          ) : (
            <FaBookmarkO onClick={() => submitSave(lunch.id)} />
          )}
        </div>
      </div>
    );
    function lowres(img) {
      const i = img.lastIndexOf(".");
      if (i < 0) {
        return img;
      }
      return img.substring(0, i) + "_lowres" + img.substring(i);
    }
    return (
      <div className="lunch-thumb-box-for-grid" key={lunch.id}>
        <div className="ltb-user-header-for-grid">
          <div
            className="ltb-user-pic-for-grid"
            style={{ backgroundImage: user.image ? `url(${user.image})` : "" }}
          />
          <Link to={`/user/${user.user_name}`} className="ltb-user-link">
            {user.user_name}
          </Link>
          {user.id === 2277 ? <div className="ltb-user-verifiedbadge" /> : ""}
        </div>
        <Link
          data-id={lunch.id}
          style={{ backgroundImage: `url(${lowres(lunch.image)})` }}
          className="lunch-thumb-for-grid"
          role="link"
          tabIndex="0"
          to={{
            pathname: `/l/${lunch.slug}`,
            state: { modal: true, returnTo: path },
          }}
          onClick={opened}
        >
          
        </Link>
        {actionPanel}
      </div>
    );
  }
}

BrowseLunchThumbGrid.propTypes = {
  lunch: PropTypes.object,
  loggedIn: PropTypes.bool,
  data: PropTypes.object,
  location: PropTypes.object,
  opened: PropTypes.func,
  submitLike: PropTypes.func,
  submitSave: PropTypes.func,
};

const toggleLike = gql`
  mutation likeLunch($id: Int!) {
    likeLunch(id: $id) {
      id
      liked
    }
  }
`;

const toggleSave = gql`
  mutation saveLunch($id: Int!) {
    saveLunch(id: $id) {
      id
      saved
    }
  }
`;

export default compose(
  graphql(toggleLike, {
    name: "likeMutation",
    props: ({ ownProps, likeMutation }) => ({
      submitLike: (id) =>
        likeMutation({
          variables: { id },
          optimisticResponse: {
            __typename: "Mutation",
            likeLunch: {
              __typename: "Lunch",
              id: ownProps.lunch.id,
              liked: !ownProps.lunch.liked,
            },
          },
        }),
    }),
  }),
  graphql(toggleSave, {
    name: "saveMutation",
    props: ({ ownProps, saveMutation }) => ({
      submitSave: (id) =>
        saveMutation({
          variables: { id },
          optimisticResponse: {
            __typename: "Mutation",
            saveLunch: {
              __typename: "Lunch",
              id: ownProps.lunch.id,
              saved: !ownProps.lunch.saved,
            },
          },
        }),
    }),
  })
)(BrowseLunchThumbGrid);
