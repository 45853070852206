import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import PropTypes from "prop-types";
import FacebookLogin from '@greatsumini/react-facebook-login';
import FaFacebook from "../../fa/facebook-square";
import { Helmet } from "react-helmet";

function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.setError = this.setError.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.state = {
      error: "",
    };
  }
  UNSAFE_componentWillMount() {
    if (this.props.user.id) browserHistory.push("/");
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ error: "" });
    const email = this.email.value;
    const password = this.password.value;
    if (!email || !password)
      return this.setError("Please enter an email and password");
    else if (!validateEmail(email))
      return this.setError("Please enter a valid email");
    const myInit = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password,
      }),
    };
    fetch(`/login`, myInit)
      .then((res) => {
        if (!res.ok) throw Error("Incorrect email or password");
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("sessionID", data.sessionID);
        window.location.href = "/";
      })
      .catch((err) => {
        this.setError(err.toString());
      });
  };
  setError(error) {
    this.setState({ error });
  }
  handleEnter(event) {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  }
  responseFacebook(facebook) {
    const myInit = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        accessToken: facebook.accessToken,
        profile: facebook,
      }),
    };
    fetch(`/loginfacebook`, myInit)
      .then((res) => {
        if (!res.ok)
          return this.setError(
            "No account associated with this Facebook account"
          );
        return res.json();
      })
      .then((data, err) => {
        if (data) {
          localStorage.setItem("sessionID", data.sessionID);
          window.location.href = "/";
        } else {
          throw new Error("Facebook login error! " + err);
        }
      })
      .catch((err) => {
        this.setError(err.toString());
      });
  }
  /* eslint jsx-a11y/anchor-is-valid: 0 */
  render() {
    return (
      <div className="popup-modal accounts-modal">
        <Helmet>
          <title>Teuko Lunchbox Community - Log In</title>
        </Helmet>
        <h3>Welcome back</h3>
        <div className="sign-up-options">
          <span className="error-class">{this.state.error}</span>
          <FacebookLogin
            appId="566204480216246"
            fields="name, email, picture"
            onSuccess={this.responseFacebook}
            initParams={{
              version: 'v17.0',
              xfbml: true,
            }}
            style={{
              borderRadius: '4px',
              marginTop: '12px',
              borderRadius: '27.5px',
              textTransform: 'uppercase',
              fontSize: '15px',
              backgroundColor: '#3b5997',
              fontWeight: '500',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              margin: '0 auto',
            }}
            render={({ onClick, logout }) => (
              <button 
                onClick={onClick}
                onLogoutClick={logout}
                type="button"
                class="standard-button account-button facebook-button facebook-login-button metro"
              >
                <FaFacebook/>&nbsp;&nbsp;Log In with Facebook
              </button>
            )}
            disableMobileRedirect={true}
          />
          <h6>Or</h6>
          <div className="account-details-group">
            <input
              className="full-input"
              type="email"
              placeholder="Email"
              ref={(e) => (this.email = e)}
            />
            <input
              className="full-input"
              type="password"
              placeholder="Password"
              ref={(e) => (this.password = e)}
              onKeyPress={this.handleEnter}
            />
          </div>
          <a
            href="#"
            className="standard-button account-button"
            onClick={this.handleSubmit}
          >
            Log In
          </a>

          <div className="teuko-agreement">
            <Link to="/forgotpassword" className="accounts-link">
              Forgot Password?
            </Link>
          </div>
          <div className="loginpage-invitesignup">
            <p>
              New to Teuko? Sign up <Link to="/signup">here</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

LogIn.propTypes = {
  close: PropTypes.func,
};

export default LogIn;
