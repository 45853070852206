import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Link, browserHistory } from "react-router";
import { map, find } from "lodash";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import moment from "moment";
import FaPrint from "../../fa/print";
import FaShoppingCart from "../../fa/shopping-cart";
import FaPieChart from "../../fa/pie-chart";
import FaClose from "../../fa/close";
import Lunch from "../../components/lunches/Lunch";
import ShoppingList from "../../components/lunches/ShoppingList";
import StatList from "../../components/lunches/StatList";
import { sortFoods } from "./helpers";

class OneMenu extends Component {
  constructor(props) {
    super(props);
    this.openLunch = this.openLunch.bind(this);
    this.close = this.close.bind(this);
    this.print = this.print.bind(this);
    this.state = {
      id: 0,
      grocery: false,
      stat: false,
    };
  }
  openLunch(e) {
    const { id } = e.currentTarget.dataset;
    this.setState({ id: parseInt(id, 10) });
  }
  close() {
    this.setState({ id: 0, grocery: false, stat: false });
  }
  print() {
    const { data, dates, dateString } = this.props;
    browserHistory.push({
      pathname: "/menuprint",
      state: {
        dateString,
        dates,
        getMenu: data.getMenu,
      },
    });
  }
  render() {
    const { id, grocery, stat } = this.state;
    const { data, dates, dateString, location, submit } = this.props;
    const { loading } = data;
    if (loading) {
      return (
        <div className="menu">
          <div className="menu-dates" />
          <div className="menu-lunches" />
        </div>
      );
    }
    const menu = data.getMenu;
    const Lunches = map(dates, (d) => {
      const sMenu = find(menu, { date: d });
      if (!sMenu) {
        return (
          <div className="lunch-placeholder" key={`d${d}`}>
            No lunch yet! Add one below.
          </div>
        );
      }
      const LunchFoods = map(sortFoods(sMenu.lunch.foods), (f) => (
        <span key={`omen${f.id}a`} className="food-hover-name">
          {f.name}
        </span>
      ));
      return (
        <Link
          className="lunch-thumb"
          style={{ backgroundImage: `url(${sMenu.lunch.image})` }}
          data-id={sMenu.lunch.id}
          to={{
            pathname: `/l/${sMenu.lunch.slug}`,
            state: { modal: true, returnTo: location.pathname },
          }}
          role="link"
          tabIndex="0"
          key={`awef${sMenu.id}`}
        >
          <div className="lunch-thumb-hover">{LunchFoods}</div>
          <FaClose
            className="lunch-thumb-hover-delete"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              submit(sMenu.id.toString());
            }}
          />
        </Link>
      );
    });
    const Dates = map(dates, (d) => (
      <div key={d + "parent"}>
        <span key={d + "ddd"}>{moment(d).format("ddd")}</span>
        <span key={d + "mmmddyyyy"}>{moment(d).format("MMM DD YYYY")}</span>
      </div>
    ));
    let statModal = "";
    if (stat) {
      statModal = ReactDOM.createPortal(
        <StatList menu={menu} dateString={dateString} close={this.close} />,
        document.getElementById("popup-mount")
      );
    }
    return (
      <Fragment>
        <div className="menu-action-icons">
          <FaPrint className="print-icon" onClick={this.print} />
          <FaShoppingCart
            className="cart-icon"
            onClick={() => this.setState({ grocery: true })}
          />
          <FaPieChart
            className="stat-icon"
            onClick={() => this.setState({ stat: true })}
          />
          {/* <a target="_blank" rel="noopener noreferrer" href="https://www.supergirlsclub.org/single-post/2018/08/14/Women-in-Health-Wellness-Interview-with-Jessica-Gury-Co-founder-CEO-Teuko?utm_source=teuko"><img alt="" src="/images/superglogo.jpg" /></a><a href="https://www.icliniq.com/ask-a-doctor-online?utm_source=teuko&utm_medium=cta&utm_campaign=partnership" target="_blank" rel="noopener noreferrer"><FaUserMd className="medic-icon" /></a> */}
        </div>
        <div className="menu">
          <div className="menu-dates">{Dates}</div>
          <div className="menu-lunches">{Lunches}</div>
          {id
            ? ReactDOM.createPortal(
                <Lunch id={id} close={this.close} />,
                document.getElementById("popup-mount")
              )
            : ""}
          {grocery
            ? ReactDOM.createPortal(
                <ShoppingList
                  menu={menu}
                  dateString={dateString}
                  close={this.close}
                />,
                document.getElementById("popup-mount")
              )
            : ""}
          {statModal}
        </div>
      </Fragment>
    );
  }
}

OneMenu.propTypes = {
  userId: PropTypes.string,
  dates: PropTypes.array,
  dateString: PropTypes.string,
  location: PropTypes.object,
  submit: PropTypes.func,
};

export const menuQuery = gql`
  query getMenu($dates: [String]) {
    getMenu(dates: $dates) {
      id
      lunch_id
      user_id
      date
      lunch {
        id
        user_id
        image
        slug
        foods {
          id
          name
          veggies
          grains
          dairy
          protein
          fruits
          drink
        }
      }
    }
  }
`;

export const deleteFromMenuQuery = gql`
  mutation deleteFromMenu($id: String!) {
    deleteFromMenu(id: $id)
  }
`;

export default compose(
  graphql(menuQuery, {
    options: (props) => ({
      variables: { dates: props.dates },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }),
  }),
  graphql(deleteFromMenuQuery, {
    props: ({ mutate }) => ({
      submit: (id) =>
        mutate({
          variables: { id },
        }),
    }),
    options: {
      refetchQueries: ["getMenu"],
    },
  })
)(OneMenu);
