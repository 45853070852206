import React, { Component } from "react";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { Helmet } from "react-helmet";
import MyLunches from "../../components/lunches/MyLunches";
import MyMenus from "../../components/lunches/MyMenus";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

class MyLunchesPage extends Component {
  constructor(props) {
    super(props);
    this.handleInfiniteLoad = this.handleInfiniteLoad.bind(this);
    this.state = {
      id: 0,
    };
  }
  UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (!user.id) browserHistory.push("/");
  }
  handleInfiniteLoad(e) {
    this.props.loadMore();
  }
  render() {
    const { user, location } = this.props;
    const { data } = this.props;

    let lunchbox_count = 0;
    // let follower_count = 0;
    // let following_count = 0;
    if (data.currentUser) {
      lunchbox_count = data.currentUser.lunchbox_count;
      // follower_count = data.currentUser.follower_count;
      // following_count = data.currentUser.following_count;
    }

    return (
      <div className="my-lunches-page">
        <Helmet>
          <title>Teuko Lunchbox Community - My Lunches</title>
        </Helmet>
        <div className="in-app-container">

          {/* <div className="my-lunches-titleandcounter">
            <h1>My Lunches</h1>
            <div className="profile-details">
              <div className="profile-stats">
                <Link to={`/user/${user.user_name}`}>
                  <h2>
                      {lunchbox_count} Lunchbox Ideas
                  </h2>
                </Link>
              </div>
            </div>
          </div> */}


          <MyMenus user={user} userId={user.id} location={location} lunchbox_count={lunchbox_count}/>
          <MyLunches userId={user.id} location={location} />
        </div>
      </div>
    );
  }
}

MyLunchesPage.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
};

// export const myProfileQuery = gql`
//   query currentUser {
//     currentUser {
//       id
//       follower_count
//       following_count
//       lunchbox_count
//     }
//   }
// `;
export const myProfileQuery = gql`
  query currentUser {
    currentUser {
      id
      lunchbox_count
    }
  }
`;

export default graphql(myProfileQuery)(MyLunchesPage);
