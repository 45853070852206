import React, { Component } from "react";
import { browserHistory } from "react-router";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setError = this.setError.bind(this);
    this.state = {
      sent: false,
      error: "",
    };
  }
  UNSAFE_componentWillMount() {
    const { user } = this.props;
    if (user.id) browserHistory.push("/");
  }
  setError(error) {
    this.setState({ error });
  }
  async handleSubmit(e) {
    e.preventDefault();
    this.setError("");
    const {
      mutate,
      params: { token },
    } = this.props;
    const new_password = this.new_password.value;
    const new_password_confirm = this.new_password_confirm.value;
    const variables = { new_password, token };
    if (!new_password || !new_password_confirm)
      return this.setError("Please fill in all fields");
    else if (new_password !== new_password_confirm)
      return this.setError("Your new passwords do not match");
    const response = await mutate({
      variables,
    });
    if (response.data.changePassword) {
      document.getElementById("change-password").innerText =
        "Password Changed!";
      setTimeout(() => {
        browserHistory.push("/");
      }, 2000);
    } else return this.setError("There was an error");
  }
  render() {
    const { error } = this.state;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="popup-modal reset-page">
        <h3>Change Password</h3>
        <div className="sign-up-options">
          <span className="error-class">{error}</span>
          <div className="account-details-group">
            <input
              className="full-input"
              type="password"
              placeholder="New Password"
              ref={(e) => (this.new_password = e)}
            />
            <input
              className="full-input"
              type="password"
              placeholder="Confirm New Password"
              ref={(e) => (this.new_password_confirm = e)}
            />
          </div>
          <div className="form-group">
            <a
              href="#"
              className="standard-button account-button"
              id="change-password"
              onClick={this.handleSubmit}
            >
              Change Password
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const changePasswordMutation = gql`
  mutation changePassword(
    $password: String
    $new_password: String!
    $token: String
  ) {
    changePassword(
      password: $password
      new_password: $new_password
      token: $token
    )
  }
`;

export default graphql(changePasswordMutation)(ChangePasswordPage);
