import React from "react";
import { Router, Route, browserHistory, IndexRoute } from "react-router";
import ReactGA from "react-ga";
// main layout
import App from "./App";
import HomeSwitcher from "./containers/HomeSwitcher";
// home & logged out pages
import LogIn from "./components/accounts/LogIn";
import SignUp from "./components/accounts/SignUp";
import AboutUsPage from "./pages/home/AboutUsPage";
import DiscoverPage from "./pages/home/DiscoverPage";
import FeaturesPage from "./pages/home/Features";
import LunchboxcardsPage from "./pages/home/Lunchboxcards";
import FoodgroupsPage from "./pages/home/Foodgroups";
import SchoollunchmenusPage from "./pages/home/Schoollunchmenus";
import LunchboxdiaryPage from "./pages/home/Lunchboxdiary";
import GroceryshoppingPage from "./pages/home/Groceryshopping";
import HashtagteukoPage from "./pages/home/Hashtagteuko";
import JobsPage from "./pages/home/JobsPage";
import PartnersPage from "./pages/home/PartnersPage";
import ContactPage from "./pages/home/ContactPage";
import FAQPage from "./pages/home/FAQ";
import BoutiquePage from "./pages/home/BoutiquePage";
import BoutiquePageThemesAZ from "./pages/home/BoutiquePageThemesAZ";
import BoutiquePageBrands from "./pages/home/BoutiquePageBrands";
import ResetPasswordPage from "./pages/home/ResetPasswordPage";
import ChangePasswordPage from "./pages/home/ChangePasswordPage";
import TermsPage from "./pages/home/TermsPage";
import PrivacyPage from "./pages/home/PrivacyPage";
import GiveAway from "./pages/home/GiveAway";
// interior pages
import Browse from "./pages/main/Browse";
import Feed from "./pages/main/Feed";
import MyLunchesPage from "./pages/main/MyLunchesPage";
import ProfilePage from "./pages/main/ProfilePage";
import TrophiesPage from "./pages/main/MyTrophiesPage";
import ChallengesRules from "./pages/main/ChallengesRules";
import MenuPrintPage from "./pages/main/MenuPrintPage";
import ShoppingListPrintPage from "./pages/main/ShoppingListPrintPage";
import ExploreFoodPage from "./pages/explore/ExploreFoodPage";
import SettingsPage from "./pages/settings/SettingsPage";
// other containers
import Lunch from "./components/lunches/Lunch";
import CreateLunch from "./components/create/CreateLunch";
import ChallengesPage from "./pages/main/ChallengesPage";

ReactGA.initialize("UA-53306339-1");

function logPageView() {
  ReactGA.pageview(window.location.pathname);
}

function scrollUp() {
  logPageView();

  const { location } = this.state;
  if (
    location &&
    location.state &&
    (location.state.modal || location.state.closedModal)
  )
    return true;
  return window.scrollTo(0, 0);
}

const Routes = (props) => (
  <Router onUpdate={scrollUp} history={browserHistory}>
    <Route path="/" component={App}>
      <IndexRoute component={HomeSwitcher} />
      <Route path="/login" component={LogIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/feed" component={Feed} />
      <Route path="/browse" component={Browse} />
      <Route path="/about" component={AboutUsPage} />
      <Route path="/blog" component={DiscoverPage} />
      <Route path="/features" component={FeaturesPage} />
      <Route path="/lunchboxcards" component={LunchboxcardsPage} />
      <Route path="/foodgroups" component={FoodgroupsPage} />
      <Route path="/schoollunchmenus" component={SchoollunchmenusPage} />
      <Route path="/lunchboxdiary" component={LunchboxdiaryPage} />  
      <Route path="/groceryshopping" component={GroceryshoppingPage} />  
      <Route path="/hashtagteuko" component={HashtagteukoPage} />
      <Route path="/jobs" component={JobsPage} />
      <Route path="/partners" component={PartnersPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/privacy" component={PrivacyPage} />
      <Route path="/boutique" component={BoutiquePage} />
      <Route path="/boutique-themes-AZ" component={BoutiquePageThemesAZ} />
      <Route path="/boutique-brands" component={BoutiquePageBrands} />
      <Route path="/forgotpassword" component={ResetPasswordPage} />
      <Route path="/changepassword/:token" component={ChangePasswordPage} />
      <Route path="/mylunches" component={MyLunchesPage} />
      <Route path="/settings" component={SettingsPage} />
      <Route path="/menuprint" component={MenuPrintPage} />
      <Route path="/shoppinglistprint" component={ShoppingListPrintPage} />
      <Route path="/giveaway" component={GiveAway} />
      <Route path="/challenges/:challengeName/rules" component={ChallengesRules} />
      <Route path="/challenges" component={ChallengesPage} />
      <Route path="/search" component={ExploreFoodPage} />
      <Route path="/search:food_name" component={ExploreFoodPage} />
      <Route path="/l/:slug" component={Lunch} />
      <Route path="/edit/:slug" component={CreateLunch} />
      <Route path="/user/:user_name/trophies" component={TrophiesPage} />
      <Route path="/user/:user_name" component={ProfilePage} />
      <Route path="/:user_name" component={ProfilePage} />
    </Route>
  </Router>
);

export default Routes;
