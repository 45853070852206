import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { graphql, withApollo, compose } from "react-apollo";
import gql from "graphql-tag";
import {
  concat,
  filter,
  reject,
  find,
  includes,
  toString,
  compact,
  map,
} from "lodash";
import VirtualizedSelect from "react-virtualized-select";
import FaSearch from "../../fa/search";
import FaClose from "../../fa/close";

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.setError = this.setError.bind(this);
    this.boutiqueItemOptionRenderer = this.boutiqueItemOptionRenderer.bind(
      this
    );
    this.state = {
      error: "",
      loading: true,
      boutiqueItems: [],
    };
  }
  setError(error) {
    this.setState({ error });
  }
  async handleSubmit(e) {
    e.preventDefault();
    const like_it_hot = this.like_it_hot.checked ? 1 : 0;
    const like_it_cold = this.like_it_cold.checked ? 1 : 0;
    const culinary_trends = toString(
      compact([
        this.ct_african.checked ? "ct_african" : "",
        this.ct_american.checked ? "ct_american" : "",
        this.ct_asian.checked ? "ct_asian" : "",
        this.ct_european.checked ? "ct_european" : "",
        this.ct_latin_american ? "ct_latin_american" : "",
        this.ct_indian.checked ? "ct_indian" : "",
      ])
    );
    const lunchbox_eaters = toString(
      compact([
        this.lbe_36.checked ? "lbe_36" : "",
        this.lbe_712.checked ? "lbe_712" : "",
        this.lbe_teenager.checked ? "lbe_teenager" : "",
        this.lbe_adult.checked ? "lbe_adult" : "",
      ])
    );
    const favorite_items = toString(
      compact(map(this.state.favoriteItems, (item) => item.id))
    );
    const variables = {
      like_it_hot,
      like_it_cold,
      culinary_trends,
      lunchbox_eaters,
      favorite_items,
    };
    const response = await this.props.mutate({ variables });
    if (response.data.changePreferences) {
      document.getElementById("save-preferences").innerText = "Changes Saved!";
    }
  }
  handleSelect(option) {
    const { data } = this.props;
    let { favoriteItems, boutiqueItems } = this.state;
    if (!favoriteItems) {
      const data_items = data.currentUser.favorite_items
        ? data.currentUser.favorite_items
        : "";
      const favoriteItemIds = filter(data_items.split(","), (id) => id !== "");
      favoriteItems = filter(boutiqueItems, (item) => {
        return favoriteItemIds.indexOf(item.id) >= 0;
      });
    }

    if (!find(favoriteItems, { id: option.id })) {
      this.setState({ favoriteItems: concat(favoriteItems, option) });
    }
  }
  removeItem(e) {
    const { data } = this.props;
    let { favoriteItems, boutiqueItems } = this.state;
    if (!favoriteItems) {
      const favoriteItemIds = filter(
        data.currentUser.favorite_items.split(","),
        (id) => id !== ""
      );
      favoriteItems = filter(boutiqueItems, (item) => {
        return favoriteItemIds.indexOf(item.id) >= 0;
      });
    }

    const id = e.currentTarget.dataset.id;
    this.setState({ favoriteItems: reject(favoriteItems, { id }) });
  }
  boutiqueItemOptionRenderer({
    focusedOption,
    focusOption,
    key,
    option,
    selectValue,
    style,
  }) {
    const classNames = ["country-option"];
    if (option === focusedOption) {
      classNames.push("focused-country-option");
    }
    return (
      <div
        key={key}
        onClick={() => {
          selectValue(option);
        }}
        onMouseEnter={() => focusOption(option.id)}
        className={classNames.join(" ")}
        style={style}
      >
        <div
          className="country-image-option"
          style={{ backgroundImage: `url(${option.image})` }}
        />
        <label>{option.name}</label>
      </div>
    );
  }
  async UNSAFE_componentWillMount() {
    const boutiqueItems = await (
      await fetch(
        "https://teuko.s3-us-west-2.amazonaws.com/content/boutique.json"
      )
    ).json();

    this.setState({ loading: false, boutiqueItems });
  }
  render() {
    const { error, loading, boutiqueItems } = this.state;
    let { favoriteItems } = this.state;
    const { data } = this.props;
    if (loading || data.loading || data.error) return <div>Loading...</div>;

    const {
      currentUser: {
        like_it_hot,
        like_it_cold,
        lunchbox_eaters,
        culinary_trends,
      },
    } = data;
    const ct_african = includes(culinary_trends, "ct_african");
    const ct_american = includes(culinary_trends, "ct_american");
    const ct_latin_american = includes(culinary_trends, "ct_latin_american");
    const ct_asian = includes(culinary_trends, "ct_asian");
    const ct_european = includes(culinary_trends, "ct_european");
    const ct_indian = includes(culinary_trends, "ct_indian");
    const lbe_36 = includes(lunchbox_eaters, "lbe_36");
    const lbe_712 = includes(lunchbox_eaters, "lbe_712");
    const lbe_teenager = includes(lunchbox_eaters, "lbe_teenager");
    const lbe_adult = includes(lunchbox_eaters, "lbe_adult");

    if (!favoriteItems) {
      const data_items = data.currentUser.favorite_items
        ? data.currentUser.favorite_items
        : "";
      const favoriteItemIds = filter(data_items.split(","), (id) => id !== "");
      favoriteItems = filter(boutiqueItems, (item) => {
        return favoriteItemIds.indexOf(item.id) >= 0;
      });
    }

    let favoriteDiv = <div />;
    const favoriteLunchboxItems = map(favoriteItems, (item) => (
      <div key={item.id} className="fav-items-ids">
        <div
          className="fav-items-image-option"
          style={{
            backgroundImage: `url(${item.image})`,
            display: "inline-block",
            alignItems: "center",
            verticalAlign: "middle",
          }}
        />
        <div
          className="fav-items-text-option"
          style={{
            display: "inline-block",
            alignItems: "center",
            verticalAlign: "middle",
          }}
        >
          {item.name} <FaClose data-id={item.id} onClick={this.removeItem} />
        </div>
      </div>
    ));

    favoriteDiv = (
      <div className="preference-group">
        <p>
          <strong>Favorite Lunchbox Items</strong>
        </p>
        <div className="preference-fs-right">
          <div className="missing-lunchbox-items">
            <p>
              Items missing?{" "}
              <a href="mailto:contact@teuko.com?subject=Lunchbox%20Items%20Missing&amp;body=Hi%20Teuko%20Team%2C%0A%0AThe%20following%20lunchbox%20items%20are%20missing%3A%0A-">
                Let us know!
              </a>
            </p>
          </div>
          <div className="search-bar-holder">
            <VirtualizedSelect
              valueKey="id"
              labelKey="name"
              options={boutiqueItems}
              optionHeight={50}
              optionRenderer={this.boutiqueItemOptionRenderer}
              className="lunchboxitems-selector"
              onChange={(option) => this.handleSelect(option)}
            />
            <FaSearch className="select-search-icon" />
          </div>

          <div
            className="selected-foods-section"
            style={{ verticalAlign: "middle" }}
          >
            {favoriteLunchboxItems}
          </div>
        </div>
      </div>
    );

    return (
      <Collapsible trigger="Preferences">
        <div className="change-preference-form">
          <span className="error-class">{error}</span>
          <div className="preference-group">
            <p>
              <strong>Lunch Preference</strong>
            </p>
            <input
              type="checkbox"
              ref={(el) => (this.like_it_hot = el)}
              defaultChecked={!!like_it_hot}
              style={{ display: "inline-block", alignItems: "center" }}
            />
            Hot Lunch
            <input
              type="checkbox"
              ref={(el) => (this.like_it_cold = el)}
              defaultChecked={!!like_it_cold}
              style={{ display: "inline-block", alignItems: "center" }}
            />
            Cold Lunch
          </div>
          <div className="preference-group">
            <p>
              <strong>Culinary Trends</strong>
            </p>
            <input
              type="checkbox"
              ref={(el) => (this.ct_african = el)}
              defaultChecked={!!ct_african}
            />
            African
            <input
              type="checkbox"
              ref={(el) => (this.ct_american = el)}
              defaultChecked={!!ct_american}
            />
            American
            <input
              type="checkbox"
              ref={(el) => (this.ct_asian = el)}
              defaultChecked={!!ct_asian}
            />
            Asian
            <input
              type="checkbox"
              ref={(el) => (this.ct_european = el)}
              defaultChecked={!!ct_european}
            />
            European
            <input
              type="checkbox"
              ref={(el) => (this.ct_latin_american = el)}
              defaultChecked={!!ct_latin_american}
            />
            Latin American
            <input
              type="checkbox"
              ref={(el) => (this.ct_indian = el)}
              defaultChecked={!!ct_indian}
            />
            Indian
          </div>
          <div className="preference-group">
            <p>
              <strong>Lunchbox Eater(s) Age Range</strong>
            </p>
            <input
              type="checkbox"
              ref={(el) => (this.lbe_36 = el)}
              defaultChecked={!!lbe_36}
            />
            3-6yo
            <input
              type="checkbox"
              ref={(el) => (this.lbe_712 = el)}
              defaultChecked={!!lbe_712}
            />
            7-12yo
            <input
              type="checkbox"
              ref={(el) => (this.lbe_teenager = el)}
              defaultChecked={!!lbe_teenager}
            />
            Teenager(s)
            <input
              type="checkbox"
              ref={(el) => (this.lbe_adult = el)}
              defaultChecked={!!lbe_adult}
            />
            Adult(s)
          </div>
          {favoriteDiv}
          <div className="form-group">
            <button
              className="settings-button"
              id="save-preferences"
              onClick={this.handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
      </Collapsible>
    );
  }
}

const prefQuery = gql`
  query currentUser {
    currentUser {
      id
      like_it_hot
      like_it_cold
      lunchbox_eaters
      culinary_trends
      favorite_items
    }
  }
`;

const changePreferencesMutation = gql`
  mutation changePreferences(
    $like_it_hot: Int
    $like_it_cold: Int
    $culinary_trends: String
    $lunchbox_eaters: String
    $favorite_items: String
  ) {
    changePreferences(
      like_it_hot: $like_it_hot
      like_it_cold: $like_it_cold
      culinary_trends: $culinary_trends
      lunchbox_eaters: $lunchbox_eaters
      favorite_items: $favorite_items
    )
  }
`;

export default compose(
  withApollo,
  graphql(prefQuery, {
    options: { fetchPolicy: "network-only" },
  }),
  graphql(changePreferencesMutation)
)(Preferences);
