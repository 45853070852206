import React, { Component, Fragment } from "react";
import { withApollo } from "react-apollo";
import { createClient } from "contentful";
import { map } from "lodash";
import { Link, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import MailingListPopup from "../../components/general/MailingListPopup";

class BoutiquePageThemesAZ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      groups: [],
      activeOption: 0,
    };
  }
  UNSAFE_componentWillMount() {
    document.body.classList.add("home");
    const self = this;
    const client = createClient({
      space: "ismqagtvmv5l",
      accessToken:
        "a1ecd3f1aebb60866ede0e48a30081c5422c7e4997210922943bb28c001d000d",
    });
    client
      .getEntries({
        content_type: "boutique",
        "fields.number": 1,
        include: 2,
      })
      .then((entries) => {
        self.setState({
          groups: entries.items[0].fields.group,
          loading: false,
        });
      });
  }

  componentDidMount () {
    const googletag = window.googletag;
    if (googletag) {
      googletag.cmd.push(function() { 
        setTimeout(() => {
            googletag.display('div-gpt-ad-1676587896442-0');
            googletag.display('div-gpt-ad-1676588104910-0');
            googletag.display('div-gpt-ad-1677115459576-0'); 
            googletag.display('div-gpt-ad-1677115709301-0');
            googletag.display('div-gpt-ad-1677276949293-0'); 
            googletag.display('div-gpt-ad-1677277225534-0');
            
        }, 2000);
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("home");
  }
  render() {
    const { activeOption } = this.state;
    const { user } = this.props;
    const { groups, loading } = this.state;
    if (loading) return <div />;
   



    return (
      <Fragment>
        <Helmet>
          <title>Teuko Bento LunchBox Shop - Themes A-Z</title>
        </Helmet>
        <div className="boutique-page">
          {user.id !== 0 ? (
            ""
          ) : (
            <MailingListPopup delayInMilliseconds={15000} client={this.props.client}/>
          )}
          <div className="browse-boutique-header">
          <h1>Teuko Boutique</h1>
            <div className="my-boutique-filters">
              
              <div className="boutique-content-options">
                <a href="/boutique"><h3 style={{color: '#737373'}}>Themes by Category</h3></a>
                <h3>Themes A-Z</h3>
                <a href="/boutique-brands"><h3 style={{color: '#737373'}}>Brands</h3></a>
              </div>
          
            </div>
          </div>


          <div>
                 
                  <div className="boutique-menus">
                    
                    <Link
                    key="ThemesAtoC"
                    to="ThemesAtoC"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>A-C</h3>
                    </Link>

                    <Link
                    key="ThemesDtoG"
                    to="ThemesDtoG"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>D-G</h3>
                    </Link>

                    <Link
                    key="ThemesHtoO"
                    to="ThemesHtoO"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>H-O</h3>
                    </Link>

                    <Link
                    key="ThemesPtoR"
                    to="ThemesPtoR"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>P-R</h3>
                    </Link>

                    <Link
                    key="ThemesStoZ"
                    to="ThemesStoZ"
                    spy
                    smooth
                    offset={-200}
                    duration={300}
                    >
                    <h3>S-Z</h3>
                    </Link>

                  </div>

                  <div className="standard-container">
                    <div className="boutique-groups">

                      <div className="boutique-affiliate-message">
                        <p>
                          Disclaimer: As an Amazon Associate, Teuko earns from qualifying purchases. If you buy some
                          products, we may get a small share of the revenue from the sale. This helps Teuko.com remain free!
                        </p>
                      </div>

                      <div className="search-google-ad-banner-type-leaderboard-centered">
                        <div className="search-title-header-ad-laptop_leaderboard">
                            {/* <!-- /22874318077/Teuko-Boutique-Laptop_AdUnit_2-Leaderboard-728x90 --> */}
                            <div id='div-gpt-ad-1676587896442-0' style={{minWidth: "728px", minHeight: "90px"}} />
                        </div>

                        <div className="search-title-header-ad-smartphone_leaderboard2">
                            {/* {<!-- /22874318077/Teuko-Boutique-Smartphone_AdUnit_2-Leaderboard-320x50 */}
                            <div id='div-gpt-ad-1676588104910-0' style={{minWidth: "320px", minHeight: "50px"}} />
                        </div> 
                      </div>

                      
                      <Element
                        className="boutique-group"
                        id="ThemesAtoZ"
                        key="ThemesAtoZ"
                        name="ThemesAtoZ"
                      >
                        <div className="boutique-header-bar">
                          <h2 id="ThemesAtoC"
                        key="ThemesAtoC"
                        name="ThemesAtoC">Themes A-C</h2>
                        </div>

                        <div className="boutique-group-items">

                          <a
                            className="boutique-item"
                            key="AmericanVibes"
                            target="_blank"
                            href="https://amzn.to/3A9Cg3w"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_AmericanVibes.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">American Vibes</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="AprilFoolsDay"
                            target="_blank"
                            href="https://amzn.to/3wawk99"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_AprilFoolsDay.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">April Fools Day</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Amongus"
                            target="_blank"
                            href="https://amzn.to/3JZJnQN"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_AmongUs.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Among Us</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Barbie"
                            target="_blank"
                            href="https://amzn.to/46rHSon"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Barbie.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Barbie</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="BBQSeason"
                            target="_blank"
                            href="https://amzn.to/3w9GfvV"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_BBQSeason.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">BBQ Season</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Bear"
                            target="_blank"
                            href="https://amzn.to/3ppMgR9"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Bear.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Bear</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Birthday"
                            target="_blank"
                            href="https://amzn.to/3CfRaYJ"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Birthday.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Birthday</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Bunny"
                            target="_blank"
                            href="https://amzn.to/3weHDNL"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Bunny.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Bunny</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Butterfly"
                            target="_blank"
                            href="https://amzn.to/3dDSjiC"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Butterfly.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Butterfly</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Cars"
                            target="_blank"
                            href="https://amzn.to/3c0sicG"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Cars.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Cars</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Cats"
                            target="_blank"
                            href="https://amzn.to/3dFnpGn"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Cats.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Cats</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="ChineseNewYear"
                            target="_blank"
                            href="https://amzn.to/3CjRGVB"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_ChineseNewYear.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Chinese New Year</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Christmas"
                            target="_blank"
                            href="https://amzn.to/3dGkfT1"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_ChristmasHolidays.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Christmas / Holidays</h5>
                          </a>
                          <a
                            className="boutique-item"
                            key="Construction"
                            target="_blank"
                            href="https://amzn.to/3K3OG1x"
                            rel="noopener noreferrer"
                          >
                            <div
                              className="boutique-item-image"
                              style={{
                                backgroundImage: "url(/images/boutiquepage_themesAZ_Construction.jpg)",
                              }}
                            />
                            <h5 className="boutique-item-caption">Construction</h5>
                          </a>

                        </div>

                        <div className="search-google-ad-banner-type-leaderboard-centered">
                        <div className="search-title-header-ad-laptop_leaderboard">
                            {/* <!-- /22874318077/Teuko-Boutique_ThemesAZ-Laptop_AdUnit_2_Before_Section_DG-Leaderboard-728x90 --> */}
                            <div id='div-gpt-ad-1677115459576-0' style={{minWidth: "728px", minHeight: "90px"}} />
                        </div>

                        <div className="search-title-header-ad-smartphone_leaderboard2">
                            {/* <!-- /22874318077/Teuko-Boutique_ThemesAZ-Smartphone_AdUnit_2_Before_Section_DG-Leaderboard-320x50 --> */}
                            <div id='div-gpt-ad-1677115709301-0' style={{minWidth: "320px", minHeight: "50px"}} />
                        </div> 
                      </div>

                        <div className="boutique-header-bar">
                          <h2 id="ThemesDtoG"
                        key="ThemesDtoG"
                        name="ThemesDtoG">Themes D-G</h2>
                        </div>

                        <div className="boutique-group-items">
                          <a
                              className="boutique-item"
                              key="Dinosaurs"
                              target="_blank"
                              href="https://amzn.to/3dFnQR1"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Dinosaurs.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Dinosaurs</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Dogs"
                              target="_blank"
                              href="https://amzn.to/3AwFKPb"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Dogs.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Dogs</h5>
                            </a>
                            
                            <a
                              className="boutique-item"
                              key="Dolphins"
                              target="_blank"
                              href="https://amzn.to/3K4oKmd"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Dolphins.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Dolphins</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Elmo"
                              target="_blank"
                              href="https://amzn.to/3wg9YDj"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Elmo.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Elmo / Sesame Street</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Emoji"
                              target="_blank"
                              href="https://amzn.to/3QzwzCZ"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Emoji.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Emoji</h5>
                            </a>
                            
                            <a
                              className="boutique-item"
                              key="Fairies"
                              target="_blank"
                              href="https://amzn.to/3AsL6uv"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Fairies.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Fairies</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="FallThanksgiving"
                              target="_blank"
                              href="https://amzn.to/3c6O3Yu"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_FallThanksgiving.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Fall / Thanksgiving</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Flamingo"
                              target="_blank"
                              href="https://amzn.to/3AvDmb6"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Flamingo.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Flamingo</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Fortnite"
                              target="_blank"
                              href="https://amzn.to/3T0M7Bx"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Fortnite.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Fortnite</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Fox"
                              target="_blank"
                              href="https://amzn.to/3ArS5nG"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Fox.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Fox</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Gabbysdollhouse"
                              target="_blank"
                              href="https://amzn.to/3PWmmTj"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Gabbysdollhouse.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Gabby's Dollhouse</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Graduation"
                              target="_blank"
                              href="https://amzn.to/3QC81cs"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Graduation.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Graduation</h5>
                            </a>

                        </div>

                        <div className="search-google-ad-banner-type-leaderboard-centered">
                            <div className="search-title-header-ad-laptop_leaderboard">
                                {/* <!-- /22874318077/Teuko-Boutique_ThemesAZ-Laptop_AdUnit_3_Before_Section_HO-Leaderboard-728x90 --> */}
                                <div id='div-gpt-ad-1677276949293-0' style={{minWidth: "728px", minHeight: "90px"}} />
                            </div>
                            <div className="search-title-header-ad-smartphone_leaderboard2">
                                {/* <!-- /22874318077/Teuko-Boutique_ThemesAZ-Smartphone_AdUnit_3_Before_Section_HO-Leaderboard-320x50 --> */}
                                <div id='div-gpt-ad-1677277225534-0' style={{minWidth: "320px", minHeight: "50px"}} />
                            </div> 
                        </div>

                        <div className="boutique-header-bar">
                          <h2 id="ThemesHtoO"
                        key="ThemesHtoO"
                        name="ThemesHtoO">Themes H-O</h2>
                        </div>

                        <div className="boutique-group-items">
                            <a
                              className="boutique-item"
                              key="Halloween"
                              target="_blank"
                              href="https://amzn.to/3K5cBNV"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Halloween.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Halloween</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="HarryPotter"
                              target="_blank"
                              href="https://amzn.to/3SWABHa"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_HarryPotter.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Harry Potter</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="HelloKitty"
                              target="_blank"
                              href="https://amzn.to/3dsrgqg"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_HelloKitty.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Hello Kitty</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Horse"
                              target="_blank"
                              href="https://amzn.to/3A4rj3p"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Horse.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Horse</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Llamas"
                              target="_blank"
                              href="https://amzn.to/3QvLluH"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Llamas.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Llamas</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="MardiGrasCarnival"
                              target="_blank"
                              href="https://amzn.to/3pr6SZp"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_MardiGrasCarnival.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Mardi Gras / Carnival</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Mermaids"
                              target="_blank"
                              href="https://amzn.to/3bZX7yc"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Mermaids.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Mermaids</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="MickeyMinnie"
                              target="_blank"
                              href="https://amzn.to/3C9TRLr"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_MickeyMinnie.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Mickey & Minnie</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Minecraft"
                              target="_blank"
                              href="https://amzn.to/3QBkxcu"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Minecraft.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Minecraft</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Music"
                              target="_blank"
                              href="https://amzn.to/3c3f4fm"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Musique.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Musique</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="MyHeroAcademia"
                              target="_blank"
                              href="https://amzn.to/468HsTX"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_MyHeroAcademia.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">My Hero Academia</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="MyLittlePony"
                              target="_blank"
                              href="https://amzn.to/3Qzjqtw"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_MyLittlePony.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">My Little Pony</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Onepiece"
                              target="_blank"
                              href="https://amzn.to/3tdtPV6"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Onepiece.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">One Piece</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Owl"
                              target="_blank"
                              href="https://amzn.to/3R9f5xp"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Owl.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Owl</h5>
                            </a>

                        </div>

                    

                        <div className="boutique-header-bar">
                          <h2 id="ThemesPtoR"
                        key="ThemesPtoR"
                        name="ThemesPtoR">Themes P-R</h2>
                        </div>

                        <div className="boutique-group-items">
                            <a
                              className="boutique-item"
                              key="Panda"
                              target="_blank"
                              href="https://amzn.to/3Avqa66"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Panda.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Panda</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="PawPatrol"
                              target="_blank"
                              href="https://amzn.to/3waXyNe"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_PawPatrol.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Paw Patrol</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Peppa Pig"
                              target="_blank"
                              href="https://amzn.to/3CiajJK"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_PeppaPig.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Peppa Pig</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Pirates"
                              target="_blank"
                              href="https://amzn.to/3STuvaF"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Pirates.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Pirates</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Planes"
                              target="_blank"
                              href="https://amzn.to/3QuN6YS"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Planes.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Planes</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Princess"
                              target="_blank"
                              href="https://amzn.to/3QB8A6i"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Princess.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Princess</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Rainbows"
                              target="_blank"
                              href="https://amzn.to/3dGeO6z"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Rainbows.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Rainbows</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Robots"
                              target="_blank"
                              href="https://amzn.to/3dFKd9c"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Robots.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Robots</h5>
                            </a>
                        </div>

                    

                        <div className="boutique-header-bar">
                          <h2 id="ThemesStoZ"
                        key="ThemesStoZ"
                        name="ThemesStoZ">Themes S-Z</h2>
                        </div>

                        <div className="boutique-group-items">
                          <a
                              className="boutique-item"
                              key="Sharks"
                              target="_blank"
                              href="https://amzn.to/3dB3B7c"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Sharks.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Sharks</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Sloth"
                              target="_blank"
                              href="https://amzn.to/3QQP3i7"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Sloth.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Sloth</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Sonic"
                              target="_blank"
                              href="https://amzn.to/3T24YMk"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Sonic.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Sonic</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Space"
                              target="_blank"
                              href="https://amzn.to/3PsFwN6"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Space.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Space</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Spiderman"
                              target="_blank"
                              href="https://amzn.to/3K24mlY"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Spiderman.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Spiderman</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Sports"
                              target="_blank"
                              href="https://amzn.to/3CgfrOv"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Sports.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Sports</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Spring"
                              target="_blank"
                              href="https://amzn.to/3TcOQYL"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Spring.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Spring</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="StPatricksDay"
                              target="_blank"
                              href="https://amzn.to/3QTnPYd"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_StPatricksday.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">St Patrick's Day</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="StarWars"
                              target="_blank"
                              href="https://amzn.to/3wexuAx"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_StarWars.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Star Wars</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="SuperMario"
                              target="_blank"
                              href="https://amzn.to/3Aa43Rg"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_SuperMario.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Super Mario</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Superheroes"
                              target="_blank"
                              href="https://amzn.to/3QswYHn"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Superheroes.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Superheroes</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Trains"
                              target="_blank"
                              href="https://amzn.to/3STyXpT"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Trains.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Trains</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Trolls"
                              target="_blank"
                              href="https://amzn.to/46vztjS"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Trolls.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Trolls</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="Unicorns"
                              target="_blank"
                              href="https://amzn.to/3dywRLH"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Unicorns.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Unicorns</h5>
                            </a>
                            <a
                              className="boutique-item"
                              key="ValentinesDay"
                              target="_blank"
                              href="https://amzn.to/3QO1Od6"
                              rel="noopener noreferrer"
                            >
                              <div
                                className="boutique-item-image"
                                style={{
                                  backgroundImage: "url(/images/boutiquepage_themesAZ_Valentinesday.jpg)",
                                }}
                              />
                              <h5 className="boutique-item-caption">Valentine's Day</h5>
                            </a>
                        </div>
                       

                      </Element> 


                    </div>
                  </div>

              </div>

            
     

          <div className="boutique-visit-amz-section">
            <a
              href="https://amzn.to/3nRksn3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h2>
                See our entire selection of lunch accessories in our Amazon Store!
              </h2>
            </a>
          
              <a href="https://amzn.to/38VE9DN" 
                 target="_blank"
                 rel="noopener noreferrer"
                 className="standard-button">
                  Visit Teuko Amazon Store
              </a>
            
          </div>

          { user.id !== 0 ? (
            <div/>
          ):(      
          <div className="xoegz02">
            <div className="ab8q">
              <div className="x78zum5">

              <div className="xjbqb8wlogo">
                <img
                  src="/images/teuko logo no background square 400x400.png"
                  className="xjbqb8wlogoimage"
                  alt="Teuko lunchbox community kids bento lunch ideas logo no background"
                />
              </div>
              <div className="acps">
                <div className="x9f619">
                  <div className="aacl">
                    Log into Teuko
                  </div>
                </div>
                <div className="aadf">
                  {/* Log in to discover and keep lunchbox ideas you'll love. */}
                  Log in to discover lunchbox ideas you'll love and keep them in your account.
                </div>
              </div>
              <div>
                <Link to="/login" className="full-menu-option-outheader-login-bannerbottom">
                  Log In
                </Link>
              </div>
              <div>
                <Link to="/signup" className="full-menu-option-outheader-signup-bannerbottom">
                  Sign Up
                </Link>
              </div>
               
            </div> 
          </div>
        </div>
        )}



        </div>
      </Fragment>
    );
  }
}

export default withApollo(BoutiquePageThemesAZ);
