import React, { Component } from "react";
import { Link } from "react-router";
import { slide as Menu } from "react-burger-menu";
import PropTypes from "prop-types";
import CreateLunch from "../create/CreateLunch";
import FaSearch from "../../fa/search";
import onClickOutside from "react-onclickoutside";
import FaUser from "../../fa/user";
import FaCog from "../../fa/cog";
import FaTrophy from "../../fa/trophy";
import FaQuestionCircle from "../../fa/question-circle";
import FaPowerOff from "../../fa/power-off";
import FaPlusSquare from "../../fa/plus-square";
import NotificationDropDown from "./NotificationDropDown";

class InHeader extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      mobileOpen: false,
      createLunch: false,
      mobileSearchOpen: false,
      dropdownOpen: false,
    };
  }
  handleClickOutside() {
    this.setState({ dropdownOpen: false });
  }
  logout(e) {
    e.preventDefault();
    const myInit = {
      method: "get",
      headers: {
        authorization: localStorage.getItem("sessionID") || null,
      },
    };
    fetch(`/logout`, myInit)
      .then((res) => {
        localStorage.removeItem("sessionID");
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
      });
  }
  render() {
    const { mobileOpen, createLunch, dropdownOpen } = this.state;
    const { user, location } = this.props;
    const { pathname } = location;
    /* eslint jsx-a11y/anchor-is-valid: 0 */
    return (
      <div className="main-header">
        {createLunch ? (
          <CreateLunch
            userId={user.id}
            closePopup={() => this.setState({ createLunch: false })}
          />
        ) : (
          ""
        )}
        <header className="in-header header-standard">
          <div className="options-left">
            <Link to="/">
              <img src="/images/teukologo.png" alt="Teuko Logo Lunchbox" />
            </Link>
            <Link
              to="/feed"
              className={
                pathname === "/browse" || pathname === "/"
                  ? "active-header-option"
                  : ""
              }
            >
              Browse
            </Link>
            <Link
              to="/mylunches"
              className={
                pathname.includes("mylunches") ? "active-header-option" : ""
              }
            >
              My Lunches
            </Link>

            <Link
              to="/blog"
              className={
                pathname.includes("blog") ? "active-header-option" : ""
              }
            >
              Blog
            </Link>
            
            <Link
              to="/boutique"
              className={
                pathname.includes("boutique") ? "active-header-option" : ""
              }
            >
              Shop
            </Link>

            
            {/* <Link
              to={`/user/${user.user_name}/trophies`}
              className={
                pathname.includes("trophies") ? "active-header-option" : ""
              }
            >
              Trophies
            </Link> */}
            <Link
              to={`/challenges`}
              className={
                pathname.includes("challenges") ? "active-header-option" : ""
              }
            >
              Challenges
            </Link>
          </div>
          <div className="options-right">
            <Link to="/search">
              <FaSearch className="mobile-search-icon" />
            </Link>
            <NotificationDropDown smartphone={false} />

            <div
              className="header-profile-picture"
              style={{
                backgroundImage: user.image ? `url(${user.image})` : "",
              }}
              onClick={() => this.setState({ dropdownOpen: !dropdownOpen })}
            >
              {dropdownOpen ? (
                <div className="header-dropdown">
                  <Link to={`/user/${user.user_name}`}><FaUser /> My Profile</Link>
                  <Link to={`/user/${user.user_name}/trophies`}
                    className={
                      pathname.includes("trophies") ? "active-header-option" : ""
                    }
                  ><FaTrophy /> My Trophies
                  </Link>
                  <Link to="/settings"><FaCog /> Settings</Link>
                  <Link to="#" onClick={this.logout}><FaPowerOff /> Logout
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <a
              href="#"
              className="full-menu-option filled-menu-option"
              onClick={() => this.setState({ createLunch: true })}
            >
              Upload Lunchbox
            </a>
          </div>
        </header>
        {/* <div className="in-mobile-menu mobile-menu"> */}
        <div className="mobile-menu">
          <Link to="/" className="mobile-company-logo">
            <img src="/images/teukologo.png" alt="Teuko Logo Lunchbox" />
          </Link>
          {/* <Link to="/" className="mobile-company-logo">
            <img src="/images/teukoglyph.png" alt="Teuko Logo Lunchbox" />
          </Link> */}
          
          {/* <Menu
            right
            isOpen={mobileOpen}
            onClick={() => this.setState({ mobileOpen: !mobileOpen })}
          >
            <Link to={`/user/${user.user_name}`}>My Profile</Link>
            <Link to="/settings">Settings</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="#" onClick={this.logout}>
              Logout
            </Link>
          </Menu> */}
          <div className="mobile-right">
            {/* <Link to="/search">
              <FaSearch className="mobile-search-icon" />
            </Link> */}
            
            <NotificationDropDown smartphone={true} />

            <div
              className="header-profile-picture-smartphone"
              style={{
                backgroundImage: user.image ? `url(${user.image})` : ""
              }}
            >
              <Menu
              right
              isOpen={mobileOpen}
              onClick={() => this.setState({ mobileOpen: !mobileOpen })}
            >
              <Link to={`/${user.user_name}`}><FaUser /> My Profile</Link>
              <Link
                to={`/user/${user.user_name}/trophies`}
                className={
                  pathname.includes("trophies") ? "active-header-option" : ""
                }
               ><FaTrophy /> My Trophies
              </Link>
              <Link to="/settings"><FaCog /> Settings</Link>
              <Link to="/faq"><FaQuestionCircle /> FAQ</Link>
              <Link to="#" onClick={this.logout}><FaPowerOff /> Logout</Link>
              </Menu>
          </div>

          <a
              href="#"
              className="full-menu-option filled-menu-option"
              onClick={() => this.setState({ createLunch: true })}
            ><FaPlusSquare /><span className="span-btn-upload-smartphone">Upload</span>
            </a>
            {/* <a href="#" onClick={() => this.setState({ createLunch: true })}>
              <FaPlusSquare />
            </a> */}

          </div>
        </div>
      </div>
    );
  }
}

InHeader.propTypes = {
  user: PropTypes.object,
};

export default onClickOutside(InHeader);
