import React, { Component } from "react";
import PropTypes from "prop-types";
import CreateLunchHeader from "./CreateLunchHeader.js";
import CreateLunchStepOne from "./CreateLunchStepOne.js";
import CreateLunchStepTwo from "./CreateLunchStepTwo.js";
import CreateLunchStepThree from "./CreateLunchStepThree.js";
import CreateLunchStepFour from "./CreateLunchStepFour.js";
import CreateLunchStepFive from "./CreateLunchStepFive.js";
import FaClose from "../../fa/close";
import gql from "graphql-tag";
import { graphql, compose, withApollo } from "react-apollo";
import { map, find } from "lodash";
import ImageCompressor from "image-compressor.js";
import FormData from "form-data";

class CreateLunch extends Component {
  constructor(props) {
    super(props);
    this.moveToStepTwo = this.moveToStepTwo.bind(this);
    this.completeStepTwo = this.completeStepTwo.bind(this);
    this.completeStepThree = this.completeStepThree.bind(this);
    this.completeStepFour = this.completeStepFour.bind(this);
    this.completeStepFive = this.completeStepFive.bind(this);
    this.state = {
      step: 1,
      invalidMenu: false,
      file: {},
      previewUrl: "",
      chosenFoods: [],
      foodStatus: {
        veggies: false,
        grains: false,
        protein: false,
        dairy: false,
        fruits: false,
        drink: false,
      },
      menu_user_id: 0,
      chosenThemes: [],
      chosenAccessories: [],
      title: "",
      link: "",
      notes: "",
      slug: props.params ? props.params["slug"] : "CREATE",
    };
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
    document.body.style.width = "100%";
    document.body.style.height = "100%";
    if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      document.body.style.position = "fixed";
    }
  }
  componentDidUpdate(prevProps) {
    if (this.state.slug === "CREATE") {
      return;
    }

    const { loadLunch } = this.props;
    if (!loadLunch.loading && prevProps.loadLunch.loading) {
      const oneLunch = loadLunch.oneLunch;
      if (oneLunch) {
        this.setState({
          chosenFoods: oneLunch.foods,
          menu_user_id: oneLunch.user_id,
          foodStatus: {
            veggies: Boolean(find(oneLunch.foods, { veggies: true })),
            grains: Boolean(find(oneLunch.foods, { grains: true })),
            protein: Boolean(find(oneLunch.foods, { protein: true })),
            dairy: Boolean(find(oneLunch.foods, { dairy: true })),
            fruits: Boolean(find(oneLunch.foods, { fruits: true })),
            drink: Boolean(find(oneLunch.foods, { drink: true })),
          },
          file: {
            preview: oneLunch.image,
          },
          notes: oneLunch.notes,
          link: oneLunch.link,
          title: oneLunch.title,
          chosenThemes: oneLunch.themes,
          chosenAccessories: oneLunch.accessories,
        });
      } else {
        console.log("$$$ componentDidUpdate set invalid menu");
        this.setState({ invalidMenu: true });
      }
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
    document.body.style.width = "unset";
    document.body.style.height = "unset";
    document.body.style.position = "unset";
  }
  moveToStepTwo(file, type) {
    if (file) {
      this.setState({ file, type, step: 2 });
    }
  }
  completeStepTwo(chosenFoods, foodStatus, step = 3) {
    this.setState({ chosenFoods, foodStatus, step });
  }
  completeStepThree(chosenThemes, step = 4) {
    this.setState({ chosenThemes, step });
  }
  completeStepFour(chosenAccessories, step = 5) {
    this.setState({ chosenAccessories, step });
  }
  async completeStepFive(title, link, notes, back = false) {
    const self = this;
    if (back) this.setState({ title, notes, step: 4 });
    else {
      const { file, chosenFoods, chosenAccessories, chosenThemes, slug } = this.state;
      let filePromise = Promise.resolve(file.preview);
      if (file.lastModified) {
        const imageCompressor = new ImageCompressor();
        const cFile = await imageCompressor.compress(file, {
          checkOrientation: false,
          maxWidth: 1200,
        });
        const lowresFile = await imageCompressor.compress(file, {
          checkOrientation: false,
          maxWidth: 300,
        });

        let formData = new FormData();
        formData.append("file", cFile);
        formData.append("lowresfile", lowresFile);
        filePromise = fetch("/upload/img", {
          method: "POST",
          body: formData,
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.status === "OK") {
              return response.url;
            } else {
              throw Error("Img upload status: " + response.status);
            }
          });
      }

      filePromise
        .then((url) => {
          const req = {
            variables: {
              slug: slug,
              image: url,
              title: title,
              link: link,
              notes: notes,
              foods: map(chosenFoods, "id"),
              themes: map(chosenThemes, "id"),
              accessories: map(chosenAccessories, "id")
            },
          };
          self.props.mutate(req).then(({ data }) => {
            window.location.href = `/mylunches`;
          });
        })
        .catch((err) => console.log(err));
    }
  }
  render() {
    const {
      step,
      file,
      foodStatus,
      chosenFoods,
      chosenAccessories,
      chosenThemes,
      invalidMenu,
      menu_user_id,
      slug,
      title,
      link,
      notes,
      type,
    } = this.state;
    let stepComponent = "";
    switch (step) {
      case 1:
        stepComponent = (
          <CreateLunchStepOne
            finishStep={this.moveToStepTwo}
            file={file}
            type={type}
          />
        );
        break;
      case 2:
        stepComponent = (
          <CreateLunchStepTwo
            finishStep={this.completeStepTwo}
            file={file}
            foodStatus={foodStatus}
            chosenFoods={chosenFoods}
          />
        );
        break;
      case 3:
        stepComponent = (
          <CreateLunchStepThree
            file={file}
            finishStep={this.completeStepThree}
            title={title}
            link={link}
            notes={notes}
            chosenThemes={chosenThemes}
          />
        );
        break;
      case 4:
        stepComponent = (
          <CreateLunchStepFour
            file={file}
            finishStep={this.completeStepFour}
            chosenAccessories={chosenAccessories}
            title={title}
            link={link}
            notes={notes}
          />
        );
        break;
      case 5:
        stepComponent = (
          <CreateLunchStepFive
            file={file}
            finishStep={this.completeStepFive}
            title={title}
            link={link}
            notes={notes}
          />
        );
        break;
      default:
        console.log("Error unkonwn step: " + step);
    }
    if (slug !== "CREATE") {
      if (invalidMenu) {
        stepComponent = (
          <div className="create-lunch-step-one">
            <h2>Invalid Menu</h2>
            <div>Sorry, the menu '{slug}' doesn't exist in our database.</div>
            <div>
              <a href="/">Back to Teuko</a>
            </div>
          </div>
        );
      }
      let isAdmin = false;
      let isOwner = false;
      if (menu_user_id > 0 && this.props.user.id) {
        if (menu_user_id === this.props.user.id) isOwner = true;
        if ([1, 2, 17].includes(this.props.user.id)) isAdmin = true;
      }
      let accessOk = isOwner || isAdmin;
      if (!accessOk) {
        stepComponent = (
          <div className="create-lunch-step-one">
            <h2>Invalid Menu</h2>
            <div>Sorry, you don't have access to the menu '{slug}'.</div>
            <div>
              <a href="/">Back to Teuko</a>
            </div>
          </div>
        );
      }
    }
    return (
      <div className="screen-overlay">
        <div className="create-lunch-popup">
          <FaClose
            className="close-lunch"
            onClick={() => (window.location = "/")}
          />
          <CreateLunchHeader step={step} />
          <div className="create-lunch-body">{stepComponent}</div>
        </div>
      </div>
    );
  }
}

CreateLunch.propTypes = {
  userId: PropTypes.number,
  closePopup: PropTypes.func,
};

const createNewLunchOption = gql`
  mutation createLunch(
    $image: String!
    $title: String
    $link: String
    $notes: String
    $foods: [String]
    $themes: [String]
    $accessories: [String]
  ) {
    createLunch(
      image: $image
      title: $title
      link: $link
      notes: $notes
      foods: $foods
      themes: $themes
      accessories: $accessories
    ) {
      slug
    }
  }
`;

const editLunchOption = gql`
  mutation editLunch(
    $slug: String!
    $image: String!
    $title: String
    $link: String
    $notes: String
    $foods: [String]
    $themes: [String]
    $accessories: [String]
  ) {
    editLunch(
      slug: $slug
      image: $image
      title: $title
      link: $link
      notes: $notes
      foods: $foods
      themes: $themes
      accessories: $accessories
    ) {
      slug
    }
  }
`;

const loadLunch = gql`
  query oneLunch($slug: String!) {
    oneLunch(slug: $slug) {
      id
      user_id
      image
      title
      link
      notes
      foods {
        id
        name
        image
        veggies
        grains
        protein
        dairy
        fruits
        drink
        tags
        asin
        groceryCategory
        groceryLabel
      }
      themes {
        id
        name
        image
      }
      accessories {
        id
        name
        image
      }
    }
  }
`;

export default compose(
  withApollo,
  graphql(createNewLunchOption),
  graphql(editLunchOption),
  graphql(loadLunch, {
    name: "loadLunch",
    options: (props) => ({
      variables: { slug: props.params ? props.params["slug"] : "CREATE" },
      fetchPolicy: "network-only",
    }),
  })
)(CreateLunch);

// export default graphql(createNewLunchOption)(CreateLunch);
